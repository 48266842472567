<template>
	<div
		:id="'header-'+step.id+'Question'+question.id"
		:class="[
			'QuestionTitle',
			{active: step.id === $store.state.currentStep && question.id === $store.state.currentQuestion},
			{validated: answered},
		]"
		@click="$store.commit('setCurrentQuestion', question.id)"
	>
		
		{{ $t(question.extraHtml.label) }}
		<div class="recap limit-length" v-show="showSpoiler != false">

			<div v-if="question.type === 'MultipleImageRadioChoice' && question.toShow !== 'nombre'">
				<span
					v-for="(answer, answerIndex) in showSpoiler" :key="answerIndex"
				>
					<img v-if="answer" :src="answer.imageUrl" alt="Image">
				</span>
			</div>

			<div v-else-if="question.type === 'MultipleInputsBasic'">
				<span v-for="(answer, answerIndex) in showSpoiler" :key="answerIndex">
					{{ answer.value }}
					<span v-if="answerIndex < Object.keys(showSpoiler).length - 1">|</span>
				</span>
			</div>

			<div v-else-if="question.type === 'TextAreaField' && showSpoiler">
				<span >&#10004;</span>
			</div>

			<div v-else>
				{{ $t(showSpoiler) }}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'QuestionTitle',
	props: {
		step: {
			type: Object,
			default () {
				return {}
			}
		},
		question: {
			type: Object,
			default () {
				return {}
			}
		}
	},
	data () {
		return {}
	},
	computed: {
		showSpoiler () {
			let showSpoiler = false
			if (this.$store.state.answers[this.$store.state.currentStep]) {
				if (this.$store.state.answers[this.$store.state.currentStep][this.question.id]) {
					if (this.$store.state.answers[this.$store.state.currentStep][this.question.id][0]) {
						let label = this.$store.state.answers[this.$store.state.currentStep][this.question.id][0].label
						showSpoiler = label.length > 30 ? `${label.slice(0, 30)}...` : label	
					}
				}
			}

			if (this.question.type === 'MultipleImageRadioChoice') {
				if (!this.$store.state.answers[this.$store.state.currentStep]) return
				let answObj = this.$store.state.answers[this.$store.state.currentStep][this.question.id]
				if (!answObj) return showSpoiler
				Object.keys(answObj).forEach((answ) => {
					console.log(answObj[answ])
					let skip = false
					if (!skip) {
						if (answObj[answ].name === 'Sans Option' && this.question.toShow === 'nombre') {
							showSpoiler = 0
						} else if (this.question.toShow === 'nombre') {
							showSpoiler = Object.keys(answObj).length
						} else {
							showSpoiler = answObj
						}
						skip = true
					}
				})
			}

			if (this.question.type === 'MultipleInputsBasic') {
				if (!this.$store.state.answers[this.$store.state.currentStep]) return
				let answObj = this.$store.state.answers[this.$store.state.currentStep][this.question.id]
				if (!answObj) return showSpoiler
				showSpoiler = answObj
			}
			return showSpoiler
		},

		answered () {
			let answ = false
			if (this.$store.state.answers[this.step.id]) {
				if (this.$store.state.answers[this.step.id][this.question.id]) {
					if (this.$store.state.answers[this.step.id][this.question.id][0]) {
						if (this.$store.state.answers[this.step.id][this.question.id][0] !== null) {
							answ = true
							return answ
						}
					}
				}
			}
		}
	},
	methods: {
		strip: function (html) {
			let doc = new DOMParser().parseFromString(html, 'text/html');
			return doc.body.textContent || "";
		}
	}
}
</script>

<style lang="scss" scoped>
.QuestionTitle {
	background: #c4c4c5;
	color: white;
	font-family: 'Pommier Light';
	font-size: 18px;
	height: 45px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	position: relative;

	.recap {
		display: flex;
		gap: 2px;
		flex-direction: row;

		img {
			max-height: 32px;
		}
	}

	.recap {
		color: white;
		position: absolute;
		right: 10px;
	}

	&.active {
		background: white;
		border: 1px solid #004494;
		color: #004494;
	}

	&.validated {
		background: #004494;
		color: white;
		font-weight: 600;
	}
}
</style>
