<template>
    <div class="loader-container">
        <div class="loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <!--<img :src="srcLoader" alt="">-->

    </div>
</template>

<script>
    export default {
        name: "Loader",
        props:['srcLoader']
        
        
    }
</script>

<style scoped>
    .loader-container {
        width: 100vw;
        height: 100vh;

        position: fixed;
        top: 0;
        bottom: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        background: rgba(255, 255, 255, 0.9);

        z-index: 100;
    }

    .loader {
        display: inline-block;
        position: relative;
        width: 64px;
        height: 64px;
    }
    .loader div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 51px;
        height: 51px;
        margin: 6px;
        border: 6px solid #4c5252;
        border-radius: 50%;
        animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #4c5252 transparent transparent transparent;
    }
    .loader div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .loader div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .loader div:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes loader {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }



</style>