import _ from 'lodash'
import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import Home from './views/Home.vue'
import Constructor from './views/Constructor.vue'
import PreConstructor from './views/PreConstructor.vue'
import Projects from './views/Projects.vue'
import Presentation from "./views/Presentation.vue"
import GeneratePdf from "./views/GeneratePdf.vue"

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'home',
            component: PreConstructor,
            beforeEnter: async (to, from, next) => {
            if (from.params.step === "1" && !store.state.hitOtherProd) {
                    await store.dispatch('deleteProduct', store.state.currentProductKey);
                    return next();
                }
                return next();
            }
        },
        {
            path: '/presentation',
            name: 'presentation',
            component: Presentation
        },
        {
            path: '/mes-devis',
            name: 'projects',
            component: Projects,
            beforeEnter: (to, from, next) => {
            }
        },
        {
            path: '/preconstructor',
            name: 'preconstructor',
            component: PreConstructor,
            beforeEnter: async (to, from, next) => {

                if (from.params.step === "1" && !store.state.hitOtherProd) {
                    await store.dispatch('deleteProduct', store.state.currentProductKey);
                    return next();
                }
                return next();
            }

        },
        {
            path: '/constructor/:type/etape:step/:toRecap?',
            name: 'constructor',
            component: Constructor,
            beforeEnter: (to, from, next) => {
                store.dispatch('currentStep', to.params.step);
                if ( ( from.name === "home" || from.name === null )  && store.state.currentProjectId === 0) {
                    store.dispatch('resetState').then(() => {
                        next();
                    })
                }

                if (from.name === "preconstructor" && to.params.toRecap === undefined) {
                    store.dispatch('resetStateWithProduct', true);
                    store.dispatch('newProduct')
                }


                if (to.params.type !== store.state.productType) {
                    store.dispatch("setProductType", to.params.type)
                }

                const validSteps = _.clone(store.state.validSteps).reverse();
                const targetStep = parseInt(to.params.step);

                if (targetStep > 1 && to.params.toRecap === undefined) {
                    for (let i = targetStep - 1; i > 0; i--) {
                        if (validSteps.indexOf(String(i)) === -1) {
                            return next('/constructor/' + store.state.productType + '/etape1');
                        }
                    }
                }
                return next();
            }
        },
        {
            path: '/edit/:project_id',
            name: 'edit',
            beforeEnter: (to, from, next) => {
                store.dispatch('resetState').then(() => {
                    store.dispatch('loadSiteInfos', to.params.project_id).then(() => {
                        store.dispatch('getProject', to.params.project_id).then(() => {
                            store.dispatch('loadSiteInfos')
                        })
                    })
                })
            }

        },
        {
            path: '/nouveau-projet/:type',
            name: 'newproject',
            beforeEnter: (to, from, next) => {
                store.dispatch('resetState').then(() => {
                    store.dispatch('newProduct').then(() => {
                        return next('/constructor/' + to.params.type + '/etape1')
                    })
                });
            }
        },
		{
            path: '/generate-pdf/',
            name: 'generatepdf',
			component: GeneratePdf,
            beforeEnter: (to, from, next) => {
				return next()
			},
        },
    ]
})
