<template>
	<div class="generatepdf">
	</div>
</template>

<script>
import makePdf from "/src/lib/dataPreSend";
import axios from "axios";

export default {
	name: "GeneratePdf",
	mounted() {
		this.pdf()
	},
	methods: {
		//Async method is needed because we need to wait for the response of axios
		async pdf() {
			//Rebuild the store from the local storage
			this.$store.replaceState(JSON.parse(localStorage.store))
			//Ask the drupal api for the user info 
			await axios.get("https://www.pommier.eu/api/customer/get/info", {withCredentials: true}).then(response => (this.$store.state.userInfos = response.data)).catch(error => console.log(error))
			if (this.$store.state.userInfos.user_authorized == false)
			{
				window.location.replace("https://www.pommier.eu/customer/login?destination_external=" + encodeURIComponent(window.location))
			}
			//Make the pdf with the userHistory infos located in the localStorage
			makePdf.createPdf(JSON.parse(localStorage.userHistory))
			//Reset the family in order to reset the navbar
			this.$store.state.family = {}
			//Return to the main page
			this.$router.push("/")
		}
	}
}
</script>