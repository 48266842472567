<template>
  <div class="change-btn">
    <router-link v-if="showPrevBtn()" class="left" :class="prevStep().toString()" @click.native="prevBtnRecapPress"
                 tag="button" :to="{name:'constructor', params : {
                        type : $store.state.productType,
                        step : prevStep().toString()
                      }}">
      {{ $t("Retour") }}
    </router-link>
    <router-link v-if="showNextBtn() && !preventNextButton && $store.state.ready " class="right" @click.native="nextBtnPress"
                 tag="button" :to="{name:'constructor', params : {
                        type : $store.state.productType,
                        step : nextStep().toString()
                      }}">
      {{ $t(nextButtonTitle) }}
    </router-link>

    <p v-else class="right-text missing-response"><span v-if="!isRecap">{{ $t("Merci de bien vouloir répondre à toutes les questions pour pouvoir passer à l'étape suivante.") }}</span>
    </p>
    <!--        AJOUTER POUR CHAQUE FAMILLE LA FAMILLE ET LE NUMERO DE L'ETAPE DE MESURE-->
    <!--        APRES MESURE ON ACTUALISERA LE PRIX APRES CHAQUE CHOIX -->
    <p class="right-text"
       v-if="showNextBtn() && !preventNextButton && $store.state.productType === 'clo' && nextStep() > 3"><span>{{ $t("Le prix sera actualisé quand vous aurez cliqué sur Etape Suivante") }}</span>
    </p>

    <div v-if="
    $store.state.family.Prefix !== 'Cre'
    && $store.state.family.Prefix !== 'wih' && $store.state.ready
    " class="popup-valid"
         :class="{'show-nextStep-modal' : showNextBtn() && !preventNextButton && isBtnModalShowing && !stepHasBannedQuestionType() && !stepHasBeenPassed}">
      <div class="button-list">
        <p>{{ $t("Pour poursuivre votre configuration, merci de cliquer sur le bouton 'Etape suivante'") }}</p>
        <router-link v-if="showNextBtn() && !preventNextButton" class="right" @click.native="nextBtnPress"
          tag="button" :to="{name:'constructor', params : {
          type : $store.state.productType,
          step : nextStep().toString()
        }}">
          {{ $t(nextButtonTitle) }}
        </router-link>
        <br>
        <span class="cursor" @click="closeNextStepModal()">{{ $t("Modifier mes réponses") }}</span>
      </div>
    </div>
  </div>
</template>


<script>
import stepDependenciesAreMet from "../lib/stepDependenciesAreMet";
//let htmlToImage = require("html-to-image");
//let htmlToJson = require("html-to-json");

export default {
  name: "ChangeStep",
  mixins: [history],

  props: {
    step: Number,
    maxNumberOfSteps: Number
  },
  data() {
    return {
      backTo: this.step - 1,
      isBtnModalShowing: true,
      stepHasBeenPassed: false,
      nextButtonTitle:'Étape suivante'
    }
  },
  watch: {
    $route(to, from) {
      if (from.path === to.path) {
        this.backTo--;
        this.$router.push({
          name: "constructor", params: {
            type: this.$store.productType,
            step: this.backTo,
          }
        })
      } else {
        this.backTo = this.step
      }
    },
    stepAnswers() {
      if (this.stepHasBeenPassed && this.step == this.$route.params.step) {
        this.stepHasBeenPassed = false;
        this.isBtnModalShowing = true
      }
    },


  },

  computed: {
    stepAnswers() {
      return this.$store.state.answers[this.step] || []
    },
    answers() {
      return this.$store.state.answers || [];
    },
    steps() {
      return this.$store.state.steps || [];
    },
    validSteps() {
      return this.$store.state.validSteps || [];
    },
    isLastBtn() {
      const step = parseInt(this.step);

      return step + 1 === this.maxNumberOfSteps
    },
    isRecap() {
      return parseInt(this.$route.params.step) === Object.keys(this.$store.state.steps).length
    },
    isPrevLastScreen() {
      const step = parseInt(this.step);

      return step === this.maxNumberOfSteps
    },
    preventNextButton() {
      return this.$store.state.preventNextButton
    },
    isBackCapLocked() {
      return this.$store.state.lockBackCap
    },
  },

  methods: {
    closeNextStepModal() {
      this.isBtnModalShowing = false
    },

    isNextStepHidden() {
      return !stepDependenciesAreMet(this.steps[parseInt(this.step) + 1], this.answers);
    },

    isPrevStepHidden(count = 1, prevStep = parseInt(this.step) - 1) {
      // const prevStep = parseInt(this.step) - 1;

      if (prevStep <= 0) {
        // return false;
        return count;
      }

      let value = (stepDependenciesAreMet(this.steps[prevStep], this.answers)) ? count : this.isPrevStepHidden(count + 1, prevStep - 1);
      if (value) {
        return value;
      }

      // return !stepDependenciesAreMet(this.steps[prevStep], this.answers);
    },

    nextStep() {


      return parseInt(this.step) + (this.isNextStepHidden() ? 2 : 1);
    },

    prevStep() {

      return parseInt(this.step) - this.isPrevStepHidden();

      // return parseInt(this.step) - (this.isPrevStepHidden() ? 2 : 1);
    },

    showNextBtn() {
      const step = parseInt(this.step);
      if (this.isLastBtn) {
        this.nextButtonTitle = "Finaliser mon projet"
      }
      return (step + 1 <= this.maxNumberOfSteps && this.validSteps.indexOf(String(step)) !== -1) || (this.steps[this.step] !== undefined && this.steps[this.step].noRequire !== undefined && this.steps[this.step].noRequire)
    },

    showPrevBtn() {

      return parseInt(this.step) - 1 !== 0 && !this.isBackCapLocked && !this.isRecap
    },
    nextBtnPress() {

      console.log('NEXT BTN PRESS')

      /*if ( this.$store.state.currentQuestion + 1 <= Object.keys(this.$store.state.steps[this.$store.state.currentStep].questions).length) {
         this.$store.commit('setCurrentQuestion', this.$store.state.currentQuestion + 1);
      }*/

      this.stepHasBeenPassed = true;
      this.isBtnModalShowing = false;
      /*if (this.isLastBtn) {
        this.$store.dispatch('calculatePrice')
      }*/
      $('html, body').css({
        scrollTop: 0
      });
    },
    prevBtnRecapPress() {
      $('html, body').css({
        scrollTop: 0
      });
      if (this.isPrevLastScreen) {
        this.$store.dispatch('saveProductPreviewNode', null)
        this.$store.dispatch('calculatePrice', null)
      }
    },

    stepHasBannedQuestionType() {
      const step = this.steps[this.step];
      let hasBannedQuestionType = false;
      Object.keys(step.questions).forEach((q) => {
        if (step.questions[q].type === "MultipleImageRadioChoice")
          hasBannedQuestionType = true
        if (step.questions[q].type === "MesureGoogleMap")
          hasBannedQuestionType = true
        if (step.questions[q].type === "MultipleQuantityChoice")
          hasBannedQuestionType = true
        if (step.questions[q].type === "MultipleImagesInputChoice")
          hasBannedQuestionType = true
        if (step.questions[q].type === "MultipleImagesInputGoogleMapChoice")
          hasBannedQuestionType = true
      })
      return hasBannedQuestionType
    }
  }
}
</script>
<style scoped>

.show-nextStep-modal {
  display: block !important;
  min-height: 250px;
  min-width: 400px;
}

.show-nextStep-modal .button-list {
  flex-direction: column;
}

.show-nextStep-modal .button-list .cursor {
  cursor: pointer;
}

.change-btn {
  width: 100%;
  margin-top: 3%;
  /* height: 22%; */
  display: inline-block;
  justify-content: space-between;
  margin-bottom: 100px;
}

.change-btn button,
.change-btn button:focus {
  border: none;
  box-shadow: none;
  cursor: pointer;
  padding: 10px 15px;
  color: #fff;
  z-index: 10;
  position: relative;
  outline: none;
}

.change-btn .left {
  background: #747878;
}

.left {
  float: left;
  margin-right: 2%;
  left: 1%;
}

.right {
  float: right;
  font-weight: 400;
  right: 1%;
}

.right-text {
  /* float: right; */
  /* margin: 1%; */
}

.right-text span {

  font-weight: bold;
  /*color: #c4c4c5;*/
  font-size: 18px;
}
@media screen and (max-width: 1220px){
  .right-text span {
    font-size: 14px;
  }
}
</style>
