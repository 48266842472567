export default (answers) => {
    const list = {};

    answers.forEach((answer) => {
        let variable = list[answer.variable];

        if (variable) {
            if (typeof variable !== 'object') {
                variable = [variable];
            }
            // TODO : Fix l'effet de bord
            if (answer.quantity === undefined && answer.lengthOfPerimeter === undefined)
                variable.push(answer.value);
            else {
                if (answer.quantity){

                    variable.push({
                        value: answer.value, quantity: answer.quantity
                    });
                } else{
                    variable.push({
                        value: answer.value, lengthOfPerimeter: answer.lengthOfPerimeter
                    });
                }
            }
        } else {
            if (answer.quantity === undefined && answer.lengthOfPerimeter === undefined)
                variable = answer.value;
            else {
                if (answer.quantity){

                    variable = [{
                        value: answer.value, quantity: answer.quantity
                    }];
                }else{
                    variable = [{
                        value: answer.value, lengthOfPerimeter: answer.lengthOfPerimeter
                    }];
                }
            }
        }

        list[answer.variable] = variable;

        if (answer.modifier !== undefined && answer.modifier !== null) {
            list['modifier'] = answer.modifier
        }
    });

    return list;
}
