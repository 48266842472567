import axios from "axios";
import store from "@/store";
import VueI18n from "vue-i18n";
import Vue from "vue";

Vue.use(VueI18n);
const i18n = new VueI18n({
    locale: navigator.language.split('-')[0] || 'fr',
    fallbackLocale: 'fr',
    messages : {}
})

export const loadLocaleMessages = async function(){
    const messages = await axios.get(store.state.apiUrl + '/traductions')
    Object.keys(messages.data).forEach((key) => {
        i18n.setLocaleMessage(key, messages.data[key]);
    })
}


export default i18n;

