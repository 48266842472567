<template>

    <div class="tra-viewer">
        <img :src="getIcon" id="icon-tra"/>
        <div class="color-holder color-choice">
            <div v-for="color in colors">
                <p>{{color.label}}</p>
                <span :style="{backgroundImage : color.background, backgroundColor : color.hex }"></span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {

        name: "tra",
        data: function () {
            return {
                state: '',
                icon: "",
                colors: [],
            }
        },
        computed: {
            apiUrl() {
                return this.$store.state.apiUrl
            },
            isViewerOpen() {
                return this.$store.state.isViewerOpen
            },
            viewerHtml() {
                return this.$store.state.viewerHtml;
            },
            steps() {
                return this.$store.state.steps;
            },
            color() {
                return [];
            },
            answers() {
                return this.$store.state.answers;
            },
            getIcon() {

                return this.icon;
            }
        },
        watch: {
            answers() {
                this.updatePreview();
            },
        },

        mounted() {
            this.updatePreview();
        },

        methods: {

            togglePov(state) {
                if (this.pov === state) {
                    return
                }

                this.pov = state;
            },

            updatePreview() {
                let $this = this;
                let runs = 3;
                let i = setInterval(function () {

                    if ($('.tra-viewer').length > 0) {
                        setTimeout(() => {
                            $this.launchPreviewUpdate()
                        }, 500);
                        clearInterval(i)
                    }
                }, 100);
            },
            launchPreviewUpdate() {
                Object.keys(this.answers).forEach((stepKey) => {
                    Object.keys(this.answers[stepKey]).forEach((questionKey) => {
                        Object.keys(this.answers[stepKey][questionKey]).forEach((answerKey) => {
                            this.updatePreviewForAnswer(this.answers[stepKey][questionKey][answerKey])
                        });
                    });
                });
            },
            updatePreviewForAnswer(answer) {

                if (!answer || !answer.effectOnSvg || answer.effectOnSvg === 'none') {
                    return;
                }

                this.state = answer.effectOnSvg;

                switch (this.state) {
                    case "change-icon" :
                        this.icon = answer.imageViewer;
                        break;

                    case  "change-coloris" :
                        this.colors = [];
                        this.colors.push({
                            label: "Coloris ou essence",
                            hex: answer.hex,
                            background: answer.background !== "" ? `url(${this.$store.state.apiUrl}/${answer.background})` : 'none'
                        });

                        break;
                }
                this.$forceUpdate();
            },
        }
    }
</script>


<style>

    .tra-viewer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }


    #icon-tra {
        /*max-height: 100px;*/
        /*width: auto;*/
        /*margin-bottom: 10px;*/
        width: 100%;
    }

    .color-holder {
        display: flex;
        justify-content: space-around;
        width: 80%;
        font-weight: bold;
    }

    .color-holder p {
        margin: 0;
        font-weight: bold;
    }

    .color-holder span {
        margin: auto;
        height: 25px;
        width: 25px;
        display: block;
        border-radius: 50%;
        -webkit-transition: 0.2s ease;
        transition: 0.2s ease;
        border: solid 1px grey;
        cursor: pointer;
        position: relative;
    }

    #icon-tra{
        max-width: 100%;
    }
</style>
