<template>
    <div class="card" v-bind:class="[question.type]">
        <!-- <div class="card-header" :id="'header-'+step+'Question'+question.id" data-toggle="collapse"
             :data-target="'#collapseStep'+step+'Question'+question.id"
             v-bind:aria-expanded="question.openByDefault ? 'true' : 'false'"
             aria-controls="collapseOne"
             :class="{'answered' : showSpoiler!==false}"
        >
            <h5 class="mb-0">
                <button class="btn btn-link">{{ $t(question.extraHtml.label) }}</button>
                <div class="recap" v-show="showSpoiler !== false">
                    <span >&#10004;</span>
                </div>
            </h5>
        </div> -->
        <div :id="'collapseStep'+step+'Question'+question.id" class="collapse"
             :class="{show : question.openByDefault}"
             :aria-labelledby="'collapseStep'+step+'Question'+question.id" data-parent="#main-form">
            <div class="extrahtml" v-if="question.extraHtml !== undefined && question.extraHtml.content_top !== null !== undefined && question.extraHtml.content_top !== null"
                 v-html="$t(question.extraHtml.content_top)"></div>

            <button v-if="question.extraHtml.label_comment_faire !== null && question.extraHtml.label_comment_faire !== undefined && question.extraHtml.label_comment_faire !== ''"
                    @click.stop="fillAndOpenGlobalModal(question.extraHtml)" type="button" class="open-modal btn-modal general-question"
                    data-toggle="modal"
                    :data-target="'#modal'+step+'-'+question.id">
                {{ $t(question.extraHtml.label_comment_faire) }}
            </button>
            <div class="card-body">
                <div class="checkbox-choice">
                    {{question.description}}

                    <label :class="{ full: answerValue }">
                        <input 
                        type="text" 
                        v-model="answerValue"
                        :placeholder="$t(question.label)" 
                        :minLength="question.minLength" 
                        :maxLength="question.maxLength">
                    </label>

                    <div v-if="question.extraHtml !== undefined && question.extraHtml.content_bottom !== null !== undefined && question.extraHtml.content_bottom !== null"
                         v-html="$t(question.extraHtml.content_bottom)"></div>
                </div>
               
            </div>
            <div class="change-btn" >
        
                <button @click="nextQuestion()" type="button"
                        class="open-modal next mt-2">
                  {{ $t("Question suivante") }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import common from './mixins/common'

    export default {
        name: "TextInput",

        props: {
            question: Object,
            questions: Object,
            step: [String, Number]
        },

        mixins: [common],

        data: function () {
            return {
                showSpoiler: false,
                answerValue: ''
            }
        },

        watch: {
            answerValue(val, oldVal) {
                let doAdd = false
                if (this.answers[this.step] && this.answers[this.step][this.question.id]) {
                    Object.keys(this.answers[this.step][this.question.id]).forEach((idx) => {
                        if ( val !== this.answers[this.step][this.question.id][idx].value) {
                            doAdd = true
                        }
                    });
                }else {
                    doAdd = true
                }

                if(doAdd) this.addToAnswers(val);
            }
        },
        mounted() {
            if (this.answers[this.step] && this.answers[this.step][this.question.id]) {
                Object.keys(this.answers[this.step][this.question.id]).forEach((idx) => {
                    this.answerValue = this.answers[this.step][this.question.id][idx].value
                    this.showSpoiler = this.answers[this.step][this.question.id][idx].value
                });
            }
        },

        methods: {
            nextQuestion() {
                const questionIndex = this.question.id;
                const question = this.questions[questionIndex];
                this.openNextQuestion(this.step, question.id);

            },
            fillAndOpenDefaultModal(answer) {
                let modal = $('#basicModal');
                modal.find('h5').text(answer.label);
                // modal.find('img').attr('src', answer.Popup);
                modal.find('.extra-popup').html(answer.Legende)
                modal.modal('show');

            },
            fillAndOpenGlobalModal(answer) {
                let modal = $('#basicModal');
                modal.find('h5').text(answer.label_comment_faire);
                modal.find('.extra-popup').html(answer.popup_comment_faire);
                modal.modal('show');

            },
            addToAnswers: function (answerValue) {
                const questionIndex = this.question.id;
                const question = this.questions[questionIndex];

                const answerIndex = 0;
                const answer = question.answers[answerIndex];

                // answer.isAnswer = true;
                let show = false
                if(question.minLength === undefined) {
                    show = answerValue != '' ? answerValue : false
                } else {
                    show = answerValue.toString().length >= question.minLength ? answerValue : false
                }

                this.showSpoiler = show

                this.$store.dispatch('addAnswersThenCalc', {
                    isGlobal : this.question.global !== undefined && this.question.global,

                    stepIndex: this.step,
                    questionIndex: questionIndex,
                    questionType: question.type,
                    effectOnSvg: question.effectOnSvg,
                    index: answerIndex,
                    label: answerValue,
                    value: answerValue,
                    variable: question.variable || null
                });
            },

            initialize() {
                Object.keys(this.questions[this.question.id].answers).forEach((answerKey) => {
                    const answer = this.questions[this.question.id].answers[answerKey];

                    if (answer.isAnswer) {
                        this.showSpoiler = answer.value;
                    }
                });
            },
        }
    }
</script>

<style scoped>
    @import "styles/text-field.css";

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    .checkbox-choice input{
        width:100%;
    }
    .next {
        border: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        cursor: pointer;
        padding: 10px 15px;
        color: #fff;
        z-index: 10;
        position: relative;
        outline: none;
    }
    @media screen and (min-width: 1023px) and (max-width: 1220px){
        .checkbox-choice input{
            font-size: 11px;
        }
    }
</style>
