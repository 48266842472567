export default {
    computed: {
        answers() {
            return this.$store.state.answers;
        }
    },

    mounted() {
        this.initialize();
    },

    methods: {
        openNextQuestion(stepId, questionId) {

            console.log(this.$store.state.steps[stepId].questions.length, stepId, questionId);

            if ( questionId + 1 <= this.$store.state.steps[stepId].questions.length) {
               this.$store.commit('setCurrentQuestion', questionId + 1); 
            }

            //this.$nextTick(() => {
                //debugger
                /*let next = $('#header-' + stepId + 'Question' + questionId).parents('.card-container')
                    .first()
                    .next();
                if (next.length != 0) {

                    //REPLI AUTOMATIQUE DES QUESTIONS
                    if (next.find('[data-toggle="collapse"]').length === 0){
                        $('#header-' + stepId + 'Question' + questionId).parents('.card-container')
                            .first().find('[data-toggle="collapse"]')
                            .click();
                    }else{
                        next.find('[data-toggle="collapse"]')
                            .click();
                    }

                } else {
                    $('#header-' + stepId + 'Question' + questionId).parents('.card-container')
                        .first().find('[data-toggle="collapse"]')
                        .click();

                }*/
            //});
        }
    }
}