<template>
	<div class="card" :class="[question.type,question.visibility, {opened: display}]">
		<!-- <div 
			class="card-header"
			:id="'header-'+step+'Question'+question.id"
			data-toggle="collapse"
			:data-target="'#collapseStep'+step+'Question'+question.id"
			v-bind:aria-expanded="question.openByDefault ? 'true' : 'false'"
			aria-controls="collapseOne"
			:class="{'answered' : showSpoiler!==false}"
		>
		<h5 class="mb-0">
			<button class="btn btn-link">
				{{ $t(question.extraHtml.label) }}
			</button>
			<div class="recap limit-length" v-show="showSpoiler != false">
				<span>{{ $t(strip(showSpoiler)) }}</span>
		 	</div>
		</h5>
		</div> -->
		<div
			:id="'collapseStep'+step+'Question'+question.id"
			class="collapse"
			v-bind:class="{show : true}"
			:aria-labelledby="'collapseStep'+step+'Question'+question.id" data-parent="#main-form"
		>
			<label v-if="question.placeholder">
				{{ question.placeholder }}
			</label>
			<div
				class="extrahtml"
				v-if=" question.extraHtml !== undefined &&
					question.extraHtml.content_top !== undefined &&
					question.extraHtml.content_top !== null"
			 		v-html="$t(question.extraHtml.content_top)"
			/>
			<div style="width: 100px; height: 100px; background-color: #ffffff; margin: 20px auto; border: 1px solid #004494;"/>
			<div
				class="extrahtml"
				v-if=" question.extraHtml !== undefined &&
					question.extraHtml.content_bottom !== undefined &&
					question.extraHtml.content_bottom !== null"
			 		v-html="$t(question.extraHtml.content_bottom)"
			/>
			<div class="card-body cards-height-wrapper">
				<div 
			   		v-bind:class="{ 'selected' : isSelected(answer) }"
					v-for="(answer, answerKey) in question.answers"
					v-bind:key="answerKey"
			   		v-on:click="addToAnswers(answerKey,isSelected(answer))"
			   		class="checkbox-choice-container"
			   		:data-size="colonneSize(question)"
				>
					<div class="checkbox-choice" v-html="$t(answer.label)" />
					<p 
						style="font-size:11px;color:#515151;padding:0;margin:0;"
			 			:data-target="answer.triggerAlert !== undefined && answer.triggerAlert ? '#question-alert-modal'+question.id : 'none'"
					>
						{{ $t(answer.subtitle) }}
					</p>
					<button
						v-if="answer.Popup !== null && answer.Legende !== null && answer.Popup !== undefined && answer.Legende !== undefined"
						@click.stop="fillAndOpenDefaultModal(answer)"
						type="button"
						class="open-modal btn-modal"
						data-toggle="modal"
						:data-target="'#modal'+step+'-'+question.id+'-'+answerKey"
					>
			 			{{ answer.BtnLegende === undefined ? "+ d'infos" : answer.BtnLegende }}
					</button>
		  		</div>
			</div>
		</div>
	</div>
  </template>
  
  <script>
  import common from './mixins/common'
  
  export default {
	name: "ColorComponent",
  
	props: {
	  question: Object,
	  questions: Object,
	  step: [String, Number],
	  opened: Boolean
	},
  
	mixins: [common],
  
	data: function () {
	  return {
		showSpoiler: false,
		alreadySet: false,
		display: null
	  }
	},
	mounted () {
		setTimeout(() => {
	      this.display = this.opened
	    }, 500)
	},
	watch: {
	  '$store.state.currentStep': function() {
		if (this.alreadySet) return
		console.log(this.question.answers[0].label)
		if (this.question.answers[0].label.indexOf('Confirmer') === -1) {
		  this.alreadySet = true
		  setTimeout(this.activeCollapse, 0);
		}
	  }
	},
	methods: {
	  strip: function (html) {
		let doc = new DOMParser().parseFromString(html, 'text/html');
		return doc.body.textContent || "";
	  },
	  isSelected(answer) {
  
		if (this.answers[this.step] && this.answers[this.step][this.question.id] !== undefined && this.answers[this.step][this.question.id][0] !== undefined) {
		  
		  return this.answers[this.step][this.question.id][0].value == answer.value;
		}
		let header = document.querySelector("#header-" + this.step + "Question" + this.question.id + "[aria-expanded = true]");
  
		//region AUTO SELECT IF ONLY ONE RESPONSE
		// if (header!==null){
		// console.log(header)
		let selectAuto = setTimeout(this.activeCollapse, 0);
  
		// }
  
		//endregion
		return false
	  },
	  activeCollapse() {
		let header = document.querySelector("#header-" + this.step + "Question" + this.question.id + "[aria-expanded = true]");
		let element = document.querySelector("#collapseStep" + this.step + "Question" + this.question.id + " .checkbox-choice");
  
		if (this.question.answers.length === 1 && header && element && this.question.autoSelect !== false) {
		  if (this.question.answers[0].label.indexOf('Confirmer') !== -1) return
		  console.log('CLICLI')
		  element.click();
		}
  
	  },
	  colonneSize(question) {
		let colonnes = (question.blocsAlign) ? question.blocsAlign : question.extraHtml.colonnes;
		let key = this.question.rang;
		if (Array.isArray(colonnes)) {
		  return colonnes[key];
		} else {
		  return colonnes;
		}
	  }
	  ,
	  fillAndOpenDefaultModal() {
  
		let modal = $('#basicModal');
		modal.find('h5').text('Comment prendre mes mesures');
		modal.find('img').attr('src', this.question.popup);
		modal.find('.extra-popup').html('')
		modal.modal('show');
  
	  },
	  fillAndOpenGlobalModal(answer) {
		let modal = $('#basicModal');
		modal.find('h5').text(answer.label_comment_faire);
		modal.find('.extra-popup').html(answer.popup_comment_faire);
		modal.modal('show');
  
	  },
	  addToAnswers: function (answerIndex, isSelected) {
		const questionIndex = this.question.id;
  
		Object.keys(this.questions[questionIndex].answers).forEach((key) => {
		  this.questions[questionIndex].answers[key].isAnswer = false;
		});
  
		const answer = this.questions[questionIndex].answers[answerIndex];
  
		answer.isAnswer = true;
		this.showSpoiler = answer.label;
		this.$store.dispatch('addAnswersThenCalc', {
		  isGlobal: this.question.global !== undefined && this.question.global,
		  name: this.questions[questionIndex].label,
		  stepIndex: this.step,
		  questionIndex: questionIndex,
		  questionType: this.questions[questionIndex].type,
		  effectOnSvg: this.questions[questionIndex].effectOnSvg,
		  index: 0,
		  label: answer.label,
		  imageViewer: answer.imageViewer,
		  value: answer.isAnswer ? answer.value : null,
		  variable: this.questions[questionIndex].variable || null,
		  changes: answer.changes !== undefined ? answer.changes : null,
		  step: this.questions[questionIndex].step
  
		});
		this.openNextQuestion(this.step, this.questions[questionIndex].id, this);
  
	  },
  
	  initialize() {
		if (this.answers[this.step] && this.answers[this.step][this.question.id]) {
		  const answers = this.answers[this.step][this.question.id];
  
		  Object.keys(answers).forEach((idx) => {
			answers[idx].isAnswer = true;
  
			this.showSpoiler = answers[idx].label || this.showSpoiler;
		  });
  
		  /*if (Object.keys(answers).length === 1) {
			let selectAuto = setTimeout(this.activeCollapse, 800);
		  }*/
		}
	  },
	}
  }
  </script>
  
  <style scoped>

  	.card {
	  max-height: 0;
	  transition: max-height 0.2s;
	  overflow: hidden;
	}

	.card.opened {
	  max-height: 800px;
	  transition: max-height 0.2s;
	}

  .card-body {
	justify-content: space-around;
  
  }
  
  .center-content {
	display: flex;
	justify-content: center;
	min-height: 100px;
  }
  
  .checkbox-choice-container {
	width: 20%;
  }
  
  .checkbox-choice {
	cursor: pointer;
	padding: 20px;
	border: 1px solid #ccc;
	margin-bottom: 10px;
	border-radius: 10px;
	width: 100%;
  }
  
  .checkbox-choice:hover {
	background-color: #fafafa;
  }
  .hidden{
	height: 0;
	opacity: 0;
  }
  .checkbox-choice.selected {
	cursor: default;
  }
  
  label {
	margin-top: 25px;
  }
  
  .checkbox-choice-container[data-size ='6'] {
	width: 15%;
  }
  
  .checkbox-choice-container[data-size ='4'] {
	width: 25%;
  }
  .checkbox-choice-container[data-size ='3'] {
	width: 30%;
  }
  .checkbox-choice-container[data-size = '2'] {
	width: 50%;
  }
  
  @media screen and (max-width: 767px) {
	.checkbox-choice {
	  margin-bottom: 30px;
	  width: 40%;
	}
  }
  
  @media screen and (max-width: 520px) {
	.checkbox-choice-container {
	  width: 40%;
	}
  
	.checkbox-choice {
	  width: 100%;
	}
  
	.checkbox-choice[data-v-4a02367d] {
	  padding: 5px;
	}
  
  }
  
  .recap.limit-length span {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	max-width: 200px;
	display: block;
  }
  
  @media screen and (max-width: 1220px){
	.card-body.cards-height-wrapper {
	  display: flex;
	  align-items: stretch;
	}
  
	.cards-height-wrapper .checkbox-choice-container {
	  margin-top: 10px;
	}
  
	.cards-height-wrapper .checkbox-choice {
	  height: 100%;
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  padding: 5px;
	}
  }
  
  @media screen and (max-width: 1024px) {
	h5 .recap {
	  display: none;
	}
  }
  
  .open-modal {
	margin-bottom: 20px;
	padding: 12px;
	/* background: #c4c4c4 !important; */
	color: #fff;
	border-radius:5px;
  }
  </style>
  