var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card",class:[_vm.question.type, {opened: _vm.display}]},[_c('div',{attrs:{"id":'collapseStep' + _vm.step + 'Question' + _vm.question.id}},[(_vm.question.showUnivers)?_c('div',{staticClass:"picto-univers"},[_c('img',{attrs:{"src":_vm.apiUrl+'/'+_vm.pictoUnivers,"alt":"Logo"}})]):_vm._e(),(
			_vm.question.extraHtml !== undefined &&
			_vm.question.extraHtml.content_top !== undefined &&
			_vm.question.extraHtml.content_top !== null
			)?_c('div',{staticClass:"extrahtml",domProps:{"innerHTML":_vm._s(_vm.$t(_vm.question.extraHtml.content_top))}}):_vm._e(),_c('div',{staticClass:"card-body"},_vm._l((_vm.question.answers),function(answer,answerKey){return _c('div',{key:answerKey,staticClass:"checkbox-choice",class:{ selected: _vm.isSelected(answer) },attrs:{"data-target":answer.triggerAlert !== undefined && answer.triggerAlert
				? '#question-alert-modal' + _vm.question.id
				: 'none',"data-permanent":_vm.isPermanent(),"data-size":_vm.colonneSize(_vm.question)},on:{"click":function($event){$event.stopPropagation();_vm.addToAnswers(answerKey, answer, _vm.isSelected(answer), $event)}}},[_c('div',{staticClass:"image-placeholder",attrs:{"data-tooltip":answer.text_info_bulle !== '' &&
					answer.text_info_bulle !== undefined
					? answer.text_info_bulle
					: false,"data-tooltip-position":"top","data-target":answer.triggerAlert !== undefined && answer.triggerAlert
					? '#question-alert-modal' + _vm.question.id
					: 'none'}},[_c('img',{class:[
						'img-' + _vm.step + '-' + _vm.question.id + '-' + answerKey,
						'img-' + _vm.step + '-' + _vm.question.id + '-' + _vm.question.variable ],style:({
						height:
						answer.height !== undefined ? answer.height + 'px' : 'none',
						}),attrs:{"src":answer.imageUrl,"alt":"","data-target":answer.triggerAlert !== undefined && answer.triggerAlert
						? '#question-alert-modal' + _vm.question.id
						: 'none'}})]),_c('p',{attrs:{"data-target":answer.triggerAlert !== undefined && answer.triggerAlert
					? '#question-alert-modal' + _vm.question.id
					: 'none'},domProps:{"innerHTML":_vm._s(_vm.$t(answer.label))}},[_vm._v(" "+_vm._s(_vm.$t(answer.label))+" ")]),_c('p',{staticStyle:{"font-size":"11px","color":"#515151","padding":"0","margin":"0"},attrs:{"data-target":answer.triggerAlert !== undefined && answer.triggerAlert
					? '#question-alert-modal' + _vm.question.id
					: 'none'},domProps:{"innerHTML":_vm._s(_vm.$t(answer.subtitle))}},[_vm._v(" "+_vm._s(_vm.$t(answer.subtitle))+" ")]),(
					answer.Popup &&
					answer.Legende &&
					answer.Popup !== null &&
					answer.Legende !== null &&
					answer.Popup !== undefined &&
					answer.Legende !== undefined
					)?_c('button',{staticClass:"open-modal btn-modal popup-info p-2",attrs:{"type":"button","data-toggle":"modal","data-target":'#modal' + _vm.step + '-' + _vm.question.id + '-' + answerKey},on:{"click":function($event){$event.stopPropagation();return _vm.fillAndOpenDefaultModal(answer)}}},[_vm._v(" "+_vm._s(answer.BtnLegende === undefined ? "en savoir plus" : answer.BtnLegende)+" ")]):_vm._e()])}),0),(
			_vm.question.extraHtml.content_bottom !== undefined &&
			_vm.question.extraHtml.content_bottom !== null
			)?_c('div',{staticClass:"contentBottom",domProps:{"innerHTML":_vm._s(_vm.$t(_vm.question.extraHtml.content_bottom))}}):_vm._e(),(
			_vm.$t(_vm.question.extraHtml.label_comment_faire) &&
			_vm.$t(_vm.question.extraHtml.label_comment_faire) !== undefined &&
			_vm.$t(_vm.question.extraHtml.label_comment_faire) !== null &&
			_vm.$t(_vm.question.extraHtml.label_comment_faire) !== ''
			)?_c('button',{staticClass:"open-modal btn-modal global-question ici p-3",attrs:{"type":"button","data-toggle":"modal","data-target":'#modal' + _vm.step + '-' + _vm.question.id},on:{"click":function($event){$event.stopPropagation();return _vm.fillAndOpenGlobalModal(_vm.question.extraHtml)}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.question.extraHtml.label_comment_faire))+" ")]):_vm._e()]),(_vm.question.hasAlerts !== undefined)?_c('div',{staticClass:"modal fade",attrs:{"data-target-close":'#question-alert-modal' + _vm.question.id,"id":'question-alert-modal' + _vm.question.id,"tabindex":"-1","role":"dialog","aria-hidden":"true"},on:{"click":function($event){$event.stopPropagation();return _vm.closeModal($event)}}},[_c('div',{staticClass:"modal-dialog",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.$t("A prendre en compte")))]),_vm._m(0)]),_c('div',{staticClass:"modal-body"},[_c('p',[_vm._v(_vm._s(_vm.question.alertMessage))])]),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button","data-dismiss":"modal","data-target-close":'#question-alert-modal' + _vm.question.id},on:{"click":function($event){$event.stopPropagation();return _vm.closeModal($event)}}},[_vm._v(" J'ai compris ")])])])])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])}]

export { render, staticRenderFns }