<template>
  <div class="card" v-bind:class="[question.type]">
    <!-- <div class="card-header" :id="'header-'+step+'Question'+question.id" data-toggle="collapse"
         :data-target="'#collapseStep'+step+'Question'+question.id"
         v-bind:aria-expanded="question.openByDefault ? 'true' : 'false'"
         aria-controls="collapseOne"
         :class="{'answered' : showSpoiler!==false}"
    >
      <h5 class="mb-0">
        <button class="btn btn-link">
          {{ $t(question.extraHtml.label) }}
        </button>
        <div class="recap" v-show="showSpoiler != false">
          {{ $t(showSpoiler) }}
        </div>
      </h5>
    </div> -->

    <div :id="'collapseStep'+step+'Question'+question.id" class="collapse"
         :class="{show : question.openByDefault}"
         :aria-labelledby="'collapseStep'+step+'Question'+question.id" data-parent="#main-form">
      <div
          v-if="question.extraHtml !== undefined && question.extraHtml.content_top !== null !== undefined && question.extraHtml.content_top !== null"
          v-html="$t(question.extraHtml.content_top)"></div>

      <button v-if="question.measureMap" @click="modal" class="col-md-3 p-2 mesurer ">
        <img
            src="img/google-screen.png" alt="">
        <p>{{ $t(question.extraHtml.label_comment_faire) }}</p>
      </button>
      <div v-else class="measure">
        <div class="measureInput">
          <div class="form-group">
            <input class="perimeterInput" type="number" id="longueur" v-model="longueur"
                   @keyup.enter=""/><label for="longueur">Longueur</label>
          </div>
          <div class="form-group">
            <input class="perimeterInput" type="number" id="largeur" v-model="largeur"
                   @keyup.enter=""/><label for="largeur">Largeur</label>
          </div>
          <button style="padding:10px 100px;text-transform:capitalize;"
                  @click="calculatePerimeter" class="validate-btn">Calculer
          </button>
        </div>
        <p>{{ $t(question.extraHtml.label_comment_faire) }}</p>

      </div>

      <div class="card-body card-perimeter">
        <div class="checkbox-choice">
          <label><strong>{{ $(questions[question.id].label) }} :</strong></label><br>
          <input class="perimeterInput" type="number" v-model="answerValue" @keyup.enter=""/>
          <div
              v-if="question.extraHtml !== undefined && question.extraHtml.content_bottom !== null !== undefined && question.extraHtml.content_bottom !== null"
              v-html="$t(question.extraHtml.content_bottom)"></div>
        </div>
      </div>


    </div>


    <!-- MODAL GOOGLE MAP-->

    <div v-if="this.open && question.measureMap" id="myModal" class="modalMap">
      <!-- Modal content -->
      <div class="modal-content-map" style="text-align:center;margin:auto">
        <span @click="modal" class="close">&times;</span>

        <div class="col-md-12" style="display:flex;justify-content: center;text-align: center;flex-wrap:wrap;">


          <!--SELECT CITY-->
          <div class="col-md-12">

            <GmapAutocomplete class="col-md-4 mb-4" @place_changed="setPlace" @keyup.enter="usePlace">
            </GmapAutocomplete>
          </div>
          <div class="col-md-4 map-text-col">
            <div class="col-md-12 consignes">
              <label><strong>{{ $t("A lire avant de commencer") }}</strong></label>
              <ol>
                <li>{{ $t("Saisissez l’adresse de votre terrain") }}</li>
                <li>{{ $t("Cliquez sur la carte pour créer votre tracé") }}</li>
                <li>{{ $t("Déplacez les points en restant appuyé dessus") }}</li>
                <li>{{ $t("Pour ajouter un autre tracé, cliquez sur le bouton +") }}</li>
                <li>{{ $t("Si besoin, supprimez un point en cliquant dessus") }}</li>
              </ol>
            </div>
            <div class="col-md-12 ">
              <div class="col-12 list-clot">
                <label><strong>{{ $t("Liste des surfaces concernées") }}</strong></label>
                <span @click="addSection" class="float-right add-section ">+</span>
              </div>

              <div class="col-md-12">
                <ul v-if="paths.length >0 ">
                  <li v-for="(path,index) in paths" @click="changeSelected(index)"
                      class="cloture-trace" v-bind:class="{active:select === index}">

                    <span class="float-left ">{{ $t("Tracé n°") }}{{index +1}} </span>
                    <span v-if="path.length>0"
                          v-html="calculateOne(path)"> </span>
                    <span v-else v-html="'0.00 m'"> </span>
                    <span class="float-right " @click="removeTarget(index)"><i
                        class="fas fa-trash"></i></span>


                  </li>
                </ul>
                <span v-else>{{ $t("Vous n'avez aucun tracé") }}</span>
              </div>
            </div>

          </div>
          <div class="col-md-8 gmap" style=";display:flex;justify-content: center;">

            <!-- IF PLACE EXIST-->
            <GmapMap class="col-md-12" style="height: 500px;" v-if="this.place" :zoom="17"
                     map-type-id='hybrid'
                     :center="{lat: this.place.geometry.location.lat(), lng:  this.place.geometry.location.lng()}"
                     @click="addPoint">


              <GmapPolygon v-if="select === index" :paths="paths" :editable="true"
                           @paths_changed="updateEdited($event)"
                           @click="removePoint($event,index)" v-for="(path, index) in paths"
                           v-bind:options="{strokeColor:'#000',draggableCursor: 'default'}"/>
              <GmapPolygon :paths="path" v-else :editable="true" @paths_changed="updateEdited($event)"
                           @click="removePoint($event,index)" v-for="(path, index) in paths"
                           v-bind:options="{strokeColor:'#808080'}"/>
            </GmapMap>

            <!--ELSE NOT PLACE EXIST-->
            <GmapMap class="col-md-12" style=" height: 500px;" v-else :zoom="5"
                     :center="{lat:48.864716,lng:2.349014}" map-type-id='hybrid'
                     @click="addPoint">

              <GmapPolygon v-if="select === index" :paths="paths" :editable="true"
                           @paths_changed="updateEdited($event)"
                           @click="removePoint($event,index)" v-for="(path, index) in paths"
                           v-bind:options="{strokeColor:'#000',draggableCursor: 'default'}"/>
              <GmapPolygon :paths="path" v-else :editable="true" @paths_changed="updateEdited($event)"
                           @click="removePoint($event,index)" v-for="(path, index) in paths"
                           v-bind:options="{strokeColor:'#808080'}"/>

            </GmapMap>
          </div>

          <div class="col-md-12 mt-4 valider-row">


            <div style="width:100%;text-align: center;display:flex;flex-wrap:wrap;justify-content: center;">
              <button style="padding:10px 100px;text-transform:capitalize;"
                      @click="calculatePath" class="validate-btn">{{ $t("Valider") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
    import common from './mixins/common'

    export default {
        name: "MesureGoogleMap",

        props: {
            question: Object,
            questions: Object,
            step: [String, Number]
        },

        mixins: [common],

        data() {

            return {
                open: false,
                paths: [],
                place: null,
                showSpoiler: false,
                answerValue: '',
                longueur: null,
                largeur: null,

                //TEST
                edited: null,
                select: null,

            }
        },

        watch: {
            answerValue(val, oldVal) {
                if (this.answers[this.step] && this.answers[this.step][this.question.id]) {
                    Object.keys(this.answers[this.step][this.question.id]).forEach((idx) => {
                        if (val && val !== this.answers[this.step][this.question.id][idx].value) {
                            this.addToAnswers(val);
                        }
                    });
                } else {
                    this.addToAnswers(val)
                }
            }
        },
        mounted() {
            if (this.answers[this.step] && this.answers[this.step][this.question.id]) {
                Object.keys(this.answers[this.step][this.question.id]).forEach((idx) => {
                    this.answerValue = this.answers[this.step][this.question.id][idx].value
                    this.showSpoiler = this.answers[this.step][this.question.id][idx].value
                });
            }
        },

        methods: {
            setPlace(place) {
                this.place = place
            },
            removeTarget(index) {
                this.paths.splice(index, 1);
                this.select = null;
            },

            updateEdited(mvcArray) {

                if (this.paths.length < 1 || (this.paths.length > 0 && this.select === this.paths.length - 1)) {
                    let paths = [];
                    for (let i = 0; i < mvcArray.getLength(); i++) {
                        let path = [];
                        for (let j = 0; j < mvcArray.getAt(i).getLength(); j++) {
                            let point = mvcArray.getAt(i).getAt(j);
                            path.push({lat: point.lat(), lng: point.lng()});
                        }
                        paths.push(path);
                    }
                    this.paths = paths
                }

            },
            addPoint(point) {
                //console.log('addpoint : '+this.select);
                if (this.select === null || this.paths.length === 0) {
                    this.select = (this.paths.length > 0) ? this.paths.length - 1 : this.paths.length;
                    this.addSection();
                }
                //console.log(this.select);

                this.paths[this.select].push({
                    lat: point.latLng.lat(),
                    lng: point.latLng.lng()
                })

            },
            calculatePerimeter() {

                let area = 0;
                let perimetre = 0;
                if (this.questions[this.question.id].measureMap) {
                    for (let i = 0; i < this.paths.length; i++) {
                        perimetre = this.calculatePerimeterOne(this.paths[i]);
                        area += perimetre;
                    }
                    area = area * 1.05;
                    this.answerValue = Math.round(area.toFixed(2));
                    this.modal()
                } else {
                    if (this.longueur != null && this.largeur != null) {
                        switch (this.questions[this.question.id].measureType) {
                            case 'surface':
                                let surface = this.longueur * this.largeur;
                                this.answerValue = Math.round(surface.toFixed(2));
                                break;
                            case 'perimetre':
                                perimetre = this.longueur * 2 + this.largeur * 2;
                                this.answerValue = Math.round(perimetre.toFixed(2));
                                break;
                        }
                    }
                }


            },
            calculatePath() {
                // console.log(this.questions[this.question.id].measureType)
                switch (this.questions[this.question.id].measureType) {
                    case 'surface':
                        return this.calculateArea();
                        break;
                    case 'perimetre':
                        return this.calculatePerimeter();
                        break;
                }
            },
            calculateOne(path) {
                switch (this.questions[this.question.id].measureType) {
                    case 'surface':
                        return this.calculateAreaOne(path).toFixed(2) + " m²";
                        break;
                    case 'perimetre':
                        return this.calculatePerimeterOne(path).toFixed(2) + ' m';
                        break;
                }
            }
            ,
            calculateArea() {
                let area = 0;

                let perimetre = 0;
                for (let i = 0; i < this.paths.length; i++) {
                    perimetre = this.calculateAreaOne(this.paths[i]);
                    area += perimetre;
                }
                this.answerValue = Math.round(area.toFixed(2));
                this.modal()

            },
            calculateAreaOne(path) {
                return this.geoArea(path);
            }
            ,
            calculatePerimeterOne(path) {

                let area = 0;

                for (let i = 0; i < path.length - 1; i++) {
                    area += this.distanceLatLng(path[i].lat, path[i].lng, path[i + 1].lat, path[i + 1].lng);
                }
                area += this.distanceLatLng(path[0].lat, path[0].lng, path[path.length - 1].lat, path[path.length - 1].lng);


                return area;
            }
            ,
            distanceLatLng(lat1, lon1, lat2, lon2) {
                let dist = 0;
                if ((lat1 === lat2) && (lon1 === lon2)) {
                    return 0;
                } else {
                    let radlat1 = Math.PI * lat1 / 180;
                    let radlat2 = Math.PI * lat2 / 180;
                    let theta = lon1 - lon2;
                    let radtheta = Math.PI * theta / 180;
                    dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

                    if (dist > 1) {
                        dist = 1;
                    }

                    dist = Math.acos(dist);
                    dist = dist * 180 / Math.PI;
                    dist = dist * 60 * 1.1515;

                    //TRANSFORM TO METER
                    dist = dist * 1.609344 * 1000;
                }
                return dist;
            },
            removePoint($event, index) {

                if (this.select === null) {
                    this.select = this.paths.length - 1
                }

                if (this.select === index) {
                    this.paths[this.select].splice($event.vertex, 1)

                }

                if (this.paths[this.select].length == 0 && this.select != 0) {
                    this.paths.splice(this.select, 1);
                }


            },
            addSection() {
                //console.log('addsection :'+this.select)
                this.paths.push([]);

                this.select = this.paths.length - 1;
                //console.log(this.select)

            },
            changeSelected(index) {
                this.select = index;
            }
            ,
            modal() {
                this.open = !this.open;
            },
            addToAnswers: function (answerValue) {
                const questionIndex = this.question.id;
                const question = this.questions[questionIndex];

                const answerIndex = 0;
                const answer = question.answers[answerIndex];

                answer.isAnswer = true;

                this.showSpoiler = answerValue;

                this.$store.dispatch('addAnswersThenCalc', {
                    isGlobal: this.question.global !== undefined && this.question.global,

                    stepIndex: this.step,
                    questionIndex: questionIndex,
                    questionType: question.type,
                    effectOnSvg: question.effectOnSvg,
                    index: answerIndex,
                    label: answerValue,
                    value: answerValue,
                    variable: question.variable || null
                });
            },
            fillAndOpenGlobalModal(answer) {
                let modal = $('#basicModal');
                modal.find('h5').text(answer.label_comment_faire);
                modal.find('.extra-popup').html(answer.popup_comment_faire);
                modal.modal('show');

            },
            fillAndOpenDefaultModal(answer) {
                let modal = $('#basicModal');
                modal.find('h5').text(answer.label);
                // modal.find('img').attr('src', answer.Popup);
                modal.find('.extra-popup').html(answer.Legende)
                modal.modal('show');

            },
            rad(coord) {
                return (coord * Math.PI) / 180;
            },
            ringArea(points) {
                const RADIUS = 6378137;
                const x = 'lng';
                const y = 'lat'
                let p1;
                let p2;
                let p3;
                let lowerIndex;
                let middleIndex;
                let upperIndex;
                let area = 0;

                if (points.length > 2) {
                    for (let i = 0; i < points.length; i++) {
                        if (i === points.length - 2) {
                            lowerIndex = points.length - 2;
                            middleIndex = points.length - 1;
                            upperIndex = 0;
                        } else if (i === points.length - 1) {
                            lowerIndex = points.length - 1;
                            middleIndex = 0;
                            upperIndex = 1;
                        } else {
                            lowerIndex = i;
                            middleIndex = i + 1;
                            upperIndex = i + 2;
                        }
                        p1 = points[lowerIndex];
                        p2 = points[middleIndex];
                        p3 = points[upperIndex];
                        area += (this.rad(p3[x]) - this.rad(p1[x])) * Math.sin(this.rad(p2[y]));
                    }
                    area = (area * RADIUS * RADIUS) / 2;
                }

                return area;
            },
            geoArea(coords) {
                let area = 0;
                if (coords && coords.length > 0) {
                    area += Math.abs(this.ringArea(coords));
                }
                return area;
            },

            initialize() {
                Object.keys(this.questions[this.question.id].answers).forEach((answerKey) => {
                    const answer = this.questions[this.question.id].answers[answerKey];

                    if (answer.isAnswer) {
                        this.showSpoiler = answer.value;
                    }
                });
            },
        }

    }

</script>

<style>

  .gmap,
  .gmap * {
    cursor: default !important;
  }

  .measure .measureInput {
    max-width: 50%;
    margin: 0 auto;
  }


  .measure .form-group {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .global-question {

  }


  .list-clot {
    margin: 20px 0;
  }

  .list-clot label {
    margin: 0;
  }

  .cloture-trace {
    cursor: pointer;
    text-align: center;
    margin: 10px 0;
    box-shadow: 0px 1px 5px 2px #808080;
    padding: 20px;
  }

  .cloture-trace.active {
    background: #D8B36C;
    color: #ffffff;
  }

  .add-section {
    padding: 1px 6px;
    box-shadow: 0px 1px 5px 2px #808080;
    font-weight: bold;
    color: #010101;
    cursor: pointer;
  }

  .add-section:hover {
    box-shadow: 0px 1px 5px 2px #808080;
    font-weight: bold;
    color: #fff;
    background: #D8B36C;
  }

  .consignes label {
    text-align: initial;
    font-size: 16px;
  }

  .consignes li {
    text-align: initial;
    font-size: 14px;
  }

  .mesurer {
    border: 1px solid #0e0e0e;
    align-items: center;
    display: inline-flex;
    transition: .3s;
  }

  .mesurer:hover {
    cursor: pointer;
    filter: brightness(80%);
  }

  .mesurer img {
    float: left;
  }

  .mesurer p {
    width: 100%;
    margin: 0;
    text-align: center;
  }

  .perimeterInput {
    text-align: center;
    border: 1px solid #0e0e0e;
  }

  .validate-btn {
    border: 1px solid #0e0e0e;
    font-size: 20px;
    transition: .3s;
  }

  .validate-btn:hover {
    cursor: pointer;
    filter: brightness(80%);
  }

  .modalMap {
    position: fixed; /* Stay in place */
    padding: 20px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    z-index: 1000;
    text-align: center;

  }

  /* Modal Content */
  .modal-content-map {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    display: inline-block;
    width: 100%;

  }

  .card .collapse {
   /* padding: 20px;*/
  }

  /* The Close Button */
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  .checkbox-choice input {
    width: auto;
  }

  .gm-style div > div:last-child > div > div > div > div > div:hover, .gm-style div > div:last-child > div > div > div > div:hover {
    cursor: initial !important;
  }

  @media screen and (min-width: 575px) and (max-width: 1220px) {
    .mesurer {
      max-width: 50%;
      flex-wrap: wrap;
      justify-content: center;
    }

    .mesurer p {
      margin-top: 5px;
    }

    .card-perimeter .checkbox-choice {
      width: 70%;
    }

    .card-perimeter .checkbox-choice p {
      margin-top: 1rem;
    }

    .map-text-col div {
      padding: 0;
    }
	  .map-text-col ul {
		  padding: 0;
	  }

	  .cloture-trace {
	  padding: 15px 10px;
	  }
	  .valider-row {
		  margin-top: 0.8rem;
	  }

	  .modal-content-map .close:not(:disabled):not(.disabled) {
		  position: absolute;
		  right: 8%;
	  }

    .map-text-col .list-clot {
      margin: 20px 0 35px;
    }

    @media screen and (min-height: 1000px) {
      .map-text-col {
        width: 85%;
        max-width: 85%;
        flex: 0 0 85%;
        padding: 0;
      }

      .gmap.col-md-8 {
        width: 85%;
        max-width: 85%;
        flex: 0 0 85%;
      }
    }
  }

</style>

