<template>
  <div class="final-recap" style="display: block !important">
    <div style="display: none">{{ history }}</div>
    <div class="recap-content">
      <div class="recap-header text-left">
        <h4>{{ $t("Récapitulatif de votre projet") }}</h4>
      </div>
      <div class="recap-inner">
        <div
          v-for="(product, product_index) of products.filter(
            (prod) => !prod.deleted
          )"
          v-if="!product.deleted"
          :key="product_index"
          class="history-wrapper"
          :id="product_index"
        >
          <div
            class="history-header"
            v-if="product.history !== undefined && product.history.length > 1"
          >
            <h5 class="d-flex align-items-center">
              {{ $t(getTitle(product.history)) }}
            </h5>
            <h5 v-if="pricesByProds[product_index]">
              PU :
              {{
                product.price
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")
              }}&nbsp;€&nbsp;{{ $t("HT") }}
            </h5>

            <div class="controls">
              <span
                v-if="
                  family.image_recap_delais &&
                    $store.state.family.Prefix !== 'Bae'
                "
                @click="fillAndOpenDefaultModal(family.image_recap_delais)"
                >{{ $t("Délais") }}</span
              >
              <span
                v-if="family.image_recap_garanties"
                @click="fillAndOpenDefaultModal(family.image_recap_garanties)"
                >{{ $t("Garanties") }}</span
              >
              <span
                @click.stop="
                  openGlobalModal(
                    $t(
                      'Toute modification peut entrainer des changements techniques obligeant à sélectionner à nouveau les caractéristiques du produit'
                    ),
                    editProduct,
                    product_index
                  )
                "
                >{{ $t("Modifier") }}</span
              >
              <span
                @click.stop="
                  openGlobalModal(
                    'Êtes-vous sûr de vouloir supprimer cet élément ?',
                    deleteProduct,
                    product_index
                  )
                "
                >{{ $t("Supprimer") }}</span
              >
            </div>
          </div>
          <div
            v-if="products.length > 1"
            class="history-content-wrapper"
            :class="{
              active:
                getRefsHistory(product, product_index).length > 3 ||
                product.ref.length <= 3,
            }"
          >
              <p style="color: red; margin-bottom: 0.75rem;"
                  v-if="product.ref.length > 1
                  && $store.state.family.Prefix === 'Bae'">
                  {{ $t("veuillez_cliquer_voir_plus") }}
              </p>
            <span
              class="history-content"
              v-for="(ref, index) in getRefsHistory(product, product_index)"
              :key="ref.ref + index"
            >
              <label><span v-if="ref.quant">{{ref.quant}}x </span>{{ $t(ref.label) }}</label
              ><label v-if="ref.quantity">{{
                " x " + ref.quantity + "&nbsp;:&nbsp;"
              }}</label>
              <span class="history-comma"> {{ ref.ref }}&nbsp;</span>
              <a
                v-if="$store.state.family.Prefix === 'Bae'
                || $store.state.family.Prefix === 'tra'
                || $store.state.family.Prefix === 'Cre'
                "
                target="_blank"
                style="position: absolute;right:10px;"
                :href="
                  seeMoreGenerateTranslatedUrl(ref.label)
                "
                >{{ $t("Voir plus") }}</a
              >
            </span>
            <div
              class="toggle_details"
              v-if="product.ref.length > 3"
              @click.stop="togglebyIndex(product_index)"
            >
              <span class="arrow">>></span>
            </div>
          </div>
          <div
            v-else
            class="history-content-wrapper"
            :class="{
              active: getRefsHistory(product, product_index).length > 3,
            }"
          >
              <p style="color: red; margin-bottom: 0.75rem;"
                 v-if="product.ref.length > 1
                 && $store.state.family.Prefix === 'Bae'">
                  {{ $t("veuillez_cliquer_voir_plus") }}
              </p>
            <span
              class="history-content"
              v-for="(ref, index) in getRefsHistory(product, product_index)"
              :key="index"
            >
              <label><span v-if="ref.quant">{{ref.quant}}x </span>{{ ref.label }}</label>
              <label
                class="history-content--quantity"
                v-if="ref.quantity !== undefined"
                >{{ " x " + ref.quantity + "&nbsp;:&nbsp;" }}</label
              >
              <span class="history-comma"> {{ ref.ref }} </span>
              <a
                v-if="$store.state.family.Prefix === 'Bae'
                || $store.state.family.Prefix === 'tra'
                || $store.state.family.Prefix === 'Cre'
                "
                target="_blank"
                style="position: absolute;right:10px;"
                :href="
                  seeMoreGenerateTranslatedUrl(ref.ref)
                "
                >{{ $t("Voir plus") }}</a
              >
            </span>
            <div
              v-if="getRefsHistory(product, product_index).length > 1"
              class="toggle_details"
              @click.stop="togglebyIndex(product_index)"
            >
              <span class="arrow">>></span>
            </div>
          </div>
        </div>
        <!-- TODO : Remettre les moda si applicable -->
        <div class="history-logo ">
          <img src="img/LOGO-Ecustomer.png" alt="" />
        </div>
        <div class="history-wrapper">
          <div class="history-header" style="display:block;">
            {{ $t("Rendez-vous dans votre espace client sur") }}
            <a target="_blank" :href="$t('espace-client')">www.pommier.eu</a>
            {{ $t("pour finaliser votre commande :") }}<br />
            {{
              $t("- Consultez vos tarifs et la disponibilité des références")
            }}
            <br />
            {{
              $t(
                "- Passez une précommande sur le site ou contacter votre interlocuteur POMMIER"
              )
            }}<br />
          </div>
        </div>
        <a class="btn btn_type3 " href="https://www.pommier.eu/customer">{{
          $t("Consultez vos autres projets")
        }}</a>

        <div
          class="col-md-12 col-sm-12S col-xs-10 row bandeau"
          v-if="siteInfos.bandeau"
        >
          <div class="row" v-html="siteInfos.bandeau"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import history from "./mixins/history";
import Loader from "./Loader";

export default {
  name: "Recapitulatif",
  components: { Loader },
  props: {
    question: Object,
    questions: Object,
    step: [String, Number],
  },
  mixins: [history],
  data() {
    return {
      preventSave: null,
      showConfirm: false,
      // refs:null,
      projectName: this.$store.state.currentProjectName,
      order: false,
      priceByTva: {},
      totalReduction: 0,
      reductions: {},
      toggledProducts: [],
    };
  },
  watch: {
    projectName: function(newVal, oldVal) {
      this.$store.dispatch("setProjectName", newVal);
      // this.getRefs();
    },
    refs() {
      return this.$store.state.refs;
    },
  },
  computed: {
    refs() {
      return this.$store.state.refs;
    },
    pricesByProds() {
      return this.$store.state.priceByProds;
    },
    totalPrice() {
      let total = 0;
      Object.keys(this.products).forEach((key) => {
        if (!this.products[key].deleted) {
          let montantTva =
            this.products[key].price.toFixed(2) /
            (1 + this.products[key].tvaPercent / 100);
          let reduction = this.$store.state.reduction[
            this.products[key].productType
          ];

          montantTva = montantTva - (montantTva * reduction) / 100;
          total +=
            montantTva + (montantTva * this.products[key].tvaPercent) / 100;
        }
      });
      // this.getRefs();

      return total;
    },

    totalPriceHt() {
      let total = 0;
      this.priceByTva = {};
      this.totalReduction = 0;
      this.reductions = {};

      Object.keys(this.products).forEach((key) => {
        if (!this.products[key].deleted) {
          let montantTva =
            this.products[key].price.toFixed(2) /
            (1 + this.products[key].tvaPercent / 100);
          let reduction = this.$store.state.reduction[
            this.products[key].productType
          ];

          let reductionForProduct;
          total += montantTva - (montantTva * reduction) / 100;
          reductionForProduct = (montantTva * reduction) / 100;

          this.reductions[reduction] = this.reduction || 0;
          this.reductions[reduction] += reductionForProduct;

          this.totalReduction += reductionForProduct;
          if (
            this.priceByTva[this.products[key].tvaPercent.toString()] ===
            undefined
          ) {
            this.priceByTva[this.products[key].tvaPercent.toString()] = 0;
          }
          this.priceByTva[this.products[key].tvaPercent.toString()] +=
            ((montantTva - reductionForProduct) *
              this.products[key].tvaPercent) /
            100;
        }
      });
      // this.getRefs();

      return total;
    },

    tvaMode() {
      return Object.keys(this.products).length === 1
        ? this.$store.state.tvaMode
        : "TTC";
    },
    products() {
      // this.getRefs();

      return this.$store.state.products;
    },

    globalAnswers() {
      // this.getRefs();

      let globals = this.$store.state.globalAnswers
        .filter((item) => item.variable !== "other_prod")
        .map((item) => {
          return {
            label: item.name,
            value: item.label.replace(/(&nbsp;|<([^>]+)>)/gi, ""),
          };
        });

      globals.unshift({
        label: "Pays",
        value: this.$store.state.country,
      });

      if (this.$store.state.departement.departement !== "") {
        globals.unshift({
          label: "Département",
          value: this.$store.state.departement.departement,
        });
      }

      return globals;
    },
    apiUrl() {
      return this.$store.state.apiUrl;
    },
    siteInfos() {
      return this.$store.state.siteInfos;
    },
    family() {
      return this.$store.state.family;
    },
    reference() {
      return this.$store.state.formule;
    },
  },
  created() {
    window.onbeforeunload = function(event) {
      // Cancel the event as stated by the standard.
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = "";
    };
    // this.getRefs();
  },
  methods: {
    getRefs() {
      this.refs = this.$store.state.refs;
    },
    getTitle(history) {
      let title =
        this.$store.state.family["Prefix"] !== "clo"
          ? history.find((el) => el.label === "Modele")
          : history.find((el) => el.label === "Sous modele");
      return title ? title.value : this.$store.state.family["Nom"];
    },
    openGlobalModal(text, confirmFunc, arg) {
      const modal = $("#global-confirm-modal");
      modal.find("p").text(text);
      modal.find("#confirm-action").on("click", (e) => {
        e.preventDefault();
        modal.find("p").text("");
        modal.find("#confirm-action").off();
        modal.hide();
        confirmFunc(arg);
      });
      modal.show();
    },

    fillAndOpenDefaultModal(imageUrl) {
      imageUrl = `${this.apiUrl}/${imageUrl}`;
      let modal = $("#basicModal");
      modal.find("h5").text("");
      modal.find("img").attr("src", imageUrl);
      modal.find(".extra-popup").html("");
      modal.modal("show");
    },
    changeQty(product_index, value) {
      this.$store.dispatch("changeProductAnswerProperty", {
        product_index,
        variable: "quantite",
        value,
      });
    },
    togglebyIndex(index) {
      if (!this.toggledProducts.includes(index))
        this.toggledProducts.push(index);
      else this.toggledProducts.splice(this.toggledProducts.indexOf(index), 1);
    },
    getProductHistory(product, product_index) {
      let filtered = product.history.filter((el) => !el.isGlobal);
      if (!this.toggledProducts.includes(product_index))
        filtered = filtered.slice(0, 3);

      return filtered;
    },
    getRefsHistory(product, product_index) {
      let filtered = product.ref.length > 0 ? product.ref : this.refs;

      if (!this.toggledProducts.includes(product_index))
        filtered = filtered.slice(0, 3);

      return filtered;
    },

    recapImageUrl(product) {
      let famille = this.$store.state.families.find((e) => {
        return e.Prefix === product.productType;
      });
      if (famille) return `${this.$store.state.apiUrl}/${famille.image_recap}`;

      return "";
    },
    editProduct(key) {
      this.$store.dispatch("loadProduct", key);
    },
    deleteProduct(key) {
      this.$store.dispatch("deleteProduct", key).then((whereTo) => {
        if (whereTo === "goHome") {
          this.$router.push("/preconstructor");
        }
      });
    },

    productQty(history) {
      let qty = history.find((res) => res.label === "Quantité");
      if (qty) return parseInt(qty.value);
      else return "";
    },
    precision_recap(productType) {
      let famille = this.$store.state.families.find((e) => {
        return e.Prefix === productType;
      });
      if (famille) return famille.precision_recap;

      return null;
    },

    seeMoreGenerateTranslatedUrl(label) {
      let url = ''
      switch(this.$store.state.lang) {
        case 'en':
          url = 'https://www.pommier.eu/en/all-our-products?search='
          break;
        case 'pl':
          url = 'https://www.pommier.eu/pl/nasze-produkty?poszukiwanie='
          break;
        case 'it':
          url = 'https://www.pommier.eu/it/i-nostri-prodotti?ricerca='
          break;
        case 'es':
          url = 'https://www.pommier.eu/es/nuestros-productos?buscar='
          break;
        case 'de':
          url = 'https://www.pommier.eu/de/unsere-produkte?suchen='
          break;
        default:
          url = 'https://www.pommier.eu/tous-nos-produits?recherche='
          break;
      }
      url += label

      return url
    }
  },
};
</script>

<style scoped>
.history-content--quantity {
  margin-left: 5px;
}

.toggle_details {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  background: linear-gradient(white, #ebebeb);
  transition: 0.3s;
  padding: 5px;
  cursor: pointer;
}
.active {
  margin-bottom: 10px;
}

.active .toggle_details {
  background: #ebebeb;
}

.toggle_details .arrow {
  font-weight: bold;
  letter-spacing: -5px;
  display: block;
  transform: rotate(90deg);
  transition: 0.3s;
}

.active .toggle_details .arrow {
  transform: rotate(-90deg);
}

.history-logo {
  text-align: left;
  width: 200px;
  margin-bottom: 10px;
}
.history-logo img {
  width: 100%;
}
.row {
  margin-left: 0% !important;
}

.qty-changer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.qty-changer i {
  font-size: 24px;
  cursor: pointer;
  transition: all 0.2s linear;
  min-height: 50%;
}

.wrapped {
  max-height: 0;
}

.bandeau {
  padding: 25px 10px;
  width: auto;
}

.img-picto {
  width: 13%;
  margin-right: 10px;
}

.col-md-4 {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

h3 {
  font-size: 1.163rem;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  line-height: initial;
}

.recap-inner {
  padding: 20px;
  background: #ffffff;
  overflow-y: auto;
}

.recap-header {
  width: 100%;
  background: #f5f4f4;
  padding: 20px 60px;
}

.history-wrapper {
  text-align: left;
  border: solid 2px #f5f4f4;
  margin-bottom: 30px;
  position: relative;
  transition: 0.3s;
}

.history-header {
  width: 100%;
  background: #f5f4f4;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.history-header h5 {
  margin: 0;
  font-size: 16px;
}

.history-header span:not(.qty-changer) {
  margin-left: 20px;
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.history-content-wrapper {
  padding: 20px;
}

h4 {
  margin: 0;
  font-weight: bold;
}

ol {
  text-align: left;
}

.no-effects,
.no-effects:hover,
.no-effects:focus {
  color: #ffffff;
  text-decoration: none;
}

.choice img {
  width: 50px;
}

.choice {
  margin-right: 0 !important;
  justify-content: space-around;
  min-width: 275px;

  font-weight: bold;
  font-size: 1.3em;
}

.history-content {
  width: auto;
  display: block;
}

.history-content label {
  font-weight: bold;
}

.history-content-ref {
  width: auto;
  display: block;
}

.history-content-ref label {
  font-weight: bold;
}

.price-container {
  justify-content: center;
  display: flex;
  margin-bottom: 60px;
}

.line-price {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 70%;
  font-size: 18px;
  padding-bottom: 10px;
  margin-top: 15px;
  border-bottom: none;
  justify-content: flex-end;
}

.card-container {
  height: 100%;
}

.recap-content {
  /* height: 65vh; */
  width: 100%;
  overflow: auto;
}

.recap-btn {
  height: 10%;
  padding: 10px;
  display: flex;
  justify-content: space-around;
}

.recap-btn button {
  background: rgba(101, 169, 169, 1);
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
  padding: 10px 15px;
  color: #fff;
}

.circle i {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

span > i {
  font-style: normal;
}

.img_pvc_product {
  width: 50%;
}

@media screen and (min-width: 1024px) and (max-width: 1400px) {
  .popup-valid {
    width: 60%;
  }

  .choice {
    max-width: 200px;
    min-width: 200px;
  }

  .choice > p {
    font-size: 16px;
    /*width: 60%;*/
  }

  .choice img {
    width: 40px;
  }
}

@media screen and (max-width: 1400px) {
  .history-content-wrapper {
    display: flex;
    flex-direction: column;
  }
  .history-header h5 {
    font-size: 14px;
  }
  .history-header {
    padding: 8px 5px;
  }
  .history-header span:not(.qty-changer) {
    margin-left: 8px;
    font-size: 12px;
  }
  .history-header .controls {
    display: flex;
    /*width: 50%;*/
    text-align: center;
  }
}

@media screen and (max-width: 1220px) {
  .history-header h5 {
    font-size: 12px;
  }
  .order-now {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 1023px) {
  .recap-btn {
    flex-wrap: wrap;
    align-items: center;
  }

  .choice {
    max-width: 45%;
    min-width: 45%;
    margin-bottom: 20px;
    height: 125px;
  }

  .choice > p {
    width: 100%;
  }

  .choice-img {
    display: none;
  }

  .line-price {
    justify-content: center;
  }
  .recap-header {
    text-align: center !important;
  }
}

@media screen and (max-width: 1023px) {
  .choice img {
    display: none;
  }

  .popup-valid {
    position: fixed;
    width: 80%;
    top: 100%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    margin: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .choice p {
    margin-right: 8%;
    margin-top: -3%;
  }
}

@media screen and (max-width: 520px) {
  .choice img {
    display: none;
  }

  .choice p {
    font-size: 16px;
    margin-top: -10px;
  }
}

@media screen and (max-width: 1200px) {
  .img_pvc_product {
    display: none;
  }
}
</style>
