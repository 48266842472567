<template>
	<div id="app" :class="$store.state.productType">
		<MainNav :title="$store.state.family['Nom']"></MainNav>

		<router-view/>

		<Loader v-if="isLoading" srcLoader="./img/loader.gif"></Loader>
		<div v-html="outPutStyle"></div>
		<footer>
			{{ $t('Réalisation') }} :
			<a target="_blank" href="https://industrie.wiboo.fr/configurateur-de-produits-sur-mesure/">{{ $t('wiboo_label') }}</a>
		</footer>
	</div>
</template>
<script>
import MainNav from "./components/MainNav";
import Loader from "./components/Loader";

export default {
  components: {Loader, MainNav},
  component: {
	MainNav
  },
  computed: {
	isLoading() {
	  return this.$store.state.isLoaderShowing
	},
	analyticsScript() {
	  return this.$store.state.siteInfos.analyticsScript
	},
	outPutStyle() {


	  return `
<style>
/*.ImageRadioChoice p{
 color: ${this.familyColor};
}*/
/*INLINE HTML*/
.TextRadioChoice .checkbox-choice.selected,.checkbox-choice-container.selected .checkbox-choice{
background: ${this.siteColor};
color:#fff;
}
.TextRadioChoice .checkbox-choice{
	border:1px solid ${this.siteColor};
	color:${this.siteColor};
	border-radius:10px;
}

.open-modal{
   border:none;
   background:${this.siteColor};
}

	.global-question:hover,.popup-info:hover{
	cursor:pointer;
		border: 1px solid ${this.siteColor};
	}

	.choice::before {
		background: ${this.siteColor}
	}

	.btn_type1,
	.btn_type1:hover {
		background: ${this.siteColor}
	}

	.slider:before {
		background-color: ${this.siteColor}
	}
	.validate-btn{
			background-color: ${this.siteColor}

	}


	button{
	  border-radius:10px;

	}
	.change-btn button,
	.change-btn button:focus {
		background-color: ${this.siteColor}
	}

	.constructor-header a.selected {
		background: ${this.siteColor}
	}

	.constructor-header a.selected::after {
		background: ${this.siteColor}
	}

	.popup-valid .button-list .btn.main {
		background: ${this.siteColor};
		color:white;
	}

	.popup-valid {
		border: 15px solid ${this.siteColor}
	}

	.controls i:hover {
		color: ${this.siteColor}
	}

	.card .selected img, .card .selectedAuto {
		border: solid 6px ${this.siteColor};
	}

	.mobile-nav-viewer .rounded-icon {
		background-color: ${this.siteColor};
	}

	.popup-valid.confirm-popup .modal-footer .main {
		background: ${this.siteColor};
	}

	.qty-changer i:hover,
	.qty-changer i:focus {
		color: ${this.siteColor}
	}

	.bandeau {
		background: ${this.siteColor};
	}

	a.constructorNavLinks.selected {
		background: ${this.siteColor} !important;
	}

	a.constructorNavLinks.selected::after {
		background: ${this.siteColor} !important;
	}

	.home-link,
	.home-link:hover,
	.home-link:focus {
		color: ${this.siteColor};
	}

	.home .footer {
		border-top: 2px solid ${this.siteColor};
	}

	.choice-link-button:before {
		background: ${this.siteColor};
	}
	.famille-name{
		color:${this.siteColor}
	}

	.router-link {
		border: solid ${this.siteColor} 1px;
	}

	.content h2 {
		background: ${this.siteColor};
	}

	.site-colors {
		color: ${this.siteColor};
	}

	.price-container {
		/*background-color: ${this.siteColor};*/
		background-color: #fff;
	}

	.yellow-one {
		color: ${this.siteColor} !important;
		border: 2px solid ${this.siteColor};
	}

	.avecPose {
		background: ${this.siteColor};
	}

	/*.checkbox-choice.selected {
		border: solid 2px ${this.siteColor};
	}*/

	.card-header.answered {
		background-color: ${this.siteColor};
	}

	@media screen and (min-width: 0 \\\\\\\\0) and (min-resolution: +72dpi) {
		.choice:before {
			background: none;
		}

		.choice {
			background: ${this.siteColor};
		}
	}

	@media screen and (max-width: 1023px) {
	}
		/*END INLINE HTML*/
</style>`
	},
	siteColor() {
	  return this.$store.state.siteInfos.Couleur
	},
	familyColor() {
	  return this.$store.state.family['couleur']
	}
  },
  mounted: function () {
	if (sessionStorage.getItem("is_reloaded")) {
	  this.$router.push('/');
	  sessionStorage.setItem("is_reloaded", false)
	} else {
	  sessionStorage.setItem("is_reloaded", true)
	}
  },
  created: function () {
	this.$store.dispatch('loadSiteInfos')
	// console.log(this.$store.state)
  }
}
</script>
<style lang="scss">
@import "../public/css/style.css";
/*Removes browser styles*/
body {
  margin: 0;
  padding: 0;
}

h1, nav, p, body, a {
  font-family: "Pommier Regular";
  letter-spacing: 0.02rem;
  color: #004494;

}

footer{
	position: fixed;
	bottom: 0;
	z-index: 10;
	background: #003B85;
	width: 100vw;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	font-size: 13px;
	color: white;
	gap: 5px;
	a {
		color: white;
	}
	a:hover {
		color: grey;
	}
}


#app{position:relative}

#app {
  font-family: "Pommier Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #3B3B3B;
  height: 100vh;
}

.btn {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
  z-index: 10;
  outline: none;
  font-weight: 600;
  border-radius: 0 !important;
  font-size: 16px;
  padding: .75rem 1.5rem;
  font-family: "Pommier Light", sans-serif;

}

.btn_type1, .btn_type1:hover {
  color: #fff;
}

.btn_type2, .btn_type2:hover {
  color: #201d1d;
  background-color: #C4C4C4;
}

.btn_type3, .btn_type3:hover {
  color: #ffffff;
  background-color: #004494;
  padding: 10px 25px;

  border-radius: 10px !important;
}


@media screen and (max-width: 500px) {
  #app {
	/*height: 110vh;*/
  }
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}


</style>
