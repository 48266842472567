<template>
  <div class="home">
    <MainNav></MainNav>
    <div class="center">
      <h1>{{ $t(siteInfos.HomeH1) }}</h1>
      <h3>{{ $t(siteInfos.HomeH2) }}</h3>
      <div class="row-links">
        <div
          to="/preconstructor"
          class="home-link start-projet-btn"
          @click="freshProject"
          :data-background-color="siteInfos.Couleur"
        >
          <img src="img/demarrer_mon_projet.png" alt=">>" />
          <p>{{ $t("Démarrer mon projet") }}</p>
        </div>
        <a
          href="https://www.pommier.eu/customer"
          class="home-link modifier-projet-btn"
        >
          <img src="img/modifier_un_projet.png" alt=">>" />
          <p>{{ $t("Modifier un projet") }}</p>
        </a>
      </div>
      <div v-if="$store.state.lang == 'fr'" class="row-links">
        <router-link to="/presentation" class="home-link modifier-projet-btn">
          <p>
            {{ $t("Vidéo de présentation") }}
          </p>
        </router-link>
      </div>
    </div>
  </div>
  
</template>

<script>
import MainNav from "../components/MainNav";
import makeWihagPdf from "../lib/dataPreSend";
import { trackUserData } from "../lib/userTracker"

export default {
  name: "home",
  components: { MainNav },
  methods: {
    freshProject() {
      this.$store.dispatch("resetState");
      this.$router.push("/preconstructor");
      trackUserData("Accueil");
    },
  },
  computed: {
    siteInfos() {
      return this.$store.state.siteInfos;
    },
    apiUrl() {
      return this.$store.state.apiUrl;
    },
  },
};
</script>

<style lang="scss">

body {
  //background: #F5F5F5;
}

#test-btn {
  background: #fff;
  padding:20px;
  margin:20px;
  color: #000;
  cursor: pointer;
}

.top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 10%;
  padding-top: 50px;
}

.top-row img {
  width: 261px;
  height: auto;
}

#background-image {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-size: cover;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
}

.home {
  /*background: url("/img/home_background.png");*/
  background: #F5F5F5;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  min-height: 100vh;
}

.home h1 {
  font-size: 3rem;
  font-family: "Pommier Regular";
}

.home h1,
.home h3 {
  /*color: #004494;*/
  color: #fff;
  font-weight: 800;
}

.center {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75vh;

  padding: 60px;
}

.row-links {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.home-link.start-projet-btn,
.home-link.modifier-projet-btn {
  font-size: 23px;
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: space-evenly;
  text-transform: uppercase;
  /*background-color: #004494;*/
  background-color: #fff;
  margin: 5em 5em 0 5em;
  color: #004494;
  font-weight: 700;
  cursor: pointer;
  border-radius: 10px;
}

.home-link p {
  margin: 0 1.5em;
  /*color: #fff;*/
  color: #004494;
}

.home-link img {
  width: 40px;
  height: auto;
  cursor: pointer;
  margin: 0 0 0 1.5em;
}

.home-link:hover,
.row-links:focus {
  font-size: 23px;
  text-decoration: none;
  display: flex;
}

.header-list-univers {
  margin-left: 72px;
}

.choice {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  color: #fff;
  padding: 20px;
  z-index: 1;
  width: 260px;
}

.choice:hover,
.choice:focus,
.choice:visited {
  color: #ffffff;
  text-decoration: none;
}

.choice:first-child {
  margin-right: 80px;
}

.choice > div,
.choice > p {
  width: 49%;
  margin: 0;
}

.choice img {
  width: 75px;
}

.choice::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  -webkit-clip-path: polygon(
    0px 82px,
    30px 112px,
    300px 112px,
    300px 0px,
    0px 0px
  );
  clip-path: url("#clipPolygon");
  opacity: 0.9;
  transition: 0.2s opacity;
}

.choice:hover::before {
  opacity: 1;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: 0 0 3em 0;
  padding: 1em 0 0 0;
}

.footer_left_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer_right_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer_right_container a.btn {
  font-weight: 500;
}

.footer_left_container a {
  font-size: 14px;
  font-weight: 600;
  padding: 0 1em;
}

.footer_right_container .btn {
  padding: 0.75rem 4rem;
}

@media screen and (max-width: 1400px) {
  .home .footer_left_container {
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin: 0;
  }
  .home .footer .row {
    margin: auto;
  }
  .home .footer {
    margin: 0 0 1.5em 0;
    padding: 1.5em 0 0 0;
  }
}

@media screen and (max-width: 1220px) {
  .home-link.start-projet-btn,
  .home-link.modifier-projet-btn {
    font-size: 18px;
  }
}

@media screen and (max-width: 1023px) {
  .home-link.start-projet-btn,
  .home-link.modifier-projet-btn {
    margin: 5em 2em 0 2em;
  }
}

@media screen and (min-width: 0\0) and (min-resolution: +72dpi) {
  #background-image {
  }
}

@media screen and (max-width: 767px) {
  .home > img {
    width: 35%;
    position: relative;
    left: 0;
  }

  .home .center {
    padding: 30px 30px 0;
  }

  .home h1 {
    font-size: 1.5rem;
    line-height: initial;
  }

  .home h3 {
    font-size: 1rem;
    line-height: initial;
    /* margin-bottom: 40px; */
  }

  .home .center {
    top: 50%;
  }

  .home .choices-container {
    flex-wrap: wrap;
    align-items: center;
    width: 80%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .home .choices-container > div,
  .home .choices-container > a {
    width: 100%;
  }

  .home .choice:first-child {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .home .choice {
    height: 125px;
  }

  .home .footer p {
    width: 100%;
    margin: 20px 0px 10px 0;
  }

  p {
    margin-bottom: 1rem;
  }

  .mobile-wrap {
    flex-wrap: wrap;
  }

  .choice.main-btn::before {
    width: 55%;
    height: 125px;
  }

  .choice.main-btn img {
    width: 55px;
  }

  .choice.main-btn p {
    font-size: 20px;
    width: 30%;
    margin-top: -10px;
  }
}

@media screen and (max-width: 520px) {
  .row-links {
    flex-direction: column;
  }

  .home-link {
    font-size: 18px;
    flex-direction: column-reverse;
  }

  .home-link:hover {
    font-size: 20px;
    transition: all 0.3s;
  }

  .home-link img {
    width: 20%;
    height: auto;
    margin: 0 0 2% 0;
  }

  .mobile-nav-bloc {
    background: #201d1d;
  }

  .home .choices-container {
    width: 100%;
  }

  .choice.main-btn img {
    width: 40px;
  }

  .choice.main-btn p {
    font-size: 16px;
  }

  .home > img {
    top: 15px;
  }

  div.home {
    position: relative;
    height: 100%;
  }

  .home .center {
    top: 300px;
    height: auto;
  }
  .home-link.start-projet-btn,
  .home-link.modifier-projet-btn {
    font-size: 14px;
    margin: 1em 5em 0 5em;
  }
  .home .footer {
    margin: 0 0 1em 0;
  }

  .home .footer > .row {
    margin: 0;
  }
}

@media screen and (max-width: 520px) and (max-height: 600px) {
  .home {
    height: 100vh;
  }
}

@media screen and (min-width: 0\0) and (min-resolution: +72dpi) {
  .choice:before {
    background: none;
  }

  .choice {
  }
}

.home .header-title {
  display: none;
}

.home .cards_icon {
  width: 51px;
  height: 32px;
  margin: 0 6px 6px 0;
  background-image: url(/img/cards.png);
}

.paypal_icon {
  background-position: 0px -33px;
}

.visa_icon {
  background-position: 155px 0px;
}

.mastercard_icon {
  background-position: 208px 0px;
}

.maestro_icon {
  background-position: -105px 0px;
}

.cirrus_icon {
  background-position: -53px 0px;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .mobile-wrap {
    margin-top: 20px;
  }
}
</style>
