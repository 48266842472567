<template>
    <div class="card" :class="[question.type, {opened: display}]">
       <!--  <div class="card-header" :id="'header-'+step+'Question'+question.id" data-toggle="collapse"

             :data-target="'#collapseStep'+step+'Question'+question.id"
             v-bind:aria-expanded="question.openByDefault ? 'true' : 'false'"
             aria-controls="collapseOne"
             :class="{'answered' : showSpoiler!==false}"
        >
            <h5 class="mb-0">
                <button class="btn btn-link">
                    {{ $t(question.extraHtml.label) }}
                </button>
                <div class="recap" v-show="showSpoiler">


                  <span v-if="question.toShow==='nombre' && !selected.find(el=>(el!=null && el.label==='Sans Option'))">
                    {{selected.filter(el=>el!=null).length}}
                    </span>
                  <span v-else-if="question.toShow==='nombre'&& selected.find(el=>(el!=null && el.label==='Sans Option'))">
                    0
                </span>
                    <span v-else v-for="(answer, answerIndex) in selected" v-bind:key="answerIndex">
                        <img v-if="answer" :src="answer.imageUrl" alt="Image">
                    </span>



                </div>
            </h5>
        </div> -->
        <div :id="'collapseStep'+step+'Question'+question.id" class="collapse"
             v-bind:class="{show : true}"
             :aria-labelledby="'collapseStep'+step+'Question'+question.id" data-parent="#main-form">
            <div class="extrahtml"
                 v-if="question.extraHtml !== undefined && question.extraHtml.content_top !== null !== undefined && question.extraHtml.content_top !== null"
                 v-html="$t(question.extraHtml.content_top)"></div>
            <button v-if="question.extraHtml.label_comment_faire !== null && question.extraHtml.label_comment_faire !== '' "
                    @click.stop="fillAndOpenGlobalModal(question.extraHtml)" type="button"
                    class="open-modal btn-modal general-question"
                    data-toggle="modal"
                    :data-target="'#modal'+step+'-'+question.id">
                {{ $t(question.extraHtml.label_comment_faire) }}
            </button>
            <div class="card-body" :class="'img-'+question.variable">
                <div v-for="(answer, answerKey) in question.answers" class="checkbox-choice"
                    v-bind:key="answerKey"
                     v-bind:class="{ 'selected': isSelected(answer) }"
                     v-on:click="addToAnswers(answerKey)"
                     :data-permanent="isPermanent()"
                     :data-size="colonneSize(question)"
                >
                    <div class="image-placeholder"
                         :data-tooltip="answer.text_info_bulle !== '' && answer.text_info_bulle !== undefined ? answer.text_info_bulle : false"
                         data-tooltip-position="top">
                        <img :class="['img-'+step+'-'+question.id+'-'+answerKey]"
                             :src="answer.imageUrl" alt=""></div>
                    <p>{{ $t(answer.label) }}</p>
                    <p v-html="$t(answer.subtitle)" style="font-size:11px;color:#515151;padding:0;margin:0;"
                       :data-target="answer.triggerAlert !== undefined && answer.triggerAlert ?
                         '#question-alert-modal'+question.id : 'none'">{{ $t(answer.subtitle) }}</p>


                    <button
                            v-if="answer.Popup !== null && answer.Legende !== null && answer.Popup !== undefined && answer.Legende !== undefined"
                            @click.stop="fillAndOpenDefaultModal(answer)" type="button"
                            class="open-modal btn-modal mt-2"
                            data-toggle="modal"
                            :data-target="'#modal'+step+'-'+question.id+'-'+answerKey">
                        {{ answer.BtnLegende === undefined ? "+ d'infos" : answer.BtnLegende }}
                    </button>
                </div>
            </div>

            <div class="contentBottom"
                 v-if="question.extraHtml !== undefined && question.extraHtml.content_bottom !== undefined && question.extraHtml.content_bottom !== null"
                 v-html="$t(question.extraHtml.content_bottom)"></div>
            <div class="change-btn" v-if="question.optional || showSpoiler">

                <button @click="nextQuestion()" type="button"
                        class="open-modal next mt-2">
                    {{ $t("Valider ma selection") }}
                </button>
            </div>
        </div>

    </div>
</template>

<script>
    import common from './mixins/common'

    export default {
        name: "MultipleImageRadioChoice",

        props: {
            question: Object,
            questions: Object,
            step: [String, Number],
        },
        created() {
            this.initialize()
        },
        mixins: [common],

        data() {
            return {
                selected: [],
                showSpoiler: false,
                display: null
            };
        },

          mounted () {
            setTimeout(() => {
              this.display = this.opened
            }, 500)
            
          },

        computed: {
            steps() {
                return this.$store.state.steps;
            },
        },

        methods: {
            nextQuestion() {
                this.$store.dispatch('calculateValidSteps');
                this.$store.dispatch('calculatePrice');
                const questionIndex = this.question.id;
                const question = this.questions[questionIndex];
                //this.openNextQuestion(this.step, question.id);

            },
            fillAndOpenDefaultModal(answer) {
                let modal = $('#basicModal');
                modal.find('h5').text(answer.label);
                modal.find('.extra-popup').html(answer.Legende)
                modal.modal('show');

            },
            fillAndOpenGlobalModal(answer) {
                let modal = $('#basicModal');
                modal.find('h5').text(answer.label_comment_faire);
                modal.find('.extra-popup').html(answer.popup_comment_faire);
                modal.modal('show');

            },
            isSelected(answer) {
                if (this.answers[this.step] && this.answers[this.step][this.question.id]) {
                    return _.find(this.answers[this.step][this.question.id], (answerEl) => {
                        return answerEl.value === answer.value
                    })
                }
                return false

            },

            colonneSize(question) {
                let colonnes = (question.blocsAlign) ? question.blocsAlign : question.extraHtml.colonnes;
                let key = this.question.rang;
                if (Array.isArray(colonnes)) {
                    return colonnes[key];
                } else {
                    return colonnes;
                }
            },
            isPermanent() {
                return (this.question.answers.length > 5)
            },

            addToAnswers: function (answerIndex) {
                const questionIndex = this.question.id;
                const question = this.questions[questionIndex];
                const answer = question.answers[answerIndex];
                const answerData = {
                    stepIndex: this.step,
                    questionIndex: questionIndex,
                    questionType: this.questions[questionIndex].type,
                    effectOnSvg: this.questions[questionIndex].effectOnSvg,
                    index: answerIndex,
                    value: answer.value,
                    variable: question.variable,
                    name: answer.label,
                    imageUrl: answer.imageUrl,
                    changes: answer.changes !== undefined ? answer.changes : null,

                };
                if (this.selected[answerIndex]) {
                    answer.isAnswer = false;
                    this.selected[answerIndex] = null;
                    this.$store.dispatch('clearAnswer', answerData);
                } else {
                  if( answer.label === "Sans Option" ){
                    
                    if (this.answers[this.step]){
                      let answer = this.answers[this.step][this.question.id]
                      if (answer) {
                        for (const [key, value] of Object.entries(answer)) {
                          value.isAnswer = false;
                          this.selected[key] = null;
                          this.$store.dispatch('clearAnswer', value);

                        }
                        
                      }

                    }
                  }else {
                    if (this.answers[this.step]){
                      let answer = this.answers[this.step][this.question.id]

                      if (answer) {
                        for (const [key, value] of Object.entries(answer)) {
                          if (value.name ==='Sans Option'){
                            value.isAnswer = false;
                            this.selected[key] = null;
                            this.$store.dispatch('clearAnswer', value);
                          }
                        }
                      }
                    }


                  }
                    answer.isAnswer = true;
                    this.selected[answerIndex] = answer;
                   
                    this.$store.dispatch('addAnswers', answerData);
                }

                // console.log(this.selected)
                this.showSpoiler = this.selected.filter((answer) => answer).length > 0;

                if('Sans Option' === answer.label) {
                     this.nextQuestion()
                }
               
            },

            initialize() {
                if (this.answers[this.step] && this.answers[this.step][this.question.id]) {
                    Object.keys(this.answers[this.step][this.question.id]).forEach((idx) => {
                        const answer = this.answers[this.step][this.question.id][idx];

                        this.selected[idx] = this.steps[answer.stepIndex].questions[answer.questionIndex].answers[answer.index];
                    });
                    this.showSpoiler = this.selected.filter((answer) => answer).length > 0;
                }
            },
        }
    }
</script>

<style scoped>
    .next {
        border: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        cursor: pointer;
        padding: 10px 15px;
        color: #fff;
        z-index: 10;
        position: relative;
        outline: none;
        margin-bottom: 20px;
    }

    .recap span {
        height: 100%;
    }

    [data-tooltip] {
        position: relative;
    }

    /* Tooltip styling */
    [data-tooltip]:before {
        content: attr(data-tooltip);
        display: none;
        position: absolute;
        background: #000;
        color: #fff;
        padding: 4px 8px;
        font-size: 14px;
        line-height: 1.4;
        min-width: 250px;
        text-align: center;
        border-radius: 4px;
        width: 150%;

    }

    /* Dynamic horizontal centering */
    [data-tooltip-position="top"]:before {
        left: 50%;
        -ms-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    [data-tooltip-position="top"]:before {
        bottom: 100%;
        margin-bottom: 10px;
    }

    [data-tooltip]:after {
        content: '';
        display: none;
        position: absolute;
        width: 0;
        height: 0;
        border: solid transparent;
    }

    [data-tooltip-position="top"]:after {
        left: 50%;
        margin-left: -6px;
        margin-bottom: 4px;
    }

    [data-tooltip-position="top"]:after {
        bottom: 100%;
        border-width: 6px 6px 0;
        border-top-color: #000;
    }

    /* Show the tooltip when hovering */
    [data-tooltip]:hover:before,
    [data-tooltip]:hover:after {
        display: block;
        z-index: 100;
    }

    .checkbox-choice[data-permanent] {
        width: 30%;
    }

    .checkbox-choice[data-size ='6'] {
        width: 15%;
    }

    .checkbox-choice[data-size ='4'] {
        width: 25%;
    }

    .checkbox-choice[data-size ='3'] {
        width: 30%;
    }

    .checkbox-choice[data-size = '2'] {
        width: 50%;
    }

    .contentBottom {
        font-weight: bold;
        color: #c4c4c5;
        font-size: 18px;
    }

    img {
        height: auto;
    }

    img[class*="img-1-2-"] {
        width: 200px;
        height: auto;
    }

    .card-header img {
        height: 100%;
    }

    .checkbox-choice {
        flex-grow: initial;
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column nowrap;
    }

    .checkbox-choice p {
        margin: 10px 0 0 0;
        max-width: 100%;
        text-align: center;
    }

    .checkbox-choice.selected {
        font-weight: bold;
    }
    @media screen and (max-width: 1220px){
        .contentBottom {
            font-size: 14px;
        }
    }
</style>
