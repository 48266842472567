var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card",class:[_vm.question.type, {opened: _vm.display}]},[_c('div',{staticClass:"collapse",class:{ show: true },attrs:{"id":'collapseStep' + _vm.step + 'Question' + _vm.question.id,"aria-labelledby":'collapseStep' + _vm.step + 'Question' + _vm.question.id,"data-parent":"#main-form"}},[(
        _vm.question.extraHtml !== undefined &&
          (_vm.question.extraHtml.content_top !== null) !== undefined &&
          _vm.question.extraHtml.content_top !== null
      )?_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t(_vm.question.extraHtml.content_top))}}):_vm._e(),_c('div',{staticClass:"card-body"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.answerIndex),expression:"answerIndex"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.answerIndex=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"disabled":"","selected":"","value":"-1"}},[_vm._v(_vm._s(_vm.$t(_vm.question.selectLabel)))]),_vm._l((_vm.question.answers),function(answer,answerKey){return _c('option',{domProps:{"selected":answer.isAnswer,"value":answerKey}},[_vm._v(" "+_vm._s(_vm.$t(answer.label))+" ")])})],2)]),(
        _vm.question.extraHtml !== undefined &&
          (_vm.question.extraHtml.content_bottom !== null) !== undefined &&
          _vm.question.extraHtml.content_bottom !== null
      )?_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t(_vm.question.extraHtml.content_bottom))}}):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }