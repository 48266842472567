<template>
	<a id="ContactButton" target="_blank" :href="link">
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
			<path d="M92.5,124.8a83.6,83.6,0,0,0,39,38.9,8,8,0,0,0,7.9-.6l25-16.7a7.9,7.9,0,0,1,7.6-.7l46.8,20.1a7.9,7.9,0,0,1,4.8,8.3A48,48,0,0,1,176,216,136,136,0,0,1,40,80,48,48,0,0,1,81.9,32.4a7.9,7.9,0,0,1,8.3,4.8l20.1,46.9a8,8,0,0,1-.6,7.5L93,117A8,8,0,0,0,92.5,124.8Z" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path>
		</svg>
		{{ $t('Nous contacter') }}
	</a>
</template>

<script>
export default {
	name: 'ContactButton',
	props: {
		link: {
			type: String,
			default: 'https://www.pommier.eu/en/contact-us'
		}
	},
	data () {
		return {}
	},
	methods: {}
}
</script>

<style lang="scss" scoped>
#ContactButton {
	padding: 10px;
    background: #003B85;
    color: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    height: 40px;
    border: 1px solid #003B85;
    transition: 0.2s;

    &:hover {
		border: 1px solid #003B85;
		background: white;
		color: #003B85;
		text-decoration: none!important;
		transition: 0.2s;

		svg {
			fill: #003B85;
			transition: 0.2s;
		}
	}

    a {
    	display: flex;
	    justify-content: center;
	    align-items: center;
    }

	svg {
		fill: white;
		height: 25px;
		transition: 0.2s;
	}
}

@media only screen and (max-width: 910px) {
	#ContactButton {
		background: white;
		color: #003B85;
		border: 1px solid #003B85;
		width: 90vw;

		svg {
			fill: #003B85;
		}
	}
}
</style>
