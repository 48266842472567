<template>
	<div class="card" :class="[question.type, {opened: display}]">

	<div
		:id="'collapseStep'+step+'Question'+question.id" class="collapse"
		:class="{show : question.openByDefault}"
		:aria-labelledby="'collapseStep'+step+'Question'+question.id" data-parent="#main-form"
	>
		<div
			class="extrahtml"
			v-if="question.extraHtml !== undefined && question.extraHtml.content_top !== undefined && question.extraHtml.content_top !== null"
			v-html="$t(question.extraHtml.content_top)">
		</div>


		<div class="card-body">
			<div
				v-for="(answer, answerKey) in question.answers" class="checkbox-choice "
				:class="{ 'selected': isSelected(answer) } "
				:data-target="(answer.triggerAlert !== undefined && answer.triggerAlert) ?
				'#question-alert-modal'+question.id : 'none'"
				:data-permanent="isPermanent()"
				:data-size="colonneSize(question)"
			>

				<div
					class="image-placeholder "
					:data-tooltip="answer.text_info_bulle !== '' && answer.text_info_bulle !== undefined ? answer.text_info_bulle : false"
					data-tooltip-position="top"
					@click="selectAnswer(answer, answerKey)"
					:data-target="(answer.triggerAlert !== undefined && answer.triggerAlert) ?
					'#question-alert-modal'+question.id : 'none'"
				>

					<img
						:style="{height : answer.height !== undefined ? answer.height + 'px' : 'none'}"
						:class="['img-'+step+'-'+question.id+'-'+answerKey,'img-'+step+'-'+question.id+'-'+question.variable ]"
						:src="answer.imageUrl" alt=""
						:data-target="(answer.triggerAlert !== undefined && answer.triggerAlert) ?
						'#question-alert-modal'+question.id : 'none'"
					>

					<p
						v-html="$t(answer.label)"
						:data-target="(answer.triggerAlert !== undefined && answer.triggerAlert) ?
						'#question-alert-modal'+question.id : 'none'"
					>
						{{ $t(answer.label) }}
					</p>

					<p
						v-html="answer.subtitle" style="font-size:11px;color:#515151;padding:0;margin:0;"
						:data-target="(answer.triggerAlert !== undefined && answer.triggerAlert) ?
						'#question-alert-modal'+question.id : 'none'"
					>
						{{ answer.subtitle }}
					</p>

				</div>

				<div v-if="answer.label!=='Aucun'" class=" quantity d-flex">
					<div class="input-group d-flex">
						<div class="input-group-prepend">
							<button
								class="btn btn-outline-secondary btn-minus"
								@click="counterQuantity(answerKey,(answer.quantity_step ? answer.quantity_step : -1))"
							>
								<i class="fa fa-minus"></i>
							</button>
						</div>
						<input
							type="number"
							v-model="currentQuantity"
							:max="answer.Qmax ? answer.Qmax : 100"
							:min="answer.Qmin ? answer.Qmin : minimumValue()"
							:placeholder="(quantity>=0) ? quantity : minimumValue()"
							@change="addToAnswers(answerKey, isSelected(answer), $event,false)"
						/>
						<div class="input-group-append">
							<button
								class="btn btn-outline-secondary btn-plus"
								@click="counterQuantity(answerKey,(answer.quantity_step ? Math.abs(answer.quantity_step) : 1))"
							>
								<i class="fa fa-plus"></i>
							</button>
						</div>
					</div>
				</div>

				<button
					v-if="answer.Popup && answer.Legende  && answer.Popup !== null && answer.Legende !== null && answer.Popup !== undefined && answer.Legende !== undefined"
					@click.stop="fillAndOpenDefaultModal(answer)" type="button"
					class="open-modal btn-modal popup-info p-2"
					data-toggle="modal"
					:data-target="'#modal'+step+'-'+question.id+'-'+answerKey"
				>
					{{ answer.BtnLegende === undefined ? "en savoir plus" : answer.BtnLegende }}
				</button>
			</div>
		</div>
		<div class="change-btn" v-if="selectedAnswer && currentQuantity > 0">
			<button
				@click="nextQuestion()" type="button"
				class="open-modal next mt-2"
			>
				{{ $t("Question suivante") }}
			</button>
		</div>
		<div
			class="contentBottom"
			v-if=" question.extraHtml.content_bottom !== undefined && question.extraHtml.content_bottom !== null"
			v-html="$t(question.extraHtml.content_bottom)"
		>
		</div>
		<button
			v-if="question.extraHtml.label_comment_faire && question.extraHtml.label_comment_faire !== undefined && question.extraHtml.label_comment_faire !== null"
			@click.stop="fillAndOpenGlobalModal(question.extraHtml)" type="button"
			class="open-modal btn-modal global-question ici p-3"
			data-toggle="modal"
			:data-target="'#modal'+step+'-'+question.id"
		>
			{{ $t(question.extraHtml.label_comment_faire) }}
		</button>

	</div>


	<div
		v-if="question.hasAlerts !== undefined" class="modal fade"
		:data-target-close="'#question-alert-modal'+question.id" @click.stop="closeModal"
		:id="'question-alert-modal'+question.id" tabindex="-1" role="dialog"
		aria-hidden="true"
	>
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">A prendre en compte</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<p>{{ question.alertMessage }}</p>
				</div>
				<div class="modal-footer">
					<button
						@click.stop="closeModal" type="button" class="btn btn-secondary"
						data-dismiss="modal"
						:data-target-close="'#question-alert-modal'+question.id"
					>
						J'ai compris
					</button>
				</div>
			</div>
		</div>
	</div>

</div>
</template>

<script>
import common from './mixins/common'

export default {
	name: "RadioQuantityChoice",

	props: {
		question: Object,
		questions: Object,
		step: [String, Number],
		opened: Boolean
	},
	computed: {
		apiUrl() {
			return this.$store.state.apiUrl;
		}
	},
	mixins: [common],

	data() {
		return {
			showSpoiler: false,
			quantity: [],
			selectedAnswer: null,
			selectedID: null,
			currentQuantity: 0,
			display: null
		}
	},

	mounted () {

		setTimeout(() => {
	      this.display = this.opened
	    }, 500)

		this.answer = null
		if (this.$store.state.answers[this.step]) {
			if (this.$store.state.answers[this.step][this.question.id]) {
				this.answer = this.$store.state.answers[this.step][this.question.id][0].value
				this.selectedAnswer = this.$store.state.steps[this.step].questions[this.question.id].answers[this.answer]
				this.currentQuantity = this.$store.state.answers[this.step][this.question.id][0].quantity
			}
		}
	},

	methods: {
		minimumValue() {
			if (this.question.minimumValue !== undefined) {
				return this.question.minimumValue
			} else {
				return 1
			}
		},
		fillAndOpenDefaultModal(answer) {
			let modal = $('#basicModal');
			modal.find('h5').text(answer.label);
			// modal.find('img').attr('src', answer.Popup);
			modal.find('.extra-popup').html(answer.Legende)
			modal.modal('show');
		},
		fillAndOpenGlobalModal(answer) {
			let modal = $('#basicModal');
			modal.find('h5').text(answer.label_comment_faire);
			modal.find('.extra-popup').html(answer.popup_comment_faire);
			modal.modal('show');
		},

		showModal(event) {
			console.log(event.target)
			if ($(event.target).data('target') !== 'none') {
				$($(event.target).data('target')).modal('toggle')
			}
		},
		closeModal(event) {
			$($(event.target).data('target-close')).modal('hide')
		},
		isSelected(answer) {
			if (this.selectedAnswer) {
				if (this.selectedAnswer.id === answer.id) {
					return true
				} else {
					return false
				}
			} else {
				return false
			}
		},
		activeCollapse() {
			let header = document.querySelector("#header-" + this.step + "Question" + this.question.id + "[aria-expanded = true]");
			let element = document.querySelector("#collapseStep" + this.step + "Question" + this.question.id + " .checkbox-choice > div:first-child");
			let nextButton = document.querySelector(".right");

			if (this.question.answers.length == 1 && header && element) {
				element.click();
			}
		},
		nextQuestion() {
			const questionIndex = this.question.id;
			const question = this.questions[questionIndex];

			this.addToAnswers(this.selectedID, this.isSelected(this.selectedAnswer), null, false)
			//this.$store.dispatch('calculatePrice')
			this.openNextQuestion(this.step, question.id);
		},

		counterQuantity(answerIndex, add) {

			if (!this.selectedAnswer) {
				this.selectAnswer(this.question.answers[answerIndex], answerIndex, true)
			}

			this.currentQuantity += add

			let qMin = this.question.answers[answerIndex].Qmin
			let qMax = this.question.answers[answerIndex].Qmax

			if (this.question.answers[answerIndex].Qmin !== undefined && this.question.answers[answerIndex].Qmax !== undefined) {

				if (this.currentQuantity < qMin) this.currentQuantity = qMin
				if (this.currentQuantity > qMax) this.currentQuantity = qMax

			} else {
				if (this.currentQuantity < this.minimumValue()) {
					this.currentQuantity = this.minimumValue()
				}
			}
		},
		colonneSize(question) {
			let colonnes = (question.blocsAlign) ? question.blocsAlign : question.extraHtml.colonnes;
			let key = this.question.rang;
			if (Array.isArray(colonnes)) {
				return colonnes[key];
			} else {
				return colonnes;
			}
		},
		isPermanent() {
			return (this.question.answers.length > 5)
		},
		selectAnswer (answer, id, dontSetQuantity) {
			this.selectedAnswer = answer
			this.selectedID = id
			if (dontSetQuantity) return
			let qMin = this.question.answers[id].Qmin
			if (qMin) {
				this.currentQuantity = qMin
			} else {
				this.currentQuantity = 1
			}
		},
		addToAnswers: function (answerIndex, isSelected, event, canClearAnswer = true) {
			if (!isSelected) event && this.showModal(event);


			const questionIndex = this.question.id;
			if (!isSelected) {
				Object.keys(this.questions[questionIndex].answers).forEach((key) => {
					this.questions[questionIndex].answers[key].isAnswer = false;
				});
			}
			const answer = this.questions[questionIndex].answers[answerIndex];

			//this.quantity = answer.quantity

			answer.isAnswer = true;
			this.display = false
			this.$store.dispatch('addAnswersThenCalc', {
				isGlobal: this.question.global !== undefined && this.question.global,
				stepIndex: this.step,
				questionIndex: questionIndex,
				questionType: this.questions[questionIndex].type,
				effectOnSvg: this.questions[questionIndex].effectOnSvg,
				index: answerIndex,
				label: answer.label,
				value: answer.isAnswer ? this.selectedID : null,
				variable: this.questions[questionIndex].variable || null,
				imageUrl: answer.imageUrl,
				icon: answer.icon,
				quantity: this.currentQuantity !== undefined ? this.currentQuantity : this.minimumValue(),
				imageViewer: answer.imageViewer,
				id_familles: answer.id_familles,
				changes: answer.changes !== undefined ? answer.changes : null,
				delai: answer.delai
			});

			this.showSpoiler = (this.questions[questionIndex].toShow ==='label')?answer.label:answer.imageUrl;

			this.$store.commit('setReady', {ready: false, from: 'RadioQuantityChoice'})
		},

		initialize() {
			if (this.answers[this.step] && this.answers[this.step][this.question.id] !== undefined && this.answers[this.step][this.question.id][0] !== undefined) {
				this.showSpoiler = this.answers[this.step][this.question.id][0].imageUrl || this.showSpoiler;
			}
		},
	}
}
</script>

<style scoped>

.card {
  max-height: 0;
  transition: max-height 0.2s;
  overflow: hidden;
}

.card.opened {
  max-height: 800px;
  transition: max-height 0.2s;
}

.next {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
  padding: 10px 15px;
  color: #fff;
  z-index: 10;
  position: relative;
  outline: none;
}

[data-tooltip] {
  position: relative;
}

/* Tooltip styling */
[data-tooltip]:before {
  content: attr(data-tooltip);
  display: none;
  position: absolute;
  background: #000;
  color: #fff;
  padding: 4px 8px;
  font-size: 12px;
  line-height: 1.4;
  min-width: 260px;
  text-align: center;
  border-radius: 4px;
}

/* Dynamic horizontal centering */
[data-tooltip-position="top"]:before,
[data-tooltip-position="bottom"]:before {
  left: 50%;
  -ms-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

/* Dynamic vertical centering */
[data-tooltip-position="right"]:before,
[data-tooltip-position="left"]:before {
  top: 50%;
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

[data-tooltip-position="top"]:before {
  bottom: 100%;
  margin-bottom: 10px;
}

.vr [data-tooltip-position="top"]:before {
  bottom: 100%;
  margin-bottom: 0px !important;
}

/* Tooltip arrow styling/placement */
[data-tooltip]:after {
  content: '';
  display: none;
  position: absolute;
  width: 0;
  height: 0;
  border: solid transparent;
}

/* Dynamic horizontal centering for the tooltip */
[data-tooltip-position="top"]:after {
  left: 50%;
  margin-left: -6px;
  margin-bottom: 4px;
}

/* Dynamic horizontal centering for the tooltip */
[data-tooltip-position="top"]:after {
  left: 50%;
  margin-left: -6px;
  margin-bottom: 4px;
}

[data-tooltip-position="top"]:after {
  bottom: 100%;
  border-width: 6px 6px 0;
  border-top-color: #000;
}

/* Show the tooltip when hovering */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  display: block;
  z-index: 50;
}

.global-question {
  border-radius: 3px;
  border: 1px solid transparent;
  box-shadow: 1px 1px 8px 1px #bebebe;
  transition: .3s;
}

.popup-info {
  border-radius: 3px;
  border: 1px solid transparent;
  box-shadow: 1px 1px 8px 1px #bebebe;
  transition: .3s;
}

.checkbox-choice[data-permanent] {
  width: 30%;
}

.checkbox-choice[data-size ='6'] {
  width: 15%;
}

.checkbox-choice[data-size ='4'] {
  width: 25%;
}

.checkbox-choice[data-size = '2'] {
  width: 50%;
}

.contentBottom {
  font-weight: bold;
  color: #c4c4c5;
  font-size: 18px;
}

img {
  height: auto;
}

.pvc img[class*="img-1-3-"] {
  width: 200px;
  height: auto;
}

.clo img[class*="img-1-1-"], .clo img[class*="-type_produit"] {
  width: 100%;
  height: auto;
}

.clo .image-placeholder img {
  max-width: 95%;
}

.RadioQuantityChoice .image-placeholder img {
  max-width: 95%;
}

.clo #collapseStep1Question1 .checkbox-choice:first-child {
  width: 100%;
  margin-bottom: 20px;
}

.clo img[class*="img-2-1-"], .clo img[class*="-type_modele"] {
  width: 200px;
  height: auto;
}

.eques #collapseStep1Question3 .checkbox-choice:first-child, .eques #collapseStep1Question3 .checkbox-choice:nth-child(2) {
  width: 50% !important;
}

/*.port div#collapseStep1Question1 div.checkbox-choice:first-child,.port div#collapseStep1Question1 div.checkbox-choice:nth-child(2){*/
/*width:50%!important;*/
/*}*/

.card-header img {
  height: 100%;
}

.checkbox-choice {
  flex-grow: initial;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  border-radius: 3px;
  cursor: pointer;
  font-weight: bold;
  background-color: #fff;
}

.checkbox-choice {

}

.checkbox-choice p {
  margin: 10px 0 0 0;
  width: 100%;
  text-align: center;
}

.checkbox-choice.selected {
  font-weight: bold;
  /* background-color: #fafafa; */
}

button.btn-modal {
  background: none;
  font-size: 13px;
  line-height: 20px;
  padding-top: 4px;
  color: grey;
  margin-top: 6px
}

.checkbox-choice .modal p {
  margin: 0;
  width: 100%;
}

.checkbox-choice .modal img {
  width: 100%;
  display: block;
  margin: 0 auto;
  height: auto;
}

/*.modal {
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: 100;
}*/

.quantity {
  /*width: 50%;*/
  /*justify-content: space-around;*/
  margin: 10px 0;
}

.quantity input {
  margin: 0 10px;
  text-align: center;
}

.btn-plus,
.btn-minus {
  padding: 0 10px;
}

@media screen and (max-width: 375px) {
  .btn-plus,
  .btn-minus {
	padding: 0;
  }
}

.quantity .btn-plus:focus, .btn-minus:focus {
  box-shadow: none !important;
}

.quantity span {
  font-weight: initial;
  align-items: center;
  display: flex;
}

.form-control[type="number"]::-webkit-inner-spin-button,
.form-control[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inline-group {
  max-width: 9rem;
  padding: .5rem;
}

.inline-group .form-control {
  text-align: right;
}

@media screen and (max-width: 1220px){
  .contentBottom {
	font-size: 14px;
  }
}


@media screen and (max-width: 767px) {
  /* img[class*="img-1-2-"] {
	  width: 80%;
	  height: auto;
  } */
}

@media (min-width: 1200px) {
  .modal-dialog {
	max-width: 680px;
	margin: 1.875rem auto;
  }
}
</style>
