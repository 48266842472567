<template>
  <div class="home preconstructor">
	<div class="mask" :class="{ 'show-warning': showWarning }" />
	<div class="content">

		<div id="bandeau-top">
			<div id="bandeau-top-content">
				<div id="bandeau-title">
					{{ $t("Nos") }} <span class="blue-emphasis">{{ $t("configurateurs") }}</span>
				</div>
				<div id="bandeau-content">
					<div id="bandeau-text">
						{{ $t("Besoin d’aide pour faire votre choix ? Trouvez les références des équipements adaptés à votre véhicule en quelques clics grâce à notre configurateur NEMO.") }}
					</div>
					<div id="bandeau-content-bottom">
						<router-link to="/presentation" id="bandeau-video-link">
							<div id="bandeau-video-link-icon">
								<svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M6 4L1.50571e-07 7.4641L4.53412e-07 0.535898L6 4Z" fill="#003B85"/>
								</svg>
							</div>
							{{ $t("Voir la vidéo de démonstration") }}
						</router-link>
						<a
							id="bandeau-button-projects"
							:href="
								$i18n.locale === 'fr' ?
								`https://www.pommier.eu/customer#block--customer-configurator-projects` :
								`https://www.pommier.eu/${$i18n.locale}/customer#block--customer-configurator-projects`
							"
						>
							<svg width="23" height="15" viewBox="0 0 23 15" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd" d="M18.8011 13.6543C18.3955 14.0449 17.8577 14.296 17.2651 14.3384L15.6272 14.4556C12.1734 14.7027 8.70631 14.7027 5.25246 14.4556L3.73845 14.3473C2.54243 14.2617 1.55592 13.3776 1.3404 12.198C0.903734 9.8081 0.828978 7.36612 1.11864 4.95398L1.39112 2.68487C1.55283 1.33826 2.69523 0.324829 4.05152 0.324829H6.34308C7.46084 0.324829 8.39211 1.12329 8.59762 2.18095L16.9121 2.18095C18.2184 2.18095 19.3311 3.13016 19.537 4.42018L19.6011 4.82162C19.6146 4.90597 19.6276 4.99037 19.64 5.07483H19.976C21.4484 5.07483 22.4607 6.55466 21.9271 7.92695L20.6776 11.1398C20.2897 12.1375 19.6388 13.005 18.8011 13.6543ZM18.0558 4.65661L18.1199 5.05805C18.1208 5.06364 18.1217 5.06923 18.1225 5.07483H8.81028C7.67457 5.07483 6.65569 5.77298 6.24571 6.8321L3.91387 12.856L3.8455 12.8511C3.33202 12.8144 2.9085 12.4348 2.81597 11.9284C2.40634 9.68645 2.33621 7.39564 2.60794 5.13283L2.88042 2.86371C2.95161 2.27094 3.45449 1.82483 4.05152 1.82483H6.34308C6.78313 1.82483 7.13985 2.18155 7.13985 2.6216C7.13985 3.20666 7.61414 3.68095 8.1992 3.68095H16.9121C17.4812 3.68095 17.9661 4.09453 18.0558 4.65661ZM5.47903 12.9679C8.82206 13.2014 12.1775 13.1986 15.5202 12.9594L17.1581 12.8423L17.3527 12.8241L17.3513 12.8216C18.2318 12.3248 18.9132 11.5384 19.2796 10.5961L20.5291 7.38328C20.6803 6.99429 20.3934 6.57483 19.976 6.57483H8.81028C8.29405 6.57483 7.83092 6.89217 7.64456 7.37359L5.47903 12.9679Z"/>
							</svg>
							{{ $t("Mes projets") }}
						</a>
					</div>
			  
				</div>
			</div>
		</div>

		<div id="families-container">
			<div
				class="family"
				v-for="(familyGroup, familyGroupIndex) in familyGroups"
				:key="familyGroup.id"
			>
				<div class="scenario-title" v-if="familyGroupIndex === 1">
					{{ $t('Configurateurs produits de') }} <span class="blue-emphasis">{{ $t('nos partenaires') }}</span>
				</div>

				<div :class="['cards-slider', {noArrow: nbOfCards(familyGroup) <= displayedCards}]">

					<div
						id="arrow-back"
						:class="['arrow-btn', 'active']"
						@click="slideLeft(familyGroup)"
						v-if="nbOfCards(familyGroup) > displayedCards"
					>
						<svg width="17" height="30" viewBox="0 0 17 30" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M16.321 0.693696C17.2263 1.61862 17.2263 3.11822 16.321 4.04315L5.59659 15L16.321 25.9569C17.2263 26.8818 17.2263 28.3814 16.321 29.3063C15.4157 30.2312 13.9479 30.2312 13.0426 29.3063L0.678979 16.6747C-0.226327 15.7498 -0.226327 14.2502 0.678979 13.3253L13.0426 0.693696C13.9479 -0.23123 15.4157 -0.23123 16.321 0.693696Z"/>
						</svg>
					</div>

					<div class="cards" ref="slider">
						<FamilyCard
							v-for="(link, index) in orderedFamilies(familyGroup)"
							:link="link"
							:key="index"
							:apiUrl="apiUrl"
							:isPartenaire="familyGroupIndex === 1"
						/>
					</div>
			
					<div
						id="arrow-next"
						:class="['arrow-btn', 'active']"
						@click="slideRight(familyGroup)"
						v-if="nbOfCards(familyGroup) > displayedCards"
					>
						<svg width="17" height="30" viewBox="0 0 17 30" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M0.678979 29.3063C-0.226327 28.3814 -0.226327 26.8818 0.678979 25.9569L11.4034 15L0.678978 4.04315C-0.226328 3.11822 -0.226328 1.61862 0.678978 0.693695C1.58428 -0.231231 3.05208 -0.231231 3.95738 0.693696L16.321 13.3253C17.2263 14.2502 17.2263 15.7498 16.321 16.6747L3.95738 29.3063C3.05208 30.2312 1.58429 30.2312 0.678979 29.3063Z"/>
						</svg>
					</div>

				</div>

			</div>
		</div>
	</div>

	<div class="popup-valid" :class="{ 'show-warning': showWarning }">
		<h4 class="beforeStarting">{{ $t("Attention!") }}</h4>
			<p>
				{{ $t("Vous ne pouvez pas ajouter une réparation/diagnostic dans une configuration de produits.") }}
			</p>

			<p>
				{{ $t("Merci de terminer votre configuration de produits en cliquant sur le bouton « Voir mes produits »") }}
			</p>
			<button class="btn main" @click="togglewWarningModal()">
				{{ $t("J'ai compris") }}
			</button>
		</div>
	</div>
</template>


<script>
import { trackUserData } from "../lib/userTracker"
import FamilyCard from '@/components/FamilyCard.vue'
import _ from 'lodash'

export default {
	name: "PreConstructor",
	data() {
		return {
			showWarning: false,
			currentIdx: 0
		}
	},

	components: {
		FamilyCard
	},

	computed: {
		apiUrl() {
			return this.$store.state.apiUrl;
		},
		links() {
			return this.$store.state.families;
		},
		familyGroups() {
			return this.$store.state.familyGroups;
		},
		activeExternalLinks() {
			return this.links.filter((el) => el.is_active && el.lien_externe);
		},
		activeInternalLinks() {
			return this.links.filter((el) => el.is_active && !el.lien_externe);
		},
		products() {
			return this.$store.state.products;
		},
		hasProducts() {
			return this.products.length > 0;
		},
		hasSav() {
			return (
				this.hasProducts &&
				this.products.some((prod) => prod.productType === "sav")
			);
		},
		displayedCards () {
			if (window.innerWidth > 1288) {
				return 4
			} else if (window.innerWidth > 988) {
				return 3
			} else {
				return 2
			}
		},

		cardWidth () {
			return window.innerWidth < 600 ? 148 : 272
		}
	},
	methods: {

		orderedFamilies (familyGroup) {
			return _.orderBy(familyGroup.familles, 'ordre')
		},

		nbOfCards (familyGroup) {
			if (familyGroup.familles.length) {
				return familyGroup.familles.length
			} else {
				return Object.keys(familyGroup.familles).length
			}
		},

		slideLeft (familyGroup) {
			if (this.currentIdx === 0) {
				this.currentIdx = this.nbOfCards(familyGroup) - this.displayedCards
				this.$refs.slider[0].scrollTo(this.cardWidth * this.currentIdx, 0)
			} else {
				this.currentIdx --
				this.$refs.slider[0].scrollTo(this.cardWidth * this.currentIdx, 0)
			}
		},

		slideRight (familyGroup) {
			if (this.displayedCards + this.currentIdx + 1 > this.nbOfCards(familyGroup)) {
				this.currentIdx = 0
				this.$refs.slider[0].scrollTo(0, 0)
			} else {
				this.currentIdx ++
				this.$refs.slider[0].scrollTo(this.cardWidth * this.currentIdx, 0)
			}
		},

		configureClick(linkPrefix) {
			trackUserData(linkPrefix)
		},

		togglewWarningModal() {
			this.showWarning = !this.showWarning;
		},

		checkPrevent(e, link) {
			if (this.hasProducts && !this.hasSav) {
				this.togglewWarningModal();
			} else {
				this.$router.push(link);
			}
		}
	}
};
</script>

<style lang="scss">
#bandeau-top {
	width: 100%;
	height: auto;
	padding: 20px 0;
	background: url("../../public/img/bandeau_NEW.png");
	background-position: center;

	background-size: 100%;
	background-position-x: 60%;
	background-position-y: 48%;

	display: flex;
	justify-content: space-around;
	align-items: center;

	position: relative;
	//padding: 0 120px 0 120px;
	gap: 20px;

	#bandeau-top-content {
		display: flex;
		justify-content: space-between;
		width: 1000px;
	}

	#bandeau-title {
		text-transform: uppercase;
		color: white;
		font-size: 22px;
		width: 240px;
		font-weight: 600;
		display: flex;
		align-items: center;
		gap: 5px;
		white-space: nowrap;
	}

	#bandeau-content {
		display: flex;
		flex-direction: column;
		gap: 30px;
		width: 600px;

		color: white;

		#bandeau-text {
			text-align: left;
			font-size: 16px;
		}

		#bandeau-content-bottom {
			display: flex;
			justify-content: space-between;

			#bandeau-video-link {
				display: flex;
				align-items: center;
				gap: 5px;
				transition: 0.1s;
				color: white;
				font-weight: 600;
				flex-shrink: 0;

				&:hover {
					color: white;
					text-decoration: none!important;
					transition: 0.1s;
				}

				#bandeau-video-link-icon {
					width: 18px;
					height: 18px;
					border-radius: 9px;
					background: white;

					display: flex;
					justify-content: center;
					align-items: center;
				}
			}

			#bandeau-button-projects {
				height: 50px;
				background: white;
				padding: 10px 20px;
				border-radius: 10px;

				display: flex;
				justify-content: center;
				align-items: center;
				gap:  5px;
				transition: 0.2s;

				&:hover {
					background: #003B85;
					color: white;
					text-decoration: none!important;
					transition: 0.2s;

					svg {
						fill: white;
						transition: 0.2s;
					}
				}

				svg {
					fill: #003B85;
					transition: 0.2s;
				}
			}
		}
	}
}

#families-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	background: #f5f5f5;

	.family {
		display: flex;
		flex-direction: column;
		align-items: center;

		.scenario-title {
			font-size: 25px;
			font-weight: 600;
			color: black;
			//width: 270px;
		}
	}

	.cards-slider {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;
		margin-top: 20px;
		width: 90vw;

		.arrow-btn {
			cursor: pointer;
			fill: gray;
			pointer-events:none;

			svg {
				fill: gray;
			}

			&.active {
				svg {
					fill: #003B85;
				}
				pointer-events: unset;
			}
		}

		.cards {
			display: flex;
			gap: 10px;
			overflow-x: hidden;
			scroll-behavior: smooth;
		}
	}
}

@media only screen and (min-width: 600px) {
	.cards-slider {
		width: 590px!important;
	}

	/* #bandeau-top {
		padding: 20px 20px;
		background-size: 164%;
		background-position-x: 60%;
		background-position-y: 48%;
	} */
}

@media only screen and (min-width: 988px) {
	.cards-slider {
		width: 860px!important;
	}
}

@media only screen and (max-width: 988px) {
	#bandeau-top {
		background-size: 130%;
		background-position-x: 27%;
	}

	#bandeau-top-content {
		width: 800px!important;
	}
}

@media only screen and (max-width: 888px) {
	#bandeau-top-content {
		width: 700px!important;
	}
}

@media only screen and (max-width: 788px) {
	#bandeau-top-content {
		width: 600px!important;
	}
}

@media only screen and (max-width: 600px) {
	#bandeau-top-content {
		width: auto!important;
	}
}

@media only screen and (min-width: 1288px) {

	.cards-slider {
		width: 1130px!important;
	}
}

@media only screen and (max-width: 1288px) {

	#bandeau-top-content {
		width: 900px;
	}
}

@media only screen and (max-width: 500px) {
	#bandeau-top {
		background-size: 202%!important;
		background-position-x: 64%!important;
	}
}

@media only screen and (max-width: 988px) {
	#bandeau-top {
		height: 260px;
		padding: 20px;
		height: auto;
		//flex-direction: column;
		align-items: flex-start;
		background-size: 134%;
		background-position-x: 36%;
		justify-content: flex-start;

		#bandeau-top-content {
			width: 150px!important;
			flex-direction: column!important;
			align-items: flex-start;
		}

		#bandeau-content {
			width: 90vw!important;
		}

		#bandeau-title {
			text-align: left;
		}

		#bandeau-text {
			text-align: left;
		}

		#bandeau-content-bottom {
			flex-direction: column;
			gap: 10px;

			#bandeau-video-link {
				color: white;
			}

			#bandeau-button-projects {
				background: none;
				border: 1px solid white;
				color: #003B85;

				svg {
					fill: white;
				}
			}
		}
	}
}

.blue-emphasis {
	color: #3D97D0;
}

.text-router-link-wrapper h3 {
  font-size: 1.8em;
}

.picto-univers img {
  width: 80px !important;
  float: right;
}

.popup-valid.show-warning,
.mask.show-warning {
  display: block;
}

.displayNone {
  display: none;
}

.error {
  color: red;
}

.preconstructor-item-2 
{
  background-color: #585858!important;
}

.preconstructor-item-2 .choice-link
{
  color: white!important;
}

.preconstructor-item-2 .choice-link-button, .preconstructor-item-2 .choice-link-button:hover
{
  background-color: white;
  color: #004494;
}

div.popup-valid {
  display: none;
  /* width: 40%; */
  /* margin: 50px auto; */
  /* box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.4); */
  /*box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);*/
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  background-color: #ffffff;
  padding: 60px 25px;
  text-align: center;
  font-family: sans-serif;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*border: 1px solid #efefef;*/
  border-radius: 3px;
  z-index: 100;
}

.mask {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  z-index: 100;
  display: none;
  margin-left: 0;
}

.popup-valid:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.popup-valid .title {
  font-size: 24px;
  margin-bottom: 20px;
}

.popup-valid form {
  width: 90%;
  margin: 0 auto;
}

.popup-valid form input {
  height: 35px;
  width: 100%;
  padding: 5px 10px;
  display: block;
  box-sizing: border-box;
}

.popup-valid .button-list {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.popup-valid .button-list .btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
	border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #d8d8d8;
  border-radius: 0;
}

.popup-valid .button-list .btn:hover {
  cursor: pointer;
}

.popup-valid .button-list .btn.main {
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 3px;
  padding: 10px 20px;
}

.popup-valid .button-list .btn:hover.main {
  transition: all 0.3s;
  background-color: #c4c4c4;
}

.popup-valid .button-list .btn:hover {
  background-color: #c4c4c4;
}

.choice-list ul,
li {
  padding: 0;
  margin: 0;
}

.choice-list ul {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  margin-bottom: 10%;
}

.choice-list li {
  padding-left: 1em;
  text-indent: -1em;
}

.choice-list li:before {
  content: "\f00c";
  font-family: FontAwesome;
  padding-right: 5px;
}

.choice-link {
  height: 50px;
  width: 100%;
  display: table;
  margin-bottom: 0.75rem;
}

h4.choice-link {
  color: #004494;
  font-weight: bold;
  float: left;
}

.choice-link > span {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

a.choice-link-button {
  padding: 15px 10px;
  width: 80%;
  margin-bottom: 15px;
  border-radius: 10px !important;
}

.choice-link-button:hover {
  transition: all 0.3s;
  text-decoration: none;
  color: #efefef;
  background: #3d3d3d;
}

.home.preconstructor {
  min-height: calc(100% - 125px);
}

.container_router-links {
  max-width: 90%;
  /*margin-top: 5em;*/
}

.router-links-row {
  margin: 0.5em 0;
}

.container_router-links .router-link {
  width: 100%;
  padding: 10px 10px 10px 10px;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #004494;
  box-shadow: 0 0 20px 20px rgba(0, 0, 0, 0.05);
  min-height: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.router-link img {
  height: auto;
  width: 100%;
}

.text-router-link-wrapper {
  padding: 1em 0;
  width: 80%;
  margin: auto;
  display: flex;
}

.router-link p span {
  margin-top: 20px;
  text-transform: uppercase;
  text-align: left;
  font-size: 14px;
  color: #868686;
  font-weight: 600;
}

.home-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  content: "";
  top: 0;
  bottom: 0;
  background-image: url("../../public/img/landing/bg-header.jpg");
  background-size: cover;
  z-index: 0;
  filter: grayscale(100%);
}

@media screen and (min-width: 0\0) and (min-resolution: +72dpi) {
  .home-overlay {
	background: url("../../public/img/landing/bg-header-bw.jpg") no-repeat
	  center;
  }
}

.content {
  position: relative;
  z-index: 6;
  padding: 0 0 50px 0;
}

.content h2 {
  width: 100%;
  color: #000;
  padding: 20px;
}

.choices-container {
  width: 80%;
  flex-wrap: wrap-reverse;
  height: auto;
  margin: 0 auto;
}

.home .choice {
  width: 260px;
  height: 260px;
  margin: 0 auto;
}

.router-link {
  flex: 1 0 20%;
  margin: 5px 1%;
}

.router-link:first-child {
  margin: 5px 0%;
}

.choice-link.coming {
  margin: 0;
  font-style: italic;
}

.router-link.coming {
  border-color: lightgray;
  cursor: not-allowed;
}

.router-link.coming img {
  opacity: 0.5;
}

.router-link.coming .choice-link {
  margin-bottom: 0;
  font-style: italic;
  color: grey;
}

.line {
  background-color: white;
  height: 8px;
  width: 98%;
  margin-top: 50px;
}

/* @media screen and (max-width: 520px) {
  .home .container_router-links {
	max-width: 85%;
	margin-top: 2em;
  }

  .home .container_router-links > .row {
	margin: 0;
  }

  .home .text-router-link-wrapper {
	padding: 1em 1em;
  }
} */
</style>
