import getAnswers from "../../lib/getAnswers";
import getQuestion from "../../lib/getQuestion";

export default {
    methods: {
        saveProduct() {
            const answers = getAnswers(this.answers);
            const history = [];
            answers.forEach((answer) => {
                const question = getQuestion(answer.variable, this.steps);
                const filteredByVariable = answers.filter((elem => {
                    return elem.questionIndex === answer.questionIndex && elem.stepIndex === answer.stepIndex
                }));


                if (filteredByVariable.length > 1) {
                    if (question !== null && question.compile !== undefined) {
                        if (history.find(elem => {
                            if (elem !== null && question !== null) return elem.label === question.label
                        }) === undefined) {
                            let compileText = "";
                            filteredByVariable.forEach((el, index) => {
                                //console.log(el, index)
                                if (index === 0)
                                    compileText += ' ' + this.$i18n.t(el.name) ;
                                else
                                    compileText += ' | ' + this.$i18n.t(el.name);

                                if (el.quantity)
                                    compileText += ' x '+el.quantity;

                                if(el.lengthOfPerimeter)
                                    compileText += ' : '+el.lengthOfPerimeter + " m";
                                
                                if(el.name=== this.$t("Poids"))
                                    compileText += ' : '+el.value + " kg";
                                
                                if(el.name=== this.$t("Hauteur"))
                                    compileText += ' : '+el.value + " m";


                            });

                            history.push({
                                isGlobal: answer.isGlobal,
                                label: question ? question.label || question.placeholder || answer.label : answer.variable,
                                value: compileText,
                                delai : answer.delai
                            })
                        }
                    } else {
                        let compileText ='';
                        if (answer.quantity)
                           compileText = answer.name + ' x '+answer.quantity;
                        else
                            compileText = answer.name;
    
                        if(answer.lengthOfPerimeter)
                            compileText += ' : '+el.lengthOfPerimeter + " m";
    
                        if(answer.name === this.$t("Poids"))
                            compileText += ' : '+answer.lengthOfPerimeter + " kg";
                        
                        if(answer.name === this.$t("Hauteur"))
                            compileText += ' : '+answer.lengthOfPerimeter + " m";
    
                        history.push({
                                isGlobal: answer.isGlobal,
                                label: answer.label || answer.variable,
                                value: compileText,
                                delai : answer.delai
                            });


                    }
                } else {
                    if (answer.questionType === "MultipleInputsBasic"){
                        history.push({
                            isGlobal: answer.isGlobal,
                            label: answer.label,
                            value:  answer.value
                        });
                    } else{
                        if (question && question.label === this.$t("Sous Total")){
                            history.push({
                                isGlobal: answer.isGlobal,
                                label: question ? this.$t(question.label) || this.$t(question.placeholder) || this.$t(answer.label) : answer.variable,
                                value: answer.rang.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')+" €",
                                delai: answer.delai

                            });
                        } else{
                            let compileText ='';
                            if (answer.quantity)
                                compileText = (answer.label||answer.name) + ' x '+answer.quantity;
                            else
                                compileText = answer.label || answer.name || answer.value ;
    
                            if(answer.lengthOfPerimeter)
                                compileText += ' : '+el.lengthOfPerimeter + " m";
    
                            if(answer.name === this.$t("Poids"))
                                compileText += ' : '+answer.value + " kg";
                            
                            if(answer.name === this.$t("Hauteur"))
                                compileText += ' : '+answer.value + " m";
    
                            history.push({
                                isGlobal: answer.isGlobal,
                                label: question ? question.label || question.placeholder || answer.label : answer.variable,
                                value: compileText,
                                delai: answer.delai

                            });
                        }

                    }

                }
            });
            history.forEach((el) => {
                el.label = this.$i18n.t(el.label);
                el.value = this.$i18n.t(el.value);
            })
            if (this.$store.state.products[this.$store.state.currentProductKey] === undefined )
                this.$store.dispatch('saveProduct', {history, deleted : false});
            else
                this.$store.dispatch('saveProduct', {history, deleted : this.$store.state.products[this.$store.state.currentProductKey].deleted});
        }
    },
    computed: {
        answers() {
            return this.$store.state.answers;
        },

        history() {
            const answers = getAnswers(this.answers);
            const history = [];
            answers.forEach((answer) => {
                const question = getQuestion(answer.variable, this.steps);
                const filteredByVariable = answers.filter((elem => {
                    return elem.questionIndex === answer.questionIndex && elem.stepIndex === answer.stepIndex
                }));


                if (filteredByVariable.length > 1) {
                    if (question !== null && question.compile !== undefined) {
                        if (history.find(elem => {
                            if (elem !== null && question !== null) return elem.label === question.label
                        }) === undefined) {
                            let compileText = "";
                            filteredByVariable.forEach((el, index) => {
                                //console.log(el, index)
                                if (index === 0)
                                    compileText += ' ' + this.$i18n.t(el.name) ;
                                else
                                    compileText += ' | ' + this.$i18n.t(el.name);

                                if (el.quantity)
                                    compileText += ' x '+el.quantity;

                                if(el.lengthOfPerimeter)
                                    compileText += ' : '+el.lengthOfPerimeter + " m";
                                
                                if(el.name=== this.$t("Poids"))
                                    compileText += ' : '+el.value + " kg";
                                
                                if(el.name=== this.$t("Hauteur"))
                                    compileText += ' : '+el.value + " m";


                            });

                            history.push({
                                isGlobal: answer.isGlobal,
                                label: question ? question.label || question.placeholder || answer.label : answer.variable,
                                value: compileText,
                                delai : answer.delai
                            })
                        }
                    } else {
                        let compileText ='';
                        if (answer.quantity)
                           compileText = answer.name + ' x '+answer.quantity;
                        else
                            compileText = answer.name;
    
                        if(answer.lengthOfPerimeter)
                            compileText += ' : '+el.lengthOfPerimeter + " m";
    
                        if(answer.name === this.$t("Poids"))
                            compileText += ' : '+answer.lengthOfPerimeter + " kg";
                        
                        if(answer.name === this.$t("Hauteur"))
                            compileText += ' : '+answer.lengthOfPerimeter + " m";
    
                        history.push({
                                isGlobal: answer.isGlobal,
                                label: answer.label || answer.variable,
                                value: compileText,
                                delai : answer.delai
                            });


                    }
                } else {
                    if (answer.questionType === "MultipleInputsBasic"){
                        history.push({
                            isGlobal: answer.isGlobal,
                            label: answer.label,
                            value:  answer.value
                        });
                    } else{
                        if (question && question.label === this.$t("Sous Total")){
                            history.push({
                                isGlobal: answer.isGlobal,
                                label: question ? this.$t(question.label) || this.$t(question.placeholder) || this.$t(answer.label) : answer.variable,
                                value: answer.rang.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')+" €",
                                delai: answer.delai

                            });
                        } else{
                            let compileText ='';
                            if (answer.quantity)
                                compileText = (answer.label||answer.name) + ' x '+answer.quantity;
                            else
                                compileText = answer.label || answer.name || answer.value ;
    
                            if(answer.lengthOfPerimeter)
                                compileText += ' : '+el.lengthOfPerimeter + " m";
    
                            if(answer.name === this.$t("Poids"))
                                compileText += ' : '+answer.value + " kg";
                            
                            if(answer.name === this.$t("Hauteur"))
                                compileText += ' : '+answer.value + " m";
    
                            history.push({
                                isGlobal: answer.isGlobal,
                                label: question ? question.label || question.placeholder || answer.label : answer.variable,
                                value: compileText,
                                delai: answer.delai

                            });
                        }

                    }

                }
            });
            history.forEach((el) => {
                el.label = this.$i18n.t(el.label);
                el.value = this.$i18n.t(el.value);
            })
            if (this.$store.state.products[this.$store.state.currentProductKey] === undefined )
                this.$store.dispatch('saveProduct', {history, deleted : false});
            else
                this.$store.dispatch('saveProduct', {history, deleted : this.$store.state.products[this.$store.state.currentProductKey].deleted});

            return history;
        },

        steps() {
            return this.$store.state.steps;
        },

    },
}

