<template>
    <div class="card" v-bind:class="question.type">
        <!-- <div class="card-header" :id="'header-'+step+'Question'+question.id" data-toggle="collapse"
             :data-target="'#collapseStep'+step+'Question'+question.id"
             v-bind:aria-expanded="question.openByDefault ? 'true' : 'false'"
             aria-controls="collapseOne"
             :class="{'answered' : showSpoiler.length !== 0}"

        >
            <h5 class="mb-0">
                <button class="btn btn-link">
                    {{ $t(question.extraHtml.label) }}

                </button>

                <div class="recap" v-show="showSpoiler.length !== 0 ">
                    <ul>
                        <li v-for="color in showSpoiler" v-if="color !== undefined"
                            :style="{ backgroundImage: 'url(https://api.laboutiquerenovart.fr/' + color.ImageBg + ')', backgroundColor:color.Hexa}"></li>
                    </ul>
                </div>
            </h5>
        </div> -->

        <div :id="'collapseStep'+step+'Question'+question.id" class="collapse"
             v-bind:class="{show : question.openByDefault}"
             :aria-labelledby="'collapseStep'+step+'Question'+question.id" data-parent="#main-form">
            <div class="extrahtml" v-if="question.extraHtml !== undefined && question.extraHtml.content_top !== null !== undefined && question.extraHtml.content_top !== null"
                 v-html="$t(question.extraHtml.content_top)"></div>
            <button v-if="question.extraHtml.label_comment_faire !== undefined && question.extraHtml.label_comment_faire !== null && question.extraHtml.label_comment_faire"
                    @click.stop="fillAndOpenGlobalModal(question.extraHtml)" type="button" class="open-modal btn-modal general-question"
                    data-toggle="modal"
                    :data-target="'#modal'+step+'-'+question.id">
                {{ $t(question.extraHtml.label_comment_faire) }}
            </button>
            <div class="card-body">
                <div v-for="(answer, answerIndex) in question.answers" class="color-choice">
                    <div v-for="(selection, selectionIndex) in answer.selection">
                        <p>{{selection.label}}</p>
                        <p>{{selection.description}}</p>
                        <ul>
                            <li v-for="(color, colorIndex) in selection.colors">
                                <span v-if="color !== undefined && color.ImageBg !== '' && color.ImageBg"
                                      v-bind:class="{selected :  answer.value === color && selection.value ==  answer.selectionIndex}"
                                      v-bind:style="{ backgroundImage: 'url(https://api.laboutiquerenovart.fr/' + color.ImageBg + ')'}"
                                      v-on:click="addToAnswers(answerIndex, selection.value, color, selection.label)"
                                      v-bind:title="answer.Nom"
                                      :data-tooltip="color.Nom" data-tooltip-position="top"
                                ></span>

                                <span v-else
                                      v-bind:class="{selected :  answer.value === color && selection.value ==  answer.selectionIndex}"
                                      v-bind:style="{ 'background-color': color.Hexa}"
                                      v-on:click="addToAnswers(answerIndex, selection.value, color)"
                                      v-bind:title="answer.Nom"
                                      :data-tooltip="color.Nom" data-tooltip-position="top"
                                ></span>

                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import common from './mixins/common'

    export default {
        name: "ColorRadio",

        props: {
            question: Object,
            questions: Object,
            step: [String, Number],
        },

        mixins: [common],

        data: function () {
            return {
                showSpoiler: [],
                lastClicked: null
            }
        },

        methods: {
            fillAndOpenGlobalModal(answer) {
                let modal = $('#basicModal');
                modal.find('h5').text(answer.label_comment_faire);
                modal.find('.extra-popup').html(answer.popup_comment_faire);
                modal.modal('show');

            },
            addToAnswers: function (answerIndex, selectionIndex, color, selectionLabel) {

                if (window.innerWidth < 1200) {
                    if (this.lastClicked !== JSON.stringify(color)){
                        this.lastClicked = JSON.stringify(color);
                        return;
                    }
                }

                const questionIndex = this.question.id;

                const answer = this.questions[questionIndex].answers[answerIndex];

                answer.value = color;
                answer.selectionIndex = selectionIndex;

                this.showSpoiler[answerIndex] = color;
                this.$store.dispatch('addAnswersThenCalc', {
                    isGlobal: this.question.global !== undefined && this.question.global,

                    stepIndex: this.step,
                    questionIndex: questionIndex,
                    questionType: this.questions[questionIndex].type,
                    selectionIndex: selectionIndex,
                    effectOnSvg: this.questions[questionIndex].effectOnSvg,
                    index: answerIndex,
                    value: color.id,
                    hex: color.Hexa,
                    background: color.ImageBg,
                    name: color.Nom,
                    variable: this.questions[questionIndex].variable || null,
                    selectionLabel: selectionLabel,
                    photo_url: color.photo_url
                });

                this.openNextQuestion(this.step, this.question.id);

            },

            initialize() {
                const showSpoiler = _.cloneDeep(this.showSpoiler);
                if (this.answers[this.step] && this.answers[this.step][this.question.id]) {
                    Object.keys(this.answers[this.step][this.question.id]).forEach((idx) => {
                        this.showSpoiler[idx] = {
                            Hexa: this.answers[this.step][this.question.id][idx].hex || showSpoiler[idx],
                            ImageBg: this.answers[this.step][this.question.id][idx].background || showSpoiler[idx],
                        };
                    });
                }

                this.$forceUpdate()
            }
        }
    }
</script>

<style scoped>
    [data-tooltip] {
        position: relative;
    }

    /* Tooltip styling */
    [data-tooltip]:before {
        content: attr(data-tooltip);
        display: none;
        position: absolute;
        background: #000;
        color: #fff;
        padding: 4px 8px;
        font-size: 14px;
        line-height: 1.4;
        min-width: 100px;
        text-align: center;
        border-radius: 4px;
    }

    /* Dynamic horizontal centering */
    [data-tooltip-position="top"]:before,
    [data-tooltip-position="bottom"]:before {
        left: 50%;
        -ms-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    /* Dynamic vertical centering */
    [data-tooltip-position="right"]:before,
    [data-tooltip-position="left"]:before {
        top: 50%;
        -ms-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    [data-tooltip-position="top"]:before {
        bottom: 100%;
        margin-bottom: 10px;
    }

    /* Tooltip arrow styling/placement */
    [data-tooltip]:after {
        content: '';
        display: none;
        position: absolute;
        width: 0;
        height: 0;
        border: solid transparent;
    }

    /* Dynamic horizontal centering for the tooltip */
    [data-tooltip-position="top"]:after {
        left: 50%;
        margin-left: -6px;
        margin-bottom: 4px;
    }

    [data-tooltip-position="top"]:after {
        bottom: 100%;
        border-width: 6px 6px 0;
        border-top-color: #000;
    }

    /* Show the tooltip when hovering */
    [data-tooltip]:hover:before,
    [data-tooltip]:hover:after {
        display: block;
        z-index: 50;
    }

    .card-body {
        display: flex;
    }

    .color-choice {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-evenly;
    }
    /*.dis .color-choice li span {*/
        /*height:100px;*/
    /*width: 100px;*/
        /*border-radius:0;*/
    /*}*/
    /*.dis .color-choice li span:after {*/
             /*content:attr(data-tooltip);*/
             /*display: flex;color:white;*/
        /*width:100%;*/
        /*height:100%;*/
             /*border: none;*/
             /*margin: inherit;*/
             /*align-items:center;*/
        /*justify-content: center;*/
             /*left: inherit;*/
             /*bottom: initial;*/
    /*}*/
       
    .color-choice > div {
        width: 50%;
    }

    .color-choice > div:nth-child(3) {
        width: 100%;
    }

    .color-choice p {
        margin: 5px;
    }

    ul {
        margin: 0;
        padding: 0;

        list-style: none;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
    }

    li {
        margin: 5px;
    }

    .recap li {
        display: block;
        width: 18px;
        height: 18px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
    }

    span {
        display: block;
        width: 18px;
        height: 18px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        transition: 0.2s ease;
        border: solid 1px grey;
        cursor: pointer;
        position: relative;
    }

    span.selected {
        transform: scale(1.3);
    }

    @media screen and (max-width: 767px) {
        span {
            width: 30px;
            height: 30px;
        }

        .color-choice > div {
            width: 100%;
            margin-bottom: 40px;
        }
    }
</style>

