<template>
	<a id="BackToSiteButton" target="_blank" :href="link">
		{{ $t('Retour vers le site') }}
	</a>
</template>

<script>
export default {
	name: 'BackToSiteButton',
	props: {
		link: {
			type: String,
			default: 'https://www.pommier.eu/en/contact-us'
		}
	},
	data () {
		return {}
	},
	methods: {}
}
</script>

<style lang="scss" scoped>
#BackToSiteButton {
	padding: 10px;
    background: #003B85;
    color: white;
    border-radius: 5px;
    height: 40px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    transition: 0.2s;

	&:hover {
		border: 1px solid #003B85;
		background: white;
		color: #003B85;
		text-decoration: none!important;
		transition: 0.2s;
	}
}

@media only screen and (max-width: 600px) {
	#BackToSiteButton {
		width: 90vw;
	}
}
</style>
