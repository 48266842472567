<template>
    <div class="sidebar">
        <ProductPreview></ProductPreview>
        <UserHistory></UserHistory>
    </div>
</template>


<style>
    .sidebar{
        height:100%;
        width:100%;
        justify-content: space-around;
        background: rgba(255,255,255,0.7);

    }
</style>


<script>
    import UserHistory from "./side-bar/UserHistory";
    import ProductPreview from "./side-bar/ProductPreview";
    export default {
        name : 'SideBar',
        components: {ProductPreview, UserHistory}
    }
</script>