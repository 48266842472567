<template>
    <div class="card" v-bind:class="[question.type]">
       <!--  <div class="card-header" :id="'header-'+step+'Question'+question.id" data-toggle="collapse"
             :data-target="'#collapseStep'+step+'Question'+question.id"
             v-bind:aria-expanded="question.openByDefault ? 'true' : 'false'"
             aria-controls="collapseOne"
             :class="{'answered' : showSpoiler!==false}"

        >
            <h5 class="mb-0">
                <button class="btn btn-link">
                    {{ $t(question.label) }}
                </button>
                <div class="recap" v-show="showSpoiler != false">
                    <span v-html="showSpoiler">{{ $t(showSpoiler) }};</span>
                </div>
            </h5>
        </div> -->
        <div :id="'collapseStep'+step+'Question'+question.id" class="collapse"
             v-bind:class="{show : question.openByDefault}"
             :aria-labelledby="'collapseStep'+step+'Question'+question.id" data-parent="#main-form">
            <label v-if="question.placeholder" v-html="question.placeholder">{{ question.placeholder }}</label>

            <!--  <div v-if="question.extraHtml !== undefined && question.extraHtml.content_top !== null !== undefined && question.extraHtml.content_top !== null"
                 v-html="question.extraHtml.content_top"></div> -->
            <div class="card-body">
                <!-- <div v-for="(answer, answerKey) in question.answers" class="checkbox-choice"
                     v-bind:class="{ selected: answer.isAnswer }"
                     v-on:click="addToAnswers(answerKey)">
                    {{ $t(answer.label) }}
                </div> -->

                <p v-if="isBackCapLocked">{{ $t("Afin d’atteindre le récapitulatif , vous devez répondre aux 2 questions suivantes : modalités de pose et modalités de livraison") }}</p>
                <div class="row">
                    <div v-for="(answer, answerKey) in question.answers"
                        :class="[{'avecPose' : answer.value, 'sansPose' : !answer.value}, question.answers.length > 1 ? 'col-md-6 col-xs-6 col-sm-6' : 'col-md-12 noTransform']">
                        <h3 v-html="$t(answer.title)"></h3>
                        <div v-html="$t(answer.description)" class="ulAlign"></div>

                        <a class="choice-link-button "
                           :class="{'grey-one' : answer.value, 'yellow-one' : !answer.value}"
                           v-on:click="addToAnswers(answerKey)">{{ $t("Sélectionner") }}</a>
                    </div>
                    <!-- <div class="col-md-6 col-xs-6 col-sm-6 sansPose">
                         <h3>Sans <strong>pose</strong></h3>
                         <br>
                         <ul>
                             <li>Livraison à domicile</li>
                             <br>
                             <br>
                             <li>Autonomie</li>
                             <br>
                             <br>
                             <li>TVA 20%</li>
                             <br>
                         </ul>
                         <a class="choice-link-button yellow-one">Sélectionner</a>
                     </div>-->
                    <!-- <div class="col-md-12" style="background: #fdc600;">
                        <h3>Avec <strong>pose</strong></h3>
                        <br>
                        <ul>
                            <li>
                                <strong>Tranquillité,</strong> réception et vérification des produits par l'artisan
                            </li>
                            <br>
                            <li>
                                <strong>Gain de temps,</strong> nos experts se chargent de tout
                            </li>
                            <br>
                            <li>
                                <strong> TVA 5,5%</strong>
                            </li>
                            <br>
                        </ul>
                        <a class="choice-link-button grey-one">Sélectionner</a>
                    </div> -->
                </div>
            </div>
            <!-- <div class="center-content" v-if="question.extraHtml !== undefined && question.extraHtml.content_bottom !== null !== undefined && question.extraHtml.content_bottom !== null"
                 v-html="question.extraHtml.content_bottom"></div> -->
        </div>
    </div>
</template>

<script>
    import common from './mixins/common'

    export default {
        name: "PoseChoice",

        props: {
            question: Object,
            questions: Object,
            step: [String, Number]
        },

        mixins: [common],

        data: function () {
            return {
                showSpoiler: false
            }
        },
        computed:{
            isBackCapLocked(){
                return this.$store.state.lockBackCap
            },
        },
        methods: {
            addToAnswers: function (answerIndex) {
                const questionIndex = this.question.id;

                Object.keys(this.questions[questionIndex].answers).forEach((key) => {
                    this.questions[questionIndex].answers[key].isAnswer = false;
                });

                const answer = this.questions[questionIndex].answers[answerIndex];
                answer.isAnswer = true;

                this.showSpoiler = answer.title;
                this.$store.dispatch('addAnswersThenCalc', {
                    isGlobal : this.question.global !== undefined && this.question.global,
                    name : this.questions[questionIndex].label,
                    stepIndex: this.step,
                    questionIndex: questionIndex,
                    questionType: this.questions[questionIndex].type,
                    effectOnSvg: this.questions[questionIndex].effectOnSvg,
                    index: 0,
                    label: answer.title,
                    value: answer.isAnswer ? answer.value : null,
                    variable: this.questions[questionIndex].variable || null,
                    changes: answer.changes !== undefined ? answer.changes : null,
                });
                this.openNextQuestion(this.step, this.questions[questionIndex].id, this);
            },

            initialize() {
                if (this.answers[this.step] && this.answers[this.step][this.question.id]) {
                    const answers = this.answers[this.step][this.question.id];

                    Object.keys(answers).forEach((idx) => {
                        answers[idx].isAnswer = true;
                        this.showSpoiler = answers[idx].title || this.showSpoiler;
                    });
                }
            },
        }
    }
</script>

<style scoped>
    strong {
        font-weight: bold !important;
    }

    ul, li {
        list-style-type: none;
        padding: 0;
    }

    li{
        margin: 10px 0
    }

    li:before {
        content: '- ';
    }

    div.col-md-6, div.col-md-12 {
        text-align: left;
        padding: 25px 25px;
        /* margin: 0 4.1666667%; */
    }

    .grey-one {
        border: 2px solid transparent;
    }

    .grey-one::before {
        background: #fff;
    }

    .grey-one:hover {
        border: 2px solid #efefef;
    }

    .choice-link-button {
        text-decoration: none !important;
        text-align: center;
        color: #fff !important;
        width: 60%;
        margin: 15px 0 10px 10px;
    }

    .yellow-one {
        background: transparent;
    }

    .card-body {
        justify-content: space-around;
    }

    .card-body > div:not(.row) {
        width: initial;
    }

    .center-content {
        display: flex;
        justify-content: center;
        min-height: 100px;
    }

    .checkbox-choice {
        cursor: pointer;
        padding: 20px;
        border: 1px solid #ccc;
        margin-bottom: 10px;
    }

    .checkbox-choice:hover {
        background-color: #fafafa;
    }

    .checkbox-choice.selected {
        font-weight: bold;
        background-color: #fafafa;
        cursor: default;
    }

    .avecPose {
        /* transform: translateX(-5%); */
    }
    .avecPose::before{
        content:'';
        position: absolute;
        right: 3%;
        top: 3%;
        display: block;
        background-repeat: no-repeat;
        background-image: url('/img/etape3-installation_black.png');
        height: 60px;
        width: 60px;
    }

    .sansPose {
        background: #efefef;
        /* transform: translateX(5%); */
    }

    .noTransform {
        transform: translateX(0%);
    }
    label {
        margin-top: 25px;
    }

    @media screen and (max-width: 767px) {
        .checkbox-choice {
            margin-bottom: 30px;
            width: 40%;
        }

        .avecPose {
            transform: translateX(0%);
        }

        .sansPose {
            background: #efefef;
            transform: translateX(0%);
        }
    }
</style>