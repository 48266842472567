<template>
	<div id="QuestionContainer">
		<QuestionTitle :step="step" :question="question" :showSpoiler="false" v-if="question.type !== 'Recapitulatif'" />
		<component
            :is="question.type"
            :question="question"
            :questions="step.questions"
            :step="step.id"
            :key="step.id"
            :isFirstQuestion="question.id === 1"
            :opened="$store.state.currentQuestion === parseInt(question.id) && $store.state.currentStep === parseInt(step.id) && $store.state.ready"
            v-if="$store.state.currentQuestion === parseInt(question.id) && $store.state.currentStep === parseInt(step.id) && $store.state.ready"
        />
	</div>
</template>

<script>
import QuestionTitle from '@/components/QuestionTitle.vue'
import ChangeStep from '@/components/ChangeStep.vue'
import ColorRadio from '@/components/form-groups/ColorRadio.vue'
import ImageRadioChoice from '@/components/form-groups/ImageRadioChoice.vue'
import MultipleImageRadioChoice from '@/components/form-groups/MultipleImageRadioChoice.vue'
import MultipleImageRadioChoiceSelected from '@/components/form-groups/MultipleImageRadioChoiceSelected.vue'
import MultipleInputsBasic from '@/components/form-groups/MultipleInputsBasic.vue'
import NumericField from '@/components/form-groups/NumericField.vue'
import Recapitulatif from '@/components/Recapitulatif.vue'
import SelectField from '@/components/form-groups/SelectField.vue'
import PoseChoice from '@/components/form-groups/PoseChoice.vue'
import TextRadioChoice from '@/components/form-groups/TextRadioChoice.vue'
import TextRadioChoiceSelected from '@/components/form-groups/TextRadioChoiceSelected.vue'
import TextAreaField from '@/components/form-groups/TextAreaField.vue'
import TextInput from '@/components/form-groups/TextInput.vue'
import MesureGoogleMap from '@/components/form-groups/MesureGoogleMap.vue'
import TypeCloChoice from '@/components/form-groups/TypeCloChoice.vue'
import MultipleQuantityChoice from '@/components/form-groups/MultipleQuantityChoice.vue'
import RadioQuantityChoice from '@/components/form-groups/RadioQuantityChoice.vue'
import MultipleImagesInputChoice from '@/components/form-groups/MultipleImagesInputChoice.vue'
import MultipleImagesInputGoogleMapChoice from '@/components/form-groups/MultipleImagesInputGoogleMapChoice.vue'
import ConfirmStep from "@/components/form-groups/ConfirmStep";
import ConfirmComponent from "@/components/form-groups/ConfirmComponent";
import ColorComponent from "@/components/form-groups/ColorComponent";
import FinalRecap from "@/components/form-groups/FinalRecap";

export default {
	name: 'QuestionContainer',
	components: {
        'ChangeStep': ChangeStep,
        'ColorRadio': ColorRadio,
        'ImageRadioChoice': ImageRadioChoice,
        'MultipleImageRadioChoice': MultipleImageRadioChoice,
        'MultipleInputsBasic': MultipleInputsBasic,
        'NumericField': NumericField,
        'SelectField': SelectField,
        'PoseChoice': PoseChoice,
        'TextRadioChoice': TextRadioChoice,
        'MultipleImageRadioChoiceSelected': MultipleImageRadioChoiceSelected,
        Recapitulatif,
        TextAreaField,
        TextRadioChoiceSelected,
        TextInput,
        MesureGoogleMap,
        TypeCloChoice,
        MultipleQuantityChoice,
        RadioQuantityChoice,
        MultipleImagesInputChoice,
		MultipleImagesInputGoogleMapChoice,
		ConfirmStep,
		QuestionTitle,
		ConfirmComponent,
		ColorComponent,
		FinalRecap
    },
	props: {
		question: {
			type: Object,
			default () {
				return {}
			}
		},
		step: {
			type: Object,
			default () {
				return {}
			}
		}
	},
	data () {
		return {}
	},
	methods: {}
}
</script>

<style scoped>
#QuestionContainer {

}
</style>
