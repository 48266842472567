import axios from 'axios'
import { toDegrees } from 'spherical-geometry-js'
import Store from "../store"

const trackUserData = (clicArea) => {
    let parameters = `&origin=${process.env.VUE_APP_ENV}`

    const isDefined = (param) => {
        return typeof param !== undefined && param !== ''
    }

    const filiale = Store.state.userInfos.user_data.company.subsidiary
    if(isDefined(filiale)) parameters += `&filiale=${filiale}`

    const idEntreprise = Store.state.userInfos.user_data.company.id
    if(isDefined(idEntreprise)) parameters += `&idEntreprise=${idEntreprise}`

    const idUtilisateur = Store.state.userInfos.user_data.customer.id
    if(isDefined(idUtilisateur)) parameters += `&idUtilisateur=${idUtilisateur}`

    let emailContactCommercial = ''
    try {
        emailContactCommercial = Store.state.userInfos.user_data.contact.spr.email 
    } catch {
        emailContactCommercial = Store.state.userInfos.user_data.contact.email
    }
    if(isDefined(emailContactCommercial)) parameters += `&emailContactCommercial=${emailContactCommercial}`

    const langue = Store.state.lang
    if(isDefined(langue)) parameters += `&langue=${langue}`

    const zoneDuClic = clicArea
    if(isDefined(zoneDuClic)) parameters += `&zoneDuClic=${zoneDuClic}`

    const uri = `${process.env.VUE_APP_WP_PATH}/?pommier_action=export-data&action=exportUserData`
    axios.get(uri+parameters).catch(() => {
        return true
    })
}

export { trackUserData }