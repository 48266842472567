import { jsPDF } from "jspdf"
import Store from "../store"
import "../../public/fonts/Neo-normal"
import "../../public/fonts/Neo-bold"
import temp from "vue-i18n"


const createPdf = function () {
	this.margin = 10
	this.maxX = 210 - this.margin
	this.maxY = 297 - this.margin

	this.colorWhite = [255, 255, 255]
	this.colorBlue1 = [13, 77, 161]
	this.colorBlue2 = [24, 150, 213]
	this.colorBlue3 = [241, 244, 255]
	this.colorGray = [118, 116, 116]

	this.deverrouillage_ref = '683700174'
	this.telecommande_ref = '683700326'

	this.nom_du_produit = ''
	this.type_assistance = ''
	this.lh = ''
	this.ih = ''
	this.lb = ''
	this.ib = ''
	this.ze = ''
	this.couleur = ''
	this.gache = ''
	this.deverrouillage = ''
	this.telecommande = ''
	this.quantite = ''
	this.commentaire = ''
	this.commentaire_ligne_2 = ''
	this.commentaire_ligne_3 = ''
	this.date = ''
	this.signature = ''
	this.client = ''
	this.compte = ''
	this.adresse = ''
	this.adresse_ligne_2 = ''
	this.adresse_ligne_3 = ''
	this.adresse_ligne_4 = ''

	this.y = 0
	this.marginX = 15
	this.marginY = 10

	this.createPdf = (args) => {
		const doc = new jsPDF()
		doc.setFillColor(255, 255, 255)
		doc.rect(0, 0, 210, 297, 'F')
		this.setDefaultColorsAndFont(doc)
		if (Store.state.family.Prefix === 'wih') {
			this.configWihag()
			this.loadImagesWihag(doc)
			this.createHeaderWihag(doc)
			this.createContentWihag(doc)
		}
		else {
			this.maxX = 210 - this.marginX
			this.maxY = 297 - this.marginY
			this.y = 0
			this.createHeader(doc)
			this.createContent(doc)
			this.createFooter(doc)
		}
		let myDoc = doc.output('datauristring')
		args.pdf = myDoc
		Store.dispatch('exportPlayload', args)
	}

	this.addY = (y, doc) => {
		if (y + this.y < this.maxY)
			this.y += y
		else {
			doc.addPage()
			this.y = this.marginY + 5
		}
	}
	this.createHeader = (doc) => {
		// Logo Pommier
		this.logo_pommier = new Image()
		this.logo_pommier.src = process.env.VUE_APP_PUBLIC_PATH + 'img/logo_pommier_v_300.jpg'
		this.logo_pommier.crossOrigin = 'anonymous'
		doc.addImage(this.logo_pommier, 'JPG', this.marginX, this.marginY, 50, 25)

		// Date text
		let date = new Date();
		let dateText = date.getDate().toString() + '/' + (date.getMonth() + 1).toString() + '/' + date.getFullYear().toString()
		doc.setFontSize(10)
		doc.setFont('Neo', 'bold')
		doc.setDrawColor(0, 0, 0)
		doc.text(this.maxX - this.marginX - 4, this.marginY + 5, dateText)
		this.addY(45, doc) //Next text will be under the image

		/* CLIENT INFOS */
		if (Store.state.userInfos && Store.state.userInfos.user_data)
		{
			//Title
			doc.setFontSize(13)
			doc.text(this.marginX, this.y, 'Mes infos personnelles')
			this.addY(5, doc)

			//Grey box
			doc.setFillColor(230, 230, 230)
			doc.rect(this.marginX, this.y, this.maxX - this.marginX, 21, 'F')
			this.addY(6, doc)

			doc.setFontSize(10)
			doc.text(this.marginX + 3, this.y, 'Code Client')
			doc.setFont('Neo', 'normal')
			doc.text(this.marginX + 50, this.y, Store.state.userInfos.user_data.customer.uid)
			this.addY(6, doc)

			doc.setFontSize(10)
			doc.setFont('Neo', 'bold')
			doc.text(this.marginX + 3, this.y, 'Nom')
			doc.setFont('Neo', 'normal')
			doc.text(this.marginX + 50, this.y, Store.state.userInfos.user_data.customer.last_name)
			doc.setFontSize(10)
			doc.setFont('Neo', 'bold')
			doc.text(this.marginX + 100, this.y, 'Prénom')
			doc.setFont('Neo', 'normal')
			doc.text(this.marginX + 120, this.y, Store.state.userInfos.user_data.customer.first_name)
			this.addY(6, doc)

			doc.setFontSize(10)
			doc.setFont('Neo', 'bold')
			doc.text(this.marginX + 3, this.y, 'Entreprise')
			doc.setFont('Neo', 'normal')
			doc.text(this.marginX + 50, this.y, Store.state.userInfos.user_data.company.name + ', ' + Store.state.userInfos.user_data.company.subsidiary)
			this.addY(17, doc)
		}
	}

	this.createContent = (doc) => {
		doc.setFontSize(16)
		doc.setFont('Neo', 'bold')
		doc.text(this.marginX, this.y, 'Récapitulatif de votre projet :')
		this.addY(16, doc)

		// Family name
		doc.setFontSize(13)
		doc.text(this.marginX, this.y, Store.state.family.Nom)
		this.addY(13, doc)

		if (Store.state.family.Prefix == 'Cre')
		{
			let cremone_img = new Image()
			if (Store.state.answers[8][3][0].value == 1) {
				cremone_img.src = 'https://api-pommier.dev.wiboo.fr/uploads/1/2022-03/cremone_assemblee.jpg'
			}
			else if (Store.state.answers[8][3][0].value == 2) {
				cremone_img.src = 'https://api-pommier.dev.wiboo.fr/uploads/1/2022-03/cremone_semi-assemblee.jpg'
			}
			doc.addImage(cremone_img, 'JPG', 5, this.y, 60, 60)
			doc.setFontSize(11)
			this.addY(40, doc)
			if (Store.state.answers[8][4])
			{
				doc.setFont('Neo', 'bold')
				doc.text(60, this.y, Store.state.answers[8][4][0].label + ' : ')
				doc.setFont('Neo', 'normal')
				doc.text(65, this.y, Store.state.answers[8][4][0].value)
				this.addY(5, doc)
				doc.setFont('Neo', 'bold')
				doc.text(60, this.y, Store.state.answers[8][4][1].label + ' : ')
				doc.setFont('Neo', 'normal')
				doc.text(65, this.y, Store.state.answers[8][4][1].value)
			}
			else if (Store.state.answers[8][5])
			{
				doc.setFont('Neo', 'bold')
				doc.text(60, this.y, Store.state.answers[8][5][0].label + ' : ')
				doc.setFont('Neo', 'normal')
				doc.text(65, this.y, Store.state.answers[8][5][0].value)
				this.addY(5, doc)
				doc.setFont('Neo', 'bold')
				doc.text(60, this.y, Store.state.answers[8][5][1].label + ' : ')
				doc.setFont('Neo', 'normal')
				doc.text(65, this.y, Store.state.answers[8][5][1].value)
			}
			this.addY(24, doc)
		}

		//Answers
		doc.setFontSize(10)
		Store.state.products[0].history.forEach((answer) => {
			doc.setFont('Neo', 'bold')
			doc.text(this.marginX, this.y, answer.label + ' :')
			var offset = doc.getTextDimensions(answer.label + ' :')
			doc.setFont('Neo', 'normal')
			doc.text(this.marginX + 2 + offset.w, this.y, answer.value)
			this.addY(6, doc)
		})

		//Blueish rectangle
		this.addY(2, doc)
		doc.setFillColor(0, 40, 92)
		doc.rect(this.marginX, this.y, this.maxX - this.marginX, 2, 'F')
		this.addY(13, doc)

		//Refs list title
		doc.setFontSize(13)
		doc.setFont('Neo', 'bold')
		doc.setTextColor(0, 68, 148)
		doc.text(this.marginX, this.y, 'Liste des références à commander')
		this.addY(10, doc)

		//Refs
		doc.setFontSize(10)
		Store.state.refs.forEach((ref) => {
			doc.setFont('Neo', 'bold')
			doc.text(this.marginX, this.y, ref.label + ' x' + ref.quantity + ' :')
			var offset = doc.getTextDimensions(ref.label + ' x' + ref.quantity + ' :')
			doc.setFont('Neo', 'normal')
			doc.text(this.marginX + 2 + offset.w, this.y, ref.ref)
			this.addY(6, doc)
		})
	}

	this.createFooter = (doc) => {
		//Blueish separator 
		this.addY(2, doc)
		doc.setFillColor(0, 40, 92)
		doc.rect(this.marginX, this.y, this.maxX - this.marginX, 1, 'F')
		this.addY(13, doc)

		//Not our fault lines
		doc.setFont('Neo', 'normal')
		doc.setTextColor(0,0,0)
		doc.text(this.marginX, this.y, '* Le nombre et la force sont des éléments théoriques conseillés.')
		this.addY(4, doc)
		var not_our_fault = '* Les informations issues du configurateur sont données à titre indicatif. POMMIER ne pourra être tenu responsable en cas d’incompatibilité du matériel défini par l’utilisateur du configurateur.'
		var not_our_fault_split = doc.splitTextToSize(not_our_fault, this.maxX - this.marginX)
		if (this.y + 4 * not_our_fault_split.length > this.maxY)
			this.addY(300, doc) //Text to big we create a new page
		doc.text(this.marginX, this.y, not_our_fault_split)
		this.addY(10, doc)

		//Mentions legales
		doc.setTextColor(0, 68, 148)
		doc.textWithLink('Voir nos conditions générales de vente.', this.marginX, this.y, {url: 'https://www.pommier.eu/CGV'})
		this.addY(10, doc)

		//Last blue rectangle
		if (this.y + 30 > this.maxY)
			this.addY(300, doc)
		doc.setFillColor(0, 68, 148)
		doc.rect(this.marginX, this.y, this.maxX - this.marginX, 31, 'F')
		this.addY(9, doc)
		doc.setFontSize(12)
		doc.setTextColor(255,255,255)
		var rendez_vous = 'Rendez-vous dans votre espace client sur www.pommier.eu pour finaliser votre commande :'
		var rendez_vous_split = doc.splitTextToSize(rendez_vous, this.maxX - this.marginX - 30)
		doc.text(this.marginX + 7, this.y, rendez_vous_split)
		this.addY(1 + 5 * rendez_vous_split.length - 1, doc)
		doc.text(this.marginX + 7, this.y, '- Consultez vos tarifs et la disponibilité des références')
		this.addY(5, doc)
		doc.text(this.marginX + 7, this.y, '- Passez une précommande sur le site ou contactez votre interlocuteur POMMIER')
	}

	this.loadImagesWihag = (doc) => {
		// Logo Pommier
		this.logo_pommier = new Image()
		this.logo_pommier.src = process.env.VUE_APP_PUBLIC_PATH + 'img/logo_pommier_v_300.jpg'
		this.logo_pommier.crossOrigin = 'anonymous'

		// Checked
		this.picto_checked = new Image()
		this.picto_checked.src = process.env.VUE_APP_PUBLIC_PATH + 'img/checked.png'
		this.picto_checked.crossOrigin = 'anonymous'

		// Logo Wihag
		this.logo_wihag = new Image()
		this.logo_wihag.src = process.env.VUE_APP_PUBLIC_PATH + 'img/logo-wihag.jpg'
		this.logo_wihag.crossOrigin = 'anonymous'


		let product_image_name = ''
		let plan_image_name = ''
		if (this.answers[1][1][0].value === 1 && this.answers[2][2][0].value === 1) {
			product_image_name = 'trainplussk.jpg' // ok
			plan_image_name = 'trainplussk-plan-new.jpg' // non 1
		} else if (this.answers[1][1][0].value === 1 && this.answers[2][2][0].value === 2) {
			product_image_name = 'trainplussk-easylift.jpg' // ok
			plan_image_name = 'trainplussk-easylift-plan.jpg' // ok
		} else if (this.answers[1][1][0].value === 2 && this.answers[2][2][0].value === 1) {
			product_image_name = 'thermosafe60.jpg' // ok
			plan_image_name = 'thermosafe60-plan.jpg' // ok
		} else if (this.answers[1][1][0].value === 2 && this.answers[2][2][0].value === 2) {
			product_image_name = 'thermosafe60-easylift.jpg' // ok
			plan_image_name = 'thermosafe60-easylift-plan.jpg' // ok
		}

		// Image plan
		this.plan_image = new Image()
		this.plan_image.src = process.env.VUE_APP_PUBLIC_PATH + 'img/wihag/' + plan_image_name
		this.plan_image.crossOrigin = 'anonymous'

		// Image produit
		this.product_image = new Image()
		this.product_image.src = process.env.VUE_APP_PUBLIC_PATH + 'img/wihag/' + product_image_name
		this.product_image.crossOrigin = 'anonymous'
	}




	this.setDefaultColorsAndFont = (doc) => {
		doc.setFont("Neo", "normal")
		doc.setFontSize(10)
		doc.setTextColor(0, 0, 0)
		doc.setFillColor(0, 0, 0)
		doc.setDrawColor(0, 0, 0)
	}




	this.createHeaderWihag = (doc) => {
		// Logo Pommier
		doc.addImage(this.logo_pommier, 'JPG', this.margin, this.margin, 46, 20)

		// Commande/Order
		doc.setFontSize(12)
		this.createCheckboxAndText(doc, 60, 17, 'COMMANDE/ORDER', this.colorBlue2, 12, true, false)

		// Demande de prix/Inquiry
		this.createCheckboxAndText(doc, 110, 17, 'DEMANDE DE PRIX/INQUIRY', this.colorBlue2, 12, true, false)

		// Logo Wihag
		doc.addImage(this.logo_wihag, 'JPG', this.maxX - 23, this.margin, 23, 20)

		doc.setFontSize(8)
		// doc.setTextColor(this.colorGray[0], this.colorGray[1], this.colorGray[2])
		doc.setTextColor("red")
		doc.text(this.margin + 33, this.margin + 23, 'Document à retourner complété et signé par email à votre interlocuteur commercial habituel / ')
		doc.setFontSize(8)
		// doc.setTextColor(this.colorGray[0], this.colorGray[1], this.colorGray[2])
		doc.setTextColor("red")
		doc.text(this.margin + 37, this.margin + 26, 'Document to be returned duly signed and completed by email to your usual sales contact')
	}




	this.createContentWihag = (doc) => {
		doc.setDrawColor(this.colorBlue1[0], this.colorBlue1[1], this.colorBlue1[2])
		doc.setLineWidth(0.5)
		doc.roundedRect(this.margin, this.margin + 28, 210 - this.margin * 2, 70, 10, 10, 'S')
		doc.rect(this.margin, this.margin + 70, 210 - this.margin * 2, 297 - this.margin * 2 - 70, 'S')

		// Carré blanc
		doc.setFillColor(255, 255, 255);
		doc.rect(this.margin + 0.5 - 0.1, this.margin + 70 + 0.5, 210 - this.margin * 2 - 0.8 + 0.1, 297 - this.margin * 2 - 70 - 0.8, 'F')

		doc.setFillColor(this.colorBlue1[0], this.colorBlue1[1], this.colorBlue1[2]);
		doc.rect(210 - this.margin - 110, this.margin + 28, 110, 42, 'FD')

		doc.setLineWidth(0.1)
		doc.line(this.margin, 165, 210 - this.margin, 165, 'S')
		doc.line(this.margin, 240, 210 - this.margin, 240, 'S')
		doc.line(90, 240, 90, 297 - this.margin, 'S')

		// Images
		doc.addImage(this.product_image, 'JPG', this.margin + 24, 39, 40, 40)
		doc.addImage(this.plan_image, 'JPG', this.margin + 26, 81, 133, 83)

		// Commentaire textarea
		this.createTextArea(doc, this.margin + 3, 216, 210 - this.margin * 2 - 2 * 3, 14, 'Commentaires / Kommentare / Comments :')

		// Text fields labels
		doc.setFontSize(12)
		doc.setFont("Neo", "bold")
		doc.setTextColor(0, 0, 0)
		doc.text(this.margin + 3, 172, 'LH =')
		doc.text(this.margin + 3, 183, 'IH =')
		doc.text(this.margin + 3, 194, 'LB =')
		doc.text(this.margin + 3, 205, 'IB =')
		doc.setFontSize(11)
		doc.text(100, 172, 'Couleur / Farbe / Color =')
		doc.text(100, 208, 'Quantité / Menge / Quantity =')


		// Text fields blue rectangles
		doc.setFillColor(this.colorBlue3[0], this.colorBlue3[1], this.colorBlue3[2])
		doc.rect(this.margin + 14, 172 - 5, 50, 6, 'F')
		doc.rect(this.margin + 14, 183 - 5, 50, 6, 'F')
		doc.rect(this.margin + 14, 194 - 5, 50, 6, 'F')
		doc.rect(this.margin + 14, 205 - 5, 50, 6, 'F')
		doc.rect(this.margin + 136, 172 - 5, 210 - this.margin * 2 - 3 - 136, 6, 'F')
		doc.rect(this.margin + 147, 208 - 5, 210 - this.margin * 2 - 147 - 3, 6, 'F')
		doc.rect(this.margin + 14, 246 - 4, 90 - 3 * 2 - this.margin - 11, 6, 'F')
		doc.rect(90 + 35, 246 - 4, 210 - 90 - 35 - 3 - this.margin, 6, 'F')
		doc.rect(90 + 61.5, 254 - 4, 210 - 90 - 61.5 - 3 - this.margin, 6, 'F')


		// Tiny grey text 
		doc.setFontSize(8)
		doc.setFont("Neo", "normal")
		doc.setTextColor(this.colorGray[0], this.colorGray[1], this.colorGray[2])
		doc.text(this.margin + 3, 234, 'Dimensions en mm / All dimension in mm / Alle Angaben in mm')
		doc.text(this.margin + 3, 238, 'Wenn nicht anders angegeben, nur Lackierung der Außenfläche / Unless otherwise stated, only painting the outer surface')
		doc.setFontSize(7)
		doc.text(this.margin + 3, 172 + 3.7, '(Hauteur utile / Lichte Höhe / clear height)')
		doc.text(this.margin + 3, 183 + 3.7, '(IH = LH + 200 mini - Hauteur intérieur / Innere Höhe / Inner height)')
		doc.text(this.margin + 3, 194 + 3.7, '(Largeur utile / Lichte Breite / Clear width)')
		doc.text(this.margin + 3, 205 + 3.7, '(IB = LB + 2 x 50 mini - Largeur intérieur / Innere Breite / Inner with)')


		// Top texts
		doc.setFontSize(15)
		doc.setFont("Neo", "normal")
		doc.setTextColor(255, 255, 255)
		doc.text(96, 47, 'Porte sectionnelle / Roller shutter door')
		doc.text(96, 63, this.type_assistance, { maxWidth: 90 })
		doc.setFontSize(18)
		doc.setFont("Neo", "bold")
		doc.text(96, 55.5, this.nom_du_produit)
		doc.setFontSize(32)
		doc.text(96, 72, '_')


		// Bottom texts
		doc.setFontSize(10)
		doc.setFont("Neo", "bold")
		doc.setTextColor(this.colorBlue1[0], this.colorBlue1[1], this.colorBlue1[2])
		doc.text(this.margin + 3, 246, 'Date :')
		doc.text(90 + 3, 246, 'Client / Customer :')
		doc.text(90 + 3, 254, 'N° de compte / Customer number :')
		this.createTextArea(doc, 90 + 3, 262, 210 - 93 - this.margin - 3, 22, 'Adresse de livraison / Delivery address :', this.colorBlue1)
		this.createTextArea(doc, this.margin + 3, 254, 90 - 3 * 2 - this.margin, 15, 'Signature & cachet client / Stamp :', this.colorBlue1)
		this.createCheckbox(doc, this.margin + 3, 273, true)
		doc.text(this.margin + 10, 276, 'Je m\'engage sur la validité des')
		doc.text(this.margin + 10, 281, 'informations ci-dessus.')


		// Conditionnal fields
		if (this.answers[2][2][0].value === 2) {
			// Deverouillage & Telecommande
			this.createCheckbox(doc, 100, 178, this.deverrouillage)
			this.createCheckbox(doc, 100, 192, this.telecommande)
			doc.setFontSize(11)
			doc.setFont("Neo", "bold")
			doc.setTextColor(0, 0, 0)
			doc.text(100 + 7, 180, 'Dispositif de déverrouillage d\'urgence /')
			doc.text(100 + 7, 185, 'Notentriegelung / Emergency release set')
			doc.text(100 + 7, 194, 'Télécommande / Fernbedienung / Remote')
			doc.text(100 + 7, 199, 'control')
			doc.setFontSize(8)
			doc.setFont("Neo", "normal")
			doc.setTextColor(this.colorGray[0], this.colorGray[1], this.colorGray[2])
			doc.text(100 + 7, 198.5 + 3.7, this.telecommande_ref)
			doc.text(100 + 7, 184.5 + 3.7, this.deverrouillage_ref)

		} else if (this.answers[2][2][0].value === 1) {
			// Gache
			this.createCheckbox(doc, 100, 178, this.gache)
			doc.setFontSize(11)
			doc.setFont("Neo", "bold")
			doc.setTextColor(0, 0, 0)
			doc.text(100 + 7, 180, 'Gâche de poignée fournie / Schließblech')
			doc.text(100 + 7, 185, 'inlusive / Catch plate included')

			if (this.answers[1][1][0].value === 1) {
				// ZE
				doc.setFontSize(11)
				doc.setFont("Neo", "bold")
				doc.setTextColor(0, 0, 0)
				doc.text(100, 191, 'ZE (Seulement pour container / Only for')
				doc.text(100, 196, 'container) =')
				doc.setFontSize(7)
				doc.setFont("Neo", "normal")
				doc.setTextColor(this.colorGray[0], this.colorGray[1], this.colorGray[2])
				doc.text(100, 196 + 3.7, '(Distance entre les deux coins de container / Maß zwischen Eckbeschlägen /')
				doc.text(100, 198.5 + 3.7, 'Width between corner castings)')
				doc.setFillColor(this.colorBlue3[0], this.colorBlue3[1], this.colorBlue3[2])
				doc.rect(90 + 36, 192, 210 - 90 - 36 - 3 - this.margin, 5, 'F')

				// Field value
				doc.setFontSize(11)
				doc.setFont("Neo", "normal")
				doc.setTextColor(0, 0, 0)
				doc.text(127, 196, this.ze)
			}

		}


		// Fields values
		doc.setFontSize(12)
		doc.setFont("Neo", "normal")
		doc.setTextColor(0, 0, 0)
		doc.text(25, 172, this.lh)
		doc.text(25, 183, this.ih)
		doc.text(25, 194, this.lb)
		doc.text(25, 205, this.ib)
		doc.text(this.margin + 3, 221, this.commentaire)
		doc.text(this.margin + 3, 225, this.commentaire_ligne_2)
		doc.text(this.margin + 3, 229, this.commentaire_ligne_3)

		doc.setFontSize(11)
		doc.text(147, 172, this.couleur)
		doc.text(158, 208, this.quantite)

		doc.setFontSize(10)
		doc.text(25, 246, this.date)
		doc.text(126, 246, this.client)
		doc.text(152, 254, this.compte)
		// doc.text(25, 263.5, this.signature)
		doc.text(90 + 3, 268, this.adresse)
		doc.text(90 + 3, 272, this.adresse_ligne_2)
		doc.text(90 + 3, 276, this.adresse_ligne_3)
		doc.text(90 + 3, 280, this.adresse_ligne_4)

	}




	this.createFooterWihag = (doc) => {
		doc.setFontSize(20)
		// doc.setTextColor(this.colorGray[0], this.colorGray[1], this.colorGray[2])
		doc.setTextColor("red")
		doc.text(this.margin, 297 - this.margin + 3, 'Document à retourner complété et signé par email à votre interlocuteur commercial habituel / Document to be returned duly signed and completed by email to your usual sales contact')
	}




	this.createCheckbox = (doc, x, y, checked = false) => {
		doc.setFillColor(this.colorBlue3[0], this.colorBlue3[1], this.colorBlue3[2]);
		doc.rect(x, y, 5, 5, 'F');

		doc.setDrawColor(200, 200, 200);
		doc.rect(x, y, 5, 5, 'S');

		if (checked) {
			doc.addImage(this.picto_checked, 'png', x + 0.5, y + 0.5, 4, 4)
			// doc.setDrawColor(0,0,0);
			// doc.line(x, y, x+5, y+5, 'S')
			// doc.line(x+5, y, x, y+5, 'S')
		}
	}


	this.createCheckboxAndText = (doc, x, y, text, color = [0, 0, 0], textSize, boldText = false, checked = false) => {
		this.createCheckbox(doc, x, y, checked)

		if (boldText) {
			doc.setFont("Neo", "bold")
		} else {
			doc.setFont("Neo", "normal")
		}

		doc.setTextColor(color[0], color[1], color[2])
		doc.setFontSize(textSize)
		doc.text(x + 6, y + 4, text)
	}


	this.createTextArea = (doc, x, y, w, h, label, color = [0, 0, 0]) => {
		doc.setFillColor(this.colorBlue3[0], this.colorBlue3[1], this.colorBlue3[2])
		doc.rect(x, y + 2, w, h - 2, 'F')

		doc.setFont("Neo", "bold")
		doc.setTextColor(color[0], color[1], color[2])
		doc.text(x, y, label)
	}

	// Get all the infos from the store
	this.configWihag = () => {
		// Caractéristiques
		this.answers = Store.state.answers
		if (this.answers[1][1][0].value === 1) {
			this.nom_du_produit = 'TrainPlus SK®'
		} else if (this.answers[1][1][0].value === 2) {
			this.nom_du_produit = 'ThermoSafe 60 TS'
		}
		if (this.answers[2][2][0].value === 1) {
			this.type_assistance = "Assistance à ressort / Spring assistance"
		} else if (this.answers[2][2][0].value === 2) {
			this.type_assistance = "Assistance électrique EasyLift / EasyLift electric assistance"
		}
		this.lh = this.answers[2][6][0].value
		this.ih = this.answers[2][6][1].value
		this.lb = this.answers[2][6][2].value
		this.ib = this.answers[2][6][3].value
		this.ze = this.answers[2][6][4] === undefined ? '' : this.answers[2][6][4].value
		const couleur_standard = this.answers[2][7][0].value
		this.couleur = couleur_standard === 1 ? '9010 (standard)' : this.answers[2][8][0].value
		if (this.answers[2][9][0].value === 1) {
			this.gache = true
		} else if (this.answers[2][9][0].value === 2) {
			this.gache = false
		}
		this.deverrouillage = (this.answers[2][3] === undefined ? '' : this.answers[2][3][0].value) === 1
		this.telecommande = (this.answers[2][4] === undefined ? '' : this.answers[2][4][0].value) === 1
		this.quantite = this.answers[2][11][0].value

		this.commentaire = this.answers[2][10][0].value
		if (this.commentaire) {
			const lignesCommentaire = this.splitLines(this.commentaire, 90, 3)
			this.commentaire = lignesCommentaire.ligne_1
			this.commentaire_ligne_2 = lignesCommentaire.ligne_2
			this.commentaire_ligne_3 = lignesCommentaire.ligne_3
		}
		else
			this.commentaire = ''

		// Date
		let today = new Date();
		let dd = String(today.getDate()).padStart(2, '0');
		let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		let yyyy = today.getFullYear();
		today = dd + '/' + mm + '/' + yyyy;
		this.date = today

		// Client
		const customer = Store.state.userInfos.user_data.customer
		this.client = customer.first_name + ' ' + customer.last_name
		this.signature = customer.first_name + ' ' + customer.last_name
		this.compte = customer.id

		this.adresse = this.answers[3][1][0].value
		if (this.adresse) {
			const lignesAdresse = this.splitLines(this.adresse, 55, 4)
			this.adresse = lignesAdresse.ligne_1
			this.adresse_ligne_2 = lignesAdresse.ligne_2
			this.adresse_ligne_3 = lignesAdresse.ligne_3
			this.adresse_ligne_4 = lignesAdresse.ligne_4
		}
		else
			this.adresse = ''

		// console.log(
		//     {
		//         'nom_du_produit' : this.nom_du_produit,
		//         'type_assistance' : this.type_assistance,
		//         'lh' : this.lh,
		//         'ih' : this.ih,
		//         'lb' : this.lb,
		//         'ib' : this.ib,
		//         'ze' : this.ze,
		//         'couleur' : this.couleur,
		//         'gache' : this.gache,
		//         'deverrouillage' : this.deverrouillage,
		//         'telecommande' : this.telecommande,
		//         'quantite' : this.quantite,
		//         'commentaire' : this.commentaire,
		//         'date' : this.date,
		//         'client' : this.client,
		//         'signature' : this.signature,
		//         'compte' : this.compte,
		//         'adresse' : this.adresse
		//     }
		// )
	}



	this.splitLines = (sentence, charPerLine, lines) => {

		if (sentence.length > lines * charPerLine) {
			const msg = ' ...(trop long)'
			sentence = sentence.substring(0, lines * charPerLine - msg.length) + ' ...(trop long)'
		}

		let lignes = {
			'ligne_1': '',
			'ligne_2': '',
			'ligne_3': '',
			'ligne_4': ''
		}

		const words = sentence.split(' ')
		let ligne_1 = ''
		let ligne_2 = ''
		let ligne_3 = ''
		let ligne_4 = ''
		words.forEach((word) => {
			if (ligne_3.length >= charPerLine) {
				ligne_4 += word
				ligne_4 += ' '
			} else if (ligne_2.length >= charPerLine) {
				ligne_3 += word
				ligne_3 += ' '
			} else if (ligne_1.length >= charPerLine) {
				ligne_2 += word
				ligne_2 += ' '
			} else {
				ligne_1 += word
				ligne_1 += ' '
			}
		})

		lignes.ligne_1 = ligne_1
		lignes.ligne_2 = ligne_2
		lignes.ligne_3 = ligne_3
		lignes.ligne_4 = ligne_4

		return lignes
	}
}
export default new createPdf()