<template>
  <div class="card" :class="[question.type, {opened: display}]">
    <div
      :id="'collapseStep' + step + 'Question' + question.id"
      class="collapse"
      v-bind:class="{ show: true }"
      :aria-labelledby="'collapseStep' + step + 'Question' + question.id"
      data-parent="#main-form"
    >
      <div
        v-if="
          question.extraHtml !== undefined &&
            (question.extraHtml.content_top !== null) !== undefined &&
            question.extraHtml.content_top !== null
        "
        v-html="$t(question.extraHtml.content_top)"
      ></div>

      <div class="card-body">
        <select v-model="answerIndex" class="form-control">
          <option disabled selected value="-1">{{
            $t(question.selectLabel)
          }}</option>
          <option
            v-for="(answer, answerKey) in question.answers"
            v-bind:selected="answer.isAnswer"
            v-bind:value="answerKey"
          >
            {{ $t(answer.label) }}
          </option>
        </select>
      </div>
      <div
        v-if="
          question.extraHtml !== undefined &&
            (question.extraHtml.content_bottom !== null) !== undefined &&
            question.extraHtml.content_bottom !== null
        "
        v-html="$t(question.extraHtml.content_bottom)"
      ></div>
    </div>
  </div>
</template>

<script>
import common from "./mixins/common";

export default {
  name: "SelectField",

  props: {
    question: Object,
    questions: Object,
    step: [String, Number],
    opened: Boolean
  },

  mixins: [common],

  mounted () {
    setTimeout(() => {
      this.display = this.opened
    }, 500)
    
  },

  data: function() {
    let answerIndex = -1;
    if (
      this.$store.state.answers[this.step] &&
      this.$store.state.answers[this.step][this.question.id]
    ) {
      answerIndex = this.$store.state.answers[this.step][this.question.id];
    }
    return {
      showSpoiler: false,
      answerIndex: answerIndex,
      display: null
    };
  },

  computed: {
    steps() {
      return this.$store.state.steps;
    },
  },

  watch: {
    answerIndex(val, oldVal) {
      if (val !== -1) {
        this.addToAnswers(val);
      }
    },
  },

  created() {},

  methods: {
    addToAnswers: function(answerIndex) {
      const questionIndex = this.question.id;
      const question = this.questions[questionIndex];

      Object.keys(question.answers).forEach((key) => {
        question.answers[key].isAnswer = false;
      });

      const answer = question.answers[answerIndex];

      answer.isAnswer = true;

      this.showSpoiler = answer.label;

      setTimeout(() => {
        this.$store.dispatch("addAnswersThenCalc", {
          isGlobal: this.question.global !== undefined && this.question.global,
          stepIndex: this.step,
          questionIndex: questionIndex,
          questionType: question.type,
          effectOnSvg: question.effectOnSvg,
          index: answerIndex,
          label: answer.label,
          value: answer.isAnswer ? answer.value : null,
          variable: question.variable || null,
          changes: answer.changes || null,
        });

        this.$store.commit('setReady', {ready: false, from: 'SelectField'})
      }, 500)

      setTimeout(() => {
        this.display = false
      }, 200)

      //this.openNextQuestion(this.step, question.id);
    },

    initialize() {
      if (
        this.answers[this.step] &&
        this.answers[this.step][this.question.id]
      ) {
        const answers = this.answers[this.step][this.question.id];

        Object.keys(answers).forEach((idx) => {
          answers[idx].isAnswer = true;

          this.showSpoiler = answers[idx].label || this.showSpoiler;
        });
      }
    },
  },
};
</script>

<style scoped>
select {
  width: 20%;
}

.card {
  max-height: 0;
  transition: max-height 0.2s;
  overflow: hidden;
}

.card.opened {
  max-height: 80px;
  transition: max-height 0.2s;
}
</style>
