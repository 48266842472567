<template>
    <div class="card" :class="[question.type, {opened: display}]">

        <div :id="'collapseStep'+step+'Question'+question.id" class="collapse"
             v-bind:class="{show : true}"
             :aria-labelledby="'collapseStep'+step+'Question'+question.id" data-parent="#main-form">
            <div
                    v-if="question.extraHtml !== undefined && question.extraHtml.content_top !== null !== undefined && question.extraHtml.content_top !== null"
                    v-html="$t(question.extraHtml.content_top)"></div>

            <div class="card-body">
                <div 
					class="checkbox-choice"
					v-for="(answer, answerIndex) in question.answers" 
					:key="answerIndex"
				>
                    <label :class="{full: form_values[answerIndex]}">
                        <span v-if="showMinMax(answer)" v-html="generateMinMax(answer)"></span>

                        <span v-else-if="showMinMax(answer) || getMinMax(answer, 'max') !== undefined && !question.hideMinMaxLabel">
                            {{ $t(answer.label) }}<br>
                            <span v-if="!answer.hideMin">Min : {{ getMinMax(answer, 'min') }}  {{ answer.unite }} - </span>Max : {{ getMinMax(answer, 'max') }} {{ answer.unite }}
                        </span>
                        <span
                                v-else-if="showMinMax(answer) || getMinMax(answer, 'min') !== undefined && getMinMax(answer, 'max') === undefined && !question.hideMinMaxLabel">
                            {{ $t(answer.label) }}<br>
                            <span v-if="!answer.hideMin">Min : {{ getMinMax(answer, 'min') }}  {{ answer.unite }}</span>
                        </span>
                        <span v-else-if="answer.unite">
                            {{ $t(answer.label) }} ({{ answer.unite }})<br>

                        </span>
                        <span v-else>
                             {{ $t(answer.label) }}
                            </span>
                        <input :type="answer.type"
                               :step="answer.step"

                               :min="getMinMax(answer, 'min')"
                               :max="getMinMax(answer, 'max')"
                               v-bind:class="{'not-valid': !isValid(form_values[answerIndex], getMinMax(answer),answer.step),'valid':isValid(form_values[answerIndex], getMinMax(answer),answer.step)}"
                               @keydown.enter="nextQuestion()"
                               
                               v-model="form_values[answerIndex]"
                               :placeholder="form_values[answerIndex] ? form_values[answerIndex] : answer.placeholder"
                        >
                    </label>
                    <span style="width: 100%;bottom:10px;left:0;font-style:italic;color:#6d071a;"
                          v-if="!isValid(form_values[answerIndex], getMinMax(answer),answer.step) && (form_values[answerIndex]!==undefined && form_values[answerIndex].length > 0) ">{{$t('error input')}}</span>
                </div>
            </div>
            <div
                    v-if="question.extraHtml !== undefined && question.extraHtml.content_bottom !== null !== undefined && question.extraHtml.content_bottom !== null">
            <p>
                <b style="caret-color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 14.6667px; text-size-adjust: auto;">
                    <span style="color: red;">
                        {{ $t("attention_dimensions_chassis") }}
                    </span>
                </b>
                <br>
            </p>
            </div>

            <!-- Button trigger modal -->
            <button v-if="question.popup !== undefined && question.popup !== ''" type="button" class="open-modal"
                    data-toggle="modal" @click="fillAndOpenDefaultModal()">
                {{ question.popupLabel }}
            </button>
            <!-- Modal -->
            <div class="change-btn" v-if="isAllValid()">

                <button @click="nextQuestion()" type="button"
                        class="open-modal next mt-2">
                    {{ $t("Question suivante") }}
                </button>
            </div>
        </div>

    </div>

</template>

<script>
    import common from './mixins/common'
    import Vue from 'vue'

    export default {
        name: "MultipleInputsBasic",

        props: {
            question: Object || Array,
            questions: Object || Array,
            step: [String, Number],
            opened: Boolean
        },

        mixins: [common],

        data: function () {
            return {
                form_values: [],
                showSpoiler: false,
                display: this.opened
            }
        },
        mounted () {
            this.form_values = [...this.form_values]

            setTimeout(() => {
              this.display = this.opened
            }, 500)
                
        },
        watch: {

            form_values: function (newVal) {
                let keepOld = false;

                const oldVal = newVal.map((el, index) => {
                    if(this.questions[this.question.id].onlyInt) {
                        el = el.replace(/([^0-9]+)/, '')
                    }
                    if (this.checkIfTooLong(this.question.answers[index], el))
                        el = el.toString().slice(0, -1);
                    return el

                });
                this.isAllValid()
                if (JSON.stringify(newVal) !== JSON.stringify(oldVal))
                    Vue.set(this, 'form_values', oldVal)

            }
        },
        methods: {
            
            checkIfTooLong(answer, value) {
                let variable;

                variable = answer.variable

                if (this.$store.state.heightWidthConstraint[variable] !== undefined) {
                    if (value > this.$store.state.heightWidthConstraint[variable].max) {
                        return true
                    }
                }
                return false;
            },
            fillAndOpenDefaultModal() {

                let modal = $('#basicModal');
                modal.find('h5').text('Comment prendre mes mesures');
                modal.find('img').attr('src', this.question.popup);
                modal.find('.extra-popup').html('')
                modal.modal('show');

            },

            /*onFocus(answerIndex, modifier, answer){
                if (this.form_values[answerIndex] !== ""){
                    this.addToAnswers(answerIndex, modifier, answer);
                }
            },*/
            showMinMax(question) {
                return question.variable === "hauteur" || question.variable === "largeur"
            },
            getMinMax(answer, type) {
                let variable = "";
                switch (answer.variable) {
                    case 'hauteur' :
                        variable = 'height';
                        break;
                    case 'largeur' :
                        variable = 'width';
                        break;
                    default :
                        variable = answer.variable
                }

                if (answer[type] !== undefined) {
                    return answer[type];
                }
                if (type !== undefined) {
                    if (this.$store.state.heightWidthConstraint[variable] !== undefined)
                        return this.$store.state.heightWidthConstraint[variable][type];
                } else {
                    if (answer.min !== undefined) {
                        return {min: answer.min, max: answer.max};
                    }
                    return this.$store.state.heightWidthConstraint[variable]
                }
            },
            isValid(value, minMax, step, onlyInt = false) {
                if (step)
                    return (value !== undefined && Number.isInteger(+value) && step)
                if (value)
                    return value === undefined || minMax === undefined || (value >= parseFloat(minMax.min) && value <= parseFloat(minMax.max) || (value >= parseFloat(minMax.min) && minMax.max === undefined) || (value <= parseFloat(minMax.max) && minMax.min === undefined))


            },
            isAllValid() {
                if (this.form_values.length === 0) {
                    return false;
                }
                let allValid = this.form_values.filter((el, index) => this.isValid(el, this.getMinMax(this.question.answers[index]), this.question.answers[index].step) != false && this.isValid(el, this.getMinMax(this.question.answers[index]), this.question.answers[index].step) != undefined)


                return allValid.length === this.question.answers.length


            },
            generateMinMax(question) {
                return question.label + '<br> Min : ' + this.$store.state.heightWidthConstraint[question.variable === "hauteur" ? 'height' : 'width'].min + ' mm  -  Max : ' + this.$store.state.heightWidthConstraint[question.variable === "hauteur" ? 'height' : 'width'].max + ' mm';
            },

            nextQuestion() {
                const questionIndex = this.question.id;
                const question = this.questions[questionIndex];
                this.display = false
                if (this.isAllValid()) {

                    for (let i = 0; i < this.question.answers.length; i++) {
                        let answ = this.question.answers[i]
                        let step = answ.step ? answ.step : this.step;
                        let calc = i === this.question.answers.length - 1 ? true : false
                        this.addToAnswers(i, answ.variable, answ, step, calc)
                    }

                    this.openNextQuestion(this.step, question.id);
                }

            },


            addToAnswers: function (answerIndex, modifier, answer, step, calc) {
                const questionIndex = this.question.id;
                //  let valid = []
                // this.question.answers.forEach((value,index)=>{
                //
                //     valid.push(this.isValid(this.form_values[index],this.getMinMax(this.form_values[index]),value.step))
                //
                //  })
                //  console.log(valid.indexOf(false)===-1)

                if (this.isValid(this.form_values[answerIndex], this.getMinMax(answer), step)) {
                    this.$store.dispatch('setPreventNextButton', false);
                    this.questions[questionIndex].answers[answerIndex].value = this.form_values[answerIndex];
                    this.$store.dispatch('addAnswers', {
                        isGlobal: this.question.global !== undefined && this.question.global,

                        stepIndex: this.step,
                        questionIndex: questionIndex,
                        questionType: this.questions[questionIndex].type,
                        effectOnSvg: this.questions[questionIndex].effectOnSvg,
                        index: answerIndex,
                        value: this.form_values[answerIndex],
                        variable: this.questions[questionIndex].answers[answerIndex].variable || null,
                        modifier: modifier !== undefined ? modifier : null,
                        changes: answer.changes !== undefined ? answer.changes : null,
                        name: this.form_values[answerIndex] !== undefined ? this.form_values[answerIndex].toString() : "",
                        label: this.questions[questionIndex].answers[answerIndex].label || null,
                        step: this.questions[questionIndex].answers[answerIndex].step

                    });

                    let isNotEmpty = true;

                    Object.keys(this.question.answers).forEach((key) => {
                        if (isNotEmpty && (this.form_values[key] === undefined || this.form_values[key] === "")) {
                            isNotEmpty = false;
                        }
                    });
                    this.showSpoiler = isNotEmpty;

                    if (calc) {
                        this.$store.dispatch('calculateValidSteps').then(() => {
                            this.$store.dispatch('calculatePrice');
                        });
                    }

                } else {
                    this.$store.dispatch('setPreventNextButton', true);
                    this.showSpoiler = false;
                }
            },

            initialize() {
                if (this.answers[this.step] && this.answers[this.step][this.question.id]) {
                    const formValues = this.form_values;

                    Object.keys(this.answers[this.step][this.question.id]).forEach((idx) => {
                        formValues[idx] = this.answers[this.step][this.question.id][idx].value || formValues[idx];
                    });
                    this.showSpoiler = formValues.filter((formValue) => formValue).length === formValues.length;
                }
            }
        }
    }
</script>
<style scoped>

    .card {
        max-height: 0;
        transition: max-height 0.2s;
        overflow: hidden;
    }

    .card.opened {
        max-height: 860px;
        transition: max-height 0.2s;
    }

    .checkbox-choice{
      width: 50%;
    }
    .checkbox-choice label{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    .checkbox-choice label span{
      width: 100%;
    }
    .checkbox-choice label input{
      width: 50%;
    }

    .recap ul, .recap li {
        margin: 0;
    }

    .next {
        border: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        cursor: pointer;
        padding: 10px 15px;
        color: #fff;
        z-index: 10;
        position: relative;
        outline: none;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type='number'] {
        width: 100%;
        -moz-appearance: textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    .open-modal {
        margin-bottom: 20px;
        padding: 12px;
        /* background: #c4c4c4 !important; */
        color: #fff;
        border-radius: 5px;
    }

    ul {
        list-style-type: none;
    }

    li {
        display: inline-block;
    }

    li:not(:last-child) {
        margin-right: 5px;
    }

    li:not(:last-child)::after {
        content: "|"
    }

    input.not-valid {
        -webkit-box-shadow: 0px 0px 0.1px 1px rgba(209, 31, 31, 1);
        -moz-box-shadow: 0px 0px 0.1px 1px rgba(209, 31, 31, 1);
        box-shadow: 0px 0px 0.1px 1px rgba(209, 31, 31, 1) !important;
    }

    input {
        font-size: 13px;
        outline: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;

    }

    input.valid {
        -webkit-box-shadow: 0px 0px 0.1px 1px rgba(51, 204, 51, 1);
        -moz-box-shadow: 0px 0px 0.1px 1px rgba(51, 204, 51, 1);
        box-shadow: 0px 0px 0.1px 1px rgba(51, 204, 51, 1) !important;
    }

    input:focus {
        -moz-box-shadow: 0px 0px 0.1px 1px rgba(51, 204, 51, 1);
    }

    @media screen and (max-width: 600px) {
        .modal-img {
            max-width: 100%;
            height: auto;
        }
    }
</style>
