<template>
  <div class="card" v-bind:class="[question.type]">
    <!-- <div class="card-header" :id="'header-'+step+'Question'+question.id" data-toggle="collapse"

         :data-target="'#collapseStep'+step+'Question'+question.id"
         v-bind:aria-expanded="question.openByDefault ? 'true' : 'false'"
         aria-controls="collapseOne"
         :class="{'answered' : showSpoiler}"
    >
      <h5 class="mb-0">
        <button class="btn btn-link">
          {{ $t(question.extraHtml.label) }}
        </button>
        <div class="recap" v-show="showSpoiler">
                    <span v-for="(answer, answerIndex) in selected">
                        <img v-if="answer" :src="answer.imageUrl" alt="Image">
                    </span>
        </div>
      </h5>
    </div> -->
    <div :id="'collapseStep'+step+'Question'+question.id" class="collapse"
         v-bind:class="{show : question.openByDefault}"
         :aria-labelledby="'collapseStep'+step+'Question'+question.id" data-parent="#main-form">
      <div class="extrahtml"
           v-if="question.extraHtml !== undefined && question.extraHtml.content_top !== null !== undefined && question.extraHtml.content_top !== null"
           v-html="$t(question.extraHtml.content_top)"></div>
      <button v-if="question.extraHtml.label_comment_faire !== null && question.extraHtml.label_comment_faire !== '' "
              @click.stop="fillAndOpenGlobalModal(question.extraHtml)" type="button"
              class="open-modal btn-modal general-question"
              data-toggle="modal"
              :data-target="'#modal'+step+'-'+question.id">
        {{ $t(question.extraHtml.label_comment_faire) }}
      </button>
      <div class="card-body" :class="'img-'+question.variable">
        <div v-for="(answer, answerKey) in question.answers" class="checkbox-choice"
             v-bind:class="{ 'selected': isSelected(answer) }"
             :data-size="colonneSize(question)"
        >
          <div @click="addToAnswers(answerKey)">
            <div class="image-placeholder"
                 :data-tooltip="answer.text_info_bulle !== undefined && answer.text_info_bulle !== '' ? answer.text_info_bulle : false"
                 data-tooltip-position="top">
              <img :class="['img-'+step+'-'+question.id+'-'+answerKey]"
                   :src="answer.imageUrl" alt=""></div>
            <p>{{ $t(answer.label) }}</p>
            <p v-html="answer.subtitle" style="font-size:11px;color:#515151;padding:0;margin:0;"
               :data-target="answer.triggerAlert !== undefined && answer.triggerAlert ?
                         '#question-alert-modal'+question.id : 'none'">{{answer.subtitle}}</p>
          </div>
          <div v-if=" isSelected(answer) && answer.label!=='Aucun'" class=" quantity d-flex">
            <!--<span>Quantité : </span>-->

            <div class="input-group d-flex">
              <input type="number"
                     v-model="answer.quantity"
                     :max="answer.Qmax ? answer.Qmax : 100"
                     :min="answer.Qmin ? answer.Qmin : 1"
                     :placeholder="answer.placeholder ? answer.placeholder : $t(answer.label)"
                     @change="addToAnswers(answerKey, false)"
                     v-bind:class="{'not-valid': !isValid(answerKey, getMinMax(answer),answer.step)}"
              />
            </div>
          </div>


          <button v-if="answer.Popup !== null && answer.Legende !== null && answer.Popup !== undefined && answer.Legende !== undefined"
                  @click.stop="fillAndOpenDefaultModal(answer)" type="button"
                  class="open-modal btn-modal mt-2"
                  data-toggle="modal"
                  :data-target="'#modal'+step+'-'+question.id+'-'+answerKey">
            {{answer.BtnLegende === undefined ? "+ d'infos" : answer.BtnLegende}}
          </button>
        </div>
      </div>
      <div class="contentBottom"
           v-if="question.extraHtml !== undefined && question.extraHtml.content_bottom !== undefined && question.extraHtml.content_bottom !== null"
           v-html="$t(question.extraHtml.content_bottom)"></div>

      <div class="change-btn" v-if="question.optional || showSpoiler">

        <button @click="nextQuestion()" type="button"
                class="open-modal next mt-2">
          {{ $t("Question suivante") }}
        </button>
      </div>
    </div>

  </div>
</template>

<script>
import common from './mixins/common'

export default {
  name: "MultipleImagesInputChoice",

  props: {
    question: Object,
    questions: Object,
    step: [String, Number],
  },
  created() {
    this.initialize()
  },
  mixins: [common],

  data() {
    return {
      form_values: [],
      selected: [],
      quantity: [],
      showSpoiler: false
    };
  },
  watch: {
    form_values: function (newVal) {
      let keepOld = false;
      const oldVal = newVal.map((el, index) => {

        return el.toString().slice(0, -1);

      });
      Vue.set(this, 'form_values', oldVal)

    }
  }
  ,

  computed: {
    steps() {
      return this.$store.state.steps;
    },
  },

  methods: {
    counterQuantity(answerIndex, add) {
      this.answers[this.step][this.question.id][answerIndex].quantity = parseInt(this.answers[this.step][this.question.id][answerIndex].quantity) + add;
      // console.log( this.answers[this.step][this.question.id][answerIndex].quantity)
      if (this.question.answers[answerIndex].Qmin !== undefined && this.question.answers[answerIndex].Qmax !== undefined) {
        if (this.answers[this.step][this.question.id][answerIndex].quantity > this.question.answers[answerIndex].Qmin && this.answers[this.step][this.question.id][answerIndex].quantity <= this.question.answers[answerIndex].Qmax && this.answers[this.step][this.question.id][answerIndex].quantity >= 1) {
          this.quantity[answerIndex] = parseInt(this.answers[this.step][this.question.id][answerIndex].quantity)
          this.showSpoiler = true
        } else {
          this.answers[this.step][this.question.id][answerIndex].quantity = parseInt(this.answers[this.step][this.question.id][answerIndex].quantity) - add;


        }

      } else {
        if (this.answers[this.step][this.question.id][answerIndex].quantity >= 1) {
          this.quantity[answerIndex] = parseInt(this.answers[this.step][this.question.id][answerIndex].quantity)
          this.showSpoiler = true

        } else {
          this.answers[this.step][this.question.id][answerIndex].quantity = parseInt(this.answers[this.step][this.question.id][answerIndex].quantity) - add;

        }
      }

      this.showSpoiler = this.answers[this.step][this.question.id][answerIndex].quantity >= 1;

    },
    getMinMax(answer, type) {
      return {min: answer.Qmin, max: answer.Qmax};
    },
    fillAndOpenDefaultModal(answer) {
      let modal = $('#basicModal');
      modal.find('h5').text(answer.label);
      // modal.find('img').attr('src', answer.Popup);
      modal.find('.extra-popup').html(answer.Legende)
      modal.modal('show');

    },
    fillAndOpenGlobalModal(answer) {
      let modal = $('#basicModal');
      modal.find('h5').text(answer.label_comment_faire);
      modal.find('.extra-popup').html(answer.popup_comment_faire);
      modal.modal('show');

    },
    isSelected(answer) {
      if (this.answers[this.step] && this.answers[this.step][this.question.id]) {
        return _.find(this.answers[this.step][this.question.id], (answerEl) => {
          return answerEl.value === answer.value
        })
      }
      return false

    },
    isValid(answerIndex, minMax, step) {
      if (this.answers[this.step][this.question.id][answerIndex]) {
        let value = this.answers[this.step][this.question.id][answerIndex].quantity;

          return value !== undefined && value !== "" && value >= 1

        }
    },

    colonneSize(question) {
      let colonnes = (question.blocsAlign) ? question.blocsAlign : question.extraHtml.colonnes;
      let key = this.question.rang;
      if (Array.isArray(colonnes)) {
        return colonnes[key];
      } else {
        return colonnes;
      }
    }
    ,
    nextQuestion() {
      const questionIndex = this.question.id;
      const question = this.questions[questionIndex];
      if (!this.showSpoiler) {
        let answer = this.answers[this.step][this.question.id]
        if (answer) {
          for (const [key, value] of Object.entries(answer)) {
            value.isAnswer = false;
            this.selected[key] = null;
            this.$store.dispatch('clearAnswer', value);

          }

        }
        const answerData = {
          stepIndex: this.step,
          questionIndex: questionIndex,
          questionType: this.questions[questionIndex].type,
          effectOnSvg: this.questions[questionIndex].effectOnSvg,
          index: 100,
          value: 'Aucun',
          variable: question.variable,
          quantity: 0,
          name: 'Aucun',
          changes: null,

        };

        this.showSpoiler = true;
        this.$store.dispatch('addAnswersThenCalc', answerData);

      }
      this.openNextQuestion(this.step, question.id);

    }
    ,
    addToAnswers: function (answerIndex, canClearAnswer = true) {
      const questionIndex = this.question.id;
      const question = this.questions[questionIndex];
      const answer = question.answers[answerIndex];

      // this.$store.dispatch('clearAnswer', old);

      const answerData = {
        stepIndex: this.step,
        questionIndex: questionIndex,
        questionType: this.questions[questionIndex].type,
        effectOnSvg: this.questions[questionIndex].effectOnSvg,
        index: answerIndex,
        value: answer.value,
        variable: question.variable,
        quantity: answer.quantity !== undefined ? answer.quantity : 1,
        name: answer.label,
        changes: answer.changes !== undefined ? answer.changes : null,

      };

      this.quantity[answerIndex] = answer.quantity;

      if (this.selected[answerIndex] && canClearAnswer) {
        answer.isAnswer = false;
        this.selected[answerIndex] = null;
        this.$store.dispatch('clearAnswer', answerData);

      } else {
        if (answer.label === 'Aucun') {
          if (this.answers[this.step]){

            let answer = this.answers[this.step][this.question.id]
            if (answer) {
              for (const [key, value] of Object.entries(answer)) {
                value.isAnswer = false;
                this.selected[key] = null;
                this.$store.dispatch('clearAnswer', value);

              }
            }

          }
          answerData['quantity']=0;
        } else {
          if (this.answers[this.step]){
            let answer = this.answers[this.step][this.question.id]

            if (answer) {

              for (const [key, value] of Object.entries(answer)) {
                if (value.name ==='Aucun'){
                  value.isAnswer = false;
                  this.selected[key] = null;
                  this.$store.dispatch('clearAnswer', value);
                }
              }
            }
          }


        }

        answer.isAnswer = true;
        this.selected[answerIndex] = answer;
        this.$store.dispatch('addAnswersThenCalc', answerData);

      }
      this.showSpoiler = this.selected.filter((answer) => answer).length > 0;
      // if (answer.Qmin && answer.Qmax) {
      //     this.showSpoiler = this.selected.filter((answer) => answer).map(answer => {
      //         return (answer.quantity >= answer.Qmin && answer.quantity <= answer.Qmax)
      //     }).filter((answer) => answer === false).length === 0 && this.selected.filter((answer) => answer).length > 0;
      //
      // } else {
      //     this.showSpoiler = this.selected.filter((answer) => answer).map(answer => {
      //         return (answer.quantity >= 1 )
      //     }).filter((answer) => answer === false).length === 0 && this.selected.filter((answer) => answer).length > 0;
      // }

    },

    initialize() {
      if (this.answers[this.step] && this.answers[this.step][this.question.id]) {
        Object.keys(this.answers[this.step][this.question.id]).forEach((idx) => {
          const answer = this.answers[this.step][this.question.id][idx];

          this.selected[idx] = this.steps[answer.stepIndex].questions[answer.questionIndex].answers[answer.index];
        });
        this.showSpoiler = this.selected.filter((answer) => answer).length > 0;


      }
    },
  }
}
</script>

<style scoped>
.quantity {
  width: 75%;
  /*justify-content: space-around;*/
  margin: 10px 0;
}

.quantity input {
  margin: 0 10px;
  width: 100%;
  text-align: center;
}

.quantity .btn-plus:focus, .btn-minus:focus {
  box-shadow: none !important;
}

.quantity span {
  font-weight: initial;
  align-items: center;
  display: flex;
}

.next {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
  padding: 10px 15px;
  color: #fff;
  z-index: 10;
  position: relative;
  outline: none;
}

input.not-valid {
  -webkit-box-shadow: 0px 0px 0.1px 1px rgba(209, 31, 31, 1);
  -moz-box-shadow: 0px 0px 0.1px 1px rgba(209, 31, 31, 1);
  box-shadow: 0px 0px 0.1px 1px rgba(209, 31, 31, 1) !important;
}

.recap span {
  height: 100%;
}

[data-tooltip] {
  position: relative;
}

/* Tooltip styling */
[data-tooltip]:before {
  content: attr(data-tooltip);
  display: none;
  position: absolute;
  background: #000;
  color: #fff;
  padding: 4px 8px;
  font-size: 14px;
  line-height: 1.4;
  min-width: 250px;
  text-align: center;
  border-radius: 4px;
  width: 150%;

}

/* Dynamic horizontal centering */
[data-tooltip-position="top"]:before {
  left: 50%;
  -ms-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

[data-tooltip-position="top"]:before {
  bottom: 100%;
  margin-bottom: 10px;
}

[data-tooltip]:after {
  content: '';
  display: none;
  position: absolute;
  width: 0;
  height: 0;
  border: solid transparent;
}

[data-tooltip-position="top"]:after {
  left: 50%;
  margin-left: -6px;
  margin-bottom: 4px;
}

[data-tooltip-position="top"]:after {
  bottom: 100%;
  border-width: 6px 6px 0;
  border-top-color: #000;
}

/* Show the tooltip when hovering */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  display: block;
  z-index: 100;
}

.contentBottom {
  font-weight: bold;
  color: #c4c4c5;
  font-size: 18px;
}

img {
  height: auto;
}

img[class*="img-1-2-"] {
  width: 200px;
  height: auto;
}

.card-header img {
  height: 100%;
}

.checkbox-choice {
  flex-grow: initial;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
}

.checkbox-choice p {
  margin: 10px 0 0 0;
}

.checkbox-choice.selected {
  font-weight: bold;
}

.checkbox-choice[data-size ='6'] {
  width: 15%;
}

.checkbox-choice[data-size ='4'] {
  width: 25%;
}
.checkbox-choice[data-size ='3'] {
  width: 30%;
}

.checkbox-choice[data-size = '2'] {
  width: 50%;
}

.inline-group {
  max-width: 9rem;
  padding: .5rem;
}

.inline-group .form-control {
  text-align: right;
}

.MultipleImagesInputChoice .image-placeholder img {
  max-width: 95%;
}

.form-control[type="number"]::-webkit-inner-spin-button,
.form-control[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

</style>
