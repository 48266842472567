<template>
	<div id="StepContainer">
		<QuestionContainer
			class="question"
			v-for="(question, index) in step.questions"
			:question="question"
			:class="{'hidden': hideQuestion(question)}"
			:step="step" v-if="!hideQuestion(question)"
		/>
		<ChangeStep
			:step="parseInt(step.id)"
			:maxNumberOfSteps="Object.keys(steps).length"
		/>
	</div>
</template>

<script>
import QuestionContainer from '@/components/QuestionContainer.vue'
import ChangeStep from '@/components/ChangeStep.vue'
import questionDependenciesAreMet from '@/lib/questionDependenciesAreMet'
import stepDependenciesAreMet from "@/lib/stepDependenciesAreMet";

export default {
	name: 'StepContainer',
	components: {
		QuestionContainer,
		ChangeStep
	},
	props: {
		step: {
			type: Object,
			default () {
				return {}
			}
		},
		steps: {
			type: Object,
			default () {
				return {}
			}
		}
	},
	data () {
		return {}
	},
	methods: {
		hideQuestion(question) {
			return !questionDependenciesAreMet(question, this.$store.state.answers);
		},
	}
}
</script>

<style lang="scss" scoped>

#StepContainer {

	padding-right: 40px;

	.question {
		margin-bottom: 2px;
	}
}
</style>
