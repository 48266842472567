<template>
    <div class="project-containers">
        <div class="project-single" v-for="project in projects">

        </div>
    </div>
</template>

<script>
    export default {
        name: "Projects",
        computed : {
            projects(){
                return this.$store.state.projects
            }
        }
    }
</script>

<style scoped>
    .project-containers {
        height: 100%;
        width: 100%;

        display: flex;
        flex-direction: column;
    }
</style>