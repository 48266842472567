export default (variable, steps) => {
    let found = null;

    if (steps) {
        Object.keys(steps).forEach((stepKey) => {
            const step = steps[stepKey];
            if (step !== undefined && step.questions !== undefined) {
                Object.keys(step.questions).forEach((questionKey) => {
                    const question = step.questions[questionKey];

                    if (['MultipleInputsBasic'].indexOf(question.type) > -1) {
                        question.answers.forEach((answer) => {
                            if (answer.variable === variable) {
                                found = question;
                            }
                        })
                    }

                    if (question.variable === variable) {
                        found = question;
                    }
                });
            }
        });
    }

    return found;
};