<template>
  <div class="card" v-bind:class="[question.type]">
   <!--  <div class="card-header answered" :id="'header-'+step+'Question'+question.id" data-toggle="collapse"
         :data-target="'#collapseStep'+step+'Question'+question.id"
         v-bind:aria-expanded="question.openByDefault ? 'true' : 'false'"
         aria-controls="collapseOne"
         :class="{'answered' : showSpoiler!==false}"
    >
      <h5 class="mb-0">
        <button class="btn btn-link">
          {{ $t(question.label) }}
        </button>
        <div class="recap" v-show="showSpoiler != false">
          <span>&#10004;</span>
        </div>
      </h5>
    </div> -->
    <div :id="'collapseStep'+step+'Question'+question.id" class="collapse"
         v-bind:class="{show : question.openByDefault}"
         :aria-labelledby="'collapseStep'+step+'Question'+question.id" data-parent="#main-form">
      <label v-if="question.placeholder">{{ question.placeholder }}</label>

      <div
          v-if="question.extraHtml !== undefined && question.extraHtml.content_top !== null !== undefined && question.extraHtml.content_top !== null"
          v-html="$t(question.extraHtml.content_top)"></div>
      <div class="card-body">
        <div v-for="(answer, answerKey) in question.answers" class="checkbox-choice selectedAuto"
             :data-size="colonneSize(question)"
        >

          {{ $t(answer.label) }}
        </div>
      </div>
      <div class="center-content"
           v-if="question.extraHtml !== undefined && question.extraHtml.content_bottom !== null !== undefined && question.extraHtml.content_bottom !== null"
           v-html="$t(question.extraHtml.content_bottom)"></div>
    </div>
  </div>
</template>

<script>
import common from './mixins/common'

export default {
  name: "TextRadioChoice",

  props: {
    question: Object,
    questions: Object,
    step: [String, Number]
  },

  mixins: [common],

  data: function () {
    return {
      showSpoiler: false
    }
  },

  watch: {
    '$route'(to, from) {
      if (to.params.step == this.step) {
        Object.keys(this.question.answers).forEach((key) => {
          this.addToAnswers(key);
        })
      } else {

      }
    }
  },

  methods: {
    colonneSize(question) {
      let colonnes = (question.blocsAlign) ? question.blocsAlign : question.extraHtml.colonnes;
      let key = this.question.rang;
      if (Array.isArray(colonnes)) {
        return colonnes[key];
      } else {
        return colonnes;
      }
    },
    addToAnswers: function (answerIndex) {
      const questionIndex = this.question.id;

      Object.keys(this.questions[questionIndex].answers).forEach((key) => {
        this.questions[questionIndex].answers[key].isAnswer = false;
      });

      const answer = this.questions[questionIndex].answers[answerIndex];
      answer.isAnswer = true;

      this.showSpoiler = answer.label;
      this.$store.dispatch('addAnswersThenCalc', {
        isGlobal: this.question.global !== undefined && this.question.global,

        stepIndex: this.step,
        questionIndex: questionIndex,
        questionType: this.questions[questionIndex].type,
        effectOnSvg: this.questions[questionIndex].effectOnSvg,
        index: 0,
        label: answer.label,
        value: answer.isAnswer ? answer.value : null,
        variable: this.questions[questionIndex].variable || null,
        changes: answer.changes !== undefined ? answer.changes : null,
      });

      //this.openNextQuestion(this.step, this.questions[questionIndex].id, this);
    },

    initialize() {
      if (this.answers[this.step] && this.answers[this.step][this.question.id]) {
        const answers = this.answers[this.step][this.question.id];

        Object.keys(answers).forEach((idx) => {
          answers[idx].isAnswer = true;

          this.showSpoiler = answers[idx].label || this.showSpoiler;
        });
      }
    },
  }
}
</script>

<style scoped>
.card-body > div {
  width: 35%;
}

.center-content {
  display: flex;
  justify-content: center;
  min-height: 100px;
}

.checkbox-choice {
  cursor: pointer;
  padding: 20px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.checkbox-choice:hover {
  background-color: #fafafa;
}

.checkbox-choice.selected {
  font-weight: bold;
  background-color: #fafafa;
  cursor: default;
}

.checkbox-choice[data-size ='6'] {
  width: 15%;
}

.checkbox-choice[data-size ='4'] {
  width: 25%;
}
.checkbox-choice[data-size ='3'] {
  width: 30%;
}

.checkbox-choice[data-size = '2'] {
  width: 50%;
}


label {
  margin-top: 25px;
}

@media screen and (max-width: 767px) {
  .checkbox-choice {
    margin-bottom: 30px;
    width: 40%;
  }
}
</style>
