<template>
  <div class="box-viewer">
    <img :src="apiUrl+'/'+ $store.state.family.image_recap_delais" id="icon-box"/>
  </div>
</template>

<script>
export default {
  name: "bae",
  data: function () {

    return {
      state: '',
      icon: "",
      colors: [],
    }
  },
  computed: {
    apiUrl() {
      return this.$store.state.apiUrl
    },
    isViewerOpen() {
      return this.$store.state.isViewerOpen
    },
    viewerHtml() {
      return this.$store.state.viewerHtml;
    },
    steps() {
      return this.$store.state.steps;
    },
    color() {
      return [];
    },
    answers() {
      return this.$store.state.answers;
    },
    getIcon() {
      return this.icon;
    }
  },

}
</script>


<style>
.box-viewer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#icon-box {
  /*max-height: 100px;*/
  /*width: auto;*/
  /*margin-bottom: 10px;*/
  width: 100%;
}

.color-holder {
  display: flex;
  justify-content: space-around;
  width: 80%;
  font-weight: bold;
}

.color-holder p {
  margin: 0;
  font-weight: bold;
}

.color-holder span {
  margin: auto;
  height: 25px;
  width: 25px;
  display: block;
  border-radius: 50%;
  -webkit-disnsition: 0.2s ease;
  disnsition: 0.2s ease;
  border: solid 1px grey;
  cursor: pointer;
  position: relative;
}

#icon-box{
  max-width: 100%;
}
</style>
