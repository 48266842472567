<template>
  <div class="card" v-bind:class="[question.type]">
    <!-- <div
      class="card-header"
      :id="'header-' + step + 'Question' + question.id"
      data-toggle="collapse"
      :data-target="'#collapseStep' + step + 'Question' + question.id"
      v-bind:aria-expanded="question.openByDefault ? 'true' : 'false'"
      aria-controls="collapseOne"
      :class="{ answered: showSpoiler }"
    >
      <h5 class="mb-0">
        <button class="btn btn-link">
          {{ $t(question.extraHtml.label) }}
        </button>
        <div class="recap" v-show="showSpoiler">
          <span v-for="(answer, answerIndex) in selected">
            <img v-if="answer" :src="answer.imageUrl" alt="Image" />
          </span>
        </div>
      </h5>
    </div> -->
    <div
      :id="'collapseStep' + step + 'Question' + question.id"
      class="collapse"
      v-bind:class="{ show: question.openByDefault }"
      :aria-labelledby="'collapseStep' + step + 'Question' + question.id"
      data-parent="#main-form"
    >
      <div
        class="extrahtml"
        v-if="
          question.extraHtml !== undefined &&
          (question.extraHtml.content_top !== null) !== undefined &&
          question.extraHtml.content_top !== null
        "
        v-html="$t(question.extraHtml.content_top)"
      ></div>
      <button
        v-if="
          question.extraHtml.label_comment_faire !== null &&
          question.extraHtml.label_comment_faire !== ''
        "
        @click.stop="fillAndOpenGlobalModal(question.extraHtml)"
        type="button"
        class="open-modal btn-modal general-question"
        data-toggle="modal"
        :data-target="'#modal' + step + '-' + question.id"
      >
        {{ $t(question.extraHtml.label_comment_faire) }}
      </button>
      <div class="card-body" :class="'img-' + question.variable">
        <div
          v-for="(answer, answerKey) in question.answers"
          class="checkbox-choice"
          :key="answerKey"
          v-bind:class="{ selected: isSelected(answer) }"
          :data-size="colonneSize(question)"
        >
          <div @click="addToAnswers(answerKey)">
            <div
              class="image-placeholder"
              :data-tooltip="
                answer.text_info_bulle !== undefined &&
                answer.text_info_bulle !== ''
                  ? answer.text_info_bulle
                  : false
              "
              data-tooltip-position="top"
            >
              <img
                :class="['img-' + step + '-' + question.id + '-' + answerKey]"
                :src="answer.imageUrl"
                alt=""
              />
            </div>
            <p>{{ $t(answer.label) }}</p>
            <p
              v-html="answer.subtitle"
              style="font-size: 11px; color: #515151; padding: 0; margin: 0"
              :data-target="
                answer.triggerAlert !== undefined && answer.triggerAlert
                  ? '#question-alert-modal' + question.id
                  : 'none'
              "
            >
              {{ answer.subtitle }}
            </p>
          </div>
          <div
            v-if="isSelected(answer) && answer.label !== 'Aucun'"
            class="quantity d-flex"
          >
            <!--<span>Quantité : </span>-->

            <div class="input-group-maps">
              <button @click="modal(answerKey)" class="p-2 mb-1 mesurer">
                <img
                  src="img/google-screen.png"
                  alt=""
                />
                <p>{{ $t(question.extraHtml.label_comment_faire) }}</p>
              </button>
              {{ question.consigne }}
              <input
                type="number"
                v-model="answer.lengthOfPerimeter"
                :placeholder="question.placeholder"
                @change="addToAnswers(answerKey, false)"
                v-bind:class="{ 'not-valid': !isValid(answerKey, '') }"
              />
            </div>
          </div>

          <button
            v-if="
              answer.Popup !== null &&
              answer.Legende !== null &&
              answer.Popup !== undefined &&
              answer.Legende !== undefined
            "
            @click.stop="fillAndOpenDefaultModal(answer)"
            type="button"
            class="open-modal btn-modal mt-2"
            data-toggle="modal"
            :data-target="'#modal' + step + '-' + question.id + '-' + answerKey"
          >
            {{
              answer.BtnLegende === undefined ? "+ d'infos" : answer.BtnLegende
            }}
          </button>
        </div>
      </div>
      <div
        class="contentBottom"
        v-if="
          question.extraHtml !== undefined &&
          question.extraHtml.content_bottom !== undefined &&
          question.extraHtml.content_bottom !== null
        "
        v-html="$t(question.extraHtml.content_bottom)"
      ></div>

      <div class="change-btn" v-if="question.optional || showSpoiler">
        <button
          @click="nextQuestion()"
          type="button"
          class="open-modal next mt-2"
        >
          {{ $t("Question suivante") }}
        </button>
      </div>
    </div>

    <!-- MODAL GOOGLE MAP-->
    <div v-if="this.open" id="myModal" class="modalMap">
      <!-- Modal content -->
      <div class="modal-content-map" style="text-align: center; margin: auto">
        <span @click="modal" class="close">&times;</span>

        <div
          class="col-md-12"
          style="
            display: flex;
            justify-content: center;
            text-align: center;
            flex-wrap: wrap;
          "
        >
          <!--SELECT CITY-->
          <div class="col-md-12">
            <GmapAutocomplete
              class="col-md-4 mb-4"
              @place_changed="setPlace"
              @keyup.enter="usePlace"
            >
            </GmapAutocomplete>
          </div>
          <div class="col-md-4">
            <div class="col-md-12 consignes">
              <label><strong>A lire avant de commencer</strong></label>
              <ol>
                <li>Saisissez l’adresse de votre terrain</li>
                <li>Cliquez sur la carte pour créer votre tracé</li>
                <li>Déplacez les points en restant appuyé dessus</li>
                <li>Pour ajouter un autre tracé, cliquez sur le bouton +</li>
                <li>Si besoin, supprimez un point en cliquant dessus</li>
              </ol>
            </div>
            <div class="col-md-12">
              <div class="col-12 list-clot">
                <label><strong>Liste des surfaces concernées</strong></label>
                <span @click="addSection" class="float-right add-section"
                  >+</span
                >
              </div>

              <div class="col-md-12">
                <ul v-if="paths[currentIndex].length > 0">
                  <li
                    v-for="(path, index) in paths[currentIndex]"
                    @click="changeSelected(index)"
                    class="cloture-trace"
                    v-bind:class="{ active: select === index }"
                  >
                    <span class="float-left">Tracé n°{{ index + 1 }} </span>
                    <span v-if="path.length > 0" v-html="calculateOne(path)">
                    </span>
                    <span v-else v-html="'0.00 m'"> </span>
                    <span class="float-right" @click="removeTarget(index)"
                      ><i class="fas fa-trash"></i
                    ></span>
                  </li>
                </ul>
                <span v-else>{{ $t("Vous n'avez aucun tracé") }}</span>
              </div>
            </div>
          </div>
          <div
            class="col-md-8 gmap"
            style="display: flex; justify-content: center"
          >
            <!-- IF PLACE EXIST-->
            <GmapMap
              class="col-md-12"
              style="height: 500px"
              v-if="this.place"
              :zoom="17"
              map-type-id="hybrid"
              :center="{
                lat: this.place.geometry.location.lat(),
                lng: this.place.geometry.location.lng(),
              }"
              @click="addPoint"
            >
              <GmapPolygon
                v-if="select === index"
                :paths="paths[currentIndex]"
                :editable="true"
                @paths_changed="updateEdited($event)"
                @click="removePoint($event, index)"
                v-for="(path, index) in paths[currentIndex]"
                :key="index"
                v-bind:options="{
                  strokeColor: '#000',
                  draggableCursor: 'default',
                }"
              />
              <GmapPolygon
                :paths="path"
                v-else
                :editable="true"
                @paths_changed="updateEdited($event)"
                @click="removePoint($event, index)"
                v-for="(path, index) in paths[currentIndex]"
                :key="index"
                v-bind:options="{ strokeColor: '#808080' }"
              />
            </GmapMap>

            <!--ELSE NOT PLACE EXIST-->
            <GmapMap
              class="col-md-12"
              style="height: 500px"
              v-else
              :zoom="5"
              :center="{ lat: 48.864716, lng: 2.349014 }"
              map-type-id="hybrid"
              @click="addPoint"
            >
              <GmapPolygon
                v-if="select === index"
                :paths="paths[currentIndex]"
                :editable="true"
                @paths_changed="updateEdited($event)"
                @click="removePoint($event, index)"
                v-for="(path, index) in paths[currentIndex]"
                :key="index"
                v-bind:options="{
                  strokeColor: '#000',
                  draggableCursor: 'default',
                }"
              />
              <GmapPolygon
                :paths="path"
                v-else
                :editable="true"
                @paths_changed="updateEdited($event)"
                @click="removePoint($event, index)"
                v-for="(path, index) in paths[currentIndex]"
                :key="index"
                v-bind:options="{ strokeColor: '#808080' }"
              />
            </GmapMap>
          </div>

          <div class="col-md-12 mt-4">
            <div
              style="
                width: 100%;
                text-align: center;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
              "
            >
              <button
                style="padding: 10px 100px; text-transform: capitalize"
                @click="calculatePath"
                class="validate-btn"
              >
                Valider
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import common from "./mixins/common";

export default {
  name: "MultipleImagesInputGoogleMapChoice",

  props: {
    question: Object,
    questions: Object,
    step: [String, Number],
  },
  created() {},
  mounted() {
    this.question.answers.forEach((el, index) => {
      this.paths[index] = [];
    });
    this.initialize();
  },
  mixins: [common],

  data() {
    return {
      form_values: [],
      selected: [],
      quantity: [],
      showSpoiler: false,
      open: false,
      paths: [],
      place: null,
      answerValue: "",

      currentIndex: undefined,

      //TEST
      edited: null,
      select: null,
    };
  },
  watch: {
    form_values: function (newVal) {
      let keepOld = false;
      const oldVal = newVal.map((el, index) => {
        return el.toString().slice(0, -1);
      });
      Vue.set(this, "form_values", oldVal);
    },
    question(newVal, oldVal) {
      newVal.answers.forEach((el, index) => {
        if (
          this.answers[this.step] &&
          this.answers[this.step][this.question.id] &&
          this.answers[this.step][this.question.id][index]
        ) {
          const answer = this.answers[this.step][this.question.id][index];
          // console.log(answer)
          el.lengthOfPerimeter = answer.lengthOfPerimeter;
          if (answer.paths !== undefined) {
            el.paths = answer.paths;
            this.paths[index] = answer.paths;
          } else {
            this.paths[index] = [];
          }

          if (answer.place !== undefined) {
            this.place = answer.place;
          }
        }
      });
      // console.log(this.place)
      // console.log(this.paths)
      this.reRenderPath();
    },
  },

  computed: {
    steps() {
      return this.$store.state.steps;
    },
  },

  methods: {
    setPlace(place) {
      this.place = place;
    },
    removeTarget(index) {
      this.paths[this.currentIndex].splice(index, 1);
      this.select = null;
      this.reRenderPath();
    },

    updateEdited(mvcArray) {
      if (
        this.paths[this.currentIndex].length < 1 ||
        (this.paths[this.currentIndex].length > 0 &&
          this.select === this.paths[this.currentIndex].length - 1)
      ) {
        let paths = [];
        for (let i = 0; i < mvcArray.getLength(); i++) {
          let path = [];
          for (let j = 0; j < mvcArray.getAt(i).getLength(); j++) {
            let point = mvcArray.getAt(i).getAt(j);
            path.push({ lat: point.lat(), lng: point.lng() });
          }
          paths.push(path);
        }
        this.paths[this.currentIndex] = paths;
      }
    },
    addPoint(point) {
      if (this.select === null || this.paths[this.currentIndex].length === 0) {
        this.select =
          this.paths[this.currentIndex].length > 0
            ? this.paths[this.currentIndex].length - 1
            : this.paths[this.currentIndex].length;
        this.addSection();
      }
      this.paths[this.currentIndex][this.select].push({
        lat: point.latLng.lat(),
        lng: point.latLng.lng(),
      });
      this.reRenderPath();
    },
    calculatePerimeter() {
      let area = 0;
      let perimetre = 0;
      if (this.questions[this.question.id].measureMap) {
        for (let i = 0; i < this.paths[this.currentIndex].length; i++) {
          perimetre = this.calculatePerimeterOne(
            this.paths[this.currentIndex][i]
          );
          area += perimetre;
        }
        area = area * 1.05;
        area = area.toFixed(2);

        this.$set(
          this.question.answers[this.currentIndex],
          "lengthOfPerimeter",
          area
        );
        this.addToAnswers(this.currentIndex, false);
        this.modal();
      } else {
        if (this.longueur != null && this.largeur != null) {
          switch (this.questions[this.question.id].measureType) {
            case "surface":
              let surface = this.longueur * this.largeur;
              this.answerValue = Math.round(surface.toFixed(2));
              break;
            case "perimetre":
              perimetre = this.longueur * 2 + this.largeur * 2;
              this.answerValue = Math.round(perimetre.toFixed(2));
              break;
          }
        }
      }
    },
    calculatePath() {
      // console.log(this.questions[this.question.id].measureType)
      switch (this.questions[this.question.id].measureType) {
        case "surface":
          return this.calculateArea();
          break;
        case "perimetre":
          return this.calculatePerimeter();
          break;
      }
    },
    calculateOne(path) {
      switch (this.questions[this.question.id].measureType) {
        case "surface":
          return this.calculateAreaOne(path).toFixed(2) + " m²";
          break;
        case "perimetre":
          return this.calculatePerimeterOne(path).toFixed(2) + " m";
          break;
      }
    },
    calculateArea() {
      let area = 0;

      let perimetre = 0;
      for (let i = 0; i < this.paths[this.currentIndex].length; i++) {
        perimetre = this.calculateAreaOne(this.paths[this.currentIndex][i]);
        area += perimetre;
      }
      this.answerValue = Math.round(area.toFixed(2));
      this.$set(
        this.question.answers[this.currentIndex],
        "lengthOfPerimeter",
        area
      );
      this.addToAnswers(this.currentIndex, false);
      this.modal();
    },
    calculateAreaOne(path) {
      return this.geoArea(path);
    },
    calculatePerimeterOne(path) {
      let area = 0;

      for (let i = 0; i < path.length - 1; i++) {
        area += this.distanceLatLng(
          path[i].lat,
          path[i].lng,
          path[i + 1].lat,
          path[i + 1].lng
        );
      }
      area += this.distanceLatLng(
        path[0].lat,
        path[0].lng,
        path[path.length - 1].lat,
        path[path.length - 1].lng
      );

      return area;
    },
    distanceLatLng(lat1, lon1, lat2, lon2) {
      let dist = 0;
      if (lat1 === lat2 && lon1 === lon2) {
        return 0;
      } else {
        let radlat1 = (Math.PI * lat1) / 180;
        let radlat2 = (Math.PI * lat2) / 180;
        let theta = lon1 - lon2;
        let radtheta = (Math.PI * theta) / 180;
        dist =
          Math.sin(radlat1) * Math.sin(radlat2) +
          Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

        if (dist > 1) {
          dist = 1;
        }

        dist = Math.acos(dist);
        dist = (dist * 180) / Math.PI;
        dist = dist * 60 * 1.1515;

        //TRANSFORM TO METER
        dist = dist * 1.609344 * 1000;
      }
      return dist;
    },
    removePoint($event, index) {
      if (this.select === null) {
        this.select = this.paths[this.currentIndex].length - 1;
      }

      if (this.select === index) {
        this.paths[this.currentIndex][this.select].splice($event.vertex, 1);
      }

      if (
        this.paths[this.currentIndex][this.select].length == 0 &&
        this.select != 0
      ) {
        this.paths[this.currentIndex].splice(this.select, 1);
      }
      this.reRenderPath();
    },
    addSection() {
      //console.log('addsection :'+this.select)
      this.paths[this.currentIndex].push([]);
      this.select = this.paths[this.currentIndex].length - 1;
      //console.log(this.select)
      this.reRenderPath();
    },
    changeSelected(index) {
      this.select = index;
    },
    modal(answerIndex = undefined) {
      this.currentIndex = answerIndex;
      this.open = !this.open;
    },

    getMinMax(answer, type) {
      return { min: answer.Qmin, max: answer.Qmax };
    },
    fillAndOpenDefaultModal(answer) {
      let modal = $("#basicModal");
      modal.find("h5").text(answer.label);
      // modal.find('img').attr('src', answer.Popup);
      modal.find(".extra-popup").html(answer.Legende);
      modal.modal("show");
    },
    fillAndOpenGlobalModal(answer) {
      let modal = $("#basicModal");
      modal.find("h5").text(answer.label_comment_faire);
      modal.find(".extra-popup").html(answer.popup_comment_faire);
      modal.modal("show");
    },
    isSelected(answer) {
      if (
        this.answers[this.step] &&
        this.answers[this.step][this.question.id]
      ) {
        return _.find(this.answers[this.step][this.question.id], (answerEl) => {
          return answerEl.value === answer.value;
        });
      }
      return false;
    },
    isValid(answerIndex, minMax, step) {
      if (this.answers[this.step][this.question.id][answerIndex]) {
        let value = this.answers[this.step][this.question.id][answerIndex]
          .lengthOfPerimeter;

        return value !== undefined && value !== "" && value >= 1;
      }
    },
    colonneSize(question) {
      let colonnes = question.blocsAlign
        ? question.blocsAlign
        : question.extraHtml.colonnes;
      let key = this.question.rang;
      if (Array.isArray(colonnes)) {
        return colonnes[key];
      } else {
        return colonnes;
      }
    },
    nextQuestion() {
      const questionIndex = this.question.id;
      const question = this.questions[questionIndex];
      if (!this.showSpoiler) {
        let answer = this.answers[this.step][this.question.id];
        if (answer) {
          for (const [key, value] of Object.entries(answer)) {
            value.isAnswer = false;
            this.selected[key] = null;
            this.$store.dispatch("clearAnswer", value);
          }
        }
        const answerData = {
          stepIndex: this.step,
          questionIndex: questionIndex,
          questionType: this.questions[questionIndex].type,
          effectOnSvg: this.questions[questionIndex].effectOnSvg,
          index: 100,
          value: "Aucun",
          variable: question.variable,
          quantity: 0,
          name: "Aucun",
          changes: null,
        };

        this.showSpoiler = true;
        this.$store.dispatch("addAnswersThenCalc", answerData);
      }
      this.openNextQuestion(this.step, question.id);
    },
    addToAnswers: function (answerIndex, canClearAnswer = true) {
      const questionIndex = this.question.id;
      const question = this.questions[questionIndex];
      const answer = question.answers[answerIndex];

      // this.$store.dispatch('clearAnswer', old);

      const answerData = {
        stepIndex: this.step,
        questionIndex: questionIndex,
        questionType: this.questions[questionIndex].type,
        effectOnSvg: this.questions[questionIndex].effectOnSvg,
        index: answerIndex,
        value: answer.value,
        lengthOfPerimeter: answer.lengthOfPerimeter,
        variable: question.variable,
        name: answer.label,
        changes: answer.changes !== undefined ? answer.changes : null,
        paths: this.paths[answerIndex],
        place: this.place,
      };

      this.quantity[answerIndex] = answer.quantity;

      if (this.selected[answerIndex] && canClearAnswer) {
        answer.isAnswer = false;
        this.selected[answerIndex] = null;
        this.$store.dispatch("clearAnswer", answerData);
      } else {
        if (answer.label === "Aucun") {
          if (this.answers[this.step]) {
            let answer = this.answers[this.step][this.question.id];
            if (answer) {
              for (const [key, value] of Object.entries(answer)) {
                value.isAnswer = false;
                this.selected[key] = null;
                this.$store.dispatch("clearAnswer", value);
              }
            }
          }
          answerData["quantity"] = 0;
        } else {
          if (this.answers[this.step]) {
            let answer = this.answers[this.step][this.question.id];

            if (answer) {
              for (const [key, value] of Object.entries(answer)) {
                if (value.name === "Aucun") {
                  value.isAnswer = false;
                  this.selected[key] = null;
                  this.$store.dispatch("clearAnswer", value);
                }
              }
            }
          }
        }

        answer.isAnswer = true;
        this.selected[answerIndex] = answer;
        this.$store.dispatch("addAnswersThenCalc", answerData);
      }
      this.showSpoiler = this.selected.filter((answer) => answer).length > 0;
      // if (answer.Qmin && answer.Qmax) {
      //     this.showSpoiler = this.selected.filter((answer) => answer).map(answer => {
      //         return (answer.quantity >= answer.Qmin && answer.quantity <= answer.Qmax)
      //     }).filter((answer) => answer === false).length === 0 && this.selected.filter((answer) => answer).length > 0;
      //
      // } else {
      //     this.showSpoiler = this.selected.filter((answer) => answer).map(answer => {
      //         return (answer.quantity >= 1 )
      //     }).filter((answer) => answer === false).length === 0 && this.selected.filter((answer) => answer).length > 0;
      // }
    },
    rad(coord) {
      return (coord * Math.PI) / 180;
    },
    geoArea(coords) {
      let area = 0;
      if (coords && coords.length > 0) {
        area += Math.abs(this.ringArea(coords));
      }
      return area;
    },
    ringArea(points) {
      const RADIUS = 6378137;
      const x = "lng";
      const y = "lat";
      let p1;
      let p2;
      let p3;
      let lowerIndex;
      let middleIndex;
      let upperIndex;
      let area = 0;

      if (points.length > 2) {
        for (let i = 0; i < points.length; i++) {
          if (i === points.length - 2) {
            lowerIndex = points.length - 2;
            middleIndex = points.length - 1;
            upperIndex = 0;
          } else if (i === points.length - 1) {
            lowerIndex = points.length - 1;
            middleIndex = 0;
            upperIndex = 1;
          } else {
            lowerIndex = i;
            middleIndex = i + 1;
            upperIndex = i + 2;
          }
          p1 = points[lowerIndex];
          p2 = points[middleIndex];
          p3 = points[upperIndex];
          area +=
            (this.rad(p3[x]) - this.rad(p1[x])) * Math.sin(this.rad(p2[y]));
        }
        area = (area * RADIUS * RADIUS) / 2;
      }

      return area;
    },
    initialize() {
      if (
        this.answers[this.step] &&
        this.answers[this.step][this.question.id]
      ) {
        Object.keys(this.answers[this.step][this.question.id]).forEach(
          (idx) => {
            const answer = this.answers[this.step][this.question.id][idx];
            // console.log(answer)
            this.selected[idx] = this.steps[answer.stepIndex].questions[
              answer.questionIndex
            ].answers[answer.index];
          }
        );
        this.showSpoiler = this.selected.filter((answer) => answer).length > 0;
      }
    },
    reRenderPath() {
      this.paths = _.cloneDeep(this.paths);
    },
  },
};
</script>

<style scoped>
.quantity,
.input-group-maps {
  width: 100%;
  /*justify-content: space-around;*/
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.quantity input {
  margin: 0 10px;
  width: 100%;
  text-align: center;
}

.quantity .btn-plus:focus,
.btn-minus:focus {
  box-shadow: none !important;
}

.quantity span {
  font-weight: initial;
  align-items: center;
  display: flex;
}

.next {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
  padding: 10px 15px;
  color: #fff;
  z-index: 10;
  position: relative;
  outline: none;
}

input.not-valid {
  -webkit-box-shadow: 0px 0px 0.1px 1px rgba(209, 31, 31, 1);
  -moz-box-shadow: 0px 0px 0.1px 1px rgba(209, 31, 31, 1);
  box-shadow: 0px 0px 0.1px 1px rgba(209, 31, 31, 1) !important;
}

.recap span {
  height: 100%;
}

[data-tooltip] {
  position: relative;
}

/* Tooltip styling */
[data-tooltip]:before {
  content: attr(data-tooltip);
  display: none;
  position: absolute;
  background: #000;
  color: #fff;
  padding: 4px 8px;
  font-size: 14px;
  line-height: 1.4;
  min-width: 250px;
  text-align: center;
  border-radius: 4px;
  width: 150%;
}

/* Dynamic horizontal centering */
[data-tooltip-position="top"]:before {
  left: 50%;
  -ms-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

[data-tooltip-position="top"]:before {
  bottom: 100%;
  margin-bottom: 10px;
}

[data-tooltip]:after {
  content: "";
  display: none;
  position: absolute;
  width: 0;
  height: 0;
  border: solid transparent;
}

[data-tooltip-position="top"]:after {
  left: 50%;
  margin-left: -6px;
  margin-bottom: 4px;
}

[data-tooltip-position="top"]:after {
  bottom: 100%;
  border-width: 6px 6px 0;
  border-top-color: #000;
}

/* Show the tooltip when hovering */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  display: block;
  z-index: 100;
}

.contentBottom {
  font-weight: bold;
  color: #c4c4c5;
  font-size: 18px;
}

img {
  height: auto;
}

img[class*="img-1-2-"] {
  width: 200px;
  height: auto;
}

.card-header img {
  height: 100%;
}

.checkbox-choice {
  flex-grow: initial;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
}

.checkbox-choice p {
  margin: 10px 0 0 0;
}

.checkbox-choice.selected {
  font-weight: bold;
}

.checkbox-choice[data-size="6"] {
  width: 15%;
}

.checkbox-choice[data-size="4"] {
  width: 25%;
}

.checkbox-choice[data-size="3"] {
  width: 30%;
}

.checkbox-choice[data-size="2"] {
  width: 50%;
}

.inline-group {
  max-width: 9rem;
  padding: 0.5rem;
}

.inline-group .form-control {
  text-align: right;
}

.MultipleImagesInputGoogleMapChoice .image-placeholder img {
  max-width: 95%;
}

.form-control[type="number"]::-webkit-inner-spin-button,
.form-control[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.gmap,
.gmap * {
  cursor: default !important;
}

.measure .measureInput {
  max-width: 50%;
  margin: 0 auto;
}

.measure .form-group {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.global-question {
}

.list-clot {
  margin: 20px 0;
}

.list-clot label {
  margin: 0;
}

.cloture-trace {
  cursor: pointer;
  text-align: center;
  margin: 10px 0;
  box-shadow: 0px 1px 5px 2px #808080;
  padding: 20px;
}

.cloture-trace.active {
  background: #d8b36c;
  color: #ffffff;
}

.add-section {
  padding: 1px 6px;
  box-shadow: 0px 1px 5px 2px #808080;
  font-weight: bold;
  color: #010101;
  cursor: pointer;
}

.add-section:hover {
  box-shadow: 0px 1px 5px 2px #808080;
  font-weight: bold;
  color: #fff;
  background: #d8b36c;
}

.consignes label {
  text-align: initial;
  font-size: 16px;
}

.consignes li {
  text-align: initial;
  font-size: 14px;
}

.mesurer {
  border: 1px solid #0e0e0e;
  align-items: center;
  display: inline-flex;
  transition: 0.3s;
}

.mesurer:hover {
  cursor: pointer;
  filter: brightness(80%);
}

.mesurer img {
  float: left;
}

.mesurer p {
  width: 100%;
  margin: 0;
  text-align: center;
}

.perimeterInput {
  text-align: center;
  border: 1px solid #0e0e0e;
}

.validate-btn {
  border: 1px solid #0e0e0e;
  font-size: 20px;
  transition: 0.3s;
}

.validate-btn:hover {
  cursor: pointer;
  filter: brightness(80%);
}

.modalMap {
  position: fixed; /* Stay in place */
  padding: 20px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  z-index: 1000;
  text-align: center;
}

/* Modal Content */
.modal-content-map {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  display: inline-block;
  width: 100%;
}

.card .collapse {
  padding: 20px;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.checkbox-choice input {
  width: 90%;
}

.gm-style div > div:last-child > div > div > div > div > div:hover,
.gm-style div > div:last-child > div > div > div > div:hover {
  cursor: initial !important;
}
</style>
