var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"change-btn"},[(_vm.showPrevBtn())?_c('router-link',{staticClass:"left",class:_vm.prevStep().toString(),attrs:{"tag":"button","to":{name:'constructor', params : {
                      type : _vm.$store.state.productType,
                      step : _vm.prevStep().toString()
                    }}},nativeOn:{"click":function($event){return _vm.prevBtnRecapPress($event)}}},[_vm._v(" "+_vm._s(_vm.$t("Retour"))+" ")]):_vm._e(),(_vm.showNextBtn() && !_vm.preventNextButton && _vm.$store.state.ready )?_c('router-link',{staticClass:"right",attrs:{"tag":"button","to":{name:'constructor', params : {
                      type : _vm.$store.state.productType,
                      step : _vm.nextStep().toString()
                    }}},nativeOn:{"click":function($event){return _vm.nextBtnPress($event)}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.nextButtonTitle))+" ")]):_c('p',{staticClass:"right-text missing-response"},[(!_vm.isRecap)?_c('span',[_vm._v(_vm._s(_vm.$t("Merci de bien vouloir répondre à toutes les questions pour pouvoir passer à l'étape suivante.")))]):_vm._e()]),(_vm.showNextBtn() && !_vm.preventNextButton && _vm.$store.state.productType === 'clo' && _vm.nextStep() > 3)?_c('p',{staticClass:"right-text"},[_c('span',[_vm._v(_vm._s(_vm.$t("Le prix sera actualisé quand vous aurez cliqué sur Etape Suivante")))])]):_vm._e(),(
  _vm.$store.state.family.Prefix !== 'Cre'
  && _vm.$store.state.family.Prefix !== 'wih' && _vm.$store.state.ready
  )?_c('div',{staticClass:"popup-valid",class:{'show-nextStep-modal' : _vm.showNextBtn() && !_vm.preventNextButton && _vm.isBtnModalShowing && !_vm.stepHasBannedQuestionType() && !_vm.stepHasBeenPassed}},[_c('div',{staticClass:"button-list"},[_c('p',[_vm._v(_vm._s(_vm.$t("Pour poursuivre votre configuration, merci de cliquer sur le bouton 'Etape suivante'")))]),(_vm.showNextBtn() && !_vm.preventNextButton)?_c('router-link',{staticClass:"right",attrs:{"tag":"button","to":{name:'constructor', params : {
        type : _vm.$store.state.productType,
        step : _vm.nextStep().toString()
      }}},nativeOn:{"click":function($event){return _vm.nextBtnPress($event)}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.nextButtonTitle))+" ")]):_vm._e(),_c('br'),_c('span',{staticClass:"cursor",on:{"click":function($event){return _vm.closeNextStepModal()}}},[_vm._v(_vm._s(_vm.$t("Modifier mes réponses")))])],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }