<template>
  <div class="card" v-bind:class="[question.type,question.visibility]">

    <div :id="'collapseStep'+step+'Question'+question.id" class="collapse"
         v-bind:class="{show : true}"
         :aria-labelledby="'collapseStep'+step+'Question'+question.id" data-parent="#main-form">
      <label v-if="question.placeholder">{{ question.placeholder }}</label>
      <div class="extrahtml"
           v-if=" question.extraHtml !== undefined &&
          question.extraHtml.content_top !== undefined &&
          question.extraHtml.content_top !== null"
           v-html="$t(question.extraHtml.content_top)"></div>
      <button
          v-if="$t(question.extraHtml.label_comment_faire) !== null && $t(question.extraHtml.label_comment_faire)!== undefined && $t(question.extraHtml.label_comment_faire)!== ''"
          @click.stop="fillAndOpenGlobalModal(question.extraHtml)" type="button"
          class="open-modal btn-modal general-question"
          data-toggle="modal"
          :data-target="'#modal'+step+'-'+question.id">
        {{ $t(question.extraHtml.label_comment_faire) }}
      </button>
      <div class="card-body cards-height-wrapper">
        <div v-for="(answer, answerKey) in question.answers"
             v-bind:class="{ 'selected' : isSelected(answer) }"

             class="checkbox-choice-container"
             :data-size="colonneSize(question)">
          <div class="details"v-if="question.fieldsToShow">
            <div v-for="(field,fieldKey) in question.fieldsToShow" v-if="answer[fieldKey]" class="field" :class="'fields-'+Object.keys(question.fieldsToShow).length">
              <label >{{$t(field)}} :</label>
              {{$t(answer[fieldKey])}}
            </div>
          </div>

          <div class="checkbox-choice" v-on:click="addToAnswers(answerKey,isSelected(answer))">
            {{$t('Confirmer')}}
          </div>


          <button
              v-if="answer.Popup !== null && answer.Legende !== null && answer.Popup !== undefined && answer.Legende !== undefined"
              @click.stop="fillAndOpenDefaultModal(answer)" type="button" class="open-modal btn-modal"
              data-toggle="modal"
              :data-target="'#modal'+step+'-'+question.id+'-'+answerKey">
            {{ answer.BtnLegende === undefined ? "+ d'infos" : answer.BtnLegende }}
          </button>
        </div>
      </div>
      <div
          v-if="question.extraHtml !== undefined && question.extraHtml.content_bottom !== null !== undefined && question.extraHtml.content_bottom !== null"
          v-html="$t(question.extraHtml.content_bottom)"></div>
    </div>
  </div>
</template>

<script>
import common from './mixins/common'
import i18n from "@/i18n";

export default {
  name: "TextRadioChoice",

  props: {
    question: Object,
    questions: Object,
    step: [String, Number]
  },

  mixins: [common],

  data: function () {
    return {
      showSpoiler: false
    }
  }
  ,
  methods: {
    strip: function (html) {
      let doc = new DOMParser().parseFromString(html, 'text/html');
      return doc.body.textContent || "";
    },
    isSelected(answer) {

      if (this.answers[this.step] && this.answers[this.step][this.question.id] !== undefined && this.answers[this.step][this.question.id][0] !== undefined) {

        return this.answers[this.step][this.question.id][0].value == answer.value;
      }
      let header = document.querySelector("#header-" + this.step + "Question" + this.question.id + "[aria-expanded = true]");

      //region AUTO SELECT IF ONLY ONE RESPONSE
      // if (header!==null){
      // console.log(header)
      //let selectAuto = setTimeout(this.activeCollapse, 800);

      // }

      //endregion
      return false
    },
    activeCollapse() {
      let header = document.querySelector("#header-" + this.step + "Question" + this.question.id + "[aria-expanded = true]");
      let element = document.querySelector("#collapseStep" + this.step + "Question" + this.question.id + " .checkbox-choice");

      // if (this.question.answers.length === 1 && header && element) {
      //   element.click();
      // }

    },
    colonneSize(question) {
      let colonnes = (question.blocsAlign) ? question.blocsAlign : question.extraHtml.colonnes;
      let key = this.question.rang;
      if (Array.isArray(colonnes)) {
        return colonnes[key];
      } else {
        return colonnes;
      }
    }
    ,
    fillAndOpenDefaultModal() {

      let modal = $('#basicModal');
      modal.find('h5').text('Comment prendre mes mesures');
      modal.find('img').attr('src', this.question.popup);
      modal.find('.extra-popup').html('')
      modal.modal('show');

    },
    fillAndOpenGlobalModal(answer) {
      let modal = $('#basicModal');
      modal.find('h5').text(answer.label_comment_faire);
      modal.find('.extra-popup').html(answer.popup_comment_faire);
      modal.modal('show');

    },
    addToAnswers: function (answerIndex, isSelected) {
      console.log({q : this.question.fieldsToShow, ans:  this.question.answers});
      const questionIndex = this.question.id;

      Object.keys(this.questions[questionIndex].answers).forEach((key) => {
        this.questions[questionIndex].answers[key].isAnswer = false;
      });

      const answer = this.questions[questionIndex].answers[answerIndex];

      answer.isAnswer = true;
      this.showSpoiler = true;
      this.$store.dispatch('addAnswersThenCalc', {
        ...answer,
        isGlobal: this.question.global !== undefined && this.question.global,
        name: i18n.t("Confirmé"),
        stepIndex: this.step,
        questionIndex: questionIndex,
        questionType: this.questions[questionIndex].type,
        effectOnSvg: this.questions[questionIndex].effectOnSvg,
        index: 0,
        label: i18n.t("Confirmé"),
        imageViewer: answer.imageViewer,
        value: answer.isAnswer ? answer.value : null,
        variable: this.questions[questionIndex].variable || null,
        changes: answer.changes !== undefined ? answer.changes : null,
        step: this.questions[questionIndex].step

      });

      this.openNextQuestion(this.step, this.questions[questionIndex].id, this);

    },

    initialize() {
      if (this.answers[this.step] && this.answers[this.step][this.question.id]) {
        const answers = this.answers[this.step][this.question.id];

        Object.keys(answers).forEach((idx) => {
          answers[idx].isAnswer = true;

          this.showSpoiler = answers[idx].label || this.showSpoiler;
        });
      }
    },
  }
}
</script>

<style scoped>
.card-body {
  justify-content: space-around;

}
.details{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom:20px;
}
.details .field{
  width: 50%;
  text-align: left;
}.details .fields-1{
  width: 100%;
  text-align: center;
}
.center-content {
  display: flex;
  justify-content: center;
  min-height: 100px;
}

.checkbox-choice-container {
  width: 80%;
}

.checkbox-choice {
  margin: auto;
  cursor: pointer;
  padding: 20px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  border-radius: 3px;
  width: 40%;
}

.checkbox-choice:hover {
  background-color: #fafafa;
}
.hidden{
  height: 0;
  opacity: 0;
}
.checkbox-choice.selected {
  cursor: default;
}

label {
  font-weight: bold;
  margin-top: 25px;
}

.checkbox-choice-container[data-size ='6'] {
  width: 15%;
}

.checkbox-choice-container[data-size ='4'] {
  width: 25%;
}
.checkbox-choice-container[data-size ='3'] {
  width: 30%;
}
.checkbox-choice-container[data-size = '2'] {
  width: 50%;
}
.showSpoiler{
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .checkbox-choice {
    margin-bottom: 30px;
    width: 40%;
  }
}

@media screen and (max-width: 520px) {
  .checkbox-choice-container {
    width: 80%;
    height: 100%;
  }

  .checkbox-choice {
    width: 100%;
  }

  .checkbox-choice[data-v-4a02367d] {
    padding: 5px;
  }

}

.recap.limit-length span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 200px;
  display: block;
}

@media screen and (max-width: 1220px){
  .card-body.cards-height-wrapper {
    display: flex;
    align-items: stretch;
  }

  .cards-height-wrapper .checkbox-choice-container {
    margin-top: 10px;
  }

  .cards-height-wrapper .checkbox-choice {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }
}

@media screen and (max-width: 1024px) {
  h5 .recap {
    display: none;
  }
}

.open-modal {
  margin-bottom: 20px;
  padding: 12px;
  /* background: #c4c4c4 !important; */
  color: #fff;
  border-radius:5px;
}
</style>
