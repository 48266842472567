import _ from 'lodash'
import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import router from './router'
import questionDependenciesAreMet from "./lib/questionDependenciesAreMet";
import questionIsAnswered from "./lib/questionIsAnswered";
import getAnswers from "./lib/getAnswers";
import aggregateAnswers from "./lib/aggregateAnswers";
import i18n from "@/i18n";
import common from "./components/form-groups/mixins/common.js"

Vue.use(Vuex);

const LOCAL_STORAGE_KEY = 'renovart-configurateur-state';

const getInitialState = () => {
    let state = null;
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString);

    try {
        state = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY));
    } catch (e) {
    }

    return _.merge({           
        apiUrl: process.env.VUE_APP_API_PATH,
        wpUrl: process.env.VUE_APP_WP_PATH,
        answers: {},
        errors: [],
        price: 0,
        subtotal: [],
        refs: [],
        heightWidthConstraint: {
            height: {
                min: 0,
                max: 0
            },
            width: {
                min: 0,
                max: 0
            }
        },
        //Trad marche pas ici, désactivé pour l'instant
        tvaMode: ("HT"), //("HT"),
        tvaPercent: 0,
        steps: {},
        siteInfos: {},
        family: {},
        families: [],
        familyGroups: [],
        validSteps: [],
        productType: "",
        products: [],
        currentProductKey: 0,
        currentProjectId: 0,
        currentProjectName: "",
        currentStep: 1,
        currentQuestion: 1,
        isViewerOpen: false,
        viewerHtml: null,
        refreshInc: 0,
        isLoaderShowing: false,
        preventNextButton: false,
        reduction: {},
        departement: "",
        country: "",
        olderThan2: null,
        globalAnswers: [],
        priceByProds: {},
        tauxTvas: {},
        livraisonPercent: 0,
        hitRecap: false,
        hitOtherProd: false,
        lockBackCap: false,
        //Trad marche pas ici, désactivé pour l'instant
        detail_title: "Votre projet en détail", //("Votre projet en détail"),
        detail_img: "img/Mon projet sur mesure.PNG",
        formule: null,
        countries: {},
        userInfos : null,
        lang : urlParams.get('lang') ? urlParams.get('lang') : 'fr',
        languages: [],
        ready: true
    }, state);
};

const store = new Vuex.Store({
    state: getInitialState(),

    mutations: {
        setDetails(state, details) {
            Vue.set(state, "detail_title", details.title)
            Vue.set(state, "detail_img", details.img)
        },

        setLockBackCap(state, lockBackCap) {
            Vue.set(state, 'lockBackCap', lockBackCap)
        },

        setPriceByProds(state, priceByProds) {
            Vue.set(state, 'priceByProds', priceByProds)
        },
        setTauxTvas(state, tauxTvas) {
            Vue.set(state, "tauxTvas", tauxTvas)
        },

        setDepartement(state, departement) {
            Vue.set(state, "departement", departement)
        },

        setCountry(state, country) {
            Vue.set(state, "country", country)
        },

        setOlderThan2(state, olderThan2) {
            Vue.set(state, "olderThan2", olderThan2)
        },
        setReduction(state, reduction) {
            Vue.set(state, 'reduction', reduction)
        },

        preventNextButton(state, bool) {
            Vue.set(state, 'preventNextButton', bool)
        },
        setSite(state, site) {
            Vue.set(state, 'siteInfos', site)
        },
        setFamilyGroups(state, groups) {
            Vue.set(state, 'familyGroups', groups)
        },
        setActiveFamilies(state, families) {
            Vue.set(state, 'families', families);
        },

        setCountries(state, countries) {
            Vue.set(state, 'countries', countries);
        },
        setViewerHtml(state, html) {
            Vue.set(state, 'viewerHtml', html)
        },
        setIsViewerOpen(state, close) {
            if (close !== undefined && close)
                Vue.set(state, 'isViewerOpen', false);
            else
                Vue.set(state, 'isViewerOpen', true)
        },

        setLoader(state, IsLoaderShowing) {
            Vue.set(state, 'isLoaderShowing', IsLoaderShowing);
        },

        addError(state, e) {
            state.errors.push(e);
        },

        deleteProduct(state, key) {
            if (state.products[key] !== undefined)
                Vue.set(state.products[key], 'deleted', true)
        },

        setStateToProduct(state, key) {
            Vue.set(state, 'currentProductKey', key);
            Vue.set(state, 'answers', state.products[key].answers);
            Vue.set(state, 'validSteps', state.products[key].validSteps);
            Vue.set(state, 'price', state.products[key].price);
        },


        setProducts(state, products) {
            Vue.set(state, 'products', products)
        },

        setAnswers(state, data) {
            Vue.set(state, 'answers', data.answers);
        },

        setReady (state, data) {
            let ready = data.ready
            if (ready) {
                if ( state.currentQuestion + 1 <= Object.keys(state.steps[state.currentStep].questions).length) {
                   Vue.set(state, 'currentQuestion', state.currentQuestion + 1);

                    let skip = false
                    Object.keys( state.steps[state.currentStep].questions).forEach((quest) => {
                        let question = state.steps[state.currentStep].questions[quest]
                        if (!skip && question.id >= state.currentQuestion) {
                            
                            if (questionDependenciesAreMet(question, state.answers)) {
                                Vue.set(state, 'currentQuestion', question.id);
                                skip = true
                            } else {
                                Vue.set(state, 'currentQuestion', question.id + 1);
                            }
                        }
                    })
                } else {
                    //if (state.currentStep < Object.keys(state.steps).length - 1) {
                        Vue.set(state, 'currentQuestion', -1);
                    //}
                }
            }

            if (ready) {
                console.log('READY', data.from)
            } else {
                console.log('NOT READY', data.from)
            }

            Vue.set(state, 'ready', ready)
        },

        setGlobalAnswers(state, answers) {
            Vue.set(state, "globalAnswers", answers)
        },

        setCurrentProjectName(state, name) {
            Vue.set(state, 'currentProjectName', name);
        },
        setStateProduct: (state) => {
            const s = getInitialState();
            Object.keys(s).forEach(key => {
                if (key !== 'products' && key !== 'currentProductKey') {
                    state[key] = s[key]
                }
            });
        },
        setState: (state, newState) => {
            const s = newState !== undefined ? newState : getInitialState();
            Object.keys(s).forEach(key => {
                state[key] = s[key]
            });
        },
        setFormule(state, formule) {
            Vue.set(state, 'formule', formule);
        },
        setReferences(state, references) {
            Vue.set(state, 'refs', references);
        },
        setPrice(state, price) {
            Vue.set(state, 'price', price);
        },
        setTvaMode(state, TvaMode) {
            Vue.set(state, 'tvaMode', TvaMode);
        },
        setTvaPercent(state, Percent) {
            Vue.set(state, 'tvaPercent', Percent);
        },
        setHeightWidthConstraint(state, heightWidthConstraint) {
            Vue.set(state, 'heightWidthConstraint', heightWidthConstraint);
        },
        setSteps(state, steps) {

            Vue.set(state, 'steps', steps);
        },
        setStep(state, data) {
            let step = data.step
            let setReady = data.setReady
            console.log('SET STEP', step.id)
            Vue.set(state.steps, step.id, step)
            if (step.id === state.currentStep) {
                console.log(`step ${step.id} data ready`)
                if (setReady) {
                    this.commit('setReady', {ready: true, from: 'setStep'})
                }
                
            }
            if (state.currentQuestion === Object.keys(state.steps[state.currentStep].questions).length && !state.answers[currentStep][state.currentQuestion][0].value) {
                if (setReady) {
                    this.commit('setReady', {ready: true, from: 'setStep'})
                }
            }
        },
        setValidSteps(state, validSteps) {
            Vue.set(state, 'validSteps', validSteps);
        },
        setCurrentProduct(state, project) {
            Vue.set(state.products, state.currentProductKey, project);
        },
        setProductPreviewNode(state, previewNode) {
            Vue.set(state.products[state.currentProductKey], 'previewNode', previewNode)
        },
        setCurrentProductShippingClass(state, shippingClass) {
            if (state.products.length === 0) return
            Vue.set(state.products[state.currentProductKey], 'shippingClass', shippingClass)
        },
        setProductType(state, productType) {
            Vue.set(state, 'productType', productType);
        },
        setCurrentProductKey(state, key) {
            Vue.set(state, 'currentProductKey', key);
        },
        setFamily(state, family) {
            Vue.set(state, 'family', family);
        },
        incRefreshInc(state) {
            state.refreshInc++;
        },
        setCurrentStep(state, step) {
            if (step !== state.currentStep) {
                Vue.set(state, 'currentStep', step);
                Vue.set(state, 'currentQuestion', 1);

                let skip = false

                if (state.steps[state.currentStep]) {
                    Object.keys( state.steps[state.currentStep].questions).forEach((quest) => {
                        if (!skip) {
                            let question = state.steps[state.currentStep].questions[quest]
                            if (questionDependenciesAreMet(question, state.answers)) {
                                skip = true
                            } else {
                                Vue.set(state, 'currentQuestion', question.id + 1);
                            }
                        }
                    })
                }
            }
        },
        setCurrentQuestion(state, id) {

            let nextQuestID = id
            let skip = false

            if (state.steps[state.currentStep]) {
                Object.keys(state.steps[state.currentStep].questions).forEach((quest) => {
                    let question = state.steps[state.currentStep].questions[quest]
                    if (question.id >= nextQuestID) {
                        if (!skip) {
                            if (questionDependenciesAreMet(question, state.answers)) {
                                skip = true
                            } else {
                                nextQuestID ++
                            }
                        }
                    }
                })
            }

            Vue.set(state, 'currentQuestion', nextQuestID);
        },
        setQuestion(state, question) {
            Vue.set(state.steps[question.stepId].questions[question.question.id], question.question)
        },
        setLivraisonPercent(state, livraisonPercent) {
            Vue.set(state, "livraisonPercent", livraisonPercent)
        },
        setHitRecap(state, hitRecap) {
            Vue.set(state, 'hitRecap', hitRecap)
        },
        setHitOtherProd(state, hitOtherProd) {
            Vue.set(state, 'hitOtherProd', hitOtherProd)
        },
        setUserInfos(state, userInfos){
            Vue.set(state, 'userInfos', userInfos)
        },
        setLang(state, lang){
            Vue.set(state, 'lang', lang)
        },
        setLanguages(state, languages)
        {
            Vue.set(state, "languages", languages)
        },
        setQuestionId (state, questionId) {
            Vue.set(state, 'questionID', questionId)
        }
    },

    actions: {
        changeLang(context, lang){
            context.commit('setLang', lang)
            context.dispatch("loadSiteInfos")
        },
        changeUserInfos(context, userInfos){
            context.commit('setUserInfos', userInfos)
        },
        changeHitRecap(context, hitRecap) {
            context.commit('setHitRecap', hitRecap)
        },
        changeHitQuantity(context, hitOtherProd) {
            context.commit('setHitOtherProd', hitOtherProd)
        },

        changePriceByProds(context, prices) {
            context.commit('setPriceByProds', prices)
        },
        /*changeProductAnswerProperty(context, args){
            let answers = context.state.products[args.product_index].answers;
            answers.map(step => {
                return step.map(question => {
                    return answers.map(answer => {
                        console.log(answer);
                        if (answer.variable === args.variable)
                            answer[args.variable] = args.value;
                    })
                })
            });

            let history;

        },*/


        commitDepartement(context, departement) {
            context.commit("setDepartement", departement);
        },

        commitCountry(context, country) {
            context.commit("setCountry", country);
        },

        commitOlderThan2(context, olderThan2) {
            context.commit("setOlderThan2", olderThan2)

        },

        setPreventNextButton(context, bool) {
            context.commit('preventNextButton', bool);
        },
        setQuestion(context, question) {
            context.commit('setQuestion', question);
        },
        toggleLoader(context) {
            context.commit('setLoader', !context.state.isLoaderShowing);
        },

        closeLoader(context) {
            context.commit('setLoader', false);
        },
        currentStep(context, step) {
            context.commit('setCurrentStep', step);
        },

        addGlobalAnswers(context, answer) {
            let answers = _.cloneDeep(context.state.globalAnswers);

            if (answers.some((res) => res.variable === answer.variable)) {
                answers[answers.findIndex((res) => res.variable === answer.variable)] = answer
            } else {
                answers.push(answer);
            }

            context.commit('setGlobalAnswers', answers)
        },

        addAnswers(context, answer) {
            //console.log({ stores: context.state, answer});
            if (answer.variable === "other_prod" && answer.value || answer.variable === "other_prod" && answer.label === "Oui") {
                if (!context.state.hitOtherProd) {

                    context.commit('setHitOtherProd', true)
                }
                return router.push('/preconstructor')
            }

            if (answer.name === "Sous Total") {
                let soustrait = (context.state.subtotal.length > 0) ? context.state.subtotal.reduce((a, b) => a + b) : 0;

                let step = answer.step ? answer.step : 2;

                if (context.state.family.Prefix !== 'ecu') {
                    if ((answer.stepIndex / step) > context.state.subtotal.length) {
                        context.state.subtotal.push(context.state.price - soustrait)
                        answer.rang = (context.state.price - soustrait)

                    } else {
                        if ((context.state.price - soustrait) > 0 && (answer.stepIndex / step) > 1) {
                            context.state.subtotal[(answer.stepIndex / step) - 1] = (context.state.price - soustrait)
                            answer.rang = (context.state.price - soustrait)
                        } else if ((answer.stepIndex / step) === 1) {
                            context.state.subtotal[(answer.stepIndex / step) - 1] = context.state.price
                            answer.rang = context.state.price
                        }
                        // console.log('update')
                    }
                } else {

                    let e = context.state.answers;
                    let keys = Object.keys(e);
                    let stepIndex = keys.indexOf(answer.stepIndex.toString())


                    if ((stepIndex / step) > context.state.subtotal.length) {
                        context.state.subtotal.push(context.state.price - soustrait)
                        answer.rang = (context.state.price - soustrait)

                    } else {
                        if ((context.state.price - soustrait) > 0 && (stepIndex / step) > 1) {
                            context.state.subtotal[(stepIndex / step) - 1] = (context.state.price - soustrait)
                            answer.rang = (context.state.price - soustrait)
                        } else if ((stepIndex / step) === 1) {
                            context.state.subtotal[(stepIndex / step) - 1] = context.state.price
                            answer.rang = context.state.price
                        }
                        // console.log('update')
                    }
                    // console.log('update')

                }

                // console.log(context.state.subtotal)

            }

            if (answer.variable === "client_prod" && answer.value !== 6 && answer.label !== "Non" || answer.variable === "client_prod" && answer.label === "Oui") {

                if (!context.state.hitOtherProd) {

                    context.commit('setHitOtherProd', true)
                }
                router.push('/preconstructor');

                // TEST AVEC SCENARIO FAIT
                // setTimeout(function (){document.querySelector("a[href='#/constructor/clo/etape1']").click()},200);
                let target = (answer.value != true) ? answer.value : context.state.family["Prefix"];

                setTimeout(function () {
                    document.querySelector("a[href='#/constructor/" + target + "/etape1']").click()
                }, 200);

                let popup = setTimeout(function () {
                    document.querySelector(".popup-valid.show-dep-modal").style.display = 'none';
                    document.querySelector(".mask.show-dep-modal").style.display = 'none';

                }, 600);

                return popup;

            }

            if (answer.variable === "other_family" && answer.targetFamilly && answer.value === 2 ) {


                if (!context.state.hitOtherProd) {

                    context.commit('setHitOtherProd', true)
                }

                router.push('/');

                // TEST AVEC SCENARIO FAIT
                // setTimeout(function (){document.querySelector("a[href='#/constructor/clo/etape1']").click()},200);
                let target = answer.targetFamilly;
                setTimeout(function () {
                    document.querySelector("div[to='/preconstructor']").click()
                }, 200);

                setTimeout(function () {
                    document.querySelector("a[href='#/constructor/" + target + "/etape1']").click()
                }, 400);

                let popup = setTimeout(function () {
                    document.querySelector(".popup-valid.show-dep-modal").style.display = 'none';
                    document.querySelector(".mask.show-dep-modal").style.display = 'none';

                }, 600);

                return popup;

            }



            if (answer.isGlobal) {
                context.dispatch("addGlobalAnswers", answer);
            }
            context.dispatch('clearAnswerNoRecalc', answer);
            let answers = _.cloneDeep(context.state.answers);

            if (answers[answer.stepIndex] === undefined) {
                answers[answer.stepIndex] = {};
            }

            if (answers[answer.stepIndex][answer.questionIndex] === undefined) {
                answers[answer.stepIndex][answer.questionIndex] = {};
            }

            if (['TextRadioChoice', 'ImageRadioChoice', 'SelectField', 'PoseChoice', 'RadioQuantityChoice'].indexOf(answer.questionType) > -1) {
                answers[answer.stepIndex][answer.questionIndex][0] = answer;
            } else {
                // console.log(answers[answer.stepIndex][answer.questionIndex])
                answers[answer.stepIndex][answer.questionIndex][answer.index] = answer;
                // console.log(answers[answer.stepIndex][answer.questionIndex])
            }
            const question = context.state.steps[answer.stepIndex].questions[answer.questionIndex];
            if (question && question.openViewer !== undefined) {
                context.commit('setViewerHtml', question.answers[answer.index].html);
                context.commit('setIsViewerOpen')
            }
            let cleanAnswers = {};
            Object.keys(answers).forEach(stepKey => {
                Object.keys(answers[stepKey]).forEach(questionKey => {
                    if (parseInt(stepKey) <= parseInt(answer.stepIndex)) {
                        if (cleanAnswers[stepKey] === undefined) {
                            cleanAnswers[stepKey] = {}
                        }
                        cleanAnswers[stepKey][questionKey] = answers[stepKey][questionKey];
                    } else {
                        if (context.state.steps[stepKey].questions[questionKey].openViewer !== undefined) {
                            context.commit('setIsViewerOpen', true)
                        }
                    }
                })
            });

            Object.keys(answers[answer.stepIndex]).forEach(key => {
                if (key > parseInt(answer.questionIndex)) {
                    delete cleanAnswers[answer.stepIndex][key];
                }
            });

            if (JSON.stringify(answers) !== JSON.stringify(cleanAnswers)) {
                context.commit('incRefreshInc');
                context.dispatch('setPreventNextButton', false);

            }
            context.commit('setAnswers', {
                answers: answers,
                type: 'cleanAnswers'
            });
        },

        addAnswersThenCalc(context, answer) {
            context.dispatch('addAnswers', answer).then(() => {
                context.dispatch('calculateValidSteps').then(() => {
                    context.dispatch('calculatePrice');
                });
            })


        },
        addMultipleAnswers(context, addedAnswers) {
            let answers = _.cloneDeep(context.state.answers);

            if (answers[addedAnswers.stepIndex] === undefined) {
                answers[addedAnswers.stepIndex] = {};
            }

            if (answers[addedAnswers.stepIndex][addedAnswers.questionIndex] === undefined) {
                answers[addedAnswers.stepIndex][addedAnswers.questionIndex] = {};
            }


            answers[addedAnswers.stepIndex][addedAnswers.questionIndex] = addedAnswers.answers;

            context.commit('setAnswers', {
                answers: answers,
                type: 'fromMultiple'
            });

            context.dispatch('calculateValidSteps');
            context.dispatch('calculatePrice');
        },

        calculateValidSteps(context) {
            const validSteps = _.cloneDeep(context.state.validSteps);
            const answers = _.cloneDeep(context.state.answers);
            Object.keys(answers).forEach(function (key) {
                const questions = context.state.steps[key].questions;

                let allAnswered = true;

                Object.keys(questions).forEach(function (questionKey) {
                    const question = questions[questionKey];
                    if (questionDependenciesAreMet(question, answers)) {
                        if (question.isOptional === undefined || !question.isOptional) {
                            if (['MultipleInputsBasic'].indexOf(question.type) > -1) {
                                question.answers.forEach((answer) => {
                                    if (!questionIsAnswered(answer.variable, answers)) {
                                        allAnswered = false;
                                    }
                                });
                            } else if (['MultipleImageRadioChoice'].indexOf(question.type) > -1) {
                                // noop
                            } else {
                                if (!questionIsAnswered(question.variable, answers)) {
                                    allAnswered = false;
                                }
                            }
                        }
                    }
                });

                let index = validSteps.indexOf(key);
                if (allAnswered) {
                    if (index === -1) {
                        validSteps.push(key)
                    }
                } else {
                    if (index !== -1) {
                        validSteps.length = index
                    }
                }
            });

            context.commit('setValidSteps', validSteps);
        },

        changeQuestion: (context, answersList) => {
            //if (context.state.currentQuestion >= Object.keys(context.state.steps[context.state.currentStep].questions).length ) return
            let answerThatImpactQuestions = answersList[0].filter((answer) => answer.changes !== undefined);
            answerThatImpactQuestions = _.uniqBy(_.flatten(answerThatImpactQuestions.map((el) => {
                return el.changes
            })), 'stepChange');

            let impactCurrentStep = false
            answerThatImpactQuestions.forEach((change) => {
                if (change !== null) {
                    if (change.stepChange === context.state.currentStep) {
                        impactCurrentStep = true
                    }
                }
            })

            if (Object.keys(answerThatImpactQuestions).length === 0) return

            answerThatImpactQuestions.forEach((change) => {

                if (change !== null) {
                    const varsFromList = {};
                    const setReady = context.state.currentStep === change.stepChange;
                    change.var_needed.forEach(el => varsFromList[el] = answersList[1][el]);
                    //_.toArray(varsFromList).filter(el => el === undefined || el === "").length === 0
                    if (change.stepChange >= context.state.currentStep) {

                        const data = {
                            'impacts': change.impacts,
                            'stepChange': change.stepChange,
                            'vars': varsFromList,
                            'lang': context.state.lang,
                        };
                        return axios
                            .post(context.state.apiUrl + '/' + context.state.productType + '/changes', data)
                            .then(response => {
                                const step = response.data.step;
                                context.commit('setStep', {step: step, setReady: setReady});
                            })
                            .catch((e) => {

                            });
                    }
                }
            });

            if (!impactCurrentStep) {
                context.commit('setCurrentQuestion', context.state.currentQuestion + 1)
                //Vue.set(context.state, 'currentQuestion', context.state.currentQuestion + 1);
                Vue.set(context.state, 'ready', true);
            }
            
        },

        calculatePrice(context, noChangeQuestion) {
            const answers = getAnswers(context.state.answers);
            const list = aggregateAnswers(answers);
            if (!noChangeQuestion) context.dispatch('changeQuestion', [answers, list]);
            let arrayOfProd = {};
            let arrayOfProdPoses = {};

            const products = context.state.products.filter(prod => !prod.deleted);
            Object.keys(products).forEach((product) => {

                if (arrayOfProd[products[product].productType] === undefined && products[product].productType !== "sav" && products[product].productType !== "vr") {
                    arrayOfProd[products[product].productType] = 0;
                }

                if (arrayOfProd[products[product].productType + '_' + product] === undefined && products[product].productType === "sav") {
                    arrayOfProd[products[product].productType + '_' + product] = null;
                }

                if (arrayOfProd[products[product].productType + '_' + product] === undefined && products[product].productType === "sav") {
                    arrayOfProd[products[product].productType + '_' + product] = null;
                }


                if (arrayOfProdPoses[products[product].productType] === undefined) {
                    arrayOfProdPoses[products[product].productType] = {
                        deps: [],
                        countries: [],
                        modele: [],
                        gamme: [],
                        quantityGamme: [],
                        priceGamme: [],
                        quantity: 0,
                    };
                }
                if (!products[product].deleted && products[product].productType !== "sav") {
                    if (products[product].history.find(el => el.label === "Quantité") !== undefined) {
                        const q = products[product].history.find(el => el.label === "Quantité");
                        arrayOfProdPoses[products[product].productType].quantity += parseInt(q.value);
                    } else {
                        arrayOfProdPoses[products[product].productType].quantity += 1;
                    }
                    arrayOfProdPoses[products[product].productType].deps.push(context.state.departement);
                    arrayOfProdPoses[products[product].productType].countries.push(context.state.country);
                    arrayOfProdPoses[products[product].productType].priceGamme = context.state.priceByProds;

                    if (products[product].history.find(el => el.label === "Gamme")) {
                        const res = products[product].history.find(el => el.label === "Gamme");

                        if (!arrayOfProdPoses[products[product].productType].gamme.includes(res.value)) {
                            arrayOfProdPoses[products[product].productType].gamme.push(res.value);
                            let id = arrayOfProdPoses[products[product].productType].gamme.indexOf(res.value)
                            if (products[product].history.find(el => el.label === "Quantité") !== undefined) {
                                const q = products[product].history.find(el => el.label === "Quantité");
                                arrayOfProdPoses[products[product].productType].quantityGamme[id] = parseInt(q.value);
                            } else {
                                arrayOfProdPoses[products[product].productType].quantityGamme[id] = 1;
                            }

                        } else {
                            let id = arrayOfProdPoses[products[product].productType].gamme.indexOf(res.value)
                            if (products[product].history.find(el => el.label === "Quantité") !== undefined) {
                                const q = products[product].history.find(el => el.label === "Quantité");
                                arrayOfProdPoses[products[product].productType].quantityGamme[id] += parseInt(q.value);
                            } else {
                                arrayOfProdPoses[products[product].productType].quantityGamme[id] += 1;
                            }
                        }
                    }

                }

                if (!products[product].deleted && products[product].productType !== "sav" && products[product].productType !== "vr") {
                    if (products[product].history.find(el => el.label === "Quantité") !== undefined) {
                        const q = products[product].history.find(el => el.label === "Quantité");
                        arrayOfProd[products[product].productType] += parseInt(q.value);
                    } else {
                        arrayOfProd[products[product].productType] += 1;
                    }
                }

                if (!products[product].deleted && products[product].productType === "sav") {
                    const presta = getAnswers(products[product].answers).find(el => el.variable === "prestation");

                    if (presta) {
                        arrayOfProd[products[product].productType + '_' + product] = presta.value
                    }
                }

                if (!products[product].deleted && products[product].productType === "vr") {
                    const color = getAnswers(products[product].answers).find(el => el.variable === "coloris_produit");

                    if (color) {
                        const q = products[product].history.find(el => el.label === "Quantité");
                        arrayOfProd[products[product].productType + '_' + product] = {
                            'quantity': q !== undefined ? q.value : 1,
                            'value': color.value
                        };
                    }
                }
            });
            list.quantiteTotal = 1;
            list.arrayOfProd = arrayOfProd;

            list.arrayOfProdPoses = arrayOfProdPoses;

            list.departement = context.state.departement;
            list.country = context.state.country;
            list.olderThan2 = context.state.olderThan2;
            list.livraison = false;
            list.metrage = false;
            list.lang = context.state.lang;

            context.state.globalAnswers.map(el => {
                if (el.variable === 'livraison')
                    list.livraison = el.value;

                if (el.variable === "metrage")
                    list.metrage = el.value
            });

            console.log('LISTE', list)


            return axios
                .post(context.state.apiUrl + '/' + context.state.productType + '/price', list)
                .then(response => {
                    context.commit('setTvaMode', response.data.tvaMode);
                    context.commit('setTvaPercent', response.data.tvaPercent);
                    context.commit('setHeightWidthConstraint', response.data.heightWidthConstraint);
                    context.commit('setReduction', response.data.reduction !== null ? response.data.reduction : {});
                    context.commit('setTauxTvas', response.data.tvas);
                    context.commit('setLivraisonPercent', response.data.livraisonPercent);
                    context.commit('setCurrentProductShippingClass', response.data.livraisonClass);
                    context.commit('setPrice', response.data.price);
                    context.commit('setFormule', response.data.formule);

                    const refTranslated = response.data.refs.map(el => {
                        return {
                            ...el,
                            label : i18n.t(el.label)
                        }

                    })
                    context.commit('setReferences', refTranslated);

                })
                .catch((e) => {
                    console.log(e)
                    context.commit('addError', e);
                });
        },

        clearAnswer(context, answer) {
            const answers = _.cloneDeep(context.state.answers);

            Object.keys(answers).forEach(function (stepKey) {
                const questions = answers[stepKey];

                Object.keys(questions).forEach(function (questionKey) {
                    const items = questions[questionKey];

                    Object.keys(items).forEach(function (itemKey) {

                        const item = items[itemKey];

                        if (item.variable === answer.variable) {
                            if ((answer.questionType !== 'MultipleImageRadioChoice' && answer.questionType !== 'MultipleQuantityChoice'
                                && answer.questionType !== 'MultipleImagesInputChoice'
                                && answer.questionType !== 'MultipleImagesInputGoogleMapChoice'
                            )
                                || itemKey == answer.index) {

                                delete answers[stepKey][questionKey][itemKey];
                            }
                        }
                    });
                });

                if (!Object.keys(questions).length) {
                    delete answers[stepKey];
                }
            });
            context.commit('setAnswers', {
                answers: answers,
                type: 'clear'
            });
            context.dispatch('calculatePrice');
        },


        clearAnswerNoRecalc(context, answer) {
            const answers = _.cloneDeep(context.state.answers);

            Object.keys(answers).forEach(function (stepKey) {
                const questions = answers[stepKey];

                Object.keys(questions).forEach(function (questionKey) {
                    const items = questions[questionKey];

                    Object.keys(items).forEach(function (itemKey) {
                        const item = items[itemKey];

                        if (item.variable === answer.variable && item.questionIndex === answer.questionIndex) {
                            if ((answer.questionType !== 'MultipleImageRadioChoice' && answer.questionType !== 'MultipleQuantityChoice'
                                && answer.questionType !== 'MultipleImagesInputChoice'
                                && answer.questionType !== 'MultipleImagesInputGoogleMapChoice'
                                && answer.questionType !== 'MultipleInputsBasic'
                            ) || itemKey == answer.index) {
                                delete answers[stepKey][questionKey][itemKey];
                            }
                        }
                    });
                });

                if (!Object.keys(questions).length) {
                    delete answers[stepKey];
                }
            });
            context.commit('setAnswers', {
                answers: answers,
                type: 'clear'
            });
        },

        loadQuestions(context) {
            context.dispatch('toggleLoader');
            return axios
                .get(context.state.apiUrl + '/' + context.state.productType + '/steps')
                .then(response => {
                    const steps = response.data.steps;
                    const family = response.data.famille;
                    // const countries = response.data.countries;
                    try {
                        Object.keys(context.state.answers).forEach((stepIndex) => {
                            const stepAnswers = context.state.answers[stepIndex];
                            Object.keys(stepAnswers).forEach((questionIndex) => {
                                Object.keys(stepAnswers[questionIndex]).forEach((idx) => {
                                    const answer = stepAnswers[questionIndex][idx];
                                    if (answer && answer.index !== undefined &&
                                        steps[stepIndex].questions[questionIndex] !== undefined &&
                                        steps[stepIndex].questions[questionIndex].answers[answer.index] !== undefined) {


                                        const questionAnswer = steps[stepIndex].questions[questionIndex].answers[answer.index];
                                        if (steps[stepIndex].questions[questionIndex].openViewer !== undefined) {
                                            context.commit('setViewerHtml', questionAnswer.html);
                                            context.commit('setIsViewerOpen')
                                        }
                                        questionAnswer.isAnswer = true;
                                        questionAnswer.value = answer.value;
                                        questionAnswer.selectionIndex = answer.selectionIndex;
                                    }
                                });
                            });
                        });
                    } catch (e) {
                    }
                    context.commit('setSteps', steps);
                    context.commit('setFamily', family);
                    // context.commit('setCountries', countries)
                    context.commit('setDetails', {
                        img: context.state.apiUrl + '/' + family.detail_img,
                        title: family.detail_title,
                    });
                    context.dispatch('calculatePrice');
                    context.dispatch('closeLoader');
                })
                .catch((e) => {
                    console.log(e)
                });
        },
        async loadSiteInfos(context) {
            context.dispatch('toggleLoader');
            return axios
                .get(context.state.apiUrl + '/infos', { params: {
                    lang: context.state.lang
                }})
                .then(async response => {
                    context.commit('setSite', response.data.tenant);
                    context.commit("setFamilyGroups", response.data.groups)
                    context.commit('setActiveFamilies', response.data.groups.reduce((acc, group) => [...acc, ...( Array.isArray(group.familles) ? group.familles : Object.values(group.familles))], []));
                    context.commit('setCountries', response.data.countries);
                    context.commit("setLanguages", response.data.languages)
                    try {
                        // let res = await fetch(response.data.tenant.UrlBoutique + '?check_user_auth');
                        //let isLogged = await res.json();

                        //if (!isLogged.user_logged_in)
                        //window.location = response.data.tenant.UrlBoutique + "/mon-compte?redirect=" + response.data.tenant.UrlBoutique + "/configurateur"

                    } catch (e) {
                        console.log(e)
                    }

                    context.dispatch('closeLoader');


                })
                .catch((e) => {
                    context.commit('addError', e);
                });
        },
        setProductType(context, productType) {
            context.commit('setProductType', productType);
        },
        saveProduct: (context, args, preview) => {
            let history = args.history;
            let deleted = args.deleted;
            context.commit('setCurrentProduct', {
                ...context.state.products[context.state.currentProductKey],
                history: history,
                ref:context.state.refs,
                price: context.state.price,
                answers: context.state.answers,
                validSteps: context.state.validSteps,
                productType: context.state.productType,
                previewNode: preview === undefined ? null : preview,
                name: history[0] !== undefined ? history[0].value : '',
                tvaMode: context.state.tvaMode,
                tvaPercent: context.state.tvaPercent,
                deleted: deleted,
            });
        },

        deleteCurrentProdAndRedirect(context) {

            context.dispatch('deleteProduct', context.state.currentProductKey).then(() => {
                const nextProduct = context.state.products.filter(product => !product.deleted).find(product => !product.deleted)
                context.dispatch('loadProduct', context.state.products.filter(product => !product.deleted).findIndex(product => !product.deleted));
                const answers = getAnswers(nextProduct.answers);
                if (context.state.hitRecap) {
                    const recapIndex = parseInt(answers.pop().stepIndex) + 1;
                    return router.push(`/constructor/${nextProduct.productType}/etape${recapIndex}`);
                }

                if (context.state.hitOtherProd) {

                    let otherProdAnswer = answers.find(answer => answer.variable === "quantite");
                    if (otherProdAnswer) {
                        context.commit('setLockBackCap', true);
                        return router.push(`/constructor/${nextProduct.productType}/etape${parseInt(otherProdAnswer.stepIndex) + 1}`);
                    }
                }
            })
        },
        redirectToRecap(context) {
            const currentProduct = context.state.products[context.state.currentProductKey]
            const answers = getAnswers(currentProduct.answers);
            if (context.state.hitRecap) {
                const recapIndex = parseInt(answers.pop().stepIndex) + 1;
                return router.push(`/constructor/${currentProduct.productType}/etape${recapIndex}/true`);
            }
            if (context.state.hitOtherProd) {
                let otherProdAnswer = answers.find(answer => answer.variable === "quantite");
                if (otherProdAnswer) {
                    context.commit('setLockBackCap', true);
                    return router.push(`/constructor/${currentProduct.productType}/etape${parseInt(otherProdAnswer.stepIndex) + 1}/true`);
                }
            }
        },

        deleteProduct(context, key) {
            if (context.state.products.filter(product => !product.deleted).length === 1) {
                context.dispatch('resetState');
                return "goHome"
            } else {
                context.commit('deleteProduct', context.state.products.indexOf(context.state.products.filter(product => !product.deleted)[key]));
                if (key === context.state.currentProductKey) {
                    //return context.dispatch('loadProduct', context.state.products.filter(product => !product.deleted).findIndex(product => !product.deleted));
                }
            }
            context.commit('incRefreshInc');
            return context.dispatch('calculatePrice');
        },
        loadProduct(context, key) {
            context.commit('setStateToProduct', key);
            router.push('/constructor/' + context.state.products[key].productType + '/etape1');
            if (context.state.productType !== context.state.products[key].productType) {
                context.commit('setProductType', context.state.products[key].productType);
                context.dispatch('loadQuestions')
            }

            const steps = context.state.steps
            try {
                Object.keys(context.state.answers).forEach((stepIndex) => {
                    const stepAnswers = context.state.answers[stepIndex];
                    Object.keys(stepAnswers).forEach((questionIndex) => {
                        Object.keys(stepAnswers[questionIndex]).forEach((idx) => {
                            const answer = stepAnswers[questionIndex][idx];
                            if (answer && answer.index !== undefined &&
                                steps[stepIndex].questions[questionIndex] !== undefined &&
                                steps[stepIndex].questions[questionIndex].answers[answer.index] !== undefined) {
                                const questionAnswer = steps[stepIndex].questions[questionIndex].answers[answer.index];
                                if (steps[stepIndex].questions[questionIndex].openViewer !== undefined) {
                                    context.commit('setViewerHtml', questionAnswer.html);
                                    context.commit('setIsViewerOpen')
                                }
                            }
                        });
                    });
                });
            } catch (e) {
            }
            context.commit('incRefreshInc');
            context.dispatch('calculatePrice');
        },
        saveProductPreviewNode: (context, previewNode) => {
            context.commit('setProductPreviewNode', previewNode)
        },
        newProduct: (context) => {
            context.commit('setIsViewerOpen', true)
            //context.commit('setGlobalAnswers', [])

            //context.commit('setCurrentProductKey', context.state.products.length + 1)
        },
        resetStateWithProduct: (context, preserveCurrentProduct) => {
            if (!preserveCurrentProduct) {
                delete context.state.products[context.state.currentProductKey]
            }
            window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({
                answers: {},
                validSteps: [],
                products: context.state.products,
                price: context.state.price,
                currentProjectId: context.state.currentProjectId,
                currentProjectName: context.state.currentProjectName,
                isViewerOpen: context.state.isViewerOpen,
                viewerHtml: context.state.viewerHtml,
                siteInfos: context.state.siteInfos,
                families: context.state.families,
                familyGroups: context.state.familyGroups,
                countries: context.state.countries,
                departement: context.state.departement,
                country: context.state.country,
                olderThan2: context.state.olderThan2,
                tauxTvas: context.state.tauxTvas,
                priceByProds: context.state.priceByProds,
                livraisonPercent: context.state.livraisonPercent,
                hitRecap: context.state.hitRecap,
                hitOtherProd: context.state.hitOtherProd,
                globalAnswers: context.state.globalAnswers,
                lockBackCap: context.state.globalAnswers,
                lang: context.state.lang,
                userInfos: context.state.userInfos,
                lang: context.state.lang,
                languages: context.state.languages
            }));
            context.commit('setStateProduct');
            context.commit('setCurrentProductKey', context.state.products.length);
            context.commit('setLockBackCap', false)
        },
        resetState: (context) => {
            window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({
                answers: {},
                validSteps: [],
                products: [],
                price: 0,
                currentProjectId: 0,
                currentProjectName: "",
                isViewerOpen: false,
                viewerHtml: null,
                siteInfos: context.state.siteInfos,
                families: context.state.families,
                familyGroups: context.state.familyGroups,
                countries: context.state.countries,
                departement: '',
                country: '',
                olderThan2: null,
                tauxTvas: {},
                priceByProds: {},
                livraisonPercent: 0,
                hitRecap: false,
                hitOtherProd: false,
                globalAnswers: [],
                lockBackCap: false,
                lang : context.state.lang,
                userInfos : context.state.userInfos,
                lang: context.state.lang,
                languages: context.state.languages
            }));
            context.commit('setState');
            //TODO::Dirty but works, fix me later
            context.commit('setCurrentProductKey', 0);
        },
        setProjectName(context, name) {
            context.commit('setCurrentProjectName', name)
        },

        getProject: (context, project_id) => {

            axios.get(context.state.siteInfos.UrlBoutique + '/?getproject=' + project_id).then(async data => {
                if (data.data.status === 200) {
                    if (data.data.project_state.currentProjectId === 0) {
                        data.data.project_state.currentProjectId = project_id
                    }

                    context.commit('setState', data.data.project_state);
                    // console.log(data.data.project_state);
                    await context.dispatch('closeLoader');
                    return router.push('/constructor/' + context.state.productType + '/etape1')
                }
                return router.push('/')
            });
        },


        exportPlayload(context, args) {
            context.dispatch('toggleLoader');
            let order = args.order;
            let transmit = args.transmit;

            let pricesCompiled = args.pricesCompiled;

            let products = context.state.products
                .filter(product => !product.deleted)
                .map((product, index, arr) => {
                    let tauxTva = product.tvaPercent;
                    if (tauxTva === 0) {
                        let tvaKey = product.productType;
                        if (context.state.tauxTvas && context.state.tauxTvas[tvaKey]) {
                            let tvaForProd = context.state.tauxTvas[tvaKey];
                            tauxTva = parseFloat(tvaForProd.Taux);
                        }
                    }
                    return {
                        ...product,
                        price: product.price,
                        tvaPercent: tauxTva
                    }
                });
            const project = {
                "name": context.state.currentProjectName,
                "project_id": context.state.currentProjectId,
                "products": products
            };


            let form = document.createElement("form");
            form.method = "POST";
            form.action = process.env.VUE_APP_WP_PATH + "/?pommier_action=update";
            form.target = "_blank";
            form.enctype = "multipart/form-data"

            let refs = document.createElement('input');
            refs.name = "refs";
            refs.type = "hidden";
            refs.value = JSON.stringify(context.state.refs);
            form.appendChild(refs);

            let projectInput = document.createElement('input');
            projectInput.name = "project";
            projectInput.type = "hidden";
            projectInput.value = JSON.stringify(project);
            form.appendChild(projectInput);

            let pdfInput = document.createElement('input');
            pdfInput.name = 'pdf';
            pdfInput.type = 'hidden';
            pdfInput.value = args.pdf;
            form.appendChild(pdfInput);


            let state = document.createElement('input');
            state.name = "state";
            state.type = "hidden";
            let cloneState = _.cloneDeep(context.state);

            cloneState.errors = [];
            cloneState.steps = [];
            cloneState.viewerHtml = "";
            cloneState.siteInfos = {};
            cloneState.families = {};
            cloneState.countries = {};
            cloneState.family = {};

            state.value = JSON.stringify(cloneState);
            form.appendChild(state);
            let action = document.createElement('input');
            action.name = "action";
            action.type = "hidden";
            action.value = "update_project";
            form.appendChild(action);

            let formuleInput = document.createElement('input');
            formuleInput.name = "formule";
            formuleInput.type = "hidden";
            formuleInput.value = JSON.stringify(context.state.formule);
            form.appendChild(formuleInput);

            let globals = context.state.globalAnswers
                .filter(item => item.variable !== "other_prod")
                .map(item => {
                    return {
                        label: item.name,
                        value: item.label.replace(/(&nbsp;|<([^>]+)>)/ig, "")
                    }
                });

            globals.unshift({
                label: ("Age de votre habitation"),
                value: context.state.olderThan2 ? ("Plus de 2 ans") : ("Moins de deux ans")
            });

            globals.unshift({
                label: ("Pays"),
                value: context.state.country
            });

            if (context.state.departement !== "") {
                globals.unshift({
                    label: ("Département"),
                    value: context.state.departement
                });
            }

            let modalites = document.createElement('input');
            modalites.name = "modalites";
            modalites.type = "hidden";
            modalites.value = JSON.stringify(globals);
            form.appendChild(modalites);

            if (order) {
                let orderInput = document.createElement('input');
                orderInput.value = "order";
                orderInput.name = "order";
                orderInput.type = "hidden";
                form.appendChild(orderInput);
            }

            if (transmit) {
                let transmitInput = document.createElement('input');
                transmitInput.value = "transmit";
                transmitInput.name = "transmit";
                transmitInput.type = "hidden";
                form.appendChild(transmitInput);
            }

            let project_language = document.createElement('input');
            project_language.value = context.state.lang;
            project_language.name = "project_language";
            project_language.type = "hidden";
            form.appendChild(project_language);

            let userInfos = document.createElement('input');

            userInfos.value = JSON.stringify(context.state.userInfos);
            userInfos.name = "userInfos";
            userInfos.type = "hidden";
            form.appendChild(userInfos);

            document.body.appendChild(form);

            if (context.state.currentProjectName !== null && context.state.currentProjectName !== "") {
                window.onbeforeunload = () => {
                };

                // let formData = new FormData(form)
                // formData.append('pdf', args.pdf)
                // console.log(formData)
                // console.log(`${process.env.VUE_APP_WP_PATH}/?pommier_action=update`);
                // axios.post(`${process.env.VUE_APP_WP_PATH}/?pommier_action=update`, formData)
                //     .then(response => {
                //         console.log(response)
                //     });
                form.submit();
            }
            context.dispatch('toggleLoader');

        },
    },
    getters: {
    }
});

store.subscribe((mutation, state) => {
    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({
        answers: state.answers,
        validSteps: state.validSteps,
        products: state.products,
        price: state.price,
        currentProjectId: state.currentProjectId,
        currentProjectName: state.currentProjectName,
        isViewerOpen: state.isViewerOpen,
        viewerHtml: state.viewerHtml
    }));
});

export default store;
