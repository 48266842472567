import answerIsSelected from "./answerIsSelected";

export default (step, answers) => {
    if (!step || !step.requires) {
        return true;
    }
    let met = true;
    Object.keys(step.requires).forEach((variable) => {
        const value = step.requires[variable];
        met = met && answerIsSelected(variable, value, answers);
    });
    return met;
};