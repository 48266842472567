import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueHtml2Canvas from 'vue-html2canvas'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueI18n from 'vue-i18n'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import i18n, {loadLocaleMessages} from "@/i18n";
import axios from "axios";

require("babel-polyfill");



(async  () => {
    // const pommierUrl = "http://pommier.test.hebergement-gm.fr/api/customer/get/info?test=1";                  // DEV test
    // const pommierUrl = "http://pommier.test.hebergement-gm.fr/api/customer/get/info";                         // DEV réel
    // const pommierUrl = "http://pommier:9F9Qq@n3@pommier.test.hebergement-gm.fr/api/customer/get/info?test=1"; // DEV passer outre .htaccess
    // const pommierUrl = "https://www.pommier.eu/api/customer/get/info?test=1";                                 // PROD test
	await axios.get("https://www.pommier.eu/api/customer/get/info", {withCredentials: true}).then(response => (store.dispatch('changeUserInfos',response.data))).catch(error => console.log(error))

    await loadLocaleMessages()

    Vue.use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyBjHG4DbGiZTe_zqEedCxDdQNI2FKrRRNk',
            libraries: 'places,drawing,visualization',
        },
    })
    Vue.config.productionTip = false

    // Require in a base component context
    const requireComponent = require.context(
        './components/viewers', true, /[\w-]+\.vue$/
    );

    requireComponent.keys().forEach(fileName => {
        const componentConfig = requireComponent(fileName);
        const componentName = upperFirst(
            camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, ''))
        );
        Vue.component(componentName, componentConfig.default || componentConfig)
    });

    Vue.use(VueHtml2Canvas);

    new Vue({
        i18n,
        router,
        store,
        render: h => h(App)
    }).$mount('#app')
})();
