export default (variable, answers) => {
    let found = false;
    Object.keys(answers).forEach((stepKey) => {
        const step = answers[stepKey];
        Object.keys(step).forEach((questionKey) => {
            const question = step[questionKey];

            Object.keys(question).forEach((answerKey) => {
                found = found || (question[answerKey].variable === variable);
            });
        });
    });
    return found;
};