<template>
    <div class="constructor">
        <div class="constructor-header">
            <ConstructorNav></ConstructorNav>
        </div>
        <div class="constructor-main">
            <div class="main-form-container" :class="{'viewer-open' : isViewerOpen}">
                <MainForm></MainForm>
            </div>

            <div v-if="isViewerOpen" class="sidebar-container" :class="{'recapResponsive' : isRecap}">
                <SideBar></SideBar>
            </div>
        </div>
    </div>
</template>

<script>

    import ConstructorNav from '@/components/ConstructorNav.vue'
    import MainForm from '@/components/MainForm.vue'
    import SideBar from '@/components/SideBar.vue'
    import Loader from "../components/Loader";

    export default {
        name: 'constructor',

        components: {
            Loader,
            'ConstructorNav': ConstructorNav,
            'MainForm': MainForm,
            'SideBar': SideBar,
        },
        computed: {
            answers() {
                return this.$store.state.answers;
            },
            isViewerOpen(){
                return this.$store.state.isViewerOpen
            },
            isRecap() {
                return parseInt(this.$route.params.step) === Object.keys(this.$store.state.steps).length
            }
        },

        watch: {
            '$route' (to, from) {
                this.$store.dispatch('currentStep', parseInt(to.params.step));
            }
        },

        created() {
            this.$store.dispatch('loadQuestions');
        }
    }
</script>

<style>

    @font-face {
        font-family: 'Avenir';
        src: url('../../public/fonts/Avenir.otf') format('truetype')
    }

    .constructor {
        height: 100%;

      width: 100%;
        /*margin-left: 2.5%;*/
        background: rgba(255, 237, 203, 0.05);
        /*overflow: hidden;*/
        position:relative;
    }

    .constructor-header {
        width: 100%;
        background: #fff;
        margin-bottom: 2%;
    }

    .constructor-main {
        height: 90%;
        width:95%;
        margin-left: 2.5%;
        display: flex;
    }

    .main-form-container {
        width: 100%;
        transition: 0.4s all;
    }

    .sidebar-container {


        flex-grow: 1;
        width: 0%;
        z-index: 1;
        padding-bottom: 25px;
    }

    .constructor-footer {
        height: 5%;
        width: 100%;
    }

    .viewer-open{
        width: 70%!important;
    }

    @media screen and (max-width: 767px) {
        .sidebar-container {
            width: 100% !important;
            height: calc( 100% - 130px ) !important;
        }
    }
    @media screen and (max-width: 1023px) {
        .recapResponsive {
            right:0 !important;
            width:100%;
            height: calc( 100% - 155px );
            overflow-y: scroll;
        }
        .sidebar-container {
            position: fixed;
            /* position: absolute; */
            bottom: 0;
            right: -100%;
            z-index: 60;
            width: 100%;
            height: calc( 100% - 150px );
            background: white;
            transition: all 0.5s;
        }

        .recapResponsive.sidebar-container {
            height: calc( 100% - 230px );
        }

        .viewer-open {
            width: 100% !important;
        }

        .constructor-header {
            height: auto;
        }
    }
</style>

