<template>
    <ul>
       
        <router-link
                v-for="(step, stepKey, stepIndex) in steps"
                :key="step.id"
                :to="path(step.id)"
                :style="cssStyle(stepIndex,Object.keys(steps).length)"
                :class="cssClass(stepKey,Object.keys(steps).length)"
                class="constructorNavLinks"
                @click="$store.commit('setReady', {ready: true, from: 'nav'})"
        >
            <span>{{ $t(step.label.trim()) }}</span>
        </router-link>
    </ul>
</template>

<script>
    import stepDependenciesAreMet from "../lib/stepDependenciesAreMet";

    export default {
        name: 'ConstructorNav',

        computed: {
            answers() {
                return this.$store.state.answers;
            },

            steps() {
                return this.$store.state.steps;
            },

            currentStep() {
                return this.$route.params.step;
            },
            isBackCapLocked(){
                return this.$store.state.lockBackCap
            },
            isRecap(){
                return parseInt(this.$route.params.step) === Object.keys(this.$store.state.steps).length
            }
        },

        methods: {
            cssClass(stepKey,count) {
                stepKey = parseInt(stepKey)
                return {
                    'done': stepKey < this.currentStep,
                    'todo': stepKey > this.currentStep,
                    'selected': stepKey === parseInt(this.currentStep),
                    'prev': stepKey === parseInt(this.currentStep) - 1,
                    'prev-2': stepKey === parseInt(this.currentStep) - 2,
                    'come': stepKey === parseInt(this.currentStep) + 1,
                    'come-2': stepKey === parseInt(this.currentStep) + 2,
                    'hidden': !stepDependenciesAreMet(this.steps[stepKey], this.answers),
                    'locked' : this.isBackCapLocked || this.isRecap,
                    'fix-step': stepKey > (parseInt(count) - 2)
                };
            },

            cssStyle(stepIndex,count) {
                return {'z-index': count - stepIndex};
            },

            path(stepId) {
                return {path: 'etape' + stepId};
            },

            prevStep() {

                return parseInt(this.step) - this.isPrevStepHidden();

                // return parseInt(this.step) - (this.isPrevStepHidden() ? 2 : 1);
            },
            showPrevBtn() {

                return parseInt(this.step) - 1 !== 0 && !this.isBackCapLocked && !this.isRecap
            },
            isPrevStepHidden(count = 1, prevStep = parseInt(this.step) - 1) {
                // const prevStep = parseInt(this.step) - 1;

                if (prevStep <= 0) {
                    // return false;
                    return count;
                }

                let value = (stepDependenciesAreMet(this.steps[prevStep], this.answers)) ? count : this.isPrevStepHidden(count + 1, prevStep - 1);
                if (value) {
                    return value;
                }

                // return !stepDependenciesAreMet(this.steps[prevStep], this.answers);
            },
        }
    }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .locked,
    .locked *{
        pointer-events: none;
    }


    ul {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-grow: 1;
        height: 65px;
        margin: 0;
        padding: 0;
        list-style: none;
        border-bottom: solid 1px lightgray;
        overflow: hidden;
    }

    a {
        position: relative;
        width: 100%;
        text-decoration: none;
        padding: 35px 0 35px 40px;
        text-align: center;
        font-weight: 600;
        letter-spacing: 0.04rem;
    }

    a:first-child {
        padding: 35px 0 35px 0px;
    }

    a.todo {
        color: #fff;
        background: #c5c5c5;
        pointer-events: none;
    }

    a.done {
        background: #005ac2;
        color: #fff;
        z-index: 3;
    }
    .ecu a.done,.fac a.done{
        display: none;

    }
    .ecu a.done.prev,.fac a.done.prev{
        display: block;
    }

    a.selected {
        color: #fff;
        z-index: 3;
    }
    a.selected::after{
    }
    a.todo::after,
    a.selected::after,
    a.done::after {
        content: "";
        position: absolute;
        background: #fff;
        transform: translateY(-50%) rotateZ(45deg);
        top: 50%;
        right: -20px;
        height: 65px;
        width: 65px;
        border-top: 1px solid #fff;
        border-right: 1px solid #fff;
        transition: 0.4s all linear;
        z-index: -1;
    }

    a.todo::after {
        background: #c5c5c5;
        pointer-events: none;
    }

    a.done::after {
        background: #005ac2;

    }

    a.selected::after {
    }

    a.selected::after,
    a.done::after,
    a.done,
    a.selected,
    a.todo{
        transition: all 0.4s linear;
    }

    a.hidden {
        display: none!important;
    }

    a > span {
        z-index: 1000;
    }

    /*.fac a.fix-step{*/
        /*transition: all 0.4s linear;*/
    /*}*/
    /*.fac a {*/
        /*display: none;*/
    /*}*/

    /*.fac a.selected, .ecu a.prev, .ecu a.come,.ecu a.fix-step {*/
        /*display: block;*/
    /*}*/

    /*.fac a.todo::after,*/
    /*.fac a.selected::after,*/
    /*.fac a.done::after {*/
        /*top: 50%;*/
        /*right: -25px;*/
        /*height: 70px;*/
        /*width: 70px;*/
    /*}*/

    /*.fac a {*/
        /*padding: 35px 20px 35px 50px*/
    /*}*/

    /*.fac ul {*/
        /*height: 60px;*/
    /*}*/
    @media screen and (min-width: 1023px) and (max-width: 1220px){
        .constructor-header a > span {
            font-size: 11px;
        }
    }

    @media screen and (max-width: 1023px) {
        a {
            display: none;
        }

        a.selected, a.prev, a.come, a.prev-2, a.come-2 {
            display: block;
        }

        a.todo::after,
        a.selected::after,
        a.done::after {
            top: 50%;
            right: -25px;
            height: 70px;
            width: 70px;
        }

        a {
            padding: 35px 20px 35px 50px
        }

        ul {
            height: 60px;
        }
    }

    @media screen and (max-width: 767px) {
        a.prev-2, a.come-2 {
            display: none;
        }

        a {
            padding: 15px 10px 15px 30px;
        }

        a.todo::after,
        a.selected::after,
        a.done::after {
            top: 50%;
            right: -10px;
            height: 70px;
            width: 70px;
        }

        ul {
            height: 50px;
        }
    }
    @media screen and (max-width: 520px) {
        a.constructorNavLinks {
            font-size: 0.7rem;
        }
        a {
            padding: 20px 10px 20px 30px;
        }
    }

</style>