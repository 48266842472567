<template>
	<div class="card" v-bind:class="[question.type]">
		<!-- <div 
			class="card-header" :id="'header-' + step + 'Question' + question.id"
			data-toggle="collapse"
			:data-target="'#collapseStep' + step + 'Question' + question.id"
			v-bind:aria-expanded="question.openByDefault ? 'true' : 'false'"
			aria-controls="collapseOne"
			:class="{ 'answered': showSpoiler !== false }"
		>
			<h5 class="mb-0">
				<button class="btn btn-link">
					{{ $t(question.label) }}
				</button>
				<div class="recap" v-show="showSpoiler != false">
					<span>&#10004;</span>
				</div>
			</h5>
		</div> -->
		<div 
			:id="'collapseStep' + step + 'Question' + question.id" 
			class="collapse" 
			:class="{ show: question.openByDefault }"
			:aria-labelledby="'collapseStep' + step + 'Question' + question.id" 
			data-parent="#main-form"
		>
			<div class="card-body">
				<div v-show="$store.state.family.Prefix == 'wih'">
					<h4>{{ $t("Votre configuration :") }}</h4>
					<div v-for="question in validAnswers" :key="question.id">
						<strong>{{ $t(question[0]) }} : </strong>
						{{ $t(question[1]) }}
					</div>
				</div>
				<button 
					v-show="$store.state.family.Prefix === 'wih'" 
					@click.stop="addAnswerAndOpenNext(answerValue)"
					type="button" class="next-btn"
				>
					{{ $t("Valider") }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import common from './mixins/common'

export default {
	name: "FinalRecap",

	props: {
		question: Object,
		questions: Object,
		step: [String, Number]
	},

	mixins: [common],

	data: function () {
		let answerValue = '';

		let answers = this.$store.state.answers;

		Object.keys(this.questions[this.question.id].answers).forEach((answerKey) => {
			const answer = this.questions[this.question.id].answers[answerKey];

			if (answer.isAnswer) {
				answerValue = answer.value;
			}
		});

		return {
			showSpoiler: false,
			answerValue: answerValue,
			maxLength: 1000
		}
	},

	computed: {
		validAnswers() {
			let validAnswers = []
			for (let i = 1; i < 3; i++)
			{
				const step = this.$store.state.steps[i]
				for(const question in step.questions)
				{
					let StepAnswers = this.$store.state.answers[i]
					if (StepAnswers)
					{
						if (StepAnswers[question])
						{
							if (question == 6)
							{
								for(const subQuestion in step.questions[question].answers)
									if (StepAnswers[question][subQuestion])
										validAnswers.push([step.questions[question].answers[subQuestion].label, `${StepAnswers[question][subQuestion].value} mm`]);
							}
							else if (StepAnswers[question][0].label)
							{
								validAnswers.push([step.questions[question].label, StepAnswers[question][0].label]);
							}
						}
					}
				}
			}
			return validAnswers
		}
	},

	watch: {
		answerValue(val, oldVal) {
			if (val) {
				this.addToAnswers(val);
			}
		}
	},

	mounted() {
		this.maxLength = this.setMaxChar()
		if (this.answerValue !== 0 || this.answerValue !== "") {
			this.answerValue = null;
			this.showSpoiler = false;
			//this.addToAnswers(this.answerValue);
		}
	},

	methods: {
		setMaxChar() {
			const max = this.questions[this.question.id].maxCharacters
			if (max > 0) {
				return max
			} else {
				return 1000;
			}
		},
		addAnswerAndOpenNext(answerValue) {
			this.addToAnswers(answerValue)
			this.openNextQuestion(this.step, this.questions[this.question.id].id, this);
		},
		addToAnswers: function (answerValue) {

			const questionIndex = this.question.id;
			const question = this.questions[questionIndex];

			const answerIndex = 0;
			const answer = question.answers[answerIndex];

			answer.isAnswer = true;

			this.showSpoiler = answerValue;

			this.$store.dispatch('addAnswersThenCalc', {
				isGlobal: this.question.global !== undefined && this.question.global,

				stepIndex: this.step,
				questionIndex: questionIndex,
				questionType: question.type,
				effectOnSvg: question.effectOnSvg,
				index: answerIndex,
				label: answerValue,
				value: answerValue,
				variable: question.variable || null
			});
		},

		initialize() {
			Object.keys(this.questions[this.question.id].answers).forEach((answerKey) => {
				const answer = this.questions[this.question.id].answers[answerKey];

				if (answer.isAnswer) {
					this.showSpoiler = answer.value;
				}
			});
		},
	}
}
</script>

<style scoped>
@import "styles/text-field.css";

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

.card-body {
	flex-direction: column;
	align-items: center;
}

.next-btn {
	border: 1px solid #004494;
	background: #fff;
	color: #004494;
	border-radius: 10px;
	padding: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30%;
	font-size: 0.9rem;
	font-weight: 300;
}

.next-btn:focus {
	background: #004494;
	color: #fff;
}

textarea {
	max-width: 600px;
}

.checkbox-choice {
	width: 100%;
}
</style>