import answerIsSelected from "./answerIsSelected";

export default (question, answers) => {
    if (!question || !question.requires) {
        return true;
    }

    let met = true;

    Object.keys(question.requires).forEach((variable) => {
        const value = question.requires[variable];

        met = met && answerIsSelected(variable, value, answers);
    });

    return met;
};