<template>
    <div v-if="$store.state.family.Prefix !== 'Bae' 
    && $store.state.family.Prefix !== 'Cre'
    && $store.state.family.Prefix !== 'wih'" class="product-preview-container" id="product-preview" >
      <div class="picto-univers" >
        <img  :src="apiUrl+'/'+pictoUnivers" alt="Logo" class="">
      </div>
        <component :is="getTheSVG"></component>
    </div>
</template>


<script>
    import getAnswers from '@/lib/getAnswers';
    import aggregateAnswers from '@/lib/aggregateAnswers';
    import upperFirst from 'lodash/upperFirst'
    import camelCase from 'lodash/camelCase'


    export default {

        name: 'ProductPreview',
        components: {
        },
        computed: {
          apiUrl() {
            return this.$store.state.apiUrl;
          },
            getTheSVG() {
               return this.$store.state.productType
            },
            isRecap() {
                return parseInt(this.$route.params.step) === Object.keys(this.$store.state.steps).length
            },
            pictoUnivers(){
              return this.$store.state.family.picto
            }
        },
        methods: {
        },
        created() {
        }
    }
</script>


<style scoped>
.picto-univers img{
  width: 50px!important;
  float: right;
}
#product-preview .picto-univers{
  position: absolute;
  top: 10px;
  right: 10px;
  width: 100px;
}
    #product-preview{
      position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        max-height: 300px;
        /*margin-bottom: 5%;*/
        width: 100%;
        transition: all .5s;
        border:1px solid #004494;
        border-radius:0 50px 0 0;
        overflow: hidden;

    }

    .fac #product-preview,.ecu #product-preview  {
        max-height: 383px;

    }

    #product-preview.animateDisplay{
        /* animation-duration: .5s;
        animation-name: displayNoneHeight; */
        transition: all .5s;
        height: 0%;
        overflow: hidden;
        margin-bottom: 0%;
    }


    /* @keyframes displayNoneHeight{
        0%{
            opacity: 1;
            height: 100%;
        }
        100%{
            opacity: 0;
            height: 0%;
        }
    } */

    @media screen and (max-width: 767px) {
        #product-preview{
            height: 50%;
        }
    }
</style>
