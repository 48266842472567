<template>
  <div class="presentation">
    <video class="player" autoplay loop controls>
      <source v-if="$i18n.locale === 'fr'" :src="videoUrl" />
      <source v-else :src="internationalVideoUrl" />
    </video>
  </div>
</template>

<script>
export default {
  name: "presentation",
  data() {
    return {
      videoUrl: process.env.VUE_APP_API_PATH + "/video/teaser_pommier.mp4",
      internationalVideoUrl: process.env.VUE_APP_API_PATH + "/video/teaser_pommier_en.mp4"
    };
  },
};
</script>
<style scoped>
.presentation {
  background: url("/img/home_background.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  padding: 30px 0;
}

.player {
  width: 60vw;
}

@media screen and (max-width: 500px) {
  .player {
    width: 80vw;
  }
}
</style>
