<template>
  <div class="history-container" id="history-parent">
    <div class="history-content-container">
      <div
        v-for="(product, key) of products.filter((prod) => !prod.deleted)"
        v-if="product.history.length > 0"
        class="single-wrapper"
      >
        <div
          data-toggle="collapse"
          :data-target="'#product-' + key"
          aria-expanded="false"
          aria-controls="collapseOne"
          class="history-content-header collapsed"
          :id="'header-product-' + key"
        >
          <div
            class="d-flex"
            v-bind:class="
              isDelai(product.history) ? 'delai-align' : 'align-items-center'
            "
            style="width: 100%"
          >
            <i class="fa fa-sort-up fa-1x"></i>
            <button class="btn btn-link" style="width: 100%">
              <span class="float-left"
                >{{ $t("Ma configuration") }}
              </span>
            </button>
          </div>
        </div>
        <div
          class="history-content-wrap collapse"
          data-parent="#history-parent"
          :id="'product-' + key"
        >
          <center><label >{{ $t(getTitle(product.history)) }}</label></center>
          <div
            v-for="item in product.history.filter((item) => !item.isGlobal)"
            class="history-content"
          >
              <label v-html="$t(item.label) + ' : '"></label>
              <span :class="{weightvalue : item.label.includes('Poids'),heightvalue:item.label.includes('Hauteur'),longvalue:item.label.includes('Longueur'),widthvalue:item.label.includes('Largeur') && !item.value.includes('m')}">{{
                $t(item.value)
              }}</span>
          </div>
        </div>
      </div>

<!--      test -->
      <div class="single-wrapper refs-container">
        <div
                data-toggle="collapse"
                data-target="#product-ref"
                aria-expanded="true"
                aria-controls="collapseOne"
                class="history-content-header collapsed"
                id="header-product-ref">

            <i class="fa fa-sort-up fa-1x"></i>
            <button class="btn btn-link" style="width: 100%">
              <span v-if="$store.state.family.Prefix === 'Bae'" class="float-left">
                {{ $t("Références à commander") }}
              </span>
              <span v-else class="float-left">{{ $t("Les références à commander") }}
              </span>
            </button>
          </div>
        <div class="history-content-wrap collapse show refs-container"
             data-parent="#history-parent "
             id="product-ref">
          <p v-if="refs.length===0" >{{ $t("Nomenclature en cours de création") }}</p>
          <div v-for="ref in refs" class="history-content ref-content">
            <img v-if="ref.ref!==null && ref.ref!=='' && $store.state.family.Prefix==='Bae'" :src="ref.imageUrl" alt="vignette">
            <label v-if="($store.state.family.Prefix==='Bae' && ref.label !== 'Option : ') || $store.state.family.Prefix==='wih'" >{{ $t(ref.label)}}</label>
            <label v-if="$store.state.family.Prefix==='Cre'" ><span v-if="ref.quant">{{ ref.quant }}x </span> {{ $t(ref.label)}}</label>
            <label v-if="$store.state.family.Prefix!=='Bae' && $store.state.family.Prefix!=='Cre' && $store.state.family.Prefix!=='wih'">{{ $t(ref.label)+' x '+ref.quantity+' : ' }}</label>
            <span style="font-weight: 600;">{{ ref.ref }}</span>
          </div>
        </div>
      </div>
      </div>
    <!-- Boutons / liens (sans redirection) -->
    <span v-if="isRecap" @click="toggleConfirm(false, false)" class="order-now"
      >{{ $t("Se connecter pour télécharger le PDF du projet") }}</span
    >
    <div class="popup-valid" :class="{ 'show-confirm': showConfirm }">
      <ol>
        <li>{{ $t("Donnez un nom à votre projet") }}</li>
        <li>{{ $t("Retrouvez tous vos projet dans votre espace client sur") }} <a :href="$t('http://www.pommier.eu')" >www.pommier.eu</a></li>
      </ol>
      <form>
        <label for="name"></label>
        <input
          type="text"
          name="name"
          id="name"
          v-model="projectName"
          :placeholder='$t("Nom de votre devis")'
          @keydown.enter="exportPlayload(order, transmit)"
        />
        <div class="button-list">
          <button class="btn" @click.prevent="toggleConfirm">{{ $t("Annuler") }}</button>
          <button class="btn main" @click.prevent="exportPlayload(order, transmit)">{{ $t("Confirmer") }}</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserHistory",
  data() {
    return {
      preventSave: null,
      showConfirm: false,
      projectName: this.$store.state.currentProjectName,
      order: false,
      transmit: false,
      formule: null,
      modalWasShownOnce: false,
    };
  },

  watch: {
    isRecap(isRecap) {
      if (isRecap) {
        $(".history-content-wrap").collapse("hide");

        if (!this.modalWasShownOnce) {
          setTimeout(() => {
            this.toggleConfirm(false, false);
            this.modalWasShownOnce = true;
          }, 2000);
        }
      }
    },
    projectName: function (newVal, oldVal) {
      this.$store.dispatch("setProjectName", newVal);
    },
    refs() {
      return this.$store.state.refs;
    },
  },

  computed: {
    isSAV() {
      return this.$store.state.family.Prefix === "sav";
    },
    isRecap() {
      return (
        parseInt(this.$route.params.step) ===
        Object.keys(this.$store.state.steps).length
      );
    },
    products() {
      return this.$store.state.products.filter((product) => !product.deleted);
    },
    refs() {
      return this.$store.state.refs;
    },
    currentProductKey() {
      return this.$store.state.currentProductKey;
    },
    apiUrl() {
      return this.$store.state.apiUrl;
    },
    wpUrl() {
      return this.$store.state.wpUrl;
    },
    siteInfos() {
      return this.$store.state.siteInfos;
    },
  },

  methods: {
    getRefs(){
      this.refs =  this.$store.state.refs;
    },
    getTitle(history) {
      let title = "";
      switch (this.$store.state.family["Prefix"]) {
        case "clo":
          title = history.find((el) => el.label === "Modele");
          return title.value;
          break;
        case "box":
          return this.$store.state.family["Nom"];
          break;
        case "fac":
          return this.$store.state.family["Nom"];
          break;
        default:
          title = history.find((el) => el.label === "Modele");
          return title ? title.value : this.$store.state.family["Nom"];
      }
    },
    getdelaiTitle(history) {
      let title = history.find((el) => el.label === "Modele");
      return title.delai ? title.delai : "";
    },
    isDelai(history) {
      let title = history.find((el) => el.label === "Modele");
      if (this.$store.state.family["Prefix"] === "par") {
        return title.delai !== undefined && title.delai !== null;
      }
      return false;
    },
    toggleConfirm(order, transmit) {
      this.showConfirm = !this.showConfirm;
      if (order !== undefined) {
        this.order = order;
      }
      if (transmit !== undefined) {
        this.transmit = transmit;
      }
    },
    exportPlayload(order, transmit) {
		localStorage.store = JSON.stringify(this.$store.state)
		localStorage.userHistory = JSON.stringify({
			order: order,
			transmit: transmit,
		})
		this.$router.push('/generate-pdf')
    },
    newProduct() {
      this.$store.dispatch("newProduct");
      this.$router.push("/preconstructor");
    },
    async newProductSav() {
      await this.$store.dispatch("resetStateWithProduct", true);
      await this.$store.dispatch("newProduct");
      await this.$store.dispatch("setProductType", "sav");

      await this.$store.dispatch("loadQuestions");

      this.$router.push("/constructor/sav/etape1");
    },
  },
  formatNumber(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  },
};
</script>

<style scoped>
.subtotal {
  background: #cecece;
}

.weightvalue::after{
  content:' kg';
}
.heightvalue::after{
  content:' mm'
}
.widthvalue::after{
  content:' mm'
}
.longvalue::after{
  content:' m'
}
label {
  margin: 0.5rem;
}
.delai {
  font-size: 12px;
  text-align: left;
  margin: 6px 0;
}

i {
  padding: 14px 0;
}

.delai-align i {
  height: fit-content;
  padding: 14px 0;
}

.btn-link span:first-child {
  text-align: left;
}

.block-ht {
  font-size: 14px;
}

.block-ht span {
  font-size: 15px;
}

div[aria-expanded="false"] .fa-sort-up {
  transform: rotate(90deg);
  transition: all 0.3s;
}

div[aria-expanded="true"] .fa-sort-up {
  transform: rotate(180deg);
  transition: all 0.3s;
}

.popup-valid.show-confirm {
  display: block;
}
.popup-valid ol{
  list-style-position: inside;
}




.popup-valid {
  display: none;
  width: 40%;
  margin: 50px auto;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.4);
  background-color: #ffffff;
  padding: 10px;
  text-align: center;
  font-family: sans-serif;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.popup-valid .title {
  font-size: 24px;
  margin-bottom: 20px;
}

.popup-valid form {
  width: 90%;
  margin: 0 auto;
}

.popup-valid form label {
  display: none;
}

.popup-valid form input {
  height: 35px;
  width: 100%;
  padding: 5px 10px;
  display: block;
  box-sizing: border-box;
}

.popup-valid .button-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.popup-valid .button-list .btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #d8d8d8;
  border-radius: 0;
}

.popup-valid .button-list .btn:hover {
  cursor: pointer;
}

.popup-valid .button-list .btn.main {
}

.popup-valid .button-list .btn:hover.main {
  background-color: #c4c4c4;
}

.popup-valid .button-list .btn:hover {
  background-color: #c4c4c4;
}

.bgwhite {
  background-color: #fff;
}

.link-recap {
  text-decoration: underline !important;
  font-weight: bold;
  cursor: pointer;
  color: #2d2d2d !important;
  font-size: 16px;
  text-transform: uppercase;
}

.order-now {
  background-color: #004494;
  color: #efefef;
  text-transform: uppercase;
  padding: 15px 10px;
  letter-spacing: 1px;
  font-weight: bold;
  border-top: 1px solid #fff;
  cursor: pointer;
  transition: 0.3s;
}

.link-recap-box,
.order-now {
  padding: 25px 10px;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.order-now,
.link-recap {
  font-size: 1.2rem;
}

.single-wrapper {
  /* margin-bottom: 2px; */
}

.single-wrapper button {
  color: #000;
}

.btn-link {
  cursor: pointer;
}

button.btn-link::before {
  content: "";
  width: 0 !important;
}

h5 {
  font-size: 15px;
  color: black;
  font-weight: bold;
  cursor: pointer;
}

.controls {
  width: 20%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.controls i {
  cursor: pointer;
  font-size: 1.2em;
}

.controls i:hover {
}

.history-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-top: 2px solid #fff;
  /* height: 40px; */
  padding: 2px 10px;
}

.history-container {
  /* transform: translateY(-40%); */
  position: relative;
  display: flex;
  flex-flow: nowrap column;
    max-height: 60%;
  overflow-y:auto;
}
.single-wrapper{  background: #f5f4f4;
}
.refs-container{
  background: #dedede;
}
.ref-content{
  display: flex;
  align-items: center;
  position: relative;
  gap: 15px;
  margin: 5px 0;
}
.ref-content img {
  height: 40px;
}
.ref-content span{
  font-weight:200!important;
}


@media screen and (min-width: 1023px) and (max-width: 1220px){
  .history-content-header .btn-link{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
}

p {
  margin: 0;
}

.history-content-container {
  width: 100%;
  text-align: left;
  /*overflow: auto;*/
  /* padding: 5px 15px; */
  display: flex;
  flex-direction: column;
  /*height: auto;*/
  max-height: 30%;
  margin-top: 2px;
}

.history-content-wrap {
  width: 100%;
  text-align: left;
  padding: 15px 15px 35px;
  overflow-y: scroll;
  /*border-top: 2px solid #efefef;*/
  height: 20vh;
}

.history-container label {
  display: inline-block;
  font-weight: bold;
  margin-right: 4px;
  margin-left: 0;
}

.circle i {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}
center label{
    text-transform: uppercase;
}

@media screen and (max-width: 1220px) {
  .order-now, .link-recap {
    font-size: 0.9rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1400px) {
  .popup-valid {
    width: 60%;
  }
}

@media screen and (max-width: 1023px) {
  .popup-valid {
    position: fixed;
    width: 80%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin: 0;
  }

  .history-content-wrap {
    width: 100%;
    text-align: left;
    padding: 15px 15px 10px;
    overflow-y: scroll;
    border-top: 2px solid #efefef;
    height: 22vh;
  }
}
</style>
