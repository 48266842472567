<template>
    <div class="card" :class="[question.type, {opened: display}]">
        <div :id="'collapseStep'+step+'Question'+question.id" class="collapse"
             :class="{show : true}"
             :aria-labelledby="'collapseStep'+step+'Question'+question.id" data-parent="#main-form">
            <div class="card-body">
                <div class="checkbox-choice">
                    <div v-if="question.extraHtml !== undefined && question.extraHtml.content_top !== null !== undefined && question.extraHtml.content_top !== null"
                         v-html="$t(question.extraHtml.content_top)"></div>
                    <label :class="{ full: answerValue }">
                        <textarea
                                v-model="answerValue" 
                                :placeholder="$t(question.label)"
                                :maxlength="maxLength"></textarea>
                    </label>
                    <div v-if="question.extraHtml !== undefined && question.extraHtml.content_bottom !== null !== undefined && question.extraHtml.content_bottom !== null"
                         v-html="$t(question.extraHtml.content_bottom)"></div>

                </div>

                <button v-show="$store.state.family.Prefix === 'wih'"
                    @click.stop="addAnswerAndOpenNext(answerValue)" type="button" class="next-btn">
                    {{$t("Suivant")}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import common from './mixins/common'

    export default {
        name: "TextAreaField",

        props: {
            question: Object,
            questions: Object,
            step: [String, Number],
            opened: Boolean
        },

        mixins: [common],

        data: function () {
            let answerValue = '';

            let answers = this.$store.state.answers;

            Object.keys(this.questions[this.question.id].answers).forEach((answerKey) => {
                const answer = this.questions[this.question.id].answers[answerKey];

                if (answer.isAnswer) {
                    answerValue = answer.value;
                }
            });

            return {
                showSpoiler: false,
                answerValue: answerValue,
                maxLength: 1000,
                display: null
            }
        },

        watch: {
            answerValue(val, oldVal) {
                if (val) {
                    //this.addToAnswers(val);
                }
            }
        },
        mounted(){

            setTimeout(() => {
              this.display = this.opened
            }, 500)

          this.maxLength = this.setMaxChar()
          if (this.answerValue !== 0 || this.answerValue !== ""){
              this.answerValue = null;
              this.showSpoiler = false;
              //this.addToAnswers(this.answerValue);
          }
        },

        methods: {
            setMaxChar() {
                const max = this.questions[this.question.id].maxCharacters
                 if(max > 0){
                    return max
                 } else {
                    return 1000;
                 }
            },
            addAnswerAndOpenNext(answerValue) {
				if (this.question.mandatory && !answerValue)
					return 
				this.addToAnswers(answerValue)
				//this.openNextQuestion(this.step, this.questions[this.question.id].id, this);
            },
            addToAnswers: function (answerValue) {

                const questionIndex = this.question.id;
                const question = this.questions[questionIndex];

                const answerIndex = 0;
                const answer = question.answers[answerIndex];

                answer.isAnswer = true;

                this.showSpoiler = answerValue;

                this.$store.dispatch('addAnswersThenCalc', {
                    isGlobal : this.question.global !== undefined && this.question.global,

                    stepIndex: this.step,
                    questionIndex: questionIndex,
                    questionType: question.type,
                    effectOnSvg: question.effectOnSvg,
                    index: answerIndex,
                    label: answerValue,
                    value: answerValue,
                    variable: question.variable || null
                });

                
            },

            initialize() {
                Object.keys(this.questions[this.question.id].answers).forEach((answerKey) => {
                    const answer = this.questions[this.question.id].answers[answerKey];

                    if (answer.isAnswer) {
                        this.showSpoiler = answer.value;
                    }
                });
            },
        }
    }
</script>

<style scoped>
    @import "styles/text-field.css";

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    .card-body {
        flex-direction: column;
        align-items: center;
    }

    .card {
      max-height: 0;
      transition: max-height 0.2s;
      overflow: hidden;
    }

    .card.opened {
      max-height: 800px;
      transition: max-height 0.2s;
    }

    .next-btn {
        border: 1px solid #004494;
        background: #fff;
        color: #004494;
        border-radius: 10px;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30%;
        font-size: 0.9rem;
        font-weight: 300;
    }

    .next-btn:focus {
        background: #004494;
        color: #fff;
    }

    textarea {
        max-width: 600px;
    }
    .checkbox-choice {
        width: 100%;
    }
</style>