<template>
    <nav>
        <div class="header">

            <div class="left-content">
                <a @click="redirectToHome()" title="Accueil" class="logo">
                    <img
                        id="logo-pommier"
                        v-if="siteInfos.ImageLogo !== undefined"
                        :src="apiUrl+'/'+siteInfos.ImageLogo"
                        alt="Logo"
                    >
                </a>

                <div class="pictos" v-if="isHome || isPreconstructor">
                    <img src="img/all-picto-univers.png"/>
                </div>
                <div class="logo-nemo" v-if="isHome || isPreconstructor">
                    <img src="img/LOGO-NEMO.png" alt="Logo">
                </div>
            </div>

            <div class="logo-center" v-if="isHome || isPreconstructor">
                <img src="img/LOGO-NEMO.png" alt="Logo">
            </div>

            <div class="lang-container">
                <a
                    href="javascript:void(0)"
                    @click="changeLang(language.name) "
                    v-for="language in languages"
                    :key="language.id"
                    :class="['lang', {active: $i18n.locale === language.name}]"
                >
                    {{ language.label }}
                </a>
            </div>

            <div class="famille-name" v-if="title">{{ $t("Configurateur") }} {{ $t(title) }}</div>

            <div class="header-buttons-container">
                <div v-if="$i18n.locale === 'en'" class="header-backtosite-container">
                    <BackToSiteButton link="https://www.pommier.eu/en/customer" />
                    <ContactButton link="https://www.pommier.eu/en/contact-us" />
                </div>
                <div v-else-if="$i18n.locale === 'pl'" class="header-backtosite-container">
                    <BackToSiteButton link="https://www.pommier.eu/pl/customer" />
                    <ContactButton link="https://www.pommier.eu/pl/kontakt" />
                </div>
                <div v-else-if="$i18n.locale === 'it'" class="header-backtosite-container">
                    <BackToSiteButton link="https://www.pommier.eu/it/customer" />
                    <ContactButton link="https://www.pommier.eu/it/come-contattarci" />
                </div>
                <div v-else-if="$i18n.locale === 'es'" class="header-backtosite-container">
                    <BackToSiteButton link="https://www.pommier.eu/es/customer" />
                    <ContactButton link="https://www.pommier.eu/es/contactenos" />
                </div>
                <div v-else-if="$i18n.locale === 'de'" class="header-backtosite-container">
                    <BackToSiteButton link="https://www.pommier.eu/de/customer" />
                    <ContactButton link="https://www.pommier.eu/de/kontakt" />
                </div>
                <div v-else class="header-backtosite-container">
                  <BackToSiteButton link="https://www.pommier.eu/customer" />
                  <ContactButton link="https://www.pommier.eu/contactez-nous" />
                </div>

                <div class="header-myproject-container">
                    <div class="btn btn_type1 " v-if="shouldDisplayCartBtn" @click="toggleCartConfirm()">
                        <span>{{ $t("Mes projets") }}</span>
                    </div>
                    <div class="btn btn_type1 " v-if="shouldDisplayCartBtnPreConf" @click="redirectToRecap()">
                        <span>{{ $t("Mes projets") }}</span>
                    </div>
                </div>
            </div>

        </div>
        <div class="popup-valid confirm-popup" :class="{'show-dep-modal' : cartModalOpen}">
            <h4 class="beforeStarting">{{ $t("Attention") }}</h4>
            <p>{{ $t("Si vous revenez à vos produits, la configuration en cours sera abandonnée") }}</p>
            <div class="modal-footer">
                <button class="btn" @click="toggleCartConfirm()">{{ $t("Annuler") }}</button>
                <button class="btn main" @click="deleteCurrentProdAndRedirect">{{ $t("Confirmer") }}</button>
            </div>
        </div>
    </nav>
</template>

<script>
import ContactButton from '@/components/ContactButton.vue'
import BackToSiteButton from '@/components/BackToSiteButton.vue'
import _ from 'lodash'

    export default {
        name: 'MainNav',
        props:['title'],
        components: {
            ContactButton,
            BackToSiteButton
        },
        data() {
            return {
                cartModalOpen: false
            }
        },
        computed: {
            links () {
                return this.$store.state.families
            },
            detailImg () {
                return this.$store.state.detail_img
            },

            detailTitle () {
                return this.$store.state.detail_title
            },

            isViewerOpen () {
                return this.$store.state.isViewerOpen
            },
            siteInfos () {
                return this.$store.state.siteInfos;
            },
            apiUrl () {
                return this.$store.state.apiUrl
            },
            isHome () {
                return this.$route.name === "home";
            },
            isPreconstructor() {
                return this.$route.name === "preconstructor";
            },
            isRecap () {
                return parseInt(this.$route.params.step) === Object.keys(this.$store.state.steps).length
            },
            shouldDisplayCartBtn () {
                return (this.$store.state.products.filter(prod => !prod.deleted).length > 1 && (this.$store.state.hitOtherProd || this.$store.state.hitRecap) && !this.isRecap) && this.$route.name !== "preconstructor"
            },

            shouldDisplayCartBtnPreConf () {
                return  (this.$store.state.products.filter(prod => !prod.deleted).length > 0 && this.$route.name === "preconstructor")
            },
            languages () {
                return _.orderBy(this.$store.state.languages, 'ordre')
            },
            innerWidth () {
                return window.innerWidth
            }
        },
        watch: {},
        mounted() {
            this.$forceUpdate();
            if (this.$i18n.locale !== this.$store.state.lang) {
                this.changeLang(this.$store.state.lang)
            }
        },
        methods: {
            changeLang(lang){
                this.$i18n.locale = lang;
                this.$store.dispatch("changeLang", lang );
            },
            fillAndOpenDefaultModal() {
                let modal = $('#basicModal');
                modal.find('h5').text("");
                modal.find('img').attr('src', this.detailImg);
                modal.find('.extra-popup').html('')
                modal.modal('show');

            },
            redirectToHome() {
                if (confirm("Attention : en revenant sur la page d'accueil, vous perdrez tous les produits dans votre panier")) {
                    this.$store.commit('setFamily', {});
                    this.$router.push('/').catch(console.log);
                }
            },

            toggleCartConfirm() {
                this.cartModalOpen = !this.cartModalOpen

            },
            deleteCurrentProdAndRedirect() {
                this.$store.dispatch('deleteCurrentProdAndRedirect').then(() => {
                    this.toggleCartConfirm();
                    this.$forceUpdate();
                })
            },
            redirectToRecap(){
                this.$store.dispatch('redirectToRecap')
            }
        }
    }
</script>

<style lang="scss">

    .header {
        height: 130px;
        background:  white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding: 0 100px 0 100px;

        .famille-name {
            font-size: 22px;
        }

        .left-content {
            display: flex;
            align-items: center;
            gap: 20px;

            .logo {
                img {
                    height: 110px;
                }
            }

            .pictos {
                img {
                    height: 40px;
                }
            }

            .logo-nemo {
                display: none;
                img {
                    height: 60px;
                }
            }
        }

        .logo-center {
            position: absolute;
            transform: translate(-50%, -50%);
            left: 50vw;
            top: 70px;
            height: 130px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                height: 60px;
            }
        }

        .lang-container {
            position: absolute;
            top: 0;
            left: 50vw;
            transform: translate(-50%, 0);
            display: flex;
            gap: 5px;
            background: #003B85;
            padding: 0 5px 0 5px;
            border-radius: 0 0 5px 5px;
            font-weight: 100;

            .lang {
                color: gray;
                transition: 0.1s;

                &:hover {
                    color: white;
                    text-decoration: none!important;
                    transition: 0.1s;
                }

                &.active {
                    color: white;
                }
            }
        }

        .header-buttons-container {

            display: flex;
            justify-content: center;
            align-items: center;

            .header-backtosite-container {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 5px;
            }
        }
    }

    .popup-valid.confirm-popup .modal-footer {
        display: flex;
        justify-content: space-around;
    }

    .popup-valid.confirm-popup .modal-footer {
        display: flex;
        justify-content: space-around;
        align-items: flex-end;
    }

    .back-btn, .back-btn:hover {
        margin-right: 30px;
    }

    .hide {
        display: none;
    }

    .recapRes {
        display: none;
    }

    .fade.show {
        opacity: 1;
        background: rgba(0, 0, 0, 0.6);
    }

    @media only screen and (max-width: 1530px) {
        .logo-center {
            display: none!important;
        }

        .logo-nemo {
            display: flex!important;
        }
    }

    @media only screen and (max-width: 1120px) {
        .header {
            padding: 20px 10px 10px 10px;
        }
    }

    @media only screen and (max-width: 788px) {
        .header {
            height: 180px;
            flex-direction: column;
            padding: 20px 10px 10px 10px;
            justify-content: space-between;

            .left-content {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 10px;

                .logo {
                    img {
                        height: 60px;
                    }
                }

                .pictos {
                    img {
                        height: 20px;
                    }
                }

                .logo-nemo {
                    img {
                        height: 40px;
                    }
                }
            }

            .header-buttons-container {
                display: flex;
                justify-content: center;
                align-items: center;

                .header-backtosite-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    gap: 5px;
                }
            }
        }
    }

    @media only screen and (max-width: 910px) {
        .header {
            flex-direction: column;
            height: auto;
            //justify-content: space-between;
            //padding: 10px 20px 10px 20px;
        }
    }
</style>
