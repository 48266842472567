<template>
    <div class="card" v-bind:class="[question.type]">
       <!--  <div class="card-header" :id="'header-'+step+'Question'+question.id" data-toggle="collapse"
             :data-target="'#collapseStep'+step+'Question'+question.id"
             v-bind:aria-expanded="question.openByDefault ? 'true' : 'false'"
             aria-controls="collapseOne"
             :class="{'answered' : showSpoiler!==false}"
        >
            <h5 class="mb-0">
                <button class="btn btn-link">{{ $t(question.extraHtml.label) }}</button>
                <div class="recap" v-show="showSpoiler != false">
                    <img :src="showSpoiler" alt="Image">
                </div>
            </h5>
        </div> -->
        <div :id="'collapseStep'+step+'Question'+question.id" class="collapse"
             v-bind:class="{show : question.openByDefault}"
             :aria-labelledby="'collapseStep'+step+'Question'+question.id" data-parent="#main-form">
            <div class="extrahtml"
                 v-if="question.extraHtml !== undefined && question.extraHtml.content_top !== undefined && question.extraHtml.content_top !== null"
                 v-html="$t(question.extraHtml.content_top)"></div>


            <div class="card-body clo-type">
                <div class="col-md-6 type_clo-left-col ">
                    <div class="type_clo-left-col_upper">
                        <div v-for="(answer, answerKey) in question.answers.filter(el => el.id === 3)"
                             class="checkbox-choice permanent"
                             v-bind:class="{ 'selected': isSelected(answer) } "
                             v-on:click.stop="addToAnswers(question.answers.indexOf(answer), answer, isSelected(answer), $event)"
                             :data-target="answer.triggerAlert !== undefined && answer.triggerAlert ?
                         '#question-alert-modal'+question.id : 'none'">
                            <div class="image-placeholder "
                                 :data-tooltip="answer.text_info_bulle !== '' && answer.text_info_bulle !== undefined ? answer.text_info_bulle : false"
                                 data-tooltip-position="top"
                                 :data-target="answer.triggerAlert !== undefined && answer.triggerAlert ?
                         '#question-alert-modal'+question.id : 'none'">

                                <img :style="{height : answer.height !== undefined ? answer.height + 'px' : 'none'}"
                                     :class="['img-'+step+'-'+question.id+'-'+question.answers.indexOf(answer),'img-'+step+'-'+question.id+'-'+question.variable ]"
                                     :src="answer.imageUrl" alt=""
                                     :data-target="answer.triggerAlert !== undefined && answer.triggerAlert ?
                         '#question-alert-modal'+question.id : 'none'">

                            </div>
                            <p v-html="$t(answer.label)"
                               :data-target="answer.triggerAlert !== undefined && answer.triggerAlert ?
                         '#question-alert-modal'+question.id : 'none'">{{ $t(answer.label) }}</p>
                            <p v-html="answer.subtitle" style="font-size:11px;color:#515151;padding:0;margin:0;"
                               :data-target="answer.triggerAlert !== undefined && answer.triggerAlert ?
                         '#question-alert-modal'+question.id : 'none'">{{answer.subtitle}}</p>
                            <button v-if="answer.Popup && answer.Legende  && answer.Popup !== null && answer.Legende !== null && answer.Popup !== undefined && answer.Legende !== undefined"
                                    @click.stop="fillAndOpenDefaultModal(answer)" type="button"
                                    class="open-modal btn-modal popup-info p-2"
                                    data-toggle="modal"
                                    :data-target="'#modal'+step+'-'+question.id+'-'+question.answers.indexOf(answer)">
                                {{answer.BtnLegende === undefined ? $t("en savoir plus") : answer.BtnLegende}}
                            </button>

                            <!-- Modal -->
                        </div>
                    </div>
                    <div class="type_clo-left-col_lower">
                        <div v-for="(answer, answerKey) in question.answers.filter(el => el.id === 4 || el.id === 2)"
                             class="checkbox-choice pvc_naturelle"
                             v-bind:class="{ 'selected': isSelected(answer) } "
                             v-on:click.stop="addToAnswers(question.answers.indexOf(answer), answer, isSelected(answer), $event)"
                             :data-target="answer.triggerAlert !== undefined && answer.triggerAlert ?
                         '#question-alert-modal'+question.id : 'none'">
                            <div class="image-placeholder "
                                 :data-tooltip="answer.text_info_bulle !== '' && answer.text_info_bulle !== undefined ? answer.text_info_bulle : false"
                                 data-tooltip-position="top"
                                 :data-target="answer.triggerAlert !== undefined && answer.triggerAlert ?
                         '#question-alert-modal'+question.id : 'none'">

                                <img :style="{height : answer.height !== undefined ? answer.height + 'px' : 'none'}"
                                     :class="['img-'+step+'-'+question.id+'-'+question.answers.indexOf(answer),'img-'+step+'-'+question.id+'-'+question.variable ]"
                                     :src="answer.imageUrl" alt=""
                                     :data-target="answer.triggerAlert !== undefined && answer.triggerAlert ?
                         '#question-alert-modal'+question.id : 'none'">

                            </div>
                            <p v-html="$t(answer.label)"
                               :data-target="answer.triggerAlert !== undefined && answer.triggerAlert ?
                         '#question-alert-modal'+question.id : 'none'">{{ $t(answer.label) }}</p>
                            <p v-html="answer.subtitle" style="font-size:11px;color:#515151;padding:0;margin:0;"
                               :data-target="answer.triggerAlert !== undefined && answer.triggerAlert ?
                         '#question-alert-modal'+question.id : 'none'">{{answer.subtitle}}</p>
                            <button v-if="answer.Popup && answer.Legende  && answer.Popup !== null && answer.Legende !== null && answer.Popup !== undefined && answer.Legende !== undefined"
                                    @click.stop="fillAndOpenDefaultModal(answer)" type="button"
                                    class="open-modal btn-modal popup-info p-2"
                                    data-toggle="modal"
                                    :data-target="'#modal'+step+'-'+question.id+'-'+question.answers.indexOf(answer)">
                                {{answer.BtnLegende === undefined ? $t("en savoir plus") : answer.BtnLegende}}
                            </button>

                            <!-- Modal -->
                        </div>
                    </div>
                </div>
                <div class="col-md-6 type_clo-right">
                    <div v-for="(answer, answerKey) in question.answers.filter(el => el.id === 1)"
                         class="checkbox-choice permanante"
                         v-bind:class="{ 'selected': isSelected(answer) } "
                         v-on:click.stop="addToAnswers(question.answers.indexOf(answer), answer, isSelected(answer), $event)"
                         :data-target="answer.triggerAlert !== undefined && answer.triggerAlert ?
                         '#question-alert-modal'+question.id : 'none'">
                        <div class="image-placeholder "
                             :data-tooltip="answer.text_info_bulle !== '' && answer.text_info_bulle !== undefined ? answer.text_info_bulle : false"
                             data-tooltip-position="top"
                             :data-target="answer.triggerAlert !== undefined && answer.triggerAlert ?
                         '#question-alert-modal'+question.id : 'none'">

                            <img :style="{height : answer.height !== undefined ? answer.height + 'px' : 'none'}"
                                 :class="['img-'+step+'-'+question.id+'-'+question.answers.indexOf(answer),'img-'+step+'-'+question.id+'-'+question.variable ]"
                                 :src="answer.imageUrl" alt=""
                                 :data-target="answer.triggerAlert !== undefined && answer.triggerAlert ?
                         '#question-alert-modal'+question.id : 'none'">

                        </div>
                        <p v-html="$t(answer.label)"
                           :data-target="answer.triggerAlert !== undefined && answer.triggerAlert ?
                         '#question-alert-modal'+question.id : 'none'">{{ $t(answer.label) }}</p>
                        <p v-html="answer.subtitle" style="font-size:11px;color:#515151;padding:0;margin:0;"
                           :data-target="answer.triggerAlert !== undefined && answer.triggerAlert ?
                         '#question-alert-modal'+question.id : 'none'">{{answer.subtitle}}</p>
                        <button v-if="answer.Popup && answer.Legende  && answer.Popup !== null && answer.Legende !== null && answer.Popup !== undefined && answer.Legende !== undefined"
                                @click.stop="fillAndOpenDefaultModal(answer)" type="button"
                                class="open-modal btn-modal popup-info p-2"
                                data-toggle="modal"
                                :data-target="'#modal'+step+'-'+question.id+'-'+question.answers.indexOf(answer)">
                            {{answer.BtnLegende === undefined ? $t("en savoir plus") : answer.BtnLegende}}
                        </button>

                        <!-- Modal -->
                    </div>
                </div>

            </div>

            <div class="contentBottom"
                 v-if=" question.extraHtml.content_bottom !== undefined && question.extraHtml.content_bottom !== null"
                 v-html="$t(question.extraHtml.content_bottom)"></div>
            <button v-if="question.extraHtml.label_comment_faire && question.extraHtml.label_comment_faire !== undefined && question.extraHtml.label_comment_faire !== null"
                    @click.stop="fillAndOpenGlobalModal(question.extraHtml)" type="button"
                    class="open-modal btn-modal global-question ici p-3"
                    data-toggle="modal"
                    :data-target="'#modal'+step+'-'+question.id">
                {{ $t(question.extraHtml.label_comment_faire) }}
            </button>
        </div>
        <div v-if="question.hasAlerts !== undefined" class="modal fade"
             :data-target-close="'#question-alert-modal'+question.id" @click.stop="closeModal"
             :id="'question-alert-modal'+question.id" tabindex="-1" role="dialog"
             aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ $t("A prendre en compte") }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p>{{question.alertMessage}}DD</p>
                    </div>
                    <div class="modal-footer">
                        <button @click.stop="closeModal" type="button" class="btn btn-secondary"
                                data-dismiss="modal"
                                :data-target-close="'#question-alert-modal'+question.id">{{ $t("J'ai compris") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import common from './mixins/common'

    export default {
        name: "ImageRadioChoice",

        props: {
            question: Object,
            questions: Object,
            step: [String, Number]
        },
        computed: {
            apiUrl() {
                return this.$store.state.apiUrl;
            }
        },
        mixins: [common],

        data() {
            return {
                showSpoiler: false
            }
        },
        created() {
        },
        methods: {

            fillAndOpenDefaultModal(answer) {
                let modal = $('#basicModal');
                modal.find('h5').text(answer.label);
                // modal.find('img').attr('src', answer.Popup);
                modal.find('.extra-popup').html(answer.Legende)
                modal.modal('show');

            },
            fillAndOpenGlobalModal(answer) {
                let modal = $('#basicModal');
                modal.find('h5').text(answer.label_comment_faire);
                modal.find('.extra-popup').html(answer.popup_comment_faire);
                modal.modal('show');

            },

            showModal(event) {
                if ($(event.target).data('target') !== 'none')
                    $($(event.target).data('target')).modal('toggle')
            },
            closeModal(event) {
                $($(event.target).data('target-close')).modal('hide')
            },
            isSelected(answer) {
                return answer.isAnswer
                if (this.answers[this.step] && this.answers[this.step][this.question.id] !== undefined && this.answers[this.step][this.question.id][0] !== undefined) {
                    return this.answers[this.step][this.question.id][0].value == answer.value;

                }
                return false
            },

            addToAnswers: function (answerIndex, answerObj, isSelected, event) {
                if (!isSelected)
                    this.showModal(event);


                const questionIndex = this.question.id;

                Object.keys(this.questions[questionIndex].answers).forEach((key) => {
                    this.questions[questionIndex].answers[key].isAnswer = false;
                });
                const answer = this.questions[questionIndex].answers[answerIndex];
                if (!isSelected) {
                    answer.isAnswer = true;
                    this.showSpoiler = answer.imageUrl;
                    this.$store.dispatch('addAnswersThenCalc', {

                        isGlobal: this.question.global !== undefined && this.question.global,
                        stepIndex: this.step,
                        questionIndex: questionIndex,
                        questionType: this.questions[questionIndex].type,
                        effectOnSvg: this.questions[questionIndex].effectOnSvg,
                        index: answerIndex,
                        label: answer.label,
                        value: answer.isAnswer ? answer.value : null,
                        variable: this.questions[questionIndex].variable || null,
                        imageUrl: answer.imageUrl,
                        icon: answer.icon,
                        imageViewer: answer.imageViewer,
                        id_familles: answer.id_familles,
                        changes: answer.changes !== undefined ? answer.changes : null,
                    });
                    this.openNextQuestion(this.step, this.questions[questionIndex].id);
                }
                /*else {
                    this.showSpoiler = false;
                    answer.isAnswer = false;
                    this.$store.dispatch('clearAnswer', {
                        stepIndex: this.step,
                        questionIndex: questionIndex,
                        questionType: this.questions[questionIndex].type,
                        effectOnSvg: this.questions[questionIndex].effectOnSvg,
                        index: answerIndex,
                        label: answer.label,
                        value: answer.isAnswer ? answer.value : null,
                        variable: this.questions[questionIndex].variable || null,
                        imageUrl: answer.imageUrl,
                        icon: answer.icon,
                        changes: answer.changes !== undefined ? answer.changes : null,
                    })
                }*/
            },

            initialize() {
                if (this.answers[this.step] && this.answers[this.step][this.question.id] !== undefined && this.answers[this.step][this.question.id][0] !== undefined) {
                    this.showSpoiler = this.answers[this.step][this.question.id][0].imageUrl || this.showSpoiler;
                }
            },
        }
    }
</script>

<style scoped>

    .col-md-6 {
        flex: 1 0 auto;
    }

    .card-body.clo-type {
        align-items: flex-start;
    }

    .type_clo-left-col {
        display: flex;
        flex-direction: column;
    }

    .type_clo-left-col .type_clo-left-col_upper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .type_clo-left-col .type_clo-left-col_lower {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .type_clo-left-col .type_clo-left-col_lower .pvc_naturelle {
        width: 48%;
        flex-grow: initial;
    }

    .type_clo-left-col .type_clo-left-col_lower .pvc_naturelle .image-placeholder {
        width: 100%;
    }

    .type_clo-left-col .type_clo-left-col_lower .image-placeholder img {
        width: 100%;
    }

    .type_clo-right, .type_clo-right > div {
        height: 100%;
        width: 100%;
    }

    img {
        max-width: 100%;
    }


    .global-question {
        border-radius: 3px;
        border: 1px solid transparent;
        box-shadow: 1px 1px 8px 1px #bebebe;
        transition: .3s;
    }

    .popup-info {
        border-radius: 3px;
        border: 1px solid transparent;
        box-shadow: 1px 1px 8px 1px #bebebe;
        transition: .3s;
    }

    .contentBottom {
        font-weight: bold;
        color: #c4c4c5;
        font-size: 18px;
    }

    img {
        height: auto;
    }


    .card-header img {
        height: 100%;
    }

    .checkbox-choice {
        flex-grow: 1;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column nowrap;
        border-radius: 3px;
        cursor: pointer;
        font-weight: bold;
        background-color: #fff;
    }

    .checkbox-choice {

    }

    .checkbox-choice p {
        margin: 10px 0 0 0;
        width: 85%;
    }

    .checkbox-choice.selected {
        font-weight: bold;
        /* background-color: #fafafa; */
    }

    button.btn-modal {
        background: none;
        font-size: 13px;
        line-height: 20px;
        padding-top: 4px;
        color: grey;
        margin-top: 6px
    }

    .checkbox-choice .modal p {
        margin: 0;
        width: 100%;
    }

    .checkbox-choice .modal img {
        width: 100%;
        display: block;
        margin: 0 auto;
        height: auto;
    }

    /*.modal {
        position: fixed;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        z-index: 100;
    }*/

    [data-tooltip] {
        position: relative;
    }

    /* Tooltip styling */
    [data-tooltip]:before {
        content: attr(data-tooltip);
        display: none;
        position: absolute;
        background: #000;
        color: #fff;
        padding: 4px 8px;
        font-size: 12px;
        line-height: 1.4;
        min-width: 260px;
        text-align: center;
        border-radius: 4px;
    }

    /* Dynamic horizontal centering */
    [data-tooltip-position="top"]:before,
    [data-tooltip-position="bottom"]:before {
        left: 50%;
        -ms-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    /* Dynamic vertical centering */
    [data-tooltip-position="right"]:before,
    [data-tooltip-position="left"]:before {
        top: 50%;
        -ms-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    [data-tooltip-position="top"]:before {
        bottom: 100%;
        margin-bottom: 10px;
    }


    /* Tooltip arrow styling/placement */
    [data-tooltip]:after {
        content: '';
        display: none;
        position: absolute;
        width: 0;
        height: 0;
        border: solid transparent;
    }

    /* Dynamic horizontal centering for the tooltip */
    [data-tooltip-position="top"]:after {
        left: 50%;
        margin-left: -6px;
        margin-bottom: 4px;
    }

    /* Dynamic horizontal centering for the tooltip */
    [data-tooltip-position="top"]:after {
        left: 50%;
        margin-left: -6px;
        margin-bottom: 4px;
    }

    [data-tooltip-position="top"]:after {
        bottom: 100%;
        border-width: 6px 6px 0;
        border-top-color: #000;
    }

    /* Show the tooltip when hovering */
    [data-tooltip]:hover:before,
    [data-tooltip]:hover:after {
        display: block;
        z-index: 50;
    }

    @media screen and (max-width: 767px) {
        .card-body.clo-type > div {
            width: 100%;
        }
        .card-body.clo-type {
            flex-direction: column-reverse;
        }
    }

    @media (min-width: 1200px) {
        .modal-dialog {
            max-width: 680px;
            margin: 1.875rem auto;
        }
    }
</style>
