<template>
	<div id="FamilyCard" :class="{partenaire: isPartenaire}">
		<div class="picto">
			<img
				:src="apiUrl + '/' + link.picto"
				alt="Logo"
			/>
		</div>

		<router-link
			class="image"
			v-if="!link.lien_externe"
			:to="'/constructor/' + link.Prefix + '/etape1'"
		>
			<img :src="apiUrl + '/' + link.Image" />
		</router-link>
		<a
			class="image"
			v-if="link.lien_externe"
			:href="link.lien_externe"
			target="_blank"
		>
			<img :src="apiUrl + '/' + link.Image" />
		</a>

		<div class="name">{{ $t(link.Nom) }}</div>
		<div
			class="choice-list text-left"
			v-html="$t(link.Description)"
		/>

		<router-link
			v-if="!link.lien_externe"
			:to="'/constructor/' + link.Prefix + '/etape1'"
			class="button"
			@click.native="configureClick(link.Prefix)"
		>
			{{ $t("Configurer") }}
		</router-link>
		<a
			v-if="link.lien_externe"
			class="button"
			target="_blank" 
			:href="link.lien_externe"
		>
			{{ $t("Accéder") }}
		</a>
	</div>
</template>

<script>
import { trackUserData } from "../lib/userTracker"

export default {
	name: 'FamilyCard',
	props: {
		link: {
			type: Object,
			default () {
				return {}
			}
		},
		apiUrl: {
			type: String,
			default: ''
		},
		isPartenaire: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {}
	},
	methods: {
		configureClick(linkPrefix) {
			trackUserData(linkPrefix)
	    }
	}
}
</script>

<style lang="scss" scoped>
#FamilyCard {

	position: relative;
	width: 270px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 5px;
	background: white;
	padding: 15px;

	&.partenaire {
		background: #585858;
		color: white;

		.button {
			background: white;
			color: #003B85;
			border: none;
		}
	}

	.picto {
		position: absolute;
		top: 5px;
		right: 5px;

		img {
			width: 30px;
		}
	}

	.image {

		max-height: 160px;
		min-height: 160px;

		img {
			width: 230px;
			max-height: 160px;
			min-height: 160px;
		}
	}

	.name {
		text-transform: uppercase;
		min-height: 41px;
	}

	.button {
		width: 230px;
		height: 40px;
		background: #003B85;
		color: white;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 5px;
		text-transform: uppercase;
		border: 1px solid #003B85;
		transition: 0.2s;
		font-weight: 600;
		margin-top: auto;

		&:hover {
			border: 1px solid #003B85;
			background: white;
			color: #003B85;
			text-decoration: none!important;
			transition: 0.2s;
		}
	}
}

@media only screen and (max-width: 600px) {
	#FamilyCard {
		width: 135px;

		.image {
			max-height: 77px;
			min-height: 77px;

			img {
				width: 110px;
				max-height: 77px;
				min-height: 77px;
			}
		}

		.button {
			width: 120px;
		}
	}
}
</style>
