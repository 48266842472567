<template>
    <div class="card" v-bind:class="[question.type]">
        <!-- <div class="card-header" :id="'header-'+step+'Question'+question.id" data-toggle="collapse"
             :data-target="'#collapseStep'+step+'Question'+question.id"
             v-bind:aria-expanded="question.openByDefault ? 'true' : 'false'"
             aria-controls="collapseOne"
             :class="{'answered' : showSpoiler!==false}"
        >
            <h5 class="mb-0">
                <button class="btn btn-link">
                    {{ $t(question.label) }}
                </button>
                <div class="recap" v-show="showSpoiler != false">
                    {{ showSpoiler }}
                </div>
            </h5>
        </div> -->
        <div :id="'collapseStep'+step+'Question'+question.id" class="collapse"
             :class="{show : question.openByDefault}"
             :aria-labelledby="'collapseStep'+step+'Question'+question.id" data-parent="#main-form">
            <div class="card-body">
                <div class="checkbox-choice">
                    {{ $t(question.label) }}
                    <div v-if="question.extraHtml !== undefined && question.extraHtml.content_top !== null !== undefined && question.extraHtml.content_top !== null"
                         v-html="$t(question.extraHtml.content_top)"></div>
                    <label :class="{ full: answerValue }">
                        <input type="number"
                               v-model="answerValue" :placeholder="$t(question.label)">
                    </label>
                </div>

            </div>
            <div class="extra" v-if="question.extraHtml !== undefined && question.extraHtml.content_bottom !== null !== undefined && question.extraHtml.content_bottom !== null"
                 v-html="$t(question.extraHtml.content_bottom)"></div>
        </div>
    </div>
</template>

<script>
    import common from './mixins/common'

    export default {
        name: "NumericField",

        props: {
            question: Object,
            questions: Object,
            step: [String, Number]
        },

        mixins: [common],

        data: function () {
            return {
                showSpoiler: false,
                answerValue: ''
            }
        },

        watch: {
            answerValue(val, oldVal) {
                if (this.answers[this.step] && this.answers[this.step][this.question.id]) {
                    Object.keys(this.answers[this.step][this.question.id]).forEach((idx) => {
                        if (val && val !== this.answers[this.step][this.question.id][idx].value) {
                            this.addToAnswers(val);
                        }
                    });
                }else {
                    this.addToAnswers(val)
                }
            }
        },
        mounted() {
            if (this.answers[this.step] && this.answers[this.step][this.question.id]) {
                Object.keys(this.answers[this.step][this.question.id]).forEach((idx) => {
                    this.answerValue = this.answers[this.step][this.question.id][idx].value
                    this.showSpoiler = this.answers[this.step][this.question.id][idx].value
                });
            }
        },

        methods: {
            addToAnswers: function (answerValue) {
                const questionIndex = this.question.id;
                const question = this.questions[questionIndex];

                const answerIndex = 0;
                const answer = question.answers[answerIndex];

                answer.isAnswer = true;

                this.showSpoiler = answerValue;

                this.$store.dispatch('addAnswersThenCalc', {
                    isGlobal : this.question.global !== undefined && this.question.global,

                    stepIndex: this.step,
                    questionIndex: questionIndex,
                    questionType: question.type,
                    effectOnSvg: question.effectOnSvg,
                    index: answerIndex,
                    label: answerValue,
                    value: answerValue,
                    variable: question.variable || null
                });
            },

            initialize() {
                Object.keys(this.questions[this.question.id].answers).forEach((answerKey) => {
                    const answer = this.questions[this.question.id].answers[answerKey];

                    if (answer.isAnswer) {
                        this.showSpoiler = answer.value;
                    }
                });
            },
        }
    }
</script>

<style scoped>
    @import "styles/text-field.css";

    .NumericField .card-body{
        flex-direction: column ;
        align-items: center;
        justify-content: space-around;
    }

    .extra{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
</style>