<template>
    <div class="main-form" id="main-form">
<!--        <div class="mask" :class="{'show-dep-modal' : showDepModal}"></div>-->
        <div class="step-group">

            <StepContainer :step="$store.state.steps[$store.state.currentStep]" :steps="$store.state.steps" />

            <!-- <div v-for="(step, stepKey) in steps" class="list-item list-move" :id="stepKey+'--'+currentStep"
                :key="stepKey"
                :class="{
                    'list-enter' : parseInt(stepKey)=== parseInt(currentStep),
                    'list-leave-to-left' : parseInt(stepKey)< parseInt(currentStep),
                    'list-leave-to-right' : parseInt(stepKey)> parseInt(currentStep)
                }">

                <div v-for="(question, index) in step.questions"
                    :key="index"
                    v-if="!hideQuestion(question)"
                    class="card-container"
                    :id="'header-'+stepKey">
                    <component
                        :is="question.type"
                        :question="question"
                        :questions="step.questions"
                        :step="step.id"
                        :key="step.id+reload"
                    >
                    </component>
                </div>
                <ChangeStep :step="parseInt(stepKey)" :maxNumberOfSteps="Object.keys(steps).length"></ChangeStep>
            </div> -->
        </div>
        <div class="modal fade" id="basicModal" tabindex="-1" role="dialog"
             aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title"></h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <img class="modal-img img-responsive" src="">
                        <div class="extra-popup"></div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t("J'ai compris") }}</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="popup-valid" id="global-confirm-modal">
            <h4>{{ $t("Attention") }}</h4>
            <p></p>
            <div class="button-list">
                <button class="btn" @click="toggleGlobalConfirm()">{{ $t("Annuler") }}</button>
                <button class="btn main" id="confirm-action">{{ $t("Confirmer") }}</button>
            </div>
        </div>
    </div>
</template>


<script>
    import StepContainer from '@/components/StepContainer.vue'
    import ChangeStep from '@/components/ChangeStep.vue'
    import ColorRadio from '@/components/form-groups/ColorRadio.vue'
    import ImageRadioChoice from '@/components/form-groups/ImageRadioChoice.vue'
    import MultipleImageRadioChoice from '@/components/form-groups/MultipleImageRadioChoice.vue'
    import MultipleImageRadioChoiceSelected from '@/components/form-groups/MultipleImageRadioChoiceSelected.vue'
    import MultipleInputsBasic from '@/components/form-groups/MultipleInputsBasic.vue'
    import NumericField from '@/components/form-groups/NumericField.vue'
    import Recapitulatif from '@/components/Recapitulatif.vue'
    import SelectField from '@/components/form-groups/SelectField.vue'
    import PoseChoice from '@/components/form-groups/PoseChoice.vue'
    import TextRadioChoice from '@/components/form-groups/TextRadioChoice.vue'
    import TextRadioChoiceSelected from '@/components/form-groups/TextRadioChoiceSelected.vue'
    import TextAreaField from '@/components/form-groups/TextAreaField.vue'
    import TextInput from '@/components/form-groups/TextInput.vue'
    import MesureGoogleMap from '@/components/form-groups/MesureGoogleMap.vue'
    import TypeCloChoice from '@/components/form-groups/TypeCloChoice.vue'
    import MultipleQuantityChoice from '@/components/form-groups/MultipleQuantityChoice.vue'
    import RadioQuantityChoice from '@/components/form-groups/RadioQuantityChoice.vue'
    import MultipleImagesInputChoice from '@/components/form-groups/MultipleImagesInputChoice.vue'
    import MultipleImagesInputGoogleMapChoice from '@/components/form-groups/MultipleImagesInputGoogleMapChoice.vue'
    import ConfirmStep from "@/components/form-groups/ConfirmStep";

    import questionDependenciesAreMet from '@/lib/questionDependenciesAreMet'
    import stepDependenciesAreMet from "../lib/stepDependenciesAreMet";

    export default {
        name: 'MainForm',

        components: {
            'ChangeStep': ChangeStep,
            'ColorRadio': ColorRadio,
            'ImageRadioChoice': ImageRadioChoice,
            'MultipleImageRadioChoice': MultipleImageRadioChoice,
            'MultipleInputsBasic': MultipleInputsBasic,
            'NumericField': NumericField,
            'SelectField': SelectField,
            'PoseChoice': PoseChoice,
            'TextRadioChoice': TextRadioChoice,
            'MultipleImageRadioChoiceSelected': MultipleImageRadioChoiceSelected,
            Recapitulatif,
            TextAreaField,
            TextRadioChoiceSelected,
            TextInput,
            MesureGoogleMap,
            TypeCloChoice,
            MultipleQuantityChoice,
            RadioQuantityChoice,
            MultipleImagesInputChoice,
          MultipleImagesInputGoogleMapChoice,
          ConfirmStep,
          StepContainer
        },

        mounted() {
            this.country = this.countries[0];
            if (this.$store.state.country === "") {
                this.toggleDepModal()
            }
        },
        destroyed() {
            document.body.classList.remove('bodyOverflow');
        },
        data: function () {
            return {
                departement: "",
                country: "",
                showDepModal: false,
                showDepField: false,
                hasError: false,

            }
        },
        computed: {
            countries() {
                // console.log(this.$store.state.countries)
                return this.$store.state.countries;
            },
            answers() {
                return this.$store.state.answers;
            },

            steps() {
                return this.$store.state.steps;
            },

            currentStep() {
                return parseInt(this.$route.params.step);
            },
            reload() {

                return this.$store.state.refreshInc
            },
            isRecap() {
                return parseInt(this.$route.params.step) === Object.keys(this.$store.state.steps).length
            }
        },

        watch: {
            countries(val, old){
                if(old.length === undefined && val.length !== 0 ){
                    this.country = this.countries[0];
                }
            },
            country(val){
                // console.log(val)
            },

            '$route'() {
                if (!this.checkIfStepIsHidden()) {
                    setTimeout(() => {
                        this.openFirstQuestion();
                    }, 1200)
                    
                }
            },
            isRecap(val, oldval) {
                if (val) {
                    if (!this.$store.state.hitRecap) {
                        this.$store.commit('setHitRecap', true)
                    }
                    $(window).scrollTop(0);
                    document.body.classList.add('bodyOverflow');
                } else {
                    document.body.classList.remove('bodyOverflow');
                }
            }
        },

        methods: {
            toggleGlobalConfirm() {
                const modal = $('#global-confirm-modal');
                modal.find('p').text("");
                modal.find('#confirm-action').off();
                modal.hide();
            },
            toggleDepModal() {
                this.showDepModal = !this.showDepModal;
            },
            confirmDep() {
                if(this.country.pays !== undefined){
                    this.$store.dispatch("commitCountry", this.country.pays)
                    if(this.country !== undefined && !this.country.shouldDisplayDeps){
                        return this.toggleDepModal()
                    }
                }

                if (this.departement.length === 2 && !isNaN(this.departement)) {
                    this.$store.dispatch('commitDepartement', this.departement);
                    this.toggleDepModal()
                }

            },
            checkIfStepIsHidden() {
                const hidden = !stepDependenciesAreMet(this.steps[this.currentStep], this.answers);
                //console.log(this.$store.state.Object.keys(this.$store.state.steps).length)
                if (hidden /*&& parseInt(this.currentStep) + 1 !==  Object.keys(this.$store.state.steps).length*/) {
                    this.$router.push({path: 'etape' + (parseInt(this.currentStep) + 1)});
                }

                return hidden;
            },

            hideQuestion(question) {
                return !questionDependenciesAreMet(question, this.answers);
            },

            openFirstQuestion() {
                $('#header-' + this.currentStep)
                    .find('[data-toggle="collapse"].collapsed')
                    .first()
                    .click();
            }
        }
    }
</script>

<style>
    .age_choice_wrap {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 10px;
    }

    .age_choice_wrap label {
        display: block;
    }

    .age_choice_wrap label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .modal-img {
        max-height: 600px;
        width: auto;
        margin: auto;
        max-width: 100%;
    }

    .popup-valid.show-dep-modal, .mask.show-dep-modal, .displayNone.error {
        display: block;
    }

    .displayNone {
        display: none;
    }

    .error {
        color: red;
    }

    div.popup-valid {
        display: none;
        /* width: 40%; */
        /* margin: 50px auto; */
        /* box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.4); */
        /*box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);*/ /*OLD */
        border:1px solid #004494;
        transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
        background-color: #ffffff;
        padding: 60px 25px;
        text-align: center;
        font-family: sans-serif;
        position: fixed;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        /*border: 1px solid #efefef;*/
        border-radius: 3px;
        z-index: 100;
    }

    .mask {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        margin-left: -2.5%;
        z-index: 100;
        display: none;
    }

    .popup-valid:hover {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }

    .popup-valid .title {
        font-size: 24px;
        margin-bottom: 20px;
    }

    .popup-valid form {
        width: 90%;
        margin: 0 auto;
    }

    .popup-valid form input {
        height: 35px;
        width: 100%;
        padding: 5px 10px;
        display: block;
        box-sizing: border-box;
    }

    .popup-valid form select {
        height: 35px;
        width: 100%;
        padding: 5px 10px;
        display: block;
        box-sizing: border-box;
        margin-bottom: 15px;
    }

    .popup-valid .button-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
    }

    .popup-valid .button-list .btn {
        display: inline-block;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border: 1px solid transparent;
        padding: 10px 20px;
        font-size: 1rem;
        line-height: 1.5;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        background-color: #d8d8d8;
        border-radius: 0;
    }

    #global-confirm-modal .button-list {
        display: flex;
        justify-content: space-around;
    }

    #global-confirm-modal .btn {
        padding: 10px 20px;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5;

    }

    #global-confirm-modal {
        max-width: 518px;
    }

    .popup-valid .button-list .btn:hover {
        cursor: pointer;
    }

    .popup-valid .button-list .btn.main {
        text-transform: uppercase;
        font-weight: bold;
        border-radius: 3px;
        padding: 10px 20px;
    }

    .popup-valid .button-list .btn:hover.main {
        transition: all .3s;
        background-color: #c4c4c4;
    }

    .popup-valid .button-list .btn:hover {
        background-color: #c4c4c4;
    }

    textarea {
        min-height: 175px;
        width: 100%;
    }

    .main-form {
        display: block;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* padding: 10px; */
        height: 100%;
    }

    .main-form::-webkit-scrollbar {
        width: 1em;
    }

    .main-form::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    .main-form::-webkit-scrollbar-thumb {
        background-color: #7d8b8c;
        outline: 1px solid slategrey;
    }

    button.btn-link {
        text-decoration: none;
        color: #fff;
        position: relative;
        padding: 12px;
        font-weight: 500;
    }

    button.btn-link::before {
        content: "";
        width: 0;
        height: 2px;
        background: #fff;
        bottom: 7px;
        left: 0;
        position: absolute;
        transition: 0.4s ease-in-out;
    }

    button.btn-link:hover::before {
        width: 100%;
    }

    button.btn-link:hover,
    button.btn-link:active,
    button.btn-link:focus {
        text-decoration: none;
        color: #fff;
    }

    .card-container {
    }

    .card-header:first-child {
        padding: 0;
        border-radius: 0;
    }

    .card-header {
        background: #c4c4c5;
    }

    .card-header[aria-expanded="true"]:not(.answered) {
        background-color: white;
        border:1px solid #004494;
    }


    .card-header.answered > h5 > button {
        color: #fff;
    }

    .card-header[aria-expanded="true"] > h5 > button {
        color:#004494;
    font-weight:bold;
    }
    .card-header.answered[aria-expanded="true"] > h5 > button {
        color: #fff;

        font-weight:bold;
    }

    .card-header[aria-expanded="true"] > h5 > button.btn-link::before {
        content: "";
        width: 0;
        height: 2px;
        background: #fff;
        bottom: 7px;
        left: 0;
        position: absolute;
        transition: 0.4s ease-in-out;
    }

    .card-header[aria-expanded="true"] > h5 > button.btn-link:hover::before {
        width: 100%;
    }

    div.card {
        width: 100%;
        border-radius: 0;
        /*margin-bottom: 2px;*/
        box-shadow: none;
    }

    /* .card .checkbox-choice img {
         opacity: 0.7;
     }*/

.card .selected img {
	opacity: 1;
}

/* .card-container:not(:last-child) h5 {
        border-bottom: 1px solid #fff;
    } */

.card-body {
	display: flex;
	justify-content: space-around;
	flex-flow: wrap row;
	padding: 20px 10px 10px 10px !important;
	align-items: baseline;
}

/*
        .card-body > div:not(.row),
        .card-body > div:not(.color-choice){
            width: 26%;
        }*/

.step-group {
	width: 100%;
	height: 100%;
	min-height: 100vh;
	max-height: 100vh;
	position: relative;
}

.list-item {
	opacity: 1;
	z-index: 0;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	padding-right: 40px;
	overflow-y: auto;
	overflow-x: hidden;
}

.list-move {
	transition: all 1s;
}

.list-enter-active,
.list-leave-active {
	transition: all 1s;
}

.list-enter {
	transform: translate(0%, 0);
	opacity: 1;
}

.list-leave-to-left {
	opacity: 0;
	transform: translate(-100%, 0);
}

.list-leave-to-right {
	opacity: 0;
	transform: translate(100%, 0)
}

.beforeStarting {
	margin: 0 0 1.735em 5%;
	width: 90%;
}

h5 {
	position: relative;
}

h5>button.btn-link {
	font-size: 0.9em;
}

h5>.recap {
	position: absolute;
	padding: 5px;
	color: #fff;
	right: 5px;
	top: 50%;
	transform: translateY(-50%);
	font-size: 1em;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.checkbox-choice button,
.checkbox-choice li,
.checkbox-choice>div {
	cursor: pointer;
}

@media screen and (min-width: 1023px) and (max-width: 1220px) {
	h5>button.btn-link {
		font-size: 0.7em;
	}
}

@media screen and (max-width: 1023px) {
	.bodyOverflow {
		overflow-y: hidden;
	}

	.list-item {
		padding-right: 0;
	}
}

@media screen and (max-width: 767px) {
	div.popup-valid {
		width: 95%;
	}

	.card-body>div {
		width: 50%;
	}

	#collapseStep3Question1 .card-body>div {
		width: 80%;
	}

	#header-3Question1 h5>.recap {
		top: 66%;
	}

	#header-3Question1 h5>.recap>ul {
		font-size: 12px;
	}

	h5>button.btn-link {
		font-size: 0.8em;
	}

	h5>button.btn-link:before {
		content: none !important;
	}

	.list-item {
		margin-bottom: 20px;
	}

	#collapseStep2Question5 img {
		max-width: 120px;
		height: 100px;
	}
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}
</style>


