var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"final-recap",staticStyle:{"display":"block !important"}},[_c('div',{staticStyle:{"display":"none"}},[_vm._v(_vm._s(_vm.history))]),_c('div',{staticClass:"recap-content"},[_c('div',{staticClass:"recap-header text-left"},[_c('h4',[_vm._v(_vm._s(_vm.$t("Récapitulatif de votre projet")))])]),_c('div',{staticClass:"recap-inner"},[_vm._l((_vm.products.filter(
          function (prod) { return !prod.deleted; }
        )),function(product,product_index){return (!product.deleted)?_c('div',{key:product_index,staticClass:"history-wrapper",attrs:{"id":product_index}},[(product.history !== undefined && product.history.length > 1)?_c('div',{staticClass:"history-header"},[_c('h5',{staticClass:"d-flex align-items-center"},[_vm._v(" "+_vm._s(_vm.$t(_vm.getTitle(product.history)))+" ")]),(_vm.pricesByProds[product_index])?_c('h5',[_vm._v(" PU : "+_vm._s(product.price .toFixed(2) .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 "))+" € "+_vm._s(_vm.$t("HT"))+" ")]):_vm._e(),_c('div',{staticClass:"controls"},[(
                _vm.family.image_recap_delais &&
                  _vm.$store.state.family.Prefix !== 'Bae'
              )?_c('span',{on:{"click":function($event){return _vm.fillAndOpenDefaultModal(_vm.family.image_recap_delais)}}},[_vm._v(_vm._s(_vm.$t("Délais")))]):_vm._e(),(_vm.family.image_recap_garanties)?_c('span',{on:{"click":function($event){return _vm.fillAndOpenDefaultModal(_vm.family.image_recap_garanties)}}},[_vm._v(_vm._s(_vm.$t("Garanties")))]):_vm._e(),_c('span',{on:{"click":function($event){$event.stopPropagation();_vm.openGlobalModal(
                  _vm.$t(
                    'Toute modification peut entrainer des changements techniques obligeant à sélectionner à nouveau les caractéristiques du produit'
                  ),
                  _vm.editProduct,
                  product_index
                )}}},[_vm._v(_vm._s(_vm.$t("Modifier")))]),_c('span',{on:{"click":function($event){$event.stopPropagation();return _vm.openGlobalModal(
                  'Êtes-vous sûr de vouloir supprimer cet élément ?',
                  _vm.deleteProduct,
                  product_index
                )}}},[_vm._v(_vm._s(_vm.$t("Supprimer")))])])]):_vm._e(),(_vm.products.length > 1)?_c('div',{staticClass:"history-content-wrapper",class:{
            active:
              _vm.getRefsHistory(product, product_index).length > 3 ||
              product.ref.length <= 3,
          }},[(product.ref.length > 1
                && _vm.$store.state.family.Prefix === 'Bae')?_c('p',{staticStyle:{"color":"red","margin-bottom":"0.75rem"}},[_vm._v(" "+_vm._s(_vm.$t("veuillez_cliquer_voir_plus"))+" ")]):_vm._e(),_vm._l((_vm.getRefsHistory(product, product_index)),function(ref,index){return _c('span',{key:ref.ref + index,staticClass:"history-content"},[_c('label',[(ref.quant)?_c('span',[_vm._v(_vm._s(ref.quant)+"x ")]):_vm._e(),_vm._v(_vm._s(_vm.$t(ref.label)))]),(ref.quantity)?_c('label',[_vm._v(_vm._s(" x " + ref.quantity + " : "))]):_vm._e(),_c('span',{staticClass:"history-comma"},[_vm._v(" "+_vm._s(ref.ref)+" ")]),(_vm.$store.state.family.Prefix === 'Bae'
              || _vm.$store.state.family.Prefix === 'tra'
              || _vm.$store.state.family.Prefix === 'Cre'
              )?_c('a',{staticStyle:{"position":"absolute","right":"10px"},attrs:{"target":"_blank","href":_vm.seeMoreGenerateTranslatedUrl(ref.label)}},[_vm._v(_vm._s(_vm.$t("Voir plus")))]):_vm._e()])}),(product.ref.length > 3)?_c('div',{staticClass:"toggle_details",on:{"click":function($event){$event.stopPropagation();return _vm.togglebyIndex(product_index)}}},[_c('span',{staticClass:"arrow"},[_vm._v(">>")])]):_vm._e()],2):_c('div',{staticClass:"history-content-wrapper",class:{
            active: _vm.getRefsHistory(product, product_index).length > 3,
          }},[(product.ref.length > 1
               && _vm.$store.state.family.Prefix === 'Bae')?_c('p',{staticStyle:{"color":"red","margin-bottom":"0.75rem"}},[_vm._v(" "+_vm._s(_vm.$t("veuillez_cliquer_voir_plus"))+" ")]):_vm._e(),_vm._l((_vm.getRefsHistory(product, product_index)),function(ref,index){return _c('span',{key:index,staticClass:"history-content"},[_c('label',[(ref.quant)?_c('span',[_vm._v(_vm._s(ref.quant)+"x ")]):_vm._e(),_vm._v(_vm._s(ref.label))]),(ref.quantity !== undefined)?_c('label',{staticClass:"history-content--quantity"},[_vm._v(_vm._s(" x " + ref.quantity + " : "))]):_vm._e(),_c('span',{staticClass:"history-comma"},[_vm._v(" "+_vm._s(ref.ref)+" ")]),(_vm.$store.state.family.Prefix === 'Bae'
              || _vm.$store.state.family.Prefix === 'tra'
              || _vm.$store.state.family.Prefix === 'Cre'
              )?_c('a',{staticStyle:{"position":"absolute","right":"10px"},attrs:{"target":"_blank","href":_vm.seeMoreGenerateTranslatedUrl(ref.ref)}},[_vm._v(_vm._s(_vm.$t("Voir plus")))]):_vm._e()])}),(_vm.getRefsHistory(product, product_index).length > 1)?_c('div',{staticClass:"toggle_details",on:{"click":function($event){$event.stopPropagation();return _vm.togglebyIndex(product_index)}}},[_c('span',{staticClass:"arrow"},[_vm._v(">>")])]):_vm._e()],2)]):_vm._e()}),_vm._m(0),_c('div',{staticClass:"history-wrapper"},[_c('div',{staticClass:"history-header",staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(_vm.$t("Rendez-vous dans votre espace client sur"))+" "),_c('a',{attrs:{"target":"_blank","href":_vm.$t('espace-client')}},[_vm._v("www.pommier.eu")]),_vm._v(" "+_vm._s(_vm.$t("pour finaliser votre commande :"))),_c('br'),_vm._v(" "+_vm._s(_vm.$t("- Consultez vos tarifs et la disponibilité des références"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t( "- Passez une précommande sur le site ou contacter votre interlocuteur POMMIER" ))),_c('br')])]),_c('a',{staticClass:"btn btn_type3 ",attrs:{"href":"https://www.pommier.eu/customer"}},[_vm._v(_vm._s(_vm.$t("Consultez vos autres projets")))]),(_vm.siteInfos.bandeau)?_c('div',{staticClass:"col-md-12 col-sm-12S col-xs-10 row bandeau"},[_c('div',{staticClass:"row",domProps:{"innerHTML":_vm._s(_vm.siteInfos.bandeau)}})]):_vm._e()],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"history-logo "},[_c('img',{attrs:{"src":"img/LOGO-Ecustomer.png","alt":""}})])}]

export { render, staticRenderFns }