<template>
  <div class="card" v-bind:class="[question.type]">
    <!-- <div
      class="card-header answered"
      :id="'header-' + step + 'Question' + question.id"
      data-toggle="collapse"
      :data-target="'#collapseStep' + step + 'Question' + question.id"
      v-bind:aria-expanded="question.openByDefault ? 'true' : 'false'"
      aria-controls="collapseOne"
      :class="{ answered: showSpoiler !== false }"
    >
      <h5 class="mb-0">
        <button class="btn btn-link">
          {{ $t(question.extraHtml.label) }}
        </button>
        <div class="recap">
          <span
            v-for="(answer, answerIndex) in question.answers"
            :key="answerIndex"
          >
            <img v-if="answer" :src="answer.imageUrl" alt="Image" />
          </span>
        </div>
      </h5>
    </div> -->
    <div
      :id="'collapseStep' + step + 'Question' + question.id"
      class="collapse"
      v-bind:class="{ show: question.openByDefault }"
      :aria-labelledby="'collapseStep' + step + 'Question' + question.id"
      data-parent="#main-form"
    >
      <div
        class="extrahtml"
        v-if="
          question.extraHtml !== undefined &&
          (question.extraHtml.content_top !== null) !== undefined &&
          question.extraHtml.content_top !== null
        "
        v-html="$t(question.extraHtml.content_top)"
      ></div>
      <button
        v-if="question.extraHtml.label_comment_faire !== null"
        @click.stop="fillAndOpenGlobalModal(question.extraHtml)"
        type="button"
        class="open-modal btn-modal general-question"
        data-toggle="modal"
        :data-target="'#modal' + step + '-' + question.id"
      >
        {{ $t(question.extraHtml.label_comment_faire) }}
      </button>
      <div class="card-body">
        <div
          v-for="(answer, answerKey) in question.answers"
          class="checkbox-choice selected"
          :data-permanent="isPermanent()"
          :data-size="colonneSize(question)"
          :key="answerKey"
        >
          <div
            class="image-placeholder"
            :data-tooltip="answer.alt"
            data-tooltip-position="top"
          >
            <img
              :class="['img-' + step + '-' + question.id + '-' + answerKey]"
              :src="answer.imageUrl"
              alt=""
            />
          </div>
          <p>{{ $t(answer.label) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import common from "./mixins/common";

export default {
  name: "MultipleImageRadioChoiceSelected",

  props: {
    question: Object,
    questions: Object,
    step: [String, Number],
  },
  created() {},
  watch: {
    $route(to, from) {
      if (to.params.step == this.step) {
        this.addToAnswers();
      }
    },
  },
  mixins: [common],

  data() {
    return {
      selected: [],
      showSpoiler: false,
    };
  },

  computed: {
    steps() {
      return this.$store.state.steps;
    },
  },

  methods: {
    fillAndOpenGlobalModal(answer) {
      let modal = $("#basicModal");
      modal.find("h5").text(answer.label_comment_faire);
      modal.find(".extra-popup").html(answer.popup_comment_faire);
      modal.modal("show");
    },

    colonneSize(question) {
      let colonnes = question.blocsAlign
        ? question.blocsAlign
        : question.extraHtml.colonnes;
      let key = this.question.rang;
      if (Array.isArray(colonnes)) {
        return colonnes[key];
      } else {
        return colonnes;
      }
    },
    isPermanent() {
      // console.log('questions:'+question);
      return this.question.answers.length > 5;
      // return question.blocsAlign
    },

    addToAnswers: function () {
      const questionIndex = this.question.id;
      const question = this.questions[questionIndex];

      const answers = {
        stepIndex: this.step,
        questionIndex: questionIndex,
        answers: {},
      };

      Object.keys(question.answers).forEach((answerIndex) => {
        const answer = question.answers[answerIndex];
        answers.answers[answerIndex] = {
          stepIndex: this.step,
          questionIndex: questionIndex,
          questionType: this.questions[questionIndex].type,
          effectOnSvg: this.questions[questionIndex].effectOnSvg,
          index: answerIndex,
          value: answer.value,
          variable: question.variable,
          name: answer.label,
          changes: answer.changes !== undefined ? answer.changes : null,
        };
        this.selected[answerIndex] = answer;
      });

      this.$store.dispatch("addMultipleAnswers", answers);
    },
    initialize() {},
  },
};
</script>

<style scoped>
.recap span {
  height: 100%;
}

[data-tooltip] {
  position: relative;
}

/* Tooltip styling */
[data-tooltip]:before {
  content: attr(data-tooltip);
  display: none;
  position: absolute;
  background: #000;
  color: #fff;
  padding: 4px 8px;
  font-size: 14px;
  line-height: 1.4;
  min-width: 250px;
  text-align: center;
  border-radius: 4px;
  width: 150%;
}

/* Dynamic horizontal centering */
[data-tooltip-position="top"]:before {
  left: 50%;
  -ms-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

[data-tooltip-position="top"]:before {
  bottom: 100%;
  margin-bottom: 10px;
}

[data-tooltip]:after {
  content: "";
  display: none;
  position: absolute;
  width: 0;
  height: 0;
  border: solid transparent;
}

[data-tooltip-position="top"]:after {
  left: 50%;
  margin-left: -6px;
  margin-bottom: 4px;
}

[data-tooltip-position="top"]:after {
  bottom: 100%;
  border-width: 6px 6px 0;
  border-top-color: #000;
}

/* Show the tooltip when hovering */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  display: block;
  z-index: 100;
}

.checkbox-choice[data-size="6"] {
  width: 15%;
}

.checkbox-choice[data-size="4"] {
  width: 25%;
}
.checkbox-choice[data-size="3"] {
  width: 30%;
}

.checkbox-choice[data-size="2"] {
  width: 50%;
}

img {
  height: 100px;
}

img[class*="img-1-2-"] {
  width: 200px;
  height: auto;
}

.card-header img {
  height: 100%;
}

.checkbox-choice {
  flex-grow: initial;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
}

.checkbox-choice p {
  margin: 10px 0 0 0;
  max-width: 85%;
}

.checkbox-choice.selected {
  font-weight: bold;
}
</style>
