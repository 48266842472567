export default (answers) => {
    let list = [];

    if (answers) {
        Object.keys(answers).forEach((stepKey) => {
            const step = answers[stepKey];

            Object.keys(step).forEach((questionKey) => {
                const question = step[questionKey];

                Object.keys(question).forEach((answerKey) => {
                    list.push(question[answerKey]);
                });
            });
        });
    }
    return list;
};
