import getAnswers from "./getAnswers";

export default (variable, value, answers) => {
    let found = null;
    const answerList = getAnswers(answers);

    answerList.forEach((answer) => {
        let pool = [];

        if (value && typeof value === 'object') {
            Object.keys(value).forEach((key) => {
                pool.push(value[key]);
            })
        } else {
            pool = [value];
        }

        pool.forEach((value) => {
            if (answer.variable === variable && answer.value === value) {
                found = answer;
            }
        });
    });

    return found;
};